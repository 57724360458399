
import React from "react";
import {ModuleService} from "../../_services/ModuleService";

class ModulePairingMemory extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        memoryCards: [],
        duplicateMemoryCards: [],
        randomizedMemoryCards: [],
        finalizedMemoryCards: [],
        openedMemoryCards: [],
        memory : [],
         time : 0,
        wrongOpenedPairs : 0,
        startDate  : new Date(),
      }
     
    }
    
     componentDidMount() {
       fetch("/pairingmemory/find/" + localStorage.getItem("activeModuleId"))
                    .then(response => response.json())
                    .then(items => {


                       this.setState({memoryCards : items});
                       this.start();
                    });
        }

    handleClick(name,pairIndex,index){
      if(this.state.openedMemoryCards.length === 2){
        setTimeout(() => {
          this.check()
        },750)
      }else {
        let memoryCard = {
          pairIndex,
          index
        }
        let finalizedMemoryCards = this.state.finalizedMemoryCards
        let memoryCards = this.state.openedMemoryCards
        finalizedMemoryCards[index].close = false
        memoryCards.push(memoryCard)
        this.setState({
          openedMemoryCards: memoryCards,
          finalizedMemoryCards: finalizedMemoryCards
        })
        if(this.state.openedMemoryCards.length === 2){
          setTimeout(() => {
            this.check()
          },750)
        }
      }
    } 
    check(){
       
      let finalizedMemoryCards = this.state.finalizedMemoryCards;
    
      if((this.state.openedMemoryCards[0].name === this.state.openedMemoryCards[1].name) && (this.state.openedMemoryCards[0].index !== this.state.openedMemoryCards[1].index)){
        finalizedMemoryCards[this.state.openedMemoryCards[0].index].complete = true
        finalizedMemoryCards[this.state.openedMemoryCards[1].index].complete = true
      }else {
        finalizedMemoryCards[this.state.openedMemoryCards[0].index].close = true
        finalizedMemoryCards[this.state.openedMemoryCards[1].index].close = true
        
         this.setState({wrongOpenedPairs : this.state.wrongOpenedPairs+1});
         
         
      }
      this.setState({
        finalizedMemoryCards,
        openedMemoryCards: []
      })
     
      if (this.state.finalizedMemoryCards.length===this.state.finalizedMemoryCards.filter(card =>  card.complete===true).length){
          document.getElementById("root").classList.add("gameend-body");
          
            var endDate   = new Date();
          var seconds = (endDate.getTime() - this.state.startDate.getTime()) / 1000;

          document.getElementById("root").classList.add("gameend-body");
          var score = 2;

            /*  switch (true) {
             case (seconds < 30):
             score = 100;
             if (this.state.wrongOpenedPairs > 3)
             score = score - this.state.wrongOpenedPairs;
             break;
             case (seconds < 60):
             score = 70;
             if (this.state.wrongOpenedPairs > 3)
             score = score - this.state.wrongOpenedPairs;
             break;
             case (seconds < 90):
             score = 50;
             if (this.state.wrongOpenedPairs > 3)
             score = score - 10;
             break;
             
             default :
             if (this.state.wrongOpenedPairs < 5)
             score = 20;
             else
             score = 0;
             
             }*/

            if (localStorage.hasOwnProperty("onlineGame")) {
                this.props.saveScore(2, JSON.stringify([{"time": seconds, "wrongedPairs": this.state.wrongOpenedPairs}]), score);
            }
        
          var cards = "";
          this.state.memoryCards.map(card => {
              cards+= '<div class="pairingmemory-end-pic"  ><div class="endlabel"><label class="label-name">'+card.name+'</label></div><img    src="' + "/file/download?fileId=" + card.image1+'" /><br><img    src="' + "/file/download?fileId=" + card.image2+'" /></div>' ;
          })
            document.getElementById("playground").innerHTML = '<div style="margin : 0 auto;">'+cards+'</div>';
            if (localStorage.hasOwnProperty("onlineGame")) {
              document.getElementById("forward_module_button").classList.remove("d-none");
              this.props.saveScore(2, JSON.stringify([{"time": seconds, "wrongedPairs": this.state.wrongOpenedPairs}]), score);

          }
      }
    }
    start(){
      let finalizedMemoryCards = [];
      this.state.duplicateMemoryCards = this.state.memoryCards;
      this.state.randomizedMemoryCards = this.shuffle(this.state.duplicateMemoryCards)
      this.state.randomizedMemoryCards.map((name,index) => {
        finalizedMemoryCards.push({
          name : name.name,
          image_url : name.image1,
          close: true,
          complete: false,
          fail: false,
          pair_index : index
        })
         finalizedMemoryCards.push({
          name : name.name,
          image_url : name.image2,
          close: true,
          complete: false,
          fail: false,
          pair_index : index
        })
      })
     
     this.setState({"finalizedMemoryCards" :finalizedMemoryCards });
    }
    shuffle(array){
      let currentIndex = array.length, temporaryValue, randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array
    }
    render(){
      
      return (
        <div className="playground" id="playground">
            {
              this.state.finalizedMemoryCards.map((memoryCard, index) => {
                return <Card key={index} memoryCardIndex={index} memoryCard={memoryCard} click={() => {this.handleClick(memoryCard.image_url,memoryCard.pair_index,index)}} close={memoryCard.close} complete={memoryCard.complete}/>
              })
            }
        </div>
      )
    }
}

class Card extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
      
      }
    }
  clicked(memoryCard){
    this.props.click(memoryCard)
  }
  render(){
    return (
      <div className={"memory-card" + (!this.props.close ? ' opened' : '') + (this.props.complete ? ' matched' : '')} onClick={() => this.clicked(this.props.memoryCard)}>
        <div className="front">
        <label>{this.props.memoryCardIndex+1}</label>
        
        </div>
        <div className="back">
          <img src={"/file/download?fileId="+this.props.memoryCard.image_url}/>
        </div>
      </div>
    )
  }
}


export default ModulePairingMemory;