
import React, { Component}from "react";
import {ThemeService} from "../../_services/ThemeService";
import ModalHeader from "../components/ModalHeader"
import ModalTable from "../components/ModalTable"

class SubThemeThemeSelectModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            themes: [],
            filters : [{"value" : true , "label" : "Aktív", "key" : "active" },{"value" : false , "label" : "Inaktív","key" : "active"} ]
        }
        this.subThemeThemeModal = React.createRef();
        this.selectTheme = this.selectTheme.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    selectTheme(themeId) {
        this.props.selectTheme(themeId);
        this.hideModal();
    }
    componentDidMount() {
        this.props.onRef(this);
       /// if (this.state.user.admin) {
            fetch("/theme/allinstitution/"+localStorage.getItem("institution"))
                    .then(response => response.json())
                    .then(data => {
                    data.map(item => {
                      item.name = "<b>"+item.themeTitle+"</b>"
                  })
                        this.setState({themes: data})
                    })
       /* } else {

            ThemeService.findUserThemes(this.state.ageId, this.state.user.id)
                    .then(themes => {
                        this.setState({"themes": themes})
                    });
        }*/
    }

    hideModal() {
       
      //  document.getElementById("root").classList.remove('display-block');
        let element = document.getElementById("theme-modal");

        element.classList.add("display-none");
    }

    render() {



        return (
                <div  className="modal theme-modal display-none" id="theme-modal">
                    <section  className="admin-form-modal modal-main" >
                        <div className="" style={{width : "98%", margin : "0 auto"}}>
                           <ModalHeader title="SAJÁT TEMATIKÁK" filteritems={this.state.theme} filter={this.filter} filters={this.state.filters} hideModal={this.hideModal} />
                           
                
                            <div className="row" style={{marginTop: '70px'}}>
                            
                                <ModalTable tableName = "TEMATIKA"  itemList = {this.state.themes}  select = {this.selectTheme}/>
                           
                               
                            </div>
                        </div>
                
                    </section>
                </div>
                );
    }
}

export default SubThemeThemeSelectModal;

