import React, { Component}from "react";
import SwitchComponent from '../components/SwitchComponent'
import {UsePopup, PopupContext}  from '../../context/popup-context'
import Pagination from "./Pagination"
import OnlineStatistics from "../module/OnlineStatistics"
import ReactDOM from "react-dom";

        class Table extends Component {
    static contextType = PopupContext;
    constructor(props, context) {
        super(props, context);
        this.state = {
            totalItems: 0,
            currentPage: 0,
            itemsPerPage: 10,
            pagedItems: [],
            itemList :[]
        };


        this.checkDelete = this.checkDelete.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.delete = this.delete.bind(this);
        this.pagination = this.pagination.bind(this);
        this.getStatistics = this.getStatistics.bind(this);

    }

    checkDelete(itemId, itemName) {
        this.context.setPopup({id: itemId, name: itemName, ok: this.context.unSetPopup, title: <div style={{textAlign: "center", paddingBottom: "20px"}}><span style={{fontSize: "20px", textAlign: "center"}}> Biztosan törli? </span></div>, type: "delete", delete: this.delete});


    }
    
    getStatistics(itemId){
           window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
          let element = document.getElementsByClassName("modal");
            element[0].classList.remove("display-none");
            element[0].classList.add("display-block");
            document.body.classList.add("modal-admin-body");
          ReactDOM.render(
                React.createElement(OnlineStatistics, {itemId : itemId, type : this.props.type}),
                document.getElementById('modal-container')
                );
    }

    delete(canDelete, itemId, itemName) {
        this.context.unSetPopup();
        if (canDelete) {
            this.props.delete(itemId, itemName)
        }
    }

    changeStatus(index, id, active) {
        this.props.changeStatus(index, id, active);
    }

    componentWillReceiveProps(props) {
      
        this.setState({totalItems: props.itemList.length, itemList : props.itemList, pagedItems:props.itemList.slice(0,5)})
     
    }
    
    pagination(page,itemPerPage) {
     
       this.setState({ pagedItems: this.state.itemList.slice((page-1)*itemPerPage, page * itemPerPage)}) 
      
    }


    render() {

        return (
                <div style={{width : "90%"}}>
                <div >
                 <div id="modal-container" className="modal admin-modal quiz-modal display-none"></div>
                    <table class="table table-dark  " id="muzped-admin-table">
                        <thead>
                            <tr>
                                <th scope="col" >{this.props.tableName} <b>ÁLLAPOT</b></th>
                                <th scope="col">{this.props.tableName} <b>Megnevezés</b></th>
                                <th scope="col">{this.props.tableName} <b>Műveletek</b></th>
                
                            </tr>
                        </thead>
                        <tbody>
                
                            {this.state.pagedItems.map((item, index) =>
                                        <tr key={item.id} className={"search-tr " + (!item.active ? "active-table-row" : "")}>
                                            <td width="350px" height ="90px"  className="list-table-left">  <SwitchComponent id={item.id} active={item.active} index={index} changeStatus = {this.changeStatus} />
                                    <a className="" className="blue-link table-link" > Megosztás</a>
                                    </td>
                                    <td width="400px" height ="90px" className="list-table-title" dangerouslySetInnerHTML={{__html: item.name}}></td>
                                    <td className="list-table-right">
                                        <a className="blue-link table-link" href={this.props.edit + item.id}>Szerkesztés  </a>
                                        <a className="delete-table-link table-link" onClick={() => this.checkDelete(item.id, item.title)}>Törlés</a>
                                        <a className="blue-link table-link" onClick={()=>this.getStatistics(item.id)}>Statisztika  </a>
                                       
                                    </td>
                            
                                    </tr>


                                    )
                        }
                
                
                
                        </tbody>
                    </table>
                    </div>
                    <Pagination  totalItems={this.props.itemList.length} pagination={this.pagination} />
                </div>

                );
    }
}

export default Table;