

import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router";
import EndQuiz from "../components/EndQuiz";

class ModulePictureQuiz extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
           
            questions: [],
            activeQuestionIndex: 0,
            answers: [],
            answersArray: [],
            rightAnswers: 0,
            isShown: false,
            theme: JSON.parse(localStorage.getItem("subTheme"))

        };


        this.clickAnswer = this.clickAnswer.bind(this);
        this.beforeQuestion = this.beforeQuestion.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
    }

    componentDidMount() {

        this._isMounted = true;

        fetch("/quizquestion/find/" + localStorage.getItem("activeModuleId"))
                .then(response => response.json())
                .then(questions => {
                    if (this._isMounted) {
                        this.setState({"questions": questions});
                    }
                    fetch("/quizitem/find/" + this.state.questions[this.state.activeQuestionIndex].id)
                            .then(response => response.json())
                            .then(data => {
                                this.state.answersArray[this.state.activeQuestionIndex] = data;
                                this.setState({"answers": data, "answersArray": this.state.answersArray});
                                if (document.getElementById("quiz_next_question")) {
                                    document.getElementById("module-next-question").classList.remove("d-none");
                                    let button = document.getElementById("quiz_next_question");
                                    button.addEventListener("click", this.nextQuestion.bind(this, ""));
                                } else {
                                    document.getElementById("module-next-question").classList.remove("d-none");

                                }


                            }
                            );
                });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    nextQuestion(code) {

        if (code !== "") {
            document.getElementById("answer_" + code).classList.remove("quiz_right_answer");
        }
        if (this.state.questions.length - 1 > this.state.activeQuestionIndex) {
            fetch("/quizitem/find/" + this.state.questions[parseInt(this.state.activeQuestionIndex) + 1].id)
                    .then(response => response.json())
                    .then(data => {
                        this.state.answersArray[parseInt(this.state.activeQuestionIndex) + 1] = data;

                        this.setState({"answers": data, "answersArray": this.state.answersArray});
                        this.setState({"answers": data});
                        this.setState({"activeQuestionIndex": parseInt(this.state.activeQuestionIndex) + 1});
                        localStorage.setItem("activeQuestionIndex", this.state.activeQuestionIndex);

                    });
        } else {

            document.getElementById("root").classList.add("gameend-body");

            document.getElementById("module-next-question").classList.add("d-none");
            document.getElementById("final-quiz-button").classList.remove("d-none");

            if (!localStorage.hasOwnProperty("onlineGame")) {
                let img = document.createElement("img");
                img.src = "file/download/?fileId=" + this.state.theme.fileId;
                img.className = "gameend-img";
                document.getElementById("root").appendChild(img);
                var answerText = "";
                var secondColumn = "";
                this.state.questions.map((question, index) => {
                    if (index < this.state.questions.length / 2) {

                        answerText += '<div class="final_question_container final_question_container_open"><span class=" quiz_end_text">' + question.question + '</span><label class="endanswer">' + parseInt(index + 1) + '</label><br/><span>' + question.answer + '</span><label class="endanswersign">' + question.rightAnswerSign + '</label></div>'

                    } else {
                        secondColumn += '<div class="final_question_container final_question_container_open"><label class="endanswer">' + parseInt(index + 1) + '</label><span class=" quiz_end_text">' + question.question + '</span><br/><label class="endanswersign">' + question.rightAnswerSign + '</label><span>' + question.answer + '</span></div>'

                    }
                }
                );
                document.getElementById("quiz_container").innerHTML = '<div class="endanswers col-md text-left"><div class="columns first-column">' +
                        answerText +
                        '</div><div class="columns second-column">' + secondColumn + '</>';

            } else {

                this.props.saveScore(this.state.questions.length * 2, localStorage.getItem("quizAnswers"), parseInt(this.state.rightAnswers) * 2);
                ReactDOM.render(
                        React.createElement(EndQuiz, {questions: this.state.questions, answers: this.state.answersArray, rightAnswers: this.state.rightAnswers, pictureQuiz: true}),
                        document.getElementById('quiz_container')
                        );



            }
        }
    }

    beforeQuestion(code) {

        if (this.state.activeQuestionIndex !== 0) {
            fetch("/quizitem/find/" + this.state.questions[parseInt(this.state.activeQuestionIndex) - 1].id)
                    .then(response => response.json())
                    .then(data => {
                        if (this._isMounted) {
                            this.state.answersArray[parseInt(this.state.activeQuestionIndex) - 1] = data;
                            this.setState({"answers": data, "answersArray": this.state.answersArray});
                            this.setState({"activeQuestionIndex": parseInt(this.state.activeQuestionIndex) - 1});
                            localStorage.setItem("activeQuestionIndex", this.state.activeQuestionIndex);
                        }

                    });
        }
    }
    clickAnswer = async(answerSign,code) =>{
        document.getElementById("quiz_container").classList.add("clickable")
        if(!this.state.sended){
           this.state.sended= true;
           this.setState({sended : this.state.sended})
           try{
           const respRightAnswer = await fetch("/quizitem/isRightAnswer/" + this.state.questions[parseInt(this.state.activeQuestionIndex)].id+"/"+answerSign);
        
       if(respRightAnswer.ok){
           const rightAnswer = await respRightAnswer.json();
           if (!localStorage.hasOwnProperty("onlineGame")) {
               if (rightAnswer) {
                   document.getElementById("answer_" + code).classList.add("quiz_right_answer");
                   setTimeout(this.nextQuestion, 500, code);
                    
   
   
               } else {
                   document.getElementById("answer_" + code).classList.add("quiz_wrong_answer");
                   setTimeout(function () {
                       document.getElementById("answer_" + code).classList.remove("quiz_wrong_answer");
                   }, 500);
               }
   
           } else {
    
         
           fetch("/quizitem/getAnswers/" + this.state.questions[parseInt(this.state.activeQuestionIndex)].id)
                       .then(response => response.json())
                       .then(data => {
               this.state.answersArray[this.state.activeQuestionIndex] = data;
               this.setState({answersArray: this.state.answersArray});
                localStorage.setItem("answersArray", JSON.stringify(this.state.answersArray));
               if (rightAnswer) {
                   this.state.rightAnswers = parseInt(this.state.rightAnswers) + 1;
                   this.setState({rightAnswers: this.state.rightAnswers})
               }
               
               var answers = JSON.parse(localStorage.getItem("quizAnswers"));
   
               answers[this.state.activeQuestionIndex] = code;
               localStorage.setItem("quizAnswers", JSON.stringify(answers));
   
               this.nextQuestion(code);
   
                       });
              
           }
       } else {
           document.getElementById("quiz_container").classList.add("clickable")
           this.setState({sended : false})
       }
   } catch(error){
       document.getElementById("quiz_container").classList.add("clickable")
           this.setState({sended : false})
   }
   }
}
    render() {

        if (this.state.questions.length > 0 && this.state.answers.length > 0) {
            return(
                    <div className="row picturequiz" id="quiz_container" style={{width: "100%", textAlign: "center"}}>
                    
                        <div onMouseEnter={() => this.setState({isShown: true})}
                             onMouseLeave={() => this.setState({isShown: false})} className="col" style={{textAlign: "center", margin: "0 auto"}}>
                           
                                <img   className="picturequiz-img" src={"/file/download?fileId=" + this.state.questions[this.state.activeQuestionIndex].question} /> <br/>
                                {this.state.isShown && this.state.questions[this.state.activeQuestionIndex].pictureComment && <div className="picture-comment">
                                    {this.state.questions[this.state.activeQuestionIndex].pictureComment}
                                </div>}
                            
                        </div>
                        <div className="col">
                    
                            {this.state.questions[this.state.activeQuestionIndex].pictureQuestion ? <label className="black_border font-weight-bold quiz_question">{this.state.questions[this.state.activeQuestionIndex].pictureQuestion}</label> : ""}
                    
                    
                    
                            {  this.state.answers.findIndex(p => p.answerSign == "A") !== -1 ? <div onClick={() => this.clickAnswer("A", "A")} id="answer_A" className="black_border font-weight-bold quiz_answear"> <div className=" answer-sign font-weight-bold">A</div> {this.state.answers[ this.state.answers.findIndex(p => p.answerSign == "A")].answer}</div> : ""}
                    
                    
                            {   this.state.answers.findIndex(p => p.answerSign == "B") !== -1 ? <div onClick={() => this.clickAnswer("B", "B")} id="answer_B" className="black_border font-weight-bold quiz_answear"> <div className=" answer-sign font-weight-bold">B</div> {this.state.answers[ this.state.answers.findIndex(p => p.answerSign == "B")].answer}</div> : ""}
                    
                    
                            { this.state.answers.findIndex(p => p.answerSign == "C") !== -1 ? <div onClick={() => this.clickAnswer("C", "C")} id="answer_C" className="black_border font-weight-bold quiz_answear"><div className=" answer-sign font-weight-bold">C</div>{this.state.answers[ this.state.answers.findIndex(p => p.answerSign == "C")].answer}</div> : ""}
                    
                            { this.state.answers.findIndex(p => p.answerSign == "D") !== -1 ? <div onClick={() => this.clickAnswer("D", "D")} id="answer_D" className="black_border font-weight-bold quiz_answear"><div className=" answer-sign font-weight-bold">D</div>{this.state.answers[ this.state.answers.findIndex(p => p.answerSign == "D")].answer}</div> : ""}
                    
                        </div>
                    </div>

                    );
        }
        return (<div></div>)
    }
}




export default ModulePictureQuiz;