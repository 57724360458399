import React, { Component } from 'react'

import BeforeAfterSlider from 'react-before-after-slider'
import { withRouter } from "react-router";

class ModuleSwipe extends Component {
    constructor(props) {
        super(props)
        this.state = {
            actualSwipeIndex: 0,
            swipes: []
        }
        this.nextSwipe = this.nextSwipe.bind(this);
        this.previosSwipe = this.previosSwipe.bind(this);
        this.initComparisons = this.initComparisons.bind(this);

    }

    componentDidMount() {
        
        fetch("/swipe/find/" + JSON.parse(localStorage.getItem("modulePreview")).id)
                 .then(response => response.json())
                .then(items => {
                    let cards = [];
                    let memoryItems = [];
                  
                    this.setState({swipes: items});
                    this.initComparisons();


                }
                )


    }

    initComparisons() {
        var x, i;

        x = document.getElementsByClassName("active")[0].getElementsByClassName("img-comp-overlay");
        if (document.getElementsByClassName("img-comp-slider").length > 0) {
            document.getElementsByClassName("img-comp-slider")[0].remove();
        }
        for (i = 0; i < x.length; i++) {

            this.compareImages(x[i]);
        }

    }

    compareImages(img) {
        var slider, img, clicked = 0, w, h;
      
   
       w = document.getElementById("swipe-images").offsetWidth ;
      
        h = img.offsetHeight;
       
        img.style.width = (w / 2) + "px";
        
        slider = document.createElement("DIV");
        slider.setAttribute("class", "img-comp-slider");
        
        img.parentElement.insertBefore(slider, img);
      
        slider.style.top = (h / 2) - (slider.offsetHeight / 2) + "px";
        slider.style.left = (w / 2) - (slider.offsetWidth / 2) + "px";
        img.getElementsByTagName("img")[0].style.width= document.getElementsByClassName("swipe-images")[0].offsetWidth +"px";
        const  slideReady = (e) => {
          
            e.preventDefault();
           
            clicked = 1;
         
            window.addEventListener("mousemove", slideMove);
            window.addEventListener("touchmove", slideMove);
        }
        const slideFinish = () => {
           
            clicked = 0;
        }
        const  slideMove = (e) => {
            var pos;
         
            if (clicked == 0)
                return false;
         
            pos = getCursorPos(e)
          
            if (pos < 0)
                pos = 0;
            if (pos > w)
                pos = w;
           
            slide(pos);
        }
        const getCursorPos = (e) => {
            var a, x = 0;
           
            e = e || window.event;
           
            a = img.getBoundingClientRect();
          
               
           if(e.type==="touchmove"){
                x =e.changedTouches[0].pageX - a.left;
           } else{
                x =e.pageX - a.left;
           }
           
            x = x - window.pageXOffset;
            return x;
        }
        const slide = (x) => {
         
            img.style.width = x + "px";
          
            slider.style.left = img.offsetWidth - (slider.offsetWidth / 2) + "px";
           
            img.getElementsByTagName("img")[0].style.width= document.getElementsByClassName("swipe-images")[0].offsetWidth +"px";
        }
       
        slider.addEventListener("mousedown", slideReady);
        /*and another function when the mouse button is released:*/
        window.addEventListener("mouseup", slideFinish);
        /*or touched (for touch screens:*/
        slider.addEventListener("touchstart", slideReady);
        /*and released (for touch screens:*/
        window.addEventListener("touchend", slideFinish);

    }

    nextSwipe() {
      
    if(this.state.swipes.length-1>=this.state.actualSwipeIndex + 1)
        this.setState({"actualSwipeIndex": this.state.actualSwipeIndex + 1}, function () {
            this.initComparisons();
        });

    }

    previosSwipe() {
        
    
    if (this.state.actualSwipeIndex-1>=0){
        this.setState({"actualSwipeIndex": this.state.actualSwipeIndex - 1}, function () {
            this.initComparisons();
        });
        }
    }

    render() {

        return (
                <div className="swipe-container" id="swipe-container" style={{margin: "0 auto", position: "absolute"}}>
                    <button onClick={this.previosSwipe} className={"btn-backward btn "+ (this.state.actualSwipeIndex===0 ? "invisible" : "")}></button> 
                    <div className="swipe-images" id="swipe-images" >
                { this.state.swipes.map.length >0 ? this.state.swipes.map( (swipe,index) => 
                        <div className={"img-comp-container " + (this.state.actualSwipeIndex === index? "active" : "") }>
                            <div className="img-comp-img">
                                <img src={'/file/download?fileId='+swipe.after_url} />
                            </div>
                            <div className="img-comp-img img-comp-overlay">
                                <img src={'/file/download?fileId='+swipe.before_url} />
                            </div>
                            <label className="swipe-label">{swipe.name}</label>
                        </div>
                        
                    ) :""}
                
                     </div>
                    <button  onClick={this.nextSwipe} id="forward_button" className={"btn-forward btn "+(this.state.actualSwipeIndex===this.state.swipes.length-1 ? "invisible" : "")}></button>
                     
                </div>
                )

    }
}

export default ModuleSwipe;