import React from 'react';
import { withRouter }from "react-router";
        class PrivacyPolicy extends React.Component {

        render(){

        return(<div style={{width:"80%",margin : "0 auto"}}>
    <p style={{textAlign : "center"}}><b>Határozott idejű Játékszabályzat és Adatkezelési tájékoztató</b></p>
    <p style={{textAlign : "center"}}><b>a Történelmi Hadi Akadémia Alapítvány és a MÚZPED által szervezett „Kvíz” idejére</b></p>
    <p>Kérjük, hogy figyelmesen <u>olvasd el</u> a jelen játékszabályzatunkat (a továbbiakban: <b>Szabályzat</b>). 
    Az ebben foglaltak Rád és a Szervezőkre nézve is kötelezőek.<b> Ha még nem vagy 16 éves, ezt a Szabályzatot és Adatvédelmi tájékoztatót szüleidnek is mutasd meg, mielőtt regisztrálsz.</b></p>
    <p>Amennyiben a Történelmi Hadi Akadémia Alapítvány és a MÚZPED által szervezett, „Történelmi Hadi Akadémia Alapítvány Hadfiak” című edukációs kvíz elnevezésű programban (a továbbiakban: Játék) részt veszel, 
    mint játékos (a továbbiakban: <b>Játékos</b>), úgy a regisztrációval elfogadod a jelen Szabályzat és Adatkezelési tájékoztató rendelkezéseit.</p>
    <p><b>I. Játékszabályzat</b></p>
    <p><b>Bevezető, általános rendelkezések, a Szervező és a Játékos meghatározása, Regisztráció</b></p>
    <p>A Játék szervezői és lebonyolítói a Történelmi Hadi Akadémia Alapítvány (a továbbiakban: <b>Szervező1</b>) és a MÚZPED (a továbbiakban: <b>Szervező2</b>, Szervező1 és Szervező2 
        együtt a továbbiakban úgy is mint Szervezők). Szervezőket az adatkezelés és a Játék lebonyolítása kapcsán Szervező2 képviseli.</p>
    <p><b>Szervező1-re vonatkozó adatok</b></p>
    <p>
    <div className="row">
        <div className="col">
            székhely (egyben a panaszügyintézés helye):<br/>
            nyilvántartási szám:<br/>
            adószám:<br/>
            nyilvántartó bíróság:<br/>
                képviselője:<br/>
                központi e - mail cím:<br/>
                </div>
                <div className="col">
                2013 Pomáz, Bocskai utca 20/a.<br/>
                13 - 01 - 0004132 <br/>
                19277576 - 1 - 13 <br/>
                Budapest Környéki Törvényszék <br/>
                Zsolnai Gábor kurátor <br/>
                <a href=""> informacio@thakademia.hu</a> <br/>
                </div>
                </div>
                </p>
                <p>A Játékban Játékosként akkor vehetsz részt, ha 10. életévét betöltötted, azonban 20. életévedet még nem töltötted be. A Szervezők külön Játékot indítanak az 5-8. osztályba járó, valamint a 9-13. osztályba járó diákok számára, amely játékok menetének leírását lentebb olvashatod részletesen. A regisztrációt csak úgy lehet befejezni, ha hozzájárulsz az Adatkezelési tájékoztatóban leírtakhoz, illetve, ha megérkezik a visszaigazoló email az összes kérdés megválaszolása után. Fontos: a visszaigazoló emailt automatikusan küldi a rendszer, de csak a kérdéssor megválaszolásának befejezése után! Ha a kérdéssor megválaszolásának befejezése után valamilyen okból nem kaptál visszaigazoló emailt, 
                sajnos nem érvényes a regisztrációd, és újra kell kezdened a kvízt. Ezért fontos, hogy létező email címet adj meg, ahová be is tudsz lépni!</p>
                <p><b>A Játék időtartama</b></p>
                <p>A Játék 2021. október 20. és 2021. november 10. között zajlik a Szervezők által biztosított online felületen, Szervező1 honlapján:<a href="http://thakademia.hu"> www.thakademia.hu</a> (a továbbiakban: <b>Honlap</b>).</p>
                 <p><b>5-8. osztályba járók Kvíz-játéka</b></p> 
                <p>Te, mint Játékos – a teljes és valós – nevednek, valamint – létező és használatban lévő – e-mail címednek megadásával vehetsz részt a Játékban a Honlapon található „Regisztrálok” gomb megnyomásával, mindenki egyszer regisztrálhat. A regisztráció után az „Építsük fel a várat!” gomb megnyomásával indíthatod el a Játékot, amelyben mindösszesen 20 feladat megoldására korlátlan idő áll rendelkezésedre. A Játék során fontos észben tartanod, hogy az előző kérdésekre nincs lehetőséged visszalépni. A Játékhoz természetesen használhatsz bármilyen segédeszközt (internetet, térképet, történelemkönyvet, egyéb könyveket, forrásokat, stb.). Ha internetet használsz segítségként a Játék közben, ne zárd be azt az ablakot, amelyikben a Játékot megnyitottad. Ha mégis bezártad, újra kell azt kezdened. Nagy segítség, 
                hogy a Facebook (<a href=">https://www.facebook.com/thakademia/">https://www.facebook.com/thakademia/</a>) oldalunkon fellelhető számos kérdésre a válasz valamelyik posztunkban. Ezeket a posztokat a #hadfiak hashtagre keresve találod meg. Az általad helyesnek ítélt válaszlehetőségre kattintva azonnal megjelenik, hogy helyesen válaszoltál-e vagy sem: helyes válasz esetén a válaszlehetőség zöldre vált, helytelen válasz esetén pedig pirosra, ebben az esetben - ezzel egyidőben - a helyes válasz is megjelenik zölddel, valamint rövid magyarázatot, a témához kapcsolódó érdekességet is olvashatsz egyes kérdéseknél. A magyarázatok helyes válasz esetén is láthatók. Az egyes játékrészeken belül tetszőleges sorrendben haladhatsz a kérdésekkel az egyes elemekre kattintva. Ezek az elemek mindaddig szürkék maradnak, amíg nem helyes a válasz, csak ezt követően színesednek
ki. A Játékos által helyesnek vélt válaszra kattintva azonnal megjelenik, hogy a válasz helyes volt-e vagy sem, utóbbi esetén a jó választ is megjelölve.</p>
                   <p>Ha az összes kérdésre válaszoltál, és elérted a Játék végét, megjelenik egy felugró ablak. Itt információt találsz arról, hogy elküldtük az összesítő emailt, úgyhogy mielőtt kilépsz, ellenőrizd, megérkezett-e. Ha már kiléptél anélkül, hogy megérkezett volna az összesítő email, sajnos újra kell kezdened a Játékot. Ha még kilépés előtt ellenőrizted, 
                   és nem érkezett meg az email, a „Nem kaptam meg az emailt, küldjétek újra!” gombbal újra elküldjük.</p>
                  <p><b>9-13. osztályba járók Kvíz-játéka</b></p>   
                <p>Te, mint Játékos - a teljes és valós – nevednek, valamint – létező és használatban lévő – e-mail címednek megadásával vehetsz részt a Játékban a Honlapon található „Regisztrálok” gomb megnyomásával. A regisztráció után a „Mindent értek, vágjunk bele!” gomb megnyomásával indul a Játék, amelyben mindösszesen 60 (48 db kérdés, 1 db gömbpanoráma, 2 db swipe képnézegető, 4 db egyéb feladat és 11 képnézegető) feladat megoldására korlátlan idő áll rendelkezésedre. A Játék során fontos észben tartanod, hogy az előző kérdésekre nincs lehetőséged visszalépni. A Játékhoz természetesen használhatsz bármilyen segédeszközt (internetet, térképet, történelemkönyvet, egyéb könyveket, forrásokat, stb.). Ha internetet használsz segítségként a Játék közben, ne zárd be azt az ablakot, amelyikben a Játékot megnyitottad! Ha mégis bezártad, újra kell azt kezdened. Nagy segítség,
                hogy a Facebook (:<a href="https://www.facebook.com/thakademia/"> https://www.facebook.com/thakademia/</a> ) oldalunkon fellelhető az
                összes kérdésre a válasz valamelyik posztunkban. Ezeket a posztokat a #hadfiak hashtagre keresve találod meg.</p>
                     <p>Az általad helyesnek ítélt válaszlehetőségre kattintva azonnal megjelenik, hogy helyesen válaszoltál-e vagy sem: helyes válasz esetén a válaszlehetőség zöldre vált,
                     helytelen válasz esetén pedig pirosra, ebben az esetben - ezzel egyidőben - a helyes válasz is megjelenik zölddel, 
                     valamint rövid magyarázatot, a témához kapcsolódó érdekességet is olvashatsz egyes kérdéseknél. A magyarázatok helyes válasz esetén is láthatók. 
                     A következő kérdés a „Következő kérdés” gombra kattintva töltődik be.</p>
                     <p>A kérdések közé pár különlegesebb feladatot is elrejtettünk, amelyeket nem kötelező megcsinálnod, azonban érdemes, hiszen plusz pont jár érte, nem is kevés! Ha mégis úgy döntesz, hogy kihagyod, ezt a „Nem kérem a plusz pontokat” gombbal teheted meg, ekkor betöltődik a következő kérdés.</p>
                      <p>Ha az összes kérdésre válaszoltál, és elérted a Játék végét, megjelenik az összesítő képernyő, ahol a gyűjtött pontjaidat találod, illetve információt arról, hogy elküldtük az összesítő emailt, úgyhogy mielőtt kilépsz, ellenőrizd, megérkezett-e. Ha már kiléptél anélkül, hogy megérkezett volna az összesítő email, sajnos újra kell kezdened a Játékot. Ha még kilépés előtt ellenőrizted, és nem érkezett meg az email, a „Nem kaptam meg az emailt, küldjétek újra!” gombbal újra elküldjük.</p>
                       <p>Pontozás: Minden kérdés 2 pontot ér (tehát 42 kérdésnél ez 84 pont). A 4 db plusz feladatért járó plusz pont egyenként 10 pont (tehát ha mindegyiket megcsinálod, az +40 pontot jelent!). A szeptember 25-én megtartott I. Hadfiak Seregszemléjén regisztrált játékosok egyszeri +20 pontot kapnak, ha regisztrációkor az erre kijelölt helyre az emailben részükre megküldött kódot beírják. Annak érdekében, hogy mindenki egyenlő eséllyel induljon a játékban, a következő szabályt hozzuk:</p>
                        <p>- az egyes helyezéseken belüli, kód nélküli legmagasabb pontszám egyenértékű a kóddal együtt számolt legmagasabb pontszámmal.</p>
                         <p>Az összes pontszám tehát a 156-ot is elérheti: 96p (kérdések) + 40p (plusz feladatok) + 20p (seregszemle) = 156. Helytelen válasz esetén nincs levonás, egyszerűen nem jár pont.</p>
                <p><b>Játékos nyereménye, eredményhirdetés</b></p> 
                <p>Az 5-8. osztályba járók esetében a Játék nyertesét a Szervezők véletlenszerű sorsolással választják ki. A helyes válaszok aránya a végeredmény szempontjából nem mérvadó, azokat pusztán oktató céllal jelenítjük meg.</p>
                 <p>Nyeremények:</p>
                  <p>Nagyértékű tablet<br/>
+ személyre szólóan dedikált, értékes könyvek a Hadtörténeti Múzeum kutatóitól
+ egy személyre szabott, egyéni foglalkozás és bevezetés a bajvívás és a végvári élet mindennapjaiba, korhű jelmezekkel és fegyverekkel, amit Zsolnai Gábor, az alapítvány kurátora, bajvívó személyesen tart majd + Történelmi Hadi Akadémia Alapítvány ajándékcsomag</p>
                   <p>A 9-13. osztályba járók esetében a Szervezők a Játékosokat a helyes válaszaik alapján, a fentebb meghatározott pontrendszer szerint rangsorolják. Ez esetben Szervezők az első 3 helyezettnek biztosítanak nyereményt. Amennyiben a pontszámok alapján holtverseny alakul ki valamelyik helyezésen belül, úgy az eredményt sorsolás dönti majd el a holtversenyben lévők között, az adott helyezésért.</p>
                 <p>Nyeremények:</p>   
                <p>1. hely:<br/>
Nagyértékű tablet és JBL wireless füles
+ személyre szólóan dedikált, értékes könyvek a Hadtörténeti Múzeum kutatóitól
+ egy személyre szabott, egyéni foglalkozás és bevezetés a bajvívás és a végvári élet mindennapjaiba, korhű jelmezekkel és fegyverekkel, amit Zsolnai Gábor, az alapítvány kurátora, bajvívó személyesen tart majd
+ Történelmi Hadi Akadémia Alapítvány ajándékcsomag</p>
                     <p>2. hely:<br/>
JBL hangszóró
+ személyre szólóan dedikált,értékes könyvek a Hadtörténeti Múzeum kutatóitól
+ egy személyre szabott, egyéni foglalkozás és bevezetés a bajvívás és a végvári élet mindennapjaiba, korhű jelmezekkel és fegyverekkel, amit Zsolnai Gábor, az alapítvány kurátora, bajvívó személyesen tart majd
+ Történelmi Hadi Akadémia Alapítvány ajándékcsomag</p>
                      <p>3. hely:<br/>
JBL wireless füles
+ személyre szólóan dedikált, értékes könyvek a Hadtörténeti Múzeum kutatóitól
+ egy személyre szabott, egyéni foglalkozás és bevezetés a bajvívás és a végvári élet mindennapjaiba, korhű jelmezekkel és fegyverekkel, amit Zsolnai Gábor, az alapítvány kurátora, bajvívó személyesen tart majd
+ Történelmi Hadi Akadémia Alapítvány ajándékcsomag</p>
                       <p>Mindkét korosztályból az első 100-100 Játékos között kisorsolunk egy-egy, a Történelmi Hadi Akadémia Alapítvány saját logózott termékeiből álló ajándékcsomagot, valamint egy-egy személyre szóló, egyedi ajándékot. Az egyedi ajándékok közül az egyik egy személyre szabott, egyéni foglalkozás és bevezetés a bajvívás és a végvári élet mindennapjaiba, korhű jelmezekkel és fegyverekkel, amit Zsolnai Gábor, az alapítvány kurátora, bajvívó személyesen tart majd.</p>
           <p><b>Játékos nyereménye, eredményhirdetés</b></p> 
                <p><b>II. Adatkezelési tájékoztató</b></p>
                <p>Szervezők a Játék során a <b>Játékosok nevét és regisztrált e-mail címét</b> (a továbbiakban: <b>Adatok</b>) kezelik, ezek személyes adatok (a továbbiakban: <b>Adatkezelés</b>). Ezen <b>adataid megadása önkéntes, de ezek hiányában a Játékon nem tudsz részt venni.</b> A Szervezők kötelesek Téged előre tájékoztatni az Adatkezelés törvény által előírt jellemzőiről, erre szolgál ez a fejezet.</p>
                <p>Az Adatkezelés <b>jogalap</b>ja a GDPR irányelv értelmében 16. életévét betöltött gyermek esetén maga az érintett, míg 16 év alatti gyermek esetén a szülő (törvényes képviselő) <b>hozzájárulás</b>a. Az irányelv 8. cikk (1) bek.-e értelmében a személyes adatok hozzájáruláson alapuló kezelése 16. életévét betöltött gyermek esetén jogszerű, hiszen a Játék közvetlenül a gyermekeknek kínált, információs társadalommal összefüggő szolgáltatás. A 16. életévét be nem töltött gyermek esetén a személyes adatok kezeléséhez szükséges hozzájárulást a szülői felügyeletet gyakorlónak kell megadnia, illetve engedélyeznie A jelen Szabályzat első bekezdése alapján Szervezők úgy tekintik, hogy a szülők a jelen Adatkezelési tájékoztató megismerték és a regisztrációval a szülői hozzájárulás megadottnak tekintendő.</p>
                <p>Az Adatkezelés <b>célja</b>, hogy a Játék során a válaszaidat és a pontszámaidat a Szervezők Hozzád tudják rendelni, és nyereményeidről tájékoztassanak, esetleges panasz esetén ez év végéig Veled levelezhessenek.</p>
                <p>Az adatkezelés <b>időtartama:</b> adataid megadásától 2021. dec. 31-ig.</p>
                  <p>Az adataidról tájékoztatást, azok módosítását vagy törlését Szervező1-nél személyesen, telefonon vagy e-mailben kezdeményezheted. Bármikor kérheted adataid kezelésének megszüntetését, ebben az esetben a megadott adatokat Szervező1 haladéktalanul törli a rendszeréből és ezzel az Adatkezelést megszünteti.</p>
                   <p>Szervezők adataidat harmadik személyek részére még a marketing célú adatkezeléshez való hozzájárulás esetén sem továbbítja, és azokat nem továbbítja külföldre.</p>
                   <p>Szervezők a jogi szabályozással összhangban mindenkor törekszenek az adatvédelmi alapelveknek megfelelően kezelni az adataidat, az Adatkezelés tehát megfelel a célhoz kötöttség, a jogszerűség, tisztességes eljárás és átláthatóság, az adattakarékosság, a pontosság, a korlátozott tárolhatóság, integritás és bizalmas jelleg és az elszámoltathatóság elveinek. Adatvédelmi tisztviselőt a Szervezők nem alkalmaznak. Adatait kizárólag a Szervezők megfelelő jogosultságokkal és titoktartási kötelezettséggel rendelkező munkatársai, megbízottjai ismerhetik meg.</p>
                 <p><b>Játékos nyereménye, eredményhirdetés</b></p>   
                <p>A Játékos jogai</p>
                <p><u>Tájékoztatás (hozzáférés).</u> Játékosnak joga van ahhoz, hogy az Adatainak kezeléséről - egyértelmű, közérthető és részletes, valamint előzetes - tájékoztatást kapjon. Szervezők az Adatok felvételekor a jelen Tájékoztató útján tájékoztatják Játékost az adatkezelésről, emellett a jelen Tájékoztató bármikor elérhető Játékos számára. Játékos az adatkezelés folyamán bármikor teljes körű tájékoztatást kérhet az Adatai kezeléséről és kérheti, hogy Szervezők az Adatok másolatát bocsássák a rendelkezésére.<br/>
                <u>Helyesbítés.</u> Játékos kérheti, hogy a Szervezők a rá vonatkozó pontatlan adatokat helyesbítsék, a hiányos Adatot kiegészítsék. A megadott Adatok többségének módosítását a Honlapon el lehet végezni, illetve postai úton is lehet kezdeményezni.<br/>
                <u>Törlés, hozzájárulás visszavonása.</u> Játékos bármikor visszavonhatja az Adatai kezeléséhez adott hozzájárulását, illetve kérheti az Adatai törlését. A Szervezők a törlést csak akkor tagadják meg, amennyiben az adatkezelés jogszabályon alapul, vagy jogi igények előterjesztéséhez, érvényesítéséhez, illetve védelméhez szükséges. Szervezők Játékos Adatait nem hozzák nyilvánosságra, így az elfeledtetéshez való jog Szervezők adatkezelése kapcsán nem releváns.<br/>
                <u>Korlátozás.</u> Játékosnak jogában áll az adatkezelés korlátozását kérni, az alábbi esetekben:<br/>
                <ul>
                <li> vitatja a személyes adatok pontosságát, ez esetben a korlátozás arra az időtartamra vonatkozik, amely lehetővé teszi, hogy a Szervezők ellenőrizzék a személyes adatok pontosságát;</li>
<li> az adatkezelés jogellenes, és Játékos ellenzi az adatok törlését, és ehelyett kéri azok felhasználásának korlátozását;</li>
<li> a Szervezőknek már nincs szüksége a személyes adatokra adatkezelés céljából, de Játékos igényli azokat jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez;</li>
<li> Játékos tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra az időtartamra vonatkozik, amíg megállapításra nem kerül, hogy a Szervezők jogos indokai elsőbbséget élveznek-e Játékos jogos indokaival szemben.</li>
</ul>
Korlátozás esetén az Adatokat a tárolás kivételével csak Játékos hozzájárulásával, vagy jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy más természetes vagy jogi személy jogainak védelme érdekében, vagy fontos közérdekből lehet kezelni.<br/>
<u>Tiltakozás.</u> Amennyiben az adatkezelés a Szervezők, vagy harmadik fél jogos érdekének érvényesítésén alapul, Játékos jogosult arra, hogy a saját helyzetével kapcsolatos okokból bármikor tiltakozzon Adatainak kezelése ellen. Ebben az esetben a Szervezők az Adatokat nem kezelhetik tovább, kivéve, ha bizonyítják, hogy az adatkezelést olyan kényszerítő erejű jogos okok indokolják, amelyek elsőbbséget élveznek Játékos érdekeivel, jogaival és szabadságaival szemben, vagy amelyek jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez kapcsolódnak. Ha az Adatok kezelése közvetlen üzletszerzés érdekében történik, Játékos bármikor jogosult tiltakozni a rá vonatkozó Adatok e célból történő kezelése ellen.<br/>
<u>Adathordozhatóság. </u>Játékos jogosult arra, hogy a rá vonatkozó Adatokat tagolt, széles körben használt, géppel olvasható formátumban megkapja, továbbá jogosult arra, hogy ezeket az Adatokat egy másik adatkezelőnek továbbítsa, feltéve, hogy az adatkezelés automatizált módon történik. Ön jogosult arra, hogy – ha ez technikailag megvalósítható – kérje az Adatok más adatkezelőhöz történő közvetlen továbbítását.</p>
                   <p>A Szervezők a jelen tájékoztatót, Játékos kérésére rendelkezésére bocsátja, továbbá a Honlapon is elérhetővé teszi. Játékos a jogai gyakorlására irányuló kérelmet az Szervező1 fent megjelölt elérhetőségek valamelyikén juttathatja el a Szervezőkhöz. Szervezők a kérelmet haladéktalanul megvizsgálják, döntést hoznak annak teljesítéséről, és megteszik a szükséges intézkedéseket, továbbá indokolatlan késedelem nélkül, de lekésőbb a kérelem beérkezésétől számított egy hónapon belül tájékoztatják Játékost a megtett intézkedésekről. A tájékoztatás minden esetben tartalmazza a Szervezők által megtett intézkedéseket, vagy a Játékos által kért tájékoztatást. Amennyiben a Szervezők a kérelem teljesítését elutasítják (nem teszik meg a kérelem teljesítéséhez szükséges intézkedéseket), a tájékoztatás tartalmazza a megtagadás jogalapját, indokait, és a Játékos jogorvoslati lehetőségeit. A Szervezők a kérelem teljesítését nem kötik díj fizetéséhez, a költségek megtérítéséhez. Amennyiben a kérelem benyújtásának körülményei, módja miatt nem bizonyos, hogy a kérelem Játékostól
származik, Szervezők kérhetik, hogy a kérelmező igazolja jogosultságát, vagy a kérelmet olyan módon terjessze elő, hogy a jogosultság egyértelműen megállapítható legyen.</p>
<p><u>Jogorvoslat.</u> Játékos a jogainak sérelme esetén Szervező1 fent meghatározott elérhetőségein kérheti, hogy a Szervezők a jogellenes adatkezelést szüntesse meg, az adatkezelést, Játékos kérelmének elutasítását vizsgálják meg. A Szervezők a Játékos ilyen irányú panaszát minden esetben kivizsgálják, és annak eredményéről tájékoztatják. Játékos panaszával fordulhat közvetlenül a Nemzeti Adatvédelmi és Információszabadság Hatósághoz (cím: 1125 Budapest, Szilágyi Erzsébet fasor 22/c.; telefon: +36-1-391-1400; fax: +36 (1) 391-1410, ügyfélszolgálat telefonszáma: 
+36 (30) 683-5969 és +36 (30) 549-6838; e-mail: <a href="">ugyfelszolgalat@naih.hu</a>; honlap: <a href="www.naih.hu">www.naih.hu</a>) is. Játékos jogosult jogainak megsértése esetén bírósághoz fordulni. A Szervezők Játékos kérelemre részletesen tájékoztatják a per elbírálására hatáskörrel és illetékességgel rendelkező bíróságról, a perindítás lehetőségéről.</p>
                 <p><b>Játékos nyereménye, eredményhirdetés</b></p>   
                <p>Felelősség, egyéb rendelkezések</p>
                   <p>Az 1991. évi XXXIV. törvény 1§. (1) bekezdése alapján a Játék nem minősül szerencsejátéknak, így Szervezőket sem engedélyeztetési, sem bejelentési kötelezettség nem terheli.</p>
                   <p>A Szervezők által a Játék során közzétett mindennemű tartalom helyességéért, pontosságáért, megbízhatóságáért, valamint a tartalom felhasználásával, alkalmazásával összefüggésben keletkezett kellemetlenségekért vagy bármilyen hátrányos következményekért a Szervezők semmilyen felelősséget nem vállalnak. A Szervezők nem felelnek azért, ha a Honlap bármely időpontban nem elérhető, esetleg korlátozottan vagy hibásan működik. A Szervezők kizárnak minden felelősséget bármilyen kártérítési, kártalanítási igényért a Játékban való részvétel során. A Szervezők megteszik az általánosan alkalmazott és általánosan elérhető műszaki védelmi intézkedéseket annak érdekében, hogy az igénybe vett hardverek és szoftverek biztonságosan működjenek együtt a Honlap böngészése és a Játék során, és hogy az adatvédelmi tájékoztatóban foglaltak szerint teljesüljön az adatbiztonság.</p>
                  
                </div>
                )
        }
        }
export default withRouter(PrivacyPolicy)


