import React, { Component}from "react";
import FileUpload from "../components/FileUpload"
import ReactDOM from "react-dom";

class AddFile extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        this.selectFile = this.selectFile.bind(this);
        this.openFileList = this.openFileList.bind(this);

    }
      selectFile(path,fileName,id){
            this.props.selectFile(id,this.props.index,this.props.answerIndex)

    }
    
    openFileList(){
        ReactDOM.render(
                React.createElement(FileUpload, {selectFile : this.selectFile}),
                document.getElementById('file-container')
                );
        if(document.getElementsByClassName("file-popup")){
            document.getElementsByClassName("file-popup")[0].classList.remove("d-none");
        }
    }

    render() {



        return (
                <div   >
                    <div className="btn btn-add-image"> 
                        
                        <label onClick={this.openFileList}> <span style={{fontSize: "55px", fontWeight: "normal"}}>+</span> <br/><b>KÉP</b> feltöltése</label>
                    </div>
                
                </div>

                );
    }
}

export default AddFile;


