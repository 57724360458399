
import React, { Component}from "react";
import {ModuleService}from "../../_services/ModuleService";
import ModuleFormsHeader from"./ModuleFormsHeader"
import AddFile from "../components/AddFile"

        class ModuleQuizPictureAnswersForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
           
            questionNo: 0,
            questions: []
        }
        this.subThemeThemeModal = React.createRef();
        this.hideModal = this.hideModal.bind(this);
        this.addQuestion = this.addQuestion.bind(this);
        this.handleQuestionChange = this.handleQuestionChange.bind(this);
        this.handleAnswerChange = this.handleAnswerChange.bind(this);
        this.handleRightAnswerChange = this.handleRightAnswerChange.bind(this);
        this.saveQuiz = this.saveQuiz.bind(this);
        this.ModuleFormsHeader = React.createRef();
        this.deleteQuestion = this.deleteQuestion.bind(this);
        this.addImage = this.addImage.bind(this);
        

    }

    addImage(id, index, answerIndex) {
        document.getElementById("item-" + answerIndex + "-" + index).style.backgroundImage = "url('/file/download?fileId=" + id + "')";
        document.getElementById("item-" + answerIndex + "-" + index).style.backgroundSize = "cover";

        this.handleAnswerChange(id, index, answerIndex)


    }

    addQuestion() {
        this.setState({"questionNo": this.state.questionNo + 1});
    }

    deleteQuestion(index) {
        this.setState({"questionNo": this.state.questionNo - 1});
        this.state.questions.splice(index, 1);
        this.setState({questions: this.state.questions});
    }

    saveQuiz() {
        var error = false;
        for (var i = 0; this.state.questionNo > i; i++) {
            if (!this.state.questions[i]["right"]) {
                alert("Helyes válasz megadása kötelező");
                error = true;
            }

        }
        if (!error) {

            var questions = {};

            this.state.questions.map((question, index) => {
                questions[index] = question;
            })
            this.props.saveModule("QuizPictureAnswers", questions);
            // ModuleService.saveQuiz(this.state.questions);
            this.hideModal();
        }
    }
    componentDidMount() {

        if (this.props.moduleId != 0) {
            fetch("/quizquestion/find/" + this.props.moduleId)
                    .then(response => response.json())
                    .then(questions => {

                        this.setState({"questionNo": 0});
                        questions.map((question, index) => {

                            this.state.questions[index] = {};
                            this.state.questions[index]["question"] = question.question;
                            this.state.questions[index]["comment"] = question.comment;
                            this.state.questions[index]["right"] = question.rightAnswerSign;
                            this.state.questions[index]["id"] = question.id;
                            this.state.questions[index]["pictureQuestion"] = question.pictureQuestion;

                            fetch("/quizitem/find/" + question.id)
                                    .then(response => response.json())
                                    .then(data => {

                                        data.map(answer => {
                                            this.state.questions[index][answer.answerSign] = answer.answer;
                                            this.state.questions[index][answer.answerSign + "id"] = answer.id;
                                            this.setState({"questions": this.state.questions});
                                          
                                            this.setState({"questionNo": this.state.questionNo});
                                             document.getElementById("item-"+answer.answerSign+"-" + index).style.backgroundImage = "url('/file/download?fileId=" + answer.answer + "')";
                                            document.getElementById("item-"+answer.answerSign+"-" + index).style.backgroundSize = "cover";
                                        })
                                    }
                                    );
                            this.setState({"questionNo": this.state.questionNo + 1});
                           
                        })

                    });

        }


    }

    hideModal() {
        window.scrollTo({
            top: 400,
            behavior: 'smooth',
        })
        document.body.classList.remove('display-block');
        let element = document.getElementsByClassName("modal");
        element[0].classList.add("display-none");
        document.body.classList.remove("modal-admin-body");
        //window.scrollTo(0,localStorage.getItem("scrollTop"));

    }

    handleQuestionChange(e, questionIndex) {

        if (this.state.questions[questionIndex]) {
            this.state.questions[questionIndex][e.target.name] = e.target.value;
        } else {
            this.state.questions[questionIndex] = {[e.target.name]: e.target.value};
        }
        this.setState({[e.target.name]: this.state.questions});

    }

   

    handleAnswerChange(answer, questionIndex, answerIndex) {
        if (this.state.questions[questionIndex]) {
            this.state.questions[questionIndex][answerIndex] = answer.toString();
        } else {
            this.state.questions[questionIndex] = {[answerIndex]: answer.toString()};

        }
        this.setState({"questions": this.state.questions});

    }

    handleRightAnswerChange(e, questionIndex, answerIndex) {
        //var checked = document.getElementById()
        if (e.target.checked) {
            document.getElementById("checked_" + answerIndex + questionIndex).classList.add("checked");
            if (this.state.questions[questionIndex]) {
                this.state.questions[questionIndex]["right"] = answerIndex;
            } else {
                this.state.questions[questionIndex] = {"right": answerIndex};

            }
        } else {
            document.getElementById("checked_" + answerIndex + questionIndex).classList.remove("checked");
        }

        this.setState({"questions": this.state.questions});

    }

    render() {



        return (
                <div  className="modal-dialog admin-modal quiz-modal ">
                    <section  className="admin-form-modal modal-content" >
                
                        <ModuleFormsHeader ref={this.ModuleFormsHeader} title="KVÍZ" save={this.saveQuiz} delete={this.props.delete} context={this.props.contextModule} />
                
                
                        <div className=" modal-body" >
                            <table className="table quiz-admin-table"  style={{width: "800px", padding: "40px", paddingTop: "0", display: "inline-block"}}>
                
                                <tbody>
                                    {[...Array(this.state.questionNo), ].map((value, index) => (
                                        <Question deleteQuestion={this.deleteQuestion} handleQuestionChange={this.handleQuestionChange} addImage={this.addImage} key={index} index={index} question={this.state.questions[index] ? this.state.questions[index] : []}  handleQuestionChange = {this.handleQuestionChange} handleAnswerChange={this.handleAnswerChange}  handleRightAnswerChange = {this.handleRightAnswerChange}/>

                                                )
                                    )
                                }
                
                
                                </tbody>
                            </table>
                            <button className={"btn btn-add-question " + (this.state.questionNo >= 10 ? "disabled-add-item" : "") } onClick={this.addQuestion}></button>
                        </div>
                
                
                
                
                    </section>
                
                </div>
                );
    }
}

class Question extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
                <React.Fragment>
                    <tr>
                        <td scope="col" colSpan="3" className="form-section-title"  > </td>
                
                
                    </tr>
                    <tr className="quiz-question-tr">
                        <td colSpan="2">
                            <div className="form-input-title left_border" style={{width: "95px"}} > <i className="fa question-icon" id="info-label"  for=""></i></div>
                
                            <div className="form-input-title left_border" ><span className="small-text  ">{this.props.index + 1}</span></div>
                            <input  name="question" id={"question" + this.props.index} required  onChange={(e) => this.props.handleQuestionChange(e, this.props.index)} value={this.props.question && this.props.question["question"] ? this.props.question["question"] : "" }  className="item-form-input "  placeholder="KÉRDÉS"  />
                        </td>
                        <td width="70px"><button className="btn btn-question-delete" onClick={() => this.props.deleteQuestion(this.props.index)}></button></td>
                    </tr>
                    <tr>
                        <td style={{width: "95px", border: "none"}}></td>
                        <td>
                
                
                            <div className="form-input-title left_border" style={{borderRight: "unset"}}><span className="small-text  ">A</span></div>
                            <div id={"item-image-A-" + this.props.index} style={{width: "200px", margin: "0 auto", marginBottom: "30px", display: "inline-block", verticalAlign: "middle"}}>
                                <div id={"item-A-" + this.props.index} style={{width: "200px", height: "200px", margin: "0 auto", background: "#252629", textAlign: "center"}}>
                                    <AddFile selectFile = {this.props.addImage} index={ this.props.index} answerIndex="A"/>
                                </div>
                            </div>
                
                            <div class="squaredThree" style={{float: "none", marginLeft: "70px", display: "inline"}}>
                                <input type="checkbox" id={"checkbox_A" + this.props.index}   name="check"  checked={this.props.question && this.props.question["right"] && this.props.question.right === "A" ? "checked" : ""}   onChange={(e) => this.props.handleRightAnswerChange(e, this.props.index, "A")}  />
                                <label for={"checkbox_A" + this.props.index} id={"checked_A" + this.props.index} className={this.props.question && this.props.question["right"] && this.props.question.right === "A" ? "checked" : ""}  ></label>
                            </div>
                        </td>
                        <td style={{width: "95px", border: "none"}} className="quiz-black-td"></td>
                    </tr>
                    <tr>
                        <td style={{width: "95px", border: "none"}}></td>
                        <td>
                
                
                            <div className="form-input-title left_border" ><span className="small-text  ">B</span></div>
                            <div id={"item-image-B-" + this.props.index} style={{width: "200px", margin: "0 auto", marginBottom: "30px", display: "inline-block", verticalAlign: "middle"}}>
                                <div id={"item-B-" + this.props.index} style={{width: "200px", height: "200px", margin: "0 auto", background: "#252629", textAlign: "center"}}>
                                    <AddFile selectFile = {this.props.addImage} index={ this.props.index} answerIndex="B"/>
                                </div>
                            </div>
                            <div class="squaredThree">
                                <input type="checkbox" id={"checkbox_B" + this.props.index}  name="check"  checked={this.props.question && this.props.question["right"] && this.props.question.right === "B" ? "checked" : ""}    onChange={(e) => this.props.handleRightAnswerChange(e, this.props.index, "B")} />
                                <label for={"checkbox_B" + this.props.index} id={"checked_B" + this.props.index} className={this.props.question && this.props.question["right"] && this.props.question.right === "B" ? "checked" : ""} ></label>
                            </div>
                        </td>
                        <td style={{width: "95px", border: "none"}} className="quiz-black-td"></td>
                    </tr>
                    <tr>
                        <td style={{width: "95px", border: "none"}}></td>
                        <td>
                
                
                            <div className="form-input-title left_border" ><span className="small-text  ">C</span></div>
                            <div id={"item-image-C-" + this.props.index} style={{width: "200px", margin: "0 auto", marginBottom: "30px", display: "inline-block", verticalAlign: "middle"}}>
                                <div id={"item-C-" + this.props.index} style={{width: "200px", height: "200px", margin: "0 auto", background: "#252629", textAlign: "center"}}>
                                    <AddFile selectFile = {this.props.addImage} index={ this.props.index} answerIndex="C"/>
                                </div>
                            </div> 
                            <div class="squaredThree">
                                <input type="checkbox" id={"checkbox_C" + this.props.index}  name="check" checked={this.props.question && this.props.question["right"] && this.props.question.right === "C" ? "checked" : ""}     onChange={(e) => this.props.handleRightAnswerChange(e, this.props.index, "C")} />
                                <label for={"checkbox_C" + this.props.index} id={"checked_C" + this.props.index} className={this.props.question && this.props.question["right"] && this.props.question.right === "C" ? "checked" : ""} ></label>
                            </div>
                        </td>
                        <td style={{width: "95px", border: "none"}} className="quiz-black-td"></td>
                    </tr>
                    <tr>
                        <td style={{width: "95px", border: "none"}}></td>
                        <td>
                
                
                            <div className="form-input-title left_border" ><span className="small-text  ">D</span></div>
                            <div id={"item-image-D-" + this.props.index} style={{width: "200px", margin: "0 auto", marginBottom: "30px", display: "inline-block", verticalAlign: "middle"}}>
                                <div id={"item-D-" + this.props.index} style={{width: "200px", height: "200px", margin: "0 auto", background: "#252629", textAlign: "center"}}>
                                    <AddFile selectFile = {this.props.addImage} index={ this.props.index} answerIndex="D"/>
                                </div>
                            </div>
                            <div class="squaredThree">
                                <input type="checkbox" id={"checkbox_D" + this.props.index}  name="check" checked={this.props.question && this.props.question["right"] && this.props.question.right === "D" ? "checked" : ""}      onChange={(e) => this.props.handleRightAnswerChange(e, this.props.index, "D")} />
                                <label for={"checkbox_D" + this.props.index} id={"checked_D" + this.props.index} className={this.props.question && this.props.question["right"] && this.props.question.right === "D" ? "checked" : ""} ></label>
                            </div>
                        </td>
                        <td style={{width: "95px", border: "none"}} className="quiz-black-td"></td>
                    </tr>
                    <tr>
                        <td style={{width: "95px", border: "none"}}></td>
                        <td>
                
                
                            <div className="form-input-title left_border" ><span className="small-text  "></span></div>
                            <input name="comment" id="comment"  className="item-form-input " onChange={(e) => this.props.handleQuestionChange(e, this.props.index)} value={this.props.question && this.props.question["comment"] ? this.props.question["comment"] : "" }   placeholder="Magyarázat"  />
                
                        </td>
                        <td style={{width: "95px", border: "none"}} className="quiz-black-td"></td>
                    </tr>
                </React.Fragment>

                )
    }
}
export default ModuleQuizPictureAnswersForm;






