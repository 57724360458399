import React, { Component}
from "react";
import Directories from "../components/Directories"
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';


        class FileUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            user:  localStorage.hasOwnProperty("user") && !localStorage.getItem("user").id ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'),"pazirik77").toString(CryptoENC)):"",
            selectedDirectory: []
        };

        this.selectImage = this.selectImage.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.cancel = this.cancel.bind(this);
        this.changeDirectory = this.changeDirectory.bind(this);
        this.createNewDirectory = this.createNewDirectory.bind(this);
        this.addDirectory = this.addDirectory.bind(this);
        this.createDirectory=this.createDirectory.bind(this);
        
    }

    createNewDirectory() {
        document.getElementById("add-directory").classList.remove("d-none");
    }

    addDirectory() {
        document.getElementById("add-directory").classList.add("d-none");
       
            this.createDirectory(document.getElementById("directory-name").value)
        

    }
    
     createDirectory(newDirectory) {

    

        var data = JSON.stringify({directoryId: this.state.selectedDirectory["id"], path: this.state.selectedDirectory["path"], directoryName: newDirectory, userId: this.state.user.id, institutionId: localStorage.getItem("institution")});
        fetch("/file/createDirectory", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: data
        })
               .then(response => response.json())
                .then(response => {
                  let directory=[];
                  directory.subDir=[];
                  directory["name"] = newDirectory;
                  directory["directoryId"]= this.state.selectedDirectory.id;
                  directory["id"]= response;
                   this.directories.directoryCreate(directory);
                   this.directories.getImages(directory);
                });
    }
     
    
    cancel() {
        document.getElementsByClassName("file-popup")[0].classList.add("d-none");
    }

    changeDirectory(images, selectedDirectory) {
        this.setState({"fileList": images, "selectedDirectory": selectedDirectory});
    }

    componentDidMount() {
        /*
         fetch("/file/getAllInsFiles/" + localStorage.getItem("institution"))
         .then(response => response.json())
         .then(data => {
         this.setState({fileList: data})
         
         })
         
         */
    }

    selectImage(path, fileName, id) {
        document.getElementsByClassName("file-popup")[0].classList.add("d-none");
        this.props.selectFile(path, fileName, id);
    }

    onFileChange(e) {

        const fileName = e.target.files[0].name;
        const formData = new FormData()
        formData.append('file', e.target.files[0]);
        formData.append("userId", this.state.user.id);
        formData.append("institutionId", localStorage.getItem("institution"));
        formData.append("path", this.state.selectedDirectory["path"]);
        formData.append("directoryId", this.state.selectedDirectory["id"]);
        //formData.append('path', '/modules/' + this.state.id + "/");
        fetch("/file/save", {
            method: 'POST',

            body: formData
        })
                .then(response => response.json())
                .then(response => {
                    let file = {};

                    file.fileName = fileName;
                    file.path = "uploads/" + fileName;
                    file.id = response;

                    this.state.fileList.unshift(file);
                    this.setState({fileList: this.state.fileList});


                });


    }

    render() {



        return (
                <div  className=" file-popup" >
                
                    <h2 style={{display: "inline"}}>MÉDIATÁR</h2>
                    <a href="" style={{float: "right", background: "black"}} className="black-link" onClick={this.cancel}>MÉGSEM</a>  
                    <div className="row">
                        <input className="item-form-input d-none" name="images" id="images" type="file" placeholder="+ KÉP CSATOLÁSA" onChange={(e) => this.onFileChange(e)}></input>
                        <label htmlFor="images" >+ <b style={{paddingLeft: "0"}}>KÉP</b> CSATOLÁSA</label> 
                        <span style={{marginLeft: "30px",fontSize : "12px"}} onClick={this.createNewDirectory}>MAPPA <b> HOZZÁADÁSA</b> </span>
                    </div><br/>
                    <div  id="filelist" className="row" >
                        <div className="col-sm directory-table-div" style={{maxHeight: "400px", marginTop: "unset"}}>
                            <table className="media-table no-edit-table" style={{marginLeft: "5px", width: "100%"}}>
                                <Directories onRef={ref => (this.directories = ref)} changeDirectory={this.changeDirectory} selectedDirectory={this.state.selectedDirectory}/>
                            </table>
                        </div>
                        <div className="col-md" style={{marginLeft: "400px"}}>
                            {this.state.fileList.length > 0 ? this.state.fileList.map(file =>
                                <div className="image-div" onClick={() => this.selectImage(file.path, file.fileName, file.id)}>
                                    <img draggable={false} src={"/file/download?fileId=" + file.id} /><br/>
                                    <span>{file.fileName}</span> 
                                </div>

                                ) : ""}
                        </div>
                    </div>
                  <div id="add-directory" className="d-none">
                       
                        <input type="text" id="directory-name" name="directory-name" placeholder="MAPPA MEGNEVEZÉSE"/>
                        <button style={{border : "none", fontSize : "12px"}} className="blue-back-link" onClick={this.addDirectory}>HOZZÁAD</button>
                    </div>
                
                </div>

                );
    }
}

export default FileUpload;

