
import React, { Component}from "react";
import {ModuleService}from "../../_services/ModuleService";
import ModuleFormsHeader from"./ModuleFormsHeader"
import AddFile from "../components/AddFile"

        class ModulePairingTextForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            itemNo: 0,
            items: {},
            bookedSigns: []
        }
        this.subThemeThemeModal = React.createRef();
        this.hideModal = this.hideModal.bind(this);
        this.addMemoryItem = this.addMemoryItem.bind(this);
        this.save = this.save.bind(this);
        this.addImage = this.addImage.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.deleteItemItem = this.deleteItemItem.bind(this);
        this.ModuleFormsHeader = React.createRef();

        this.signs = ['A', 'B', 'C', 'D', 'E'];
    }

    addImage(id, index) {

      /*  const fileName = e.target.files[0].name;

        const elementId = e.target.id;
        const elementIndex = document.getElementById(elementId).getAttribute("itemindex");
        const elementPosition = e.target.position;

        const formData = new FormData()
        formData.append('file', e.target.files[0]);

        formData.append('path', '/modules/' + localStorage.getItem("moduleId") + "/");
        fetch("/file/save", {
            method: 'POST',

            body: formData
        })
                .then(response => response.json())
                .then(response => {


                    document.getElementById("item-" + document.getElementById(elementId).getAttribute("itempos") + "-" + elementIndex).style.backgroundImage = "url('/file/download?fileName=uploads/modules/" + localStorage.getItem("moduleId") + "/" + fileName + "')";
                    document.getElementById("item-" + document.getElementById(elementId).getAttribute("itempos") + "-" + elementIndex).style.backgroundSize = "cover";
                    /*  document.getElementById("memory-item-" + index).getElementsByClassName("btn-add-image")[0].style.display = "none";*/
                   // document.getElementById("item-" + elementId).value = "uploads/modules/" + localStorage.getItem("moduleId") + "/" + fileName;

//                });
                
                 document.getElementById("item-" +index).style.backgroundImage = "url('/file/download?fileId=" + id + "')";
                    document.getElementById("item-" + index).style.backgroundSize = "cover";
                   
                    document.getElementById("item-url-" + index).value = id;
                
                
                
    }

    addMemoryItem() {

        this.setState({"itemNo": this.state.itemNo + 1});

      

    }

    save() {
        var error = false;
        var index = 0;
        for (var i = 0; i < this.state.itemNo; i++) {
            if (document.getElementById("item-div-" + i)) {

                const mainSign = document.getElementById("item-main-sign-" + i).value == "" ? (i + 1).toString() : document.getElementById("item-main-sign-" + i).value;
                var pairSign = document.getElementById("item-pair-sign-" + i).value;

                if (pairSign == "") {

                    let restSigns = this.signs.filter(x => !this.state.bookedSigns.includes(x));
                   
                    pairSign = restSigns[Math.floor(Math.random() * restSigns.length)];

                    this.state.bookedSigns.push(pairSign);
                    this.setState({bookedSigns: this.state.bookedSigns});
                }

                this.state.items[index] = {};
                this.state.items[index]["img_url"] = document.getElementById("item-url-main-" + i).value;
                this.state.items[index]["pair_sign"] = mainSign;
                this.state.items[index]["pair_item_sign"] = pairSign;
                this.state.items[index]["id"] = document.getElementById("item-id-main-" + i).value === "" ? 0 : document.getElementById("item-id-main-" + i).value;
                this.state.items[index]["main_pair"] = true;
                this.state.items[index]["pair_label_name"] = "";
                 this.state.items[index]["pair_label_name"] = document.getElementById("item-main-name-" + i).value;

                index++;

                this.state.items[index] = {};
                this.state.items[index]["img_url"] = document.getElementById("item-pair-name-" + i).value;
                this.state.items[index]["pair_sign"] = pairSign;
                this.state.items[index]["pair_item_sign"] = mainSign;
                this.state.items[index]["id"] = document.getElementById("item-id-pair-" + i).value === "" ? 0 : document.getElementById("item-id-pair-" + i).value;
                this.state.items[index]["main_pair"] = false;
                this.state.items[index]["pair_label_name"] = "";

                index++;

                this.setState({"items": this.state.items});
            }

        }

        if (!error) {
            this.props.saveModule("PairingText", this.state.items);
            this.hideModal();
        }
    }

    deleteItem(index) {
        document.getElementById("item-div-" + index).remove();
    }

    deleteItemItem(pos, index) {
        document.getElementById("item-" + pos + "-" + index).style.backgroundImage = "";
        document.getElementById("item-" + pos + "-url-" + index).value = "";

    }
    componentDidMount() {

        if (this.props.moduleId != 0) {

            fetch("/pairingitem/find/" + this.props.moduleId)
                    .then(response => response.json())
                    .then(pairs => {

                        var mainPairs = pairs.filter(pair => pair.main_pair === true);
                        this.setState({itemNo: mainPairs.length});

                     

                        for (var i = 0; i < mainPairs.length; i++) {
                            document.getElementById("item-main-" + i).style.backgroundImage = "url('/file/download?fileId=" + mainPairs[i].img_url + "')";
                            document.getElementById("item-id-main-" + i).value = mainPairs[i].id;
                            document.getElementById("item-main-name-" + i).value = mainPairs[i].pair_label_name;
                            document.getElementById("item-url-main-" + i).value = mainPairs[i].img_url;
                            document.getElementById("item-main-sign-" + i).value = mainPairs[i].pair_sign;
                            document.getElementById("item-main-pair-sign-" + i).value = mainPairs[i].pair_item_sign;

                            this.state.bookedSigns.push(mainPairs[i].pair_item_sign);
                            this.setState({bookedSigns: this.state.bookedSigns});


                            const pairPairs = pairs.filter(pairPair => pairPair.pair_sign === mainPairs[i].pair_item_sign);
                          
                            document.getElementById("item-id-pair-" + i).value = pairPairs[0].id;
                            document.getElementById("item-pair-name-" + i).value = pairPairs[0].img_url;
                            document.getElementById("item-url-pair-" + i).value = pairPairs[0].img_url;
                            document.getElementById("item-pair-sign-" + i).value = pairPairs[0].pair_sign;
                            document.getElementById("item-pair-pair-sign-" + i).value = pairPairs[0].pair_item_sign;

                        }

                    });

        }


    }

    hideModal() {
        window.scrollTo({
            top: 400,
            behavior: 'smooth',
        })
        document.body.classList.remove('display-block');
        let element = document.getElementsByClassName("modal");
        element[0].classList.add("display-none");
        document.body.classList.remove("modal-admin-body");
        //window.scrollTo(0,localStorage.getItem("scrollTop"));

    }

    render() {



        return (
                <div  className="modal-dialog">
                    <section  className="modal-content" >
                        <div className="">
                            <ModuleFormsHeader ref={this.ModuleFormsHeader} title="SZÖVEG PÁROSÍTÓ" save={this.save} delete={this.props.deletePreview} context={this.props.contextModule}/>
                
                
                
                            <div className="modal-body" >
                                <div  id="pairing-item-container" style={{width: "800px", padding: "20px",display:"inline-flex" , overflowY : "auto"}}>
                                    {[...Array(this.state.itemNo), ].map((value, index) => (
                                        <div className="add-item" key={index} id={"item-div-" + index}>
                    
                                            <input type="hidden" id={"item-id-" + index}  />
                                            <div className="item-content main-content" id={"item-main-" + index}>
                                                <div className="input-div">
                                                    <input id={"item-main-name-" + index} type="text" placeholder="MEGNEVEZÉS" />
                                                    <input type="hidden" id={"item-url-main-" + index} />
                                                    <input type="hidden" id={"item-id-main-" + index} />
                                                    <input type="hidden" id={"item-main-sign-" + index} />
                                                    <input type="hidden" id={"item-main-pair-sign-" + index} />
                    
                                                </div>
                                               <AddFile selectFile = {this.addImage} index={"main-"+index}/>
                                            </div>
                    
                                           
                                            <div className="paring-item-pair-space">
                                                <div className="paring-item-pair-space-middle"></div>
                                            </div>
                                         
                                            <div className="item-content pair-content" id={"item-pair-" + index}>
                                                <div className="input-div">
                                                    <input id={"item-pair-name-" + index} type="text" placeholder="MEGNEVEZÉS" className="pairing-text-input" />
                                                    <input type="hidden" id={"item-url-pair-" + index} />
                                                    <input type="hidden" id={"item-id-pair-" + index} />
                                                    <input type="hidden" id={"item-pair-sign-" + index} />
                                                    <input type="hidden" id={"item-pair-pair-sign-" + index} />
                    
                                                </div>
                    
                                            </div>
                                            <br/>
                    
                    
                                            <a href="#" className="delete-link black-link item-main-delete" onClick={() => this.deleteItem(index)} >Pár Törlés</a>
                                        </div>
                                                    )
                                        )
                                    }
                                </div>
                                <a className={"btn btn-add-item "+ (this.state.itemNo>=5 ? "disabled-add-item" : "") } onClick={this.addMemoryItem}>Új elem hozzáadása</a>
                            </div>
                
                
                        </div>
                
                    </section>
                
                </div>
                );
    }
}


export default ModulePairingTextForm;









