import React from 'react';


class Tour extends React.Component {
    constructor(props, context) {

        super(props, context);
        this.state = {

        }
    }

    render() {

        return(
                <div class="row" style={{position: "relative"}}>
                    <div  style={{width: "100%", height: "100vh", margin: "0 auto", textAling: "center"}}>
                
                        <iframe width='100%' height='100%' src="https://virtualisturak.pazirikkft.hu/virtualistura/eger_thaa_gyerek/" frameBorder='0'  allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"></iframe>
                    </div>
                    <a className=" little_forward" id="forward_module_button" style={{width: "300px", position: "absolute", right: "10px", bottom: "80px", textDecoration: "none", margin: "0 auto"}} href="#" onClick={this.props.endGame}></a>
                </div>
                );
    }

}
;
export default Tour;



