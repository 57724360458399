
import React, { Component}from "react";
import FileUpload from "../components/FileUpload"
import ReactDOM from "react-dom";


class FormHeader extends Component {

    render() {



        return (
                <div className="row page-header">
                
                    <div className="col-sm  align-baseline">
                        <i className="fa" id="info-label-header"  ></i>
                        <h2 className="align-text-bottom">{this.props.title} <b>LÉTREHOZÁSA</b></h2>
                    </div>
                
                    <div className="col-sm text-right">
                        <a className="blue-back-link" onClick={this.props.checkSave}>MENTÉS</a>
                        <a href="#" className="black-link delete-link" onClick={this.props.checkDelete}>TÖRLÉS</a>
                        <a href="#" className="black-link" onClick={this.props.cancel}>MÉGSEM</a> 
                    </div>
                
                </div>

                );
    }
}

export default FormHeader;


