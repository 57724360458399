


import React, { Component} from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import { Redirect, Link} from 'react-router-dom';
import muzpedIcon from "../../images/muzped_icon.png"
import "../../style/user.css";
import muzpedText from "../../images/muzped_logo_text.png"
import ModuleVideo from "./ModuleVideo";
import ModuleQuiz from "./ModuleQuiz";
import ModulePairing from "./ModulePairing";
import ModulePairingText from "./ModuleTextPairing";
import ModuleVoice from "./ModuleVoice";
import ModuleSwipe from "./ModuleSwipe";
import ModuleMultiSwipe from "./ModuleMultiSwipe";
import ModuleText from "./ModuleText";
import ModuleMemory from "./ModuleMemory";
import ModulePicture from "./ModulePicture";
import ThemeModal from "../theme/ThemeModal";
import muzpedMinta from "../../images/gameend_back.png"
import {authService} from "../../_services/authService"
import ModulePanoramaTour from "./ModulePanoramaTour"
import Module3D from "./Module3D"
import ModulePictureQuiz from "./ModulePictureQuiz"
import ModulePairingMemory from "./ModulePairingMemory"
import ModuleQuizPictureAnswers from "./ModuleQuiz"
import ModuleBonusScore from "./ModuleBonusScore"
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';

        class ModuleDashboard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'), "pazirik77").toString(CryptoENC)) : "",
            themeId: localStorage.getItem("subThemeId"),
            theme: JSON.parse(localStorage.getItem('subTheme')),
            modules: [],
            moduleCounts: 0,
            modulePreview: [],
            activeModule: localStorage.getItem("activeModule") ? localStorage.getItem("activeModule") : "",
            nextPageLink: localStorage.getItem("nextPageLink") ? localStorage.getItem("nextPageLink") : "",
            moduleIndex: localStorage.getItem("moduleIndex") ? localStorage.getItem("moduleIndex") : 0,
            module: [],
            hasModulePreview: true

        };
        this.playComponents = ["ModuleQuiz", "ModulePairing", "ModuleMemory", "ModulePairingText","ModulePictureQuiz","ModulePairingMemory"];


        this.goToNextPage = this.goToNextPage.bind(this);
        this.loadNextModule = this.loadNextModule.bind(this);
        this.goToPreviosPage = this.goToPreviosPage.bind(this);
        this.loadPreviosModule = this.loadPreviosModule.bind(this);
        this.showModules = this.showModules.bind(this);
        this.updateModuleList = this.updateModuleList.bind(this);
        this.openQuizFinal = this.openQuizFinal.bind(this);
        this.logout = this.logout.bind(this);
        this.backToThemes = this.backToThemes.bind(this);
        this.createModule = this.createModule.bind(this);
        this.childRef =  React.createRef();

    }

    backToThemes() {
        const themeId = localStorage.getItem("themeId");
        document.getElementById("root").classList.remove("gameend-body");
        fetch("/subtheme/find/" + themeId)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 1) {
                        this.props.history.push({
                            pathname: '/subThemeList',
                            data: themeId // your data array of objects
                        })

                    } else {
                        localStorage.setItem("subThemeId", data[0].id);
                        this.props.history.push({
                            pathname: '/themeList',

                        })
                    }

                })

    }
    logout() {
        authService.logout();
        this.props.history.push("/")
    }
    openQuizFinal() {

        var answers = document.getElementsByClassName('final_question_container');

        if (document.getElementsByClassName('final_question_container_closed').length > 0) {
            for (var y = 0; y < answers.length; y++) {

                answers[y].classList.add("final_question_container_open");
                answers[y].classList.remove("final_question_container_closed");
                document.getElementById("final-quiz-button").classList.add("final-quiz-button-open");
            }
        } else {
            for (var y = 0; y < answers.length; y++) {

                answers[y].classList.remove("final_question_container_open");
                answers[y].classList.add("final_question_container_closed");
                document.getElementById("final-quiz-button").classList.remove("final-quiz-button-open");
            }
        }
    }
    showModules() {

        let element = document.getElementsByClassName("modal");
        element[0].classList.remove("display-none");
        element[0].classList.add("display-block");
    }

    loadNextModule() {
        ReactDOM.unmountComponentAtNode(document.getElementById("module-container"));
         localStorage.setItem("activeModule", '');
          this.setState({"activeModule": '' });
        document.getElementById("module-next-question").classList.add("d-none");
        document.getElementById("final-quiz-button").classList.add("d-none");
        const index = parseInt(this.state.moduleIndex) + 1;

        document.getElementById("root").classList.remove("gameend-body");
        if (this.state.modules[index]) {
            fetch("/module/find/" + this.state.modules[index].moduleId)
                    .then(response => response.json())
                    .then(module => {
                        //   ReactDOM.unmountComponentAtNode(document.getElementById('module-container'));
                        this.setState({"module": module});
                        fetch("/modulepreview/find/" + this.state.modules[index].moduleId)
                                .then(response => response.json())
                                .then(data => {

                                    this.setState({"modulePreview": data})

                                    localStorage.setItem("nextPageLink", "Module" + module.moduleType);
                                    localStorage.setItem("activeModuleId", this.state.modules[index].moduleId)
                                    this.setState({"nextPageLink": "Module" + module.moduleType});
                                    if (data[0]) {
                                        localStorage.setItem("modulePreview", JSON.stringify(data[0]));
                                        this.setState({"activeModule": 'Module' + data[0].moduleType});
                                        localStorage.setItem("activeModule", 'Module' + data[0].moduleType);
                                    } else {
                                        console.log("active_module");
                                        console.log(module);
                                        localStorage.setItem("modulePreview", []);
                                         console.log(module.moduleType);
                                        this.setState({"activeModule": 'Module' + module.moduleType});
                                       
                                        localStorage.setItem("activeModule", 'Module' + module.moduleType);
                                        this.setState({"modulePreview": []})
                                        this.setState({"hasModulePreview": false})
                                    }

                                    this.setState({"moduleIndex": index});
                                    localStorage.setItem("moduleIndex", index);
                                    this.refs.themeModal.updateModules();
                                    this.refs.themeModal.getNextModule();

                                })
                    })

        } else {
            document.getElementById("root").classList.add("gameend-body");
            document.getElementById("end-module-container").classList.remove("d-none");
            document.getElementById("module-container").classList.add("d-none");
            document.getElementById("module_button").classList.add("invisible");
            document.getElementById("forward_button").classList.add("invisible");
            document.getElementById("end-modules-button").classList.remove("d-none");
            // document.getElementById("module-middle-footer").innerHTML = '<a class="black_border gameend-back" style="text-decoration:none; padding:20px;" href="/subThemeList"><b>VISSZA</b> A TÉMA VÁLASZTÓHOZ</a>'
            document.getElementById("module-name").innerHTML = "<span>Elvégezted az összes feladatot<span>";
            document.getElementById("end-module-container").innerHTML = '<div style="margin : auto;max-height:200px;font-weight:bold"><h1  class="congratulation-text">GRATULÁLUNK!</h1></div>';
            if (document.getElementsByClassName("gameend-img").length) {
                document.getElementsByClassName("gameend-img")[0].setAttribute("src", muzpedMinta)
            } else
            {

                let img = document.createElement("img");
                img.src = muzpedMinta;
                img.className = "gameend-img";
                document.getElementById("root").appendChild(img);
            }
        }


    }

    updateModuleList() {

        fetch("/thememodule/getActives/" + this.state.themeId)
                .then(response => response.json())
                .then(data => {

                    this.setState({"modules": data});
                    localStorage.setItem("modules", data);
                });
    }

    loadPreviosModule() {
       ReactDOM.unmountComponentAtNode(document.getElementById("module-container"));
       localStorage.setItem("activeModule", '');
        this.setState({"activeModule": '' });
        if (document.getElementById("final-quiz-button")) {
            document.getElementById("final-quiz-button").classList.add("d-none");
        }
        const index = parseInt(this.state.moduleIndex) - 1;
        document.getElementById("root").classList.remove("gameend-body");
        document.getElementById("module-next-question").classList.add("d-none");
        if (this.state.modules[index]) {
            fetch("/module/find/" + this.state.modules[index].moduleId)
                    .then(response => response.json())
                    .then(module => {
                        // ReactDOM.unmountComponentAtNode(document.getElementById('module-container'));

                        this.setState({"module": module});
                        fetch("/modulepreview/find/" + this.state.modules[index].moduleId)
                                .then(response => response.json())
                                .then(data => {



                                    localStorage.setItem("nextPageLink", "Module" + module.moduleType);
                                    localStorage.setItem("activeModuleId", this.state.modules[index].moduleId)


                                    if (data[0]) {
                                        this.setState({"modulePreview": data})
                                        localStorage.setItem("modulePreview", JSON.stringify(data[0]));
                                        this.setState({"nextPageLink": "Module" + module.moduleType});
                                        this.setState({"activeModule": 'Module' + data[0].moduleType});
                                        localStorage.setItem("activeModule", 'Module' + data[0].moduleType);
                                    } else {
                                        this.setState({"nextPageLink": "Module" + module.moduleType});
                                        this.setState({"activeModule": 'Module' + module.moduleType});
                                        localStorage.setItem("activeModule", 'Module' + module.moduleType);
                                        localStorage.setItem("modulePreview", []);
                                        this.setState({"hasModulePreview": false})
                                        this.setState({"modulePreview": []})
                                    }
                                    this.setState({"moduleIndex": index});
                                    localStorage.setItem("moduleIndex", index);
                                    this.refs.themeModal.updateModules();
                                    this.refs.themeModal.getNextModule();
                                })
                    })

        } else {
            this.props.history.push("/subThemeDescription");
        }
    }

    goToNextPage() {
        if (document.getElementById("final-quiz-button")) {
            document.getElementById("final-quiz-button").classList.add("d-none");
        }

        document.getElementById("root").classList.remove("gameend-body");
        if (this.playComponents.indexOf(localStorage.getItem('activeModule')) !== -1 || localStorage.getItem("nextPageLink") == "Module") {
            this.loadNextModule()
        } else {
            localStorage.setItem("activeModule", localStorage.getItem("nextPageLink"));
            this.setState({"activeModule": localStorage.getItem("nextPageLink")});

        }
    }

    goToPreviosPage() {
        if (document.getElementById("final-quiz-button")) {
            document.getElementById("final-quiz-button").classList.add("d-none");
        }
        document.getElementById("root").classList.remove("gameend-body");
        document.getElementById("module-next-question").classList.add("d-none");
        document.getElementById("end-module-container").classList.add("d-none");
        document.getElementById("module-container").classList.remove("d-none");
        document.getElementById("module_button").classList.remove("invisible");
        document.getElementById("forward_button").classList.remove("invisible");
        document.getElementById("end-modules-button").classList.add("d-none");
        if (this.playComponents.indexOf(localStorage.getItem('activeModule')) !== -1 && localStorage.getItem("modulePreview")) {

            document.getElementById("end-module-container").classList.add("d-none");
            document.getElementById("module-container").classList.remove("d-none");
            document.getElementById("end-modules-button").classList.add("d-none");
            document.getElementById("module_button").classList.remove("invisible");
            document.getElementById("forward_button").classList.remove("invisible");
            //  document.getElementById("module-middle-footer").innerHTML = "";
            var modulePreview = JSON.parse(localStorage.getItem("modulePreview"));
            localStorage.setItem("activeModule", "Module" + modulePreview.moduleType);
            this.setState({"activeModule": "Module" + modulePreview.moduleType});
        } else {

            this.loadPreviosModule()

        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        let host = window.location.host;

        let parts = host.split(".");
        let subdomain = "";

        if (parts.length >= 3) {
            subdomain = parts[0];

        }
        localStorage.removeItem("onlineGame");
        document.body.classList.add(subdomain + 'muzped_back');
        document.body.classList.remove(subdomain + 'theme_back');

        document.body.classList.add('module_back');
        document.body.classList.remove('theme_back');

        fetch("/thememodule/getActives/" + this.state.themeId)
                .then(response => response.json())
                .then(data => {

                    this.setState({"modules": data});
                    localStorage.setItem("modules", data);

                    if (this.state.modules.length) {
                        fetch("/module/find/" + this.state.modules[this.state.moduleIndex].moduleId)
                                .then(response => response.json())
                                .then(module => {

                                    this.setState({"module": module});
                                    fetch("/modulepreview/find/" + this.state.modules[this.state.moduleIndex].moduleId)
                                            .then(response => response.json())
                                            .then(data => {

                                                this.setState({"modulePreview": data})

                                                localStorage.setItem("nextPageLink", "Module" + module.moduleType);
                                                localStorage.setItem("activeModuleId", this.state.modules[this.state.moduleIndex].moduleId)

                                                if (data[0])
                                                    localStorage.setItem("modulePreview", JSON.stringify(data[0]));
                                                else
                                                    localStorage.setItem("modulePreview", []);
                                                if (this._isMounted) {
                                                    if (this.state.activeModule == "") {
                                                        if (data[0]) {
                                                            this.setState({"activeModule": 'Module' + data[0].moduleType});
                                                            localStorage.setItem("activeModule", 'Module' + data[0].moduleType);
                                                        } else {
                                                            this.setState({"activeModule": 'Module' + module.moduleType});
                                                            this.setState({"nextPageLink": "Module" + module.moduleType});
                                                            localStorage.setItem("modulePreview", []);
                                                            this.setState({"modulePreview": []});
                                                            this.setState({"hasModulePreview": false})
                                                            localStorage.setItem("activeModule", 'Module' + module.moduleType);
                                                        }


                                                        localStorage.setItem("moduleIndex", this.state.moduleIndex);
                                                    }
                                                }
                                            })
                                })
                    }
                });




    }
    
    
      createModule(){
           const components = window.components;
           
         const Component = components[this.state.activeModule];
                                    return <Component ref="childRef" onRef={ref => (this.childRef = ref)}/>;
    }
    

    render() {
        const {user, isOn, handleToggle, onColor} = this.state;
     
        const label = {"ModuleQuizPictureAnswers" : "Kvíz","ModuleSwipe": "Swipe","ModulePairingMemory" : "Párosító memória","ModulePictureQuiz" : "Kép Kvíz", "ModulePanoramaTour" : "Gömbpanoráma / Virtuális túra","Module3D" : "3D modell","ModuleMultiSwipe": "Multi-Swipe", "ModuleVideo": "Videó", "ModuleText": "Leírás", "ModuleQuiz": "Kvíz", "ModuleVoice": "Hang", "ModulePairing": "Párosító", "ModuleMemory": "Memória", "ModulePicture": "Képek", "ModulePairingText": "Párosító"};
     
        if (!user) {
            return <Redirect to="/login" />
        }
        
      
        return (
                <div id="content" className="wrapper">  
                    <div className="container" >
                        <div className="row">
                            <div className="col-lg text-left">
                                <i className="muzped-icon"></i>
                                <label className="black_border"  style={{borderLeft: "none", fontWeight: "bold", textTransform: "uppercase"}}>{this.state.theme.subThemeName}</label><br/>
                                <label className="black_border module-number" >{parseInt(this.state.moduleIndex) + 1}</label>
                            </div>
                
                            <div className="col-sm text-center align-baseline">
                                <h3 className="align-text-bottom" id="module-name" >{this.state.module.moduleName}</h3>
                            </div>
                            <div className="col-sm text-right">
                
                                <label className="black_border modul-type-name" style={{fontWeight: "bold", textTransform: "uppercase", borderRight: "none"}}>{label[this.state.activeModule]}</label> 
                                <i className="muzped-text"></i>
                                <br/>
                                <button className="btn-logout btn" onClick={this.logout}></button>
                            </div>
                        </div>
                        <div className="row d-none" style={{marginTop: '90px'}} id="end-module-container" >
                
                
                        </div>
                        <div className="row " id="module-container" >
                
                
                            {this.state.activeModule !== '' ?
                                      //  React.createElement(components[this.state.activeModule])
                                   //   <{components[this.state.activeModule} />
                                  this.createModule()
                                        : ''}
                
                        </div>
                    </div>
                    <footer className="muzped-footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <button onClick={this.goToPreviosPage} className="btn-backward btn"></button>
                                    <button  onClick={this.goToNextPage} id="forward_button" className="btn-forward btn"></button>
                                    <button className="btn-module btn" id="module_button" onClick={this.showModules} ></button>
                
                                    <div id="modal-container">
                                        <ThemeModal ref="themeModal" onRef={ref => (this.themeModal = ref)} loadNextModule={this.loadNextModule} updateModuleList={this.updateModuleList} showButtons={true} draggable={true}/> 
                
                
                
                                    </div>
                
                                </div>
                                <div className="col-md-6 text-center " id="module-middle-footer">
                                    <button className="btn black_border d-none" id="final-quiz-button"  onClick={() => this.openQuizFinal()} >?</button>
                                    <a className="black_border gameend-back d-none" id="end-modules-button" style={{textDecoration: "none", padding: "20px"}} href="#" onClick={this.backToThemes}><b>VISSZA</b> A TÉMA VÁLASZTÓHOZ</a>
                                    <div id="module-next-question" className="d-none">
                                       <button  id="quiz_before_question"  onClick={()=> this.refs.childRef.beforeQuestion("")}  className="theme_button theme_green_button quiz_buttons">Előző</button>
                                       <button  id="quiz_nextquiz_question"  onClick={()=> this.refs.childRef.nextQuestion("")}  className="theme_button theme_green_button quiz_buttons">Következő</button>
                                    </div>
                                </div>
                                <div className="col-md-3 text-right">
                                    <Link to="/dashboard"><button className="btn-home btn"></button></Link>
                                </div>
                
                            </div>
                        </div>
                    </footer>
                
                </div>
                );
    }
}

export default withRouter(ModuleDashboard);