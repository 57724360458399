import React, { Component }
from "react";
import { withRouter }
from "react-router";
import { Redirect }
from 'react-router-dom';
import "../style/admin.css";
import AdminSideBar from './AdminSideBar';
import SearchComponent from './components/SearchComponent'
import FilterComponent from './components/FilterComponent'
import SwitchComponent from './components/SwitchComponent'
import Table from './components/Table'
import ListHeader from './components/ListHeader'
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';

class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'),"pazirik77").toString(CryptoENC)):"",
           filters : [],
            tasks: [],

        };
        
        this.deleteTheme = this.deleteTheme.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
    }
    
    changeStatus(index,id, status){
       
        this.state.tasks[index].active= status;
        this.setState({tasks : this.state.tasks});
        
        fetch("/theme/setActive", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({themeId: parseInt(id), active: status})
    })

            .then(response => {
               
                return response;
            });

    }
     deleteTheme (themeId){
         fetch("/theme/delete/"+themeId)
                .then(response => response.json())
                .then(response => {
                     const deletedIndex = this.state.tasks.findIndex(theme =>   theme.id===themeId);
                   
                     this.state.tasks.splice(deletedIndex,1);
                    this.setState({tasks : this.state.tasks})
                })
        
    }

    componentDidMount() {
        localStorage.setItem("activeMenu", "");
        let tasks = [];
        fetch("/theme/allinstitution/"+localStorage.getItem("institution"))
                .then(response => response.json())
                .then(data => {
                    data.map(theme => {
                        theme.name=theme.themeTitle
                    })
                    this.setState({tasks: data})
                })
    }

    componentWillMount() {
        document.body.classList.remove('admin-login-body');
        document.body.classList.remove('admin-welcome-body');
        document.body.classList.add('admin-body');
        document.body.classList.add('body');
    }
    render() {
        const {user } = this.state;
        if (!user) {
            return <Redirect to="/adminlogin" />
        }
        return (
                <React.Fragment>  <AdminSideBar activeMenu=""/> 
                    <div className="container">
                        <ListHeader title="KEZDŐLAP" filteritems={this.state.tasks} filter={this.filter} filters={this.state.filters} AddItem="" />
                       
                       
                        <div className="row" style={{marginTop: '40px'}}>
                            <Table tableName = "TEMATIKA" edit = "/themeform/" delete = {this.deleteTheme} itemList = {this.state.tasks}  changeStatus = {this.changeStatus}/>
                             
                        </div>
                    </div>
                </React.Fragment>
                );
    }
}

export default withRouter(AdminDashboard);