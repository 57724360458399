import React, { Component }from "react";
import { withRouter }from "react-router";
import { Redirect }from 'react-router-dom';
import "../../style/admin.css";
import AdminSideBar from '../AdminSideBar';
import SearchComponent from '../components/SearchComponent'

import {ModuleService} from '../../_services/ModuleService'
import Table from '../components/Table'
import ListHeader from '../components/ListHeader'
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';


class ModuleList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.hasOwnProperty("user") && (localStorage.getItem("user").charAt(0)!=="["  && localStorage.getItem("user").charAt(0)!=="{")? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'), "pazirik77").toString(CryptoENC)) : "",
            modules: [],
            filteredModules : [],
            filters : [{"value" : "Video" , "label" : "Videó", "key" : "previewType" },{"value" : "Swipe" , "label" : "Swipe", "key" : "previewType" },{"value" : "PairingText" , "label" : "Szöveg Párosító", "key" : "moduleType" },{"value" : "Pairing" , "label" : "Párosító","key" : "moduleType"},{"value" : "Memory" , "label" : "Memória","key" : "moduleType"},{"value" : "Quiz" , "label" : "Kvíz","key" : "moduleType"} ]

        };
        
        this.deleteModule = this.deleteModule.bind(this);
        this.filter = this.filter.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
    }
    
    changeStatus(index,id, status){
       
        this.state.filteredModules[index].active= status;
        this.setState({filteredModules : this.state.filteredModules});
        
        fetch("/module/setModuleStatus", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({moduleId: parseInt(id), active: status})
    })

            .then(response => {
               
                return response;
            });

    }
        
     filter(filteredContent){
        
        this.setState({filteredModules : filteredContent});
    }
    

    deleteModule(moduleId,moduleName){
         fetch("/module/delete?moduleId="+moduleId+"&userId="+this.state.user.id+"&institutionId="+localStorage.getItem("institution")+"&name="+moduleName)
                .then(response => response.json())
                .then(response => {
                    const deletedIndex = this.state.modules.findIndex(module =>   module.id===moduleId);
                   
                     this.state.modules.splice(deletedIndex,1);
                    this.setState({filteredModules : this.state.modules, });
                })
         
         
    }
    componentDidMount() {
       localStorage.setItem("activeMenu" , "module");
         ModuleService.getModules()
                    .then(modules => {
                  //      console.log(modules);
                        this.setState({modules: modules, filteredModules : modules});
                    })
    }

    componentWillMount() {
        document.body.classList.remove('login-body');
        document.body.classList.remove('welcome-body');
        document.body.classList.add('admin-body');
        document.body.classList.add('body');
    }
    render() {
        const {user} = this.state;
        if (!user) {
            return <Redirect to="/admin" />
        }
        return (
                <React.Fragment>  <AdminSideBar activeMenu="module"/> 
                    <div className="container">
                    <ListHeader title="MODUL" filteritems={this.state.modules} filter={this.filter} filters={this.state.filters} AddItem="/adminmoduleform/0" />
                        
                
                        <div className="row" style={{marginTop: '40px'}}>
                         <Table type="module" tableName = "MODUL" edit = "/adminmoduleform/" delete = {this.deleteModule} itemList = {this.state.filteredModules}  changeStatus = {this.changeStatus}/>
                          
                        </div>
                    </div>
                </React.Fragment>
                );
    }
}

export default withRouter(ModuleList);

