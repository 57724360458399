import React from 'react';

import { withRouter } from "react-router";
import * as THREE from "three";

import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {MTLLoader} from "three/examples/jsm/loaders/MTLLoader";

const style = {
    height: 600,
    width: 1000 // we can control scene size by setting container dimensions
};


class Create3D extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            fileType: "",
            mtlFileId: 0,
            textFileId: 0,
            objFileId: 0,
            zoom: 50,
            camera: 100,
            distance: -1,
            objFileId_name: "",
            mtlfileId_name: "",
            textFileId_name: "",
            loadPercentage: 0,
            id: 0

        }


        this.load3D = this.load3D.bind(this);
    }
    componentDidMount() {

      

            fetch("/threed/find/" + localStorage.getItem('activeModuleId'))
                    .then(threeDModel => threeDModel.json())
                    .then(threeDModel => {
                        
                        this.state.objFileId = threeDModel.objFile;
                        this.state.mtlFileId = threeDModel.mtlFile;
                        this.state.textFileId = threeDModel.textFile;
                        this.state.zoom = threeDModel.zoom;

                        this.setState({id: threeDModel.id, objFileId: this.state.objFileId, mtlFileId: this.state.mtlFileId,
                            textFileId: this.state.textFileId,
                            zoom: this.state.zoom,
                            camera: threeDModel.camera,
                            distance: threeDModel.distance,
                            objFileId_name: this.state.objFileId_name
                        });
                        setTimeout(this.load3D, 300);
                    });

       


    }

    load3D() {

        if (this.state.objFileId !== 0 && this.state.textFileId !== 0) {
           
            this.sceneSetup();
            this.addLights();
            this.loadTheModel();
            this.startAnimationLoop();

        }
    }

    sceneSetup = () => {

        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0xfaf4f3);
        ;
        this.camera = new THREE.PerspectiveCamera(
                this.state.zoom,
                0.9, // aspect ratio
                10, // near plane
                1000
                );
        this.camera.position.z = this.state.camera;

        this.controls = new OrbitControls(this.camera, this.mount);
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(width, height);
        this.mount.appendChild(this.renderer.domElement); // mount using React ref
    }

    loadTheModel = () => {


        const texture = new THREE.TextureLoader().load('/file/downloadFile?fileId=' + this.state.textFileId);
        const text = new THREE.MeshBasicMaterial({
            map: texture,

        });

        if (this.state.mtlFileId !== 0) {
            const mtlLoader = new MTLLoader();
            mtlLoader.load('/file/downloadFile?fileId=' + this.state.mtlFileId,
                    (materials) => {
                materials.preload();

                const loader = new OBJLoader();
                loader.setMaterials(materials);
                loader.load(
                        '/file/downloadFile?fileId=' + this.state.objFileId,
                        (object) => {
                    object.traverse(function (node) {

                        if (node.isMesh)
                            node.material = text;

                    });
                    object.position.y = this.state.distance;
                    this.scene.add(object);

                    const el = this.scene.getObjectByName("");

                    el.translateZ(1);

                    this.model = el;
                },
                        // called when loading is in progresses
                                (xhr) => {

                            this.state.loadPercentage = Math.ceil(xhr.loaded / xhr.total * 100);
                            this.setState({"loadPercentage": this.state.loadPercentage})
                            //console.log((loadingPercentage) + '% loaded');

                        },
                                (error) => {


                        }
                        );

            })
        } else {
            const loader = new OBJLoader();

            loader.load(
                    '/file/downloadFile?fileId=' + this.state.objFileId,
                    (object) => {
                object.traverse(function (node) {

                    if (node.isMesh)
                        node.material = text;

                });
                object.position.y = this.state.distance;
                this.scene.add(object);

                const el = this.scene.getObjectByName("");

                el.translateZ(1);

                this.model = el;
            },
                    // called when loading is in progresses
                            (xhr) => {

                        this.state.loadPercentage = Math.ceil(xhr.loaded / xhr.total * 100);
                        this.setState({"loadPercentage": this.state.loadPercentage})
                        //console.log((loadingPercentage) + '% loaded');

                    },
                            (error) => {


                    }
                    );

        }



    }

    addLights = () => {
        const lights = [];

        // set color and intensity of lights
        lights[ 0 ] = new THREE.PointLight(0xffffff, 1, 0);
        lights[ 1 ] = new THREE.PointLight(0xffffff, 1, 0);
        lights[ 2 ] = new THREE.PointLight(0xffffff, 1, 0);

        // place some lights around the scene for best looks and feel
        lights[ 0 ].position.set(0, 2000, 0);
        lights[ 1 ].position.set(1000, 2000, 1000);
        lights[ 2 ].position.set(-1000, -2000, -1000);

    }

    startAnimationLoop = () => {
        // slowly rotate an object
        /*  if (this.model)
         this.model.rotation.y += 0.005;*/

        this.renderer.render(this.scene, this.camera);

        this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
    }

    render() {
        return (
                <div>
                    <label>{"Betöltés : " + this.state.loadPercentage + " %"}</label>
                    <div style={style}  ref={ref => (this.mount = ref)} />
                </div>
                )
    }

}
class Module3D extends React.Component {
    state = {isMounted: true};

    render() {
        const {isMounted = true, loadingPercentage = 0} = this.state;
        return (
                <div style={{margin: "0 auto"}}>
                
                    {isMounted && <Create3D onProgress={loadingPercentage => this.setState({loadingPercentage})} />}
                
                </div>
                )
    }
}



export default Module3D;



