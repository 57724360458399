import React, { Component }from "react";
import { withRouter }from "react-router";
import { Redirect }from 'react-router-dom';
import "../../style/admin.css";
import AdminSideBar from '../AdminSideBar';

import Table from '../components/Table'
import {UsePopup, PopupContext}  from '../../context/popup-context'
import ListHeader from "../components/ListHeader"
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';

class TaskList extends Component {
    static contextType = PopupContext;
    constructor(props,context) {
        super(props,context);
        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'), "pazirik77").toString(CryptoENC)) : "",
            isOn: true,
            handleToogle: true,
            onColor: "#fff",
            tasks: [],
            filteredThemes : [],
            filters : [{"value" : true , "label" : "Aktív", "key" : "active" },{"value" : false , "label" : "Inaktív","key" : "active"} ]

        };
        this.deleteTheme = this.deleteTheme.bind(this);
        this.filter = this.filter.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        
    }


 changeStatus(index,id, status){
       
        this.state.filteredThemes[index].active= status;
        this.setState({filteredThemes : this.state.filteredThemes});
        
        fetch("/theme/setActive", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({themeId: parseInt(id), active: status})
    })

            .then(response => {
               
                return response;
            });

    }
        
     filter(filteredContent){
        
        this.setState({filteredThemes : filteredContent});
    }
    
   
    deleteTheme (themeId=0,themeName=""){
        
        
         fetch("/theme/delete?themeId=" + themeId+"&userId="+this.state.user.id+"&institutionId="+localStorage.getItem("institution")+"&name="+themeName)
                .then(response => response.json())
                .then(response => {
                     const deletedIndex = this.state.filteredThemes.findIndex(theme =>   theme.id===themeId);
                   
                     this.state.tasks.splice(deletedIndex,1);
                      this.setState({filteredThemes : this.state.filteredThemes})
                })
            
    }
    componentDidMount() {
       localStorage.setItem("activeMenu" , "theme");
        let tasks = [];
        fetch("/theme/allinstitution/"+localStorage.getItem("institution"))
                .then(response => response.json())
                .then(data => {
                  data.map(item => {
                      item.name = item.themeTitle
                  })
                    this.setState({tasks: data, filteredThemes : data})
                })
    }

    componentWillMount() {
        document.body.classList.remove('login-body');
        document.body.classList.remove('welcome-body');
        document.body.classList.add('admin-body');
        document.body.classList.add('body');
    }
    render() {
        const {user, isOn, handleToggle, onColor} = this.state;
        if (!user) {
            return <Redirect to="/admin" />
        }
        return (
                <React.Fragment>  <AdminSideBar activeMenu="theme"/> 
                    <div className="container">
                        <ListHeader title="TEMATIKA" filteritems={this.state.tasks} filter={this.filter} filters={this.state.filters} AddItem="/themeform/0" />
                       
                       
                
                        <div className="row theme-list-div"  style={{marginTop: '40px'}}>
                        <Table type="theme" tableName = "TEMATIKA" edit = "/themeform/" delete = {this.deleteTheme} itemList = {this.state.filteredThemes}  changeStatus = {this.changeStatus}/>
                                                                                                                                                                                                                                                                                                                
                        </div>   
                    </div>
                </React.Fragment>
                );   
                                                                        
    }
}

export default withRouter(TaskList);
