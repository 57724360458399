import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';


export const ModuleService = {
    getPairingItems,
    setActiveStatus,
    getMemoryItems,
    setShort,
    saveQuiz,
    saveMemory,
    findById,
    save,
    saveVideo,
    saveSwipe,
    savePairing,
    getModules,
    getSubThemeModules,
    saveModulePreview,
    getModule,
    savePairingMemory,
    saveThreeDModel,
    saveLinkScore,
    saveBonusScore
    
};



const user  =localStorage.hasOwnProperty("user") && (localStorage.getItem("user").charAt(0)!=="["  && localStorage.getItem("user").charAt(0)!=="{") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'),"pazirik77").toString(CryptoENC)):"";

const  getModuleName = async(moduleId, moduleType, moduleName) => {

    const label = window.label;
    
    let previewName = "";
    const responseModelePreview = await fetch("/modulepreview/find/" + moduleId);
    const modulePreview = await responseModelePreview.json();
    let previewType = "";

    if (modulePreview.length > 0) {
        previewName = label["Module" + modulePreview[0].moduleType]
        previewType = modulePreview[0].moduleType
        
    }


    return {moduleName : ""+moduleName+"</br><span> [" + (previewName !== "" ? previewName + ", " : "") + label["Module" + moduleType] + "]</span>", previewType : previewType };
}

const getAllModules = async() =>{
    
    const responseModule = await fetch("/module/allinstitution/"+localStorage.getItem("institution") );
    const modules = await responseModule.json();
    
   
                     for (var i=0; i<modules.length ; i++){
                            const getModuleResponse = await  getModuleName(modules[i].id,modules[i].moduleType,modules[i].moduleName);
                            const moduleName = await getModuleResponse;
                                    modules[i].name = moduleName.moduleName;
                                    modules[i].previewType = moduleName.previewType
                                }
                              
               
                      
       return modules;               
}

function getModules() {
    var modules = getAllModules();
  
    return modules;

}

const getSelectModule = async (moduleId) =>{
    const moduleResponse = await fetch(`/module/find/` + moduleId);
    const module = await moduleResponse.json();
    
     const getModuleResponse=  await   getModuleName(module.id,module.moduleType,module.moduleName);
      const moduleName = await getModuleResponse;
     module.name = moduleName.moduleName;
     module.previewType = moduleName.previewType;
     return module;
    
}
function getModule (moduleId){
  return getSelectModule(moduleId);
}

const getThemeModules = async(subThemeId) =>{
      const modules = [];
      const responseModules= await  fetch("/thememodule/getActives/" + subThemeId)
       const activeModules = await responseModules.json();  
         
                if (activeModules.length > 0) {
                    for (var i = 0; i < activeModules.length; i++) {
                        
                         const responseModule  = await fetch("/module/find/"+ activeModules[i].moduleId);
                          const module = await responseModule.json();
                                       
                               const getModuleResponse=  await   getModuleName(module.id,module.moduleType,module.moduleName);
                               const moduleName = await getModuleResponse;
                               module.name = moduleName.moduleName;
                                    modules[i] = module;
                            
                    }
                 
                   return modules;
                }
            
}


 function getSubThemeModules(subThemeId) {
     const modules= getThemeModules(subThemeId)
    return modules;
}


function getPairingItems(moduleId) {

    return fetch('/pairingitem/find/' + moduleId, {
        method: 'GET',

    }).then(response => response.json())
            .then(response => {
                if (response) {

                    return response;
                }

                return false;
            })
            .catch((error) => {
                
            });
}

function getMemoryItems(moduleId) {

    return fetch('/memoryitem/find/' + moduleId, {
        method: 'GET',

    }).then(response => response.json())
            .then(response => {
                if (response) {
                    
                    return response;
                }

                return false;
            })
            .catch((error) => {
                console.log(error)
            });
}

function setActiveStatus(moduleId, themeId, active) {
    return fetch("/thememodule/setModuleStatus", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({moduleId: parseInt(moduleId), themeId: parseInt(themeId), active: active})
    })

            .then(response => {

                return response
            });


}

function setShort(moduleId, themeId, short) {
    return fetch("/thememodule/setModuleShort", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({moduleId: parseInt(moduleId), themeId: parseInt(themeId), short: short})
    })

            .then(response => {
                console.log(response);
                return response
            });


}

function saveQuiz(questions, moduleId) {
    return fetch("/quizquestion/save", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({questions: JSON.stringify(questions), moduleId: moduleId, userId : user.id, institutionId : localStorage.getItem("institution")})
    }).then(response => {
        
        return(response);
    })
}

function saveThreeDModel(threeDModel, moduleId, modulePreviewId){
    
     return fetch("/threed/save", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({threed: JSON.stringify(threeDModel), moduleId: moduleId, modulePreviewId: modulePreviewId, userId : user.id, institutionId : localStorage.getItem("institution")})
    }).then(response => {
        console.log(response);
        return(response);
    })
}
function saveSwipe(swipes, moduleId, modulePreviewId) {
    return fetch("/swipe/save", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({swipes: JSON.stringify(swipes), moduleId: moduleId, modulePreviewId: modulePreviewId, userId : user.id, institutionId : localStorage.getItem("institution")})
    }).then(response => {
        console.log(response);
        return(response);
    })
}

function saveLinkScore(linkScore, modulePreviewId, moduleId) {
    return fetch("/linkscore/save", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({id : linkScore.id,text: linkScore.text, linkText : linkScore.linkText, link : linkScore.link, moduleId: moduleId, modulePreviewId: modulePreviewId,score :linkScore.score, userId : user.id, institutionId : localStorage.getItem("institution")})
    }).then(response => {
        console.log(response);
        return(response);
    })
}

function saveBonusScore(bonusScore, modulePreviewId, moduleId) {
    return fetch("/bonusscore/save", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({id : bonusScore.id,text: bonusScore.text,  code : bonusScore.code, moduleId: moduleId, modulePreviewId: modulePreviewId,score :bonusScore.score, userId : user.id, institutionId : localStorage.getItem("institution")})
    }).then(response => {
        console.log(response);
        return(response);
    })
}

function saveMemory(memories, moduleId) {
    return fetch("/memoryitem/save", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({memories: JSON.stringify(memories), moduleId: moduleId, userId : user.id, institutionId : localStorage.getItem("institution")})
    }).then(response => {


        return(response);
    })
}

function savePairing(pairs, moduleId) {
    return fetch("/pairingitem/save", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({pairs: JSON.stringify(pairs), moduleId: moduleId, userId : user.id, institutionId : localStorage.getItem("institution")})
    }).then(response => {


        return(response);
    })
}

function savePairingMemory(pairs, moduleId) {
    return fetch("/pairingmemory/save", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({pairingMemories: JSON.stringify(pairs), moduleId: moduleId, userId : user.id, institutionId : localStorage.getItem("institution")})
    }).then(response => {


        return(response);
    })
}

function saveVideo(videoLink, modulePreviewId, moduleId) {
    return fetch("/modulepreview/save", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({link: videoLink, moduleId: moduleId, modulePreviewId: modulePreviewId, moduleType: "Video", userId : user.id, institutionId : localStorage.getItem("institution")})
    }).then(response => {
        console.log(response);
        return(response);
    })
}

function saveModulePreview(content, modulePreviewId, moduleId,moduleType) {
    return fetch("/modulepreview/save", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({link: content, moduleId: moduleId, modulePreviewId: modulePreviewId, moduleType: moduleType, userId : user.id, institutionId : localStorage.getItem("institution")})
    }).then(response => {
       
        return(response);
    })
}

function findById(id) {
    return fetch(`/module/find/` + id, {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),

    })

            .then(module => {

                if (module) {

                    return module;
                }

                return false;
            })
            .catch((error) => {
                console.log(error)
            });
}


function save(name, description, type, id,active) {
 
    return fetch(`/module/save`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({name: name, description: description, type: type, id: id,active : active,userId : user.id ,institutionId : localStorage.getItem("institution")})
    }).then(response => response.json())

            .then(response => {

                if (response) {

                    return response;
                }

                return false;
            })
            .catch((error) => {
                console.log(error)
            });
}


