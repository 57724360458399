import React from 'react';

import { authService }from './_services/authService';
import { withRouter }
from "react-router";
import "./style/login.css";
import logo from './images/black_logo.png';
import openEye from './images/green_open_eye.png';
import closedEye from './images/green_closed_eye.png';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class ForgetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            submitted: false,
            loading: false,
            error: '',
            type: 'password',
            eyeClass: 'closed-eye',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showPassword = this.showPassword.bind(this);
        this.closeLogin = this.closeLogin.bind(this);

    }
    ;
            showPassword(e) {
        this.setState({
            type: this.state.type === 'input' ? 'password' : 'input',
            eyeClass: this.state.type === 'input' ? 'closed-eye' : 'open-eye'
        })


    }
    closeLogin(e) {
        this.props.history.push("/");
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({submitted: true});
        const {username} = this.state;
        // stop here if form is invalid
        if (!(username)) {
            return;
        }

        this.setState({loading: true});

        authService.forgetPassword(username).then(user => {
            console.log(user);
            if (user) {
                this.props.history.push("/login");

            } else {
                this.setState({"error": "Nincs ilyen felhasználó", loading: false})
            }
        });

    }

    componentWillMount() {
        document.body.classList.remove('login-body');
        document.body.classList.remove('welcome-body');
        document.body.classList.add('forgetpassword-body');
    }

    render() {
        const {username, submitted, loading, error, type, eyeClass} = this.state;
        const {t} = this.props;
        return (
                <div id="login" className="container">
                    <div className="row">
                        <div id="left-side" style={{marginTop: "40px"}} className="col-md">
                            <div id="logo">
                                <img src={logo} />
                                <p>TEGYÜK <br/> A <b>MÚZ</b>EUM<b>-<br/>PED</b>AGÓGIÁT SZÓRAKOZTATÓVÁ <u>EGYÜTT</u>.</p>
                            </div>
                        </div>
                        <div id="right-side" className="col-md">
                
                            <div style={{textAlign: 'center'}}>
                                <button type="button" className="close float-left" aria-label="Close" onClick={this.closeLogin} >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <label id="login_label" className=" muzped-white-border" >{t("JELSZÓ VISSZAÁLLÍTÁS")}</label><br/>
                                <label className="forget-password-label"><b>Jelszó visszaállításhoz adja meg regisztrál e-mail címét vagy felhasználónevét</b></label><br/>
                                <label className="forget-password-label">Rendszerünk pár percen belül visszaigazoló e-mailt fog küldeni.</label><br/>
                                <form name="form" className="form" onSubmit={this.handleSubmit}>
                                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                        <div className="row">
                                            <div className="col-lg-4"></div>
                                            <div className="col-lg-4">
                                                <input style={{width: "400px", marginLeft: "-210px"}} type="text" className=" muzped-input" name="username" value={username} placeholder="e-mail cím / felhasználónév" onChange={this.handleChange} />
                                                {submitted && !username &&
                                    <div className="help-block">Username is required</div>

                                                }
                
                                            </div>
                                            <div className="col-lg-4" style={{background: '#fff'}}>   
                                                <button className="muzped-white-button button-login" disabled={loading}></button>
                                            </div>
                                            <div className="col-lg-4"></div>
                                        </div>
                                    </div>
                
                
                                    {error &&
                                    <div className={'alert alert-danger'}>{error}</div>
                                    }
                                </form>
                                <label className="forget-password-label" style={{opacity: "0.8"}}>Amennyiben nem kapja meg az e-mailt kérjük vegy fel velünk a kapcsolatot</label><br/>
                                <Link to="/login"><button className="muzped-input" style={{marginTop: "200px", width: "300px"}}>Bejelentkezés</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
                );
    }
}

export default   withRouter(withTranslation()(ForgetPassword), ForgetPassword);


