
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

export const ThemeService = {
    save,
    findById,
    findUserThemes,
    findSubThemeById,
    saveSubTheme,
    saveFile,
   
   
};

const user  =localStorage.hasOwnProperty("user") && (localStorage.getItem("user").charAt(0)!=="["  && localStorage.getItem("user").charAt(0)!=="{") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'),"pazirik77").toString(CryptoENC)):"";

function save(name, description, ageId, fileId,id,active=true) {


    return fetch(`/theme/save`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({name: name, description: description, ageId: parseInt(ageId), fileId : fileId, id : id, userId : user.id, institutionId : localStorage.getItem("institution"),active : active})
    })

            .then(response => {

                if (response) {

                    return true;
                }

                return false;
            })
            .catch((error) => {
                console.log(error)
            });
}

function findById(id) {

    return fetch(`/theme/select/` + id, {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
    })

            .then(task => {

                if (task) {

                    return task;
                }

                return false;
            })
            .catch((error) => {
                console.log(error)
            });
}

function findUserThemes(ageId, userId) {
    return fetch("/theme/getUserThemes", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ageId: parseInt(ageId), userId: userId})
    })
            .then(response => response.json())
            .then(themes => {
             
                return themes
            });
}

function findSubThemeById(subThemeId) {
    return fetch(`/subtheme/select/` + subThemeId, {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
    })

            .then(task => {

                if (task) {

                    return task;
                }

                return false;
            })
            .catch((error) => {
                console.log(error)
            });
}


function saveSubTheme(subTheme) {

    return fetch("/subtheme/save", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: subTheme
    })
            .then(response => response.json())
            .then(themes => {

                return themes
            });
}

function saveFile(file) {

    const formData = new FormData()
    formData.append('file', file)
    return fetch("/subtheme/saveFile", {
        method: 'POST',
        body: formData
    })
            .then(response => response.json())
            .then(themes => {

                return themes
            });
}


