import CryptoAES from 'crypto-js/aes'


export const authService = {
    login,
    logout,
    forgetPassword

};

const loginUser = async(username, password) => {

    const response = await fetch("user/login/" + username + "/" + password);
    const user = await response.json();


    let host = window.location.host;

    let parts = host.split(".");
    let subdomain = "";

    if (parts.length >= 3) {
        subdomain = parts[0];

    } else {
        subdomain = "localhost";
    }
   
   if(parts.length==4){
       subdomain="localhost";
   }
    const responseIns = await fetch("/institution/find/" + subdomain);
    const ins = await responseIns.json();

    if (ins) {
        localStorage.setItem("institution", ins[0].id);

    }
    if (user.id) {
        if (user.admin) {
            localStorage.setItem('user',CryptoAES.encrypt(JSON.stringify(user),"pazirik77"));
            return user;
        } else {

            if (ins) {
                const responseUserIns = await fetch("/userins/find/" + user.id);
                const userIns = await responseUserIns.json();
               
                userIns.map(insuser => {
                   
                    if (insuser.institutionId === ins[0].id) {
                        console.log(user);
                        localStorage.setItem('user', CryptoAES.encrypt(JSON.stringify(user),"pazirik77"));
                        return user;
                    }
                })

            }
        }


    } else {
        return false;
    }

}


function login(username, password) {

    var logged = false;
    //var loggedUser = [];


    const user = loginUser(username, password);
    return user;

}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function forgetPassword(email) {
    return fetch("/user/forgetPassword", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email: email})
    })
            .then(response => response.json())
            .then(response => {

                return response;
            });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

