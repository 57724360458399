import React from 'react';

class ModuleLBonusScore extends React.Component{
    
     constructor(props, context) {
        super(props, context);
        this.state = {
            modulePreview : JSON.parse(localStorage.getItem("modulePreview")),
            bonusScore : []
        }
        
        this.addScore = this.addScore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        
    }
    
    addScore(){
        if(document.getElementById("code").value===this.state.bonusScore.code)
          this.props.saveScore(this.state.bonusScore.score," Megadta a kódot.",this.state.bonusScore.score);
      this.props.nextModule();
       
    }
    
     handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }
    
    componentDidMount(){
      if (document.getElementById("forward_module_button"))
         document.getElementById("forward_module_button").innerHTML="Nem kérem a plussz pontokat";
         fetch("/bonusscore/geBonusScore/" + this.state.modulePreview.id)
                 .then(response => response.json())
                .then(items => {
                   
                  if(items.length>0)
                    this.setState({bonusScore : items[0]});
                    
                  
                    });
                    
                    
    }
    
    componentWillUnmount(){
      if (document.getElementById("forward_module_button"))
        document.getElementById("forward_module_button").innerHTML="<b>KÖVETKEZŐ</b> kérdés";  
    }
    
    render(){
        
        return(
                <div style={{margin : "0 auto", textAlign : "center"}}>
                <h1 className="congratulation-text" style={{marginTop : "unset"}}>Psszt!</h1>
                <p style={{fontSize : "26px"}}>A kérdések közé plusz pontokat rejtettünk - apróságokért cserébe.</p>
               
               <p style={{fontSize : "26px", marginTop : "50px",marginBottom : "80px;"}}>{this.state.bonusScore.text}</p>
                   <input className="muzped-input" value={this.state.code} onChange ={this.handleChange} name="code" id="code" placeHolder="KÓD"/> <br/> 
                   <button  className="linkbutton" onClick={this.addScore}>ELKÜLD</button>
                                
                </div>
                
                )
    }
}

export default ModuleLBonusScore;