
import React, { Component}from "react";
import {ModuleService}from "../../_services/ModuleService";
import ModuleFormsHeader from"./ModuleFormsHeader"
import AddFile from "../components/AddFile"

class ModuleSwipeForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            itemNo: 0,
            items: {}
        }
        this.subThemeThemeModal = React.createRef();
        this.hideModal = this.hideModal.bind(this);
        this.addMemoryItem = this.addMemoryItem.bind(this);
        this.save = this.save.bind(this);
        this.addImage = this.addImage.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.deleteItemItem = this.deleteItemItem.bind(this);
        this.ModuleFormsHeader = React.createRef();

    }

    addImage(id, index) {

       


                    document.getElementById("item-" + index).style.backgroundImage = "url('/file/download?fileId=" + id + "')";
                    document.getElementById("item-" +index).style.backgroundSize = "cover";
                   
                    document.getElementById("item-url-" + index).value = id;

    }

    addMemoryItem() {
        this.setState({"itemNo": this.state.itemNo + 1});
    }

    save() {
        var error = false;

        for (var i = 0; i < this.state.itemNo; i++) {
            if (document.getElementById("item-div-" + i)) {
                this.state.items[i] = {};
                this.state.items[i]["after"] = document.getElementById("item-url-after-" + i).value;
                this.state.items[i]["before"] = document.getElementById("item-url-before-" + i).value;
                this.state.items[i]["name"] = document.getElementById("item-name-" + i).value;
                this.state.items[i]["id"] = document.getElementById("item-id-" + i).value === "" ? 0 : document.getElementById("item-id-" + i).value;
                this.setState({"items": this.state.items});
            }

        }

        if (!error) {
            this.props.savePreviewModule("Swipe", this.state.items);
            this.hideModal();
        }
    }

    deleteItem(index) {
        document.getElementById("item-div-" + index).remove();
    }

    deleteItemItem(pos, index) {
        document.getElementById("item-" + pos + "-" + index).style.backgroundImage = "";
        document.getElementById("item-" + pos + "-url-" + index).value = "";

    }
    componentDidMount() {

        if (this.props.moduleId != 0) {

            fetch("/swipe/find/" + this.props.modulePreviewId)
                    .then(response => response.json())
                    .then(items => {


                        this.setState({itemNo: items.length});

                        for (var i = 0; i < items.length; i++) {

                            document.getElementById("item-before-" + i).style.backgroundImage = "url('/file/download?fileId=" + items[i].before_url + "')";
                            document.getElementById("item-after-" + i).style.backgroundImage = "url('/file/download?fileId=" + items[i].after_url + "')";
                            document.getElementById("item-url-before-" + i).value = items[i].before_url;
                            document.getElementById("item-url-after-" + i).value = items[i].after_url;
                            document.getElementById("item-name-" + i).value = items[i].name;
                            document.getElementById("item-id-" + i).value = items[i].id;

                        }

                    });

        }


    }

    hideModal() {
        window.scrollTo({
            top: 400,
            behavior: 'smooth',
        })
        document.body.classList.remove('display-block');
        let element = document.getElementsByClassName("modal");
        element[0].classList.add("display-none");
        document.body.classList.remove("modal-admin-body");
        //window.scrollTo(0,localStorage.getItem("scrollTop"));

    }

    render() {



        return (
                <div  className="modal-dialog">
                    <section  className="modal-content" >
                        
                              <ModuleFormsHeader ref={this.ModuleFormsHeader} title="SWIPE" save={this.save} delete={this.props.deletePreview} context={this.props.contextModule} />
                
                           
                
                            <div className="modal-body" >
                                <div  id="memory-item-container" style={{width: "800px", padding: "40px",display:"inline-flex" , overflowY : "auto"}}>
                                    {[...Array(this.state.itemNo), ].map((value, index) => (
                                        <div className="add-item" key={index} id={"item-div-" + index}>
                                            <input id={"item-name-" + index} type="text" className="item-input" placeholder="MEGNEVEZÉS" style={{height: "50px", marginBottom : "unset"}}/>
                                            <input type="hidden" id={"item-id-" + index}  />
                                            <div className="item-content" id={"item-before-" + index}>
                                                <div className="input-div">
                    
                                                    <input type="hidden" id={"item-url-before-" + index} />
                    
                                                </div>
                    
                                                <AddFile selectFile = {this.addImage} index={"before-"+ index}/>
                                                 
                                            </div>
                    
                                            <div className="item-content" id={"item-after-" + index}>
                                                <div className="input-div">
                    
                                                    <input type="hidden" id={"item-url-after-" + index} />
                    
                                                </div>
                    
                                                <AddFile selectFile = {this.addImage} index={"after-" +index}/>
                                                
                                            </div>
                                            <br/>
                                           
                                           
                                            <a href="#" className="black-link item-main-delete delete-link" onClick={() => this.deleteItem(index)} >Törlés</a>
                                        </div>
                                                    )
                                        )
                                    }
                                </div>
                                <a className="btn btn-add-item" onClick={this.addMemoryItem}>Új elem hozzáadása</a>
                            </div>
                
                
                        
                
                    </section>
                
                </div>
                );
    }
}


export default ModuleSwipeForm;







