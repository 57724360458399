import React, { Component }from "react";
import { withRouter }from "react-router";
import { Redirect }from 'react-router-dom';
import "../../style/admin.css";
import AdminSideBar from '../AdminSideBar';
import SearchComponent from '../components/SearchComponent'
import FilterComponent from '../components/FilterComponent'
import SwitchComponent from '../components/SwitchComponent'
import MediaList from './MediaList'
import {ThemeService} from "../../_services/ThemeService"
import {ModuleService} from "../../_services/ModuleService"
import ListHeader from "../components/ListHeader"
import Directories from "../components/Directories"
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';


class MediaDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.hasOwnProperty("user") && !localStorage.getItem("user").id ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'),"pazirik77").toString(CryptoENC)):"",
            modules: [],
            filters: [],
            images: [],
            selectedDirectory: [],
            directoryList: [],
            mainDirectory: [],
            editDirectory : false
        };
        this.getImages = this.getImages.bind(this);
        this.selectTheme = this.selectTheme.bind(this);
        this.selectSubTheme = this.selectSubTheme.bind(this);
        this.updateImages = this.updateImages.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
       
        this.addDirectory = this.addDirectory.bind(this);
       
        this.onFileChange = this.onFileChange.bind(this);
       
         this.handleDragStart = this.handleDragStart.bind(this);
        this.changeDirectory = this.changeDirectory.bind(this);
        this.editDirectoryName = this.editDirectoryName.bind(this);
        this.updateDirectory = this.updateDirectory.bind(this);

        

    }
    
    editDirectoryName(){
        
        this.setState({editDirectory : true})
    }
    
      handleDragStart(event,fileId){
         event.dataTransfer.setData('fileId',fileId);
        
    }
    
    changeDirectory(images, dir){
        this.setState({images : images, selectedDirectory : dir});
    }
  
    /* flatFilter(nestedProp, compareKey, compareId, arr) {
     
     arr.filter(o => {
     
     
     if (o.id== this.state.selectedId){
     console.log(o);
     return o;
     } else {
     if ( o.subDir) {
     return this.flatFilter(nestedProp, compareKey, compareId, o.subDir);
     }
     }
     });
     }*/
    onFileChange(e) {

        const fileName = e.target.files[0].name;
        const formData = new FormData()
        formData.append('file', e.target.files[0]);
        formData.append("userId", this.state.user.id);
        formData.append("institutionId", localStorage.getItem("institution"));
        formData.append("path", this.state.selectedDirectory["path"]);
        formData.append("directoryId", this.state.selectedDirectory["id"]);
        //formData.append('path', '/modules/' + this.state.id + "/");
        fetch("/file/save", {
            method: 'POST',

            body: formData
        })
                .then(response => response.json())
                .then(response => {
                    let file = {};

                      
                    file.fileName = fileName;
                    file.path = this.state.selectedDirectory["path"] + "/" + fileName;
                    file.id = response;
                   

                    this.state.images.unshift(file);
                    this.setState({images: this.state.images});


                });


    }
    
    addDirectory(newDirectory) {

    

        var data = JSON.stringify({directoryId: this.state.selectedDirectory["id"], path: this.state.selectedDirectory["path"], directoryName: newDirectory, userId: this.state.user.id, institutionId: localStorage.getItem("institution")});
        fetch("/file/createDirectory", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: data
        })
               .then(response => response.json())
                .then(response => {
                  let directory=[];
                  directory.subDir=[];
                  directory["name"] = newDirectory;
                  directory["directoryId"]= this.state.selectedDirectory.id;
                  directory["id"]= response;
                   this.directories.directoryCreate(directory);
                   this.directories.getImages(directory);
                });
    }
    
    updateDirectory(directoryName){
        var data = JSON.stringify({ directoryName: directoryName, id : this.state.selectedDirectory.id });
        fetch("/file/updateDirectory", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: data
        })
              
                .then(response => {
             
                 document.getElementById("directoryLabel_" + this.state.selectedDirectory.id).innerHTML= directoryName;
                 this.directories.addEditLabel(directoryName);
                });
        
    }

    addImageToList(fileId) {
        if (this.state.images.indexOf(fileId) === -1) {
            this.state.images.push(fileId);
            this.setState({images: this.state.images});
        }
    }

    updateImages(fileId) {
        var images = this.state.images;
        this.state.images = this.state.images.filter(image => image !== fileId);
        this.setState({images: []});
        this.state.images.push(fileId);
        this.setState({images: images});
    }

    deleteImage(fileId) {
       
        this.state.images = this.state.images.filter(image => image.id !== fileId);
        this.setState({images: this.state.images});
    }
    selectSubTheme(subThemeId) {
        this.deleteActives();
        fetch("/files/getDirectories/" + module.id)
                .then(response => response.json())
                .then(items => {
                    items.map(item => {
                        this.addImageToList(item.img_url);
                    })


                });
        /* document.getElementById("subtheme-" + subThemeId).classList.add("active");
         ThemeService.findSubThemeById(subThemeId)
         .then(response => response.json())
         .then(subTheme => {
         if (subTheme) {
         this.setState({images: []});
         this.state.images.push(subTheme.fileId);
         this.setState({images: this.state.images});
         ModuleService.getSubThemeModules(subTheme.id).then(modules => {
         if (modules) {
         
         modules.map(module => {
         
         if (module.moduleType) {
         if (module.moduleType === "Memory") {
         fetch("/memoryitem/find/" + module.id)
         .then(response => response.json())
         .then(items => {
         items.map(item => {
         this.addImageToList(item.image_url);
         })
         
         
         });
         }
         
         if (module.moduleType === "Pairing") {
         fetch("/pairingitem/find/" + module.id)
         .then(response => response.json())
         .then(items => {
         items.map(item => {
         this.addImageToList(item.img_url);
         })
         
         
         });
         }
         
         if (module.moduleType === "PairingText") {
         fetch("/pairingitem/find/" + module.id)
         .then(response => response.json())
         .then(items => {
         
         items.map(item => {
         if (item.main_pair) {
         this.addImageToList(item.img_url);
         }
         })
         
         
         });
         }
         
         fetch("/modulepreview/find/" + module.id)
         .then(response => response.json())
         .then(modulePreview => {
         if (modulePreview[0]) {
         
         if (modulePreview[0].moduleType === "Picture") {
         var images = modulePreview[0].link.split(";");
         images.map(image => {
         
         this.addImageToList(image);
         })
         }
         
         if (modulePreview[0].moduleType === "Swipe") {
         fetch("/swipe/find/" + JSON.parse(localStorage.getItem("modulePreview")).id)
         .then(response => response.json())
         .then(items => {
         items.map(swipe => {
         console.log(swipe);
         this.addImageToList(swipe.after_url);
         this.addImageToList(swipe.before_url);
         
         })
         
         })
         }
         }
         });
         }
         })
         }
         
         });
         
         ThemeService.findById(subTheme.themeId)
         .then(response => response.json())
         .then(theme => {
         this.addImageToList(theme.fileId);
         document.getElementById("theme-" + subTheme.themeId).classList.add("active");
         });
         
         
         
         }
         })*/

    }

    deleteActives() {
        var actives = document.getElementsByClassName("media-table")[0].getElementsByClassName("active");
        for (var i = 0; i < actives.length; i++) {
            actives[i].classList.remove("active");
        }
    }

    selectTheme(themeId) {
        this.deleteActives();
        document.getElementById("theme-" + themeId).classList.add("active");
        ThemeService.findById(themeId)
                .then(response => response.json())
                .then(theme => {
                    if (theme) {


                        this.setState({images: [theme.fileId]});
                    }
                })
    }

    getImages(dir, e) {
        e.stopPropagation();

        fetch("/file/getfilelist/" + dir.id +"/" + localStorage.getItem("institution"))
                .then(response => response.json())
                .then(data => {

                    this.setState({images: data, selectedDirectory: dir})
                })



            
      
    }
    
    goToAllDirectory(directories, directory) {

        directories.map((dir, index) => {

            if (directory.directoryId === index) {
                dir["subDir"][directory.id] = [];
                dir["subDir"][directory.id]["name"] = directory.name;
                dir["subDir"][directory.id]["directoryId"] = directory.directoryId;
                dir["subDir"][directory.id]["id"] = directory.id;
                dir["subDir"][directory.id]["path"] = directory.path;
                dir["subDir"][directory.id]["subDir"] = [];
            } else {
                if (dir["subDir"].length > 0) {
                    return this.goToAllDirectory(dir["subDir"], directory);
                }
            }
        });
        this.setState({"directoryList": directories})

    }
    
    

    componentDidMount() {

        fetch("/file/getDirectories/" + localStorage.getItem("institution"))
                .then(response => response.json())
                .then(directories => {
                    this.setState({"mainDirectory": directories[0]});

                    directories.map(directory => {
                        if (this.state.directoryList.length > 0) {

                            this.goToAllDirectory(this.state.directoryList, directory);
                        } else {
                            this.state.directoryList[directory.id] = [];
                            this.state.directoryList[directory.id]["name"] = directory.name;
                            this.state.directoryList[directory.id]["subDir"] = [];
                            this.state.directoryList[directory.id]["directoryId"] = directory.directoryId;
                            this.state.directoryList[directory.id]["id"] = directory.id;
                            this.state.directoryList[directory.id]["path"] = directory.path;
                        }
                        this.setState({"directoryList": this.state.directoryList})
                    })
                });
        /*  let tasks = [];
         
         
         fetch("/theme/allinstitution/" + localStorage.getItem("institution"))
         .then(response => response.json())
         .then(data => {
         data.map(item => {
         item.name = item.themeTitle
         })
         
         data.map(theme => {
         fetch("/subtheme/find/" + theme.id)
         .then(response => response.json())
         .then(subThemes => {
         
         theme.subThemes = subThemes;
         this.state.themes.push(theme);
         this.setState({themes: this.state.themes})
         
         })
         
         })
         
         })*/
    }

    componentWillMount() {
        document.body.classList.remove('login-body');
        document.body.classList.remove('welcome-body');
        document.body.classList.add('admin-body');
        document.body.classList.add('body');
    }

    render() {
        const {user, isOn, handleToggle, onColor} = this.state;
        if (!user.name) {
            return <Redirect to="/" />
        }


        const getsubDir = dir => {
            if (dir) {
                const [name, subDir] = dir;

                if (!subDir) {
                    return <li>name</li>;
                }

                return [name, subDir.map(getsubDir)];
            }
        }

        return (
                <React.Fragment>  <AdminSideBar activeMenu="media"/> 
                    <div className="container">
                        <ListHeader title="MÉDIATÁR" filteritems={this.state.images} filter={this.filter} filters={this.state.filters} AddItem="" />
                
                
                        <div className="row " style={{marginTop: '40px'}}>
                            <div className = "col-4 directory-table-div">
                                <table class="table table-dark media-table " id="muzped-admin-table" style={{width: "360px", fontSize: "15px", textTransform: "uppercase"}}>
                
                                    <Directories onRef={ref => (this.directories = ref)} changeDirectory={this.changeDirectory} editDirectoryName={this.editDirectoryName}  />
                                </table>
                            </div>
                            <div id="image-container" className="col-8">
                                <MediaList handleDragStart = {this.handleDragStart} deleteImage ={this.deleteImage}  updateDirectory={this.updateDirectory} editDirectory={this.state.editDirectory} selectedDirectory={this.state.selectedDirectory} onFileChange={this.onFileChange} addDirectory={this.addDirectory} updateImages={this.updateImages} images={this.state.images} id={this.state.selectedDirectory.id}/>
                            </div>
                        </div>
                
                    </div>
                </React.Fragment>
                );
    }
}

export default withRouter(MediaDashboard);


