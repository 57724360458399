
import React, { Component }from "react";
import ReactDOM from "react-dom";
import { withRouter, Lifecycle, browserHistory }from "react-router";
import { Redirect, useParams, Link, Prompt, Route }from 'react-router-dom';
import "../../style/admin.css";
import AdminSideBar from '../AdminSideBar';
import {ModuleService} from '../../_services/ModuleService'
import ModuleQuizForm from "./ModuleQuizForm"
import ModuleMemoryForm from "./ModuleMemoryForm"
import ModuleSwipeForm from "./ModuleSwipeForm"
import ModuleVideoForm from "./ModuleVideoForm"
import ModulePairingForm from "./ModulePairingForm"
import ModulePairingTextForm from "./ModulePairingTextForm"
import ModuleTextForm from "./ModuleTextForm"
import ModulePictureForm from "./ModulePictureForm"
import SwitchComponent from "../components/SwitchComponent"
import {UsePopup, PopupContext}  from '../../context/popup-context'
import FormHeader from "../components/FormHeader"
import ModulePanoramaTourForm from "./ModulePanoramaTourForm"
import ModulePictureQuizForm from "./ModulePictureQuizForm"
import Module3DForm from "./Module3DForm"
import ModulePairingMemoryForm from "./ModulePairingMemoryForm"
import ModuleLinkScoreForm from "./ModuleLinkScoreForm"
import ModuleBonusScoreForm from "./ModuleBonusScoreForm"
import ModuleQuizPictureAnswersForm from "./ModuleQuizPictureAnswersForm"
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';


        class ModuleForm extends Component {
    static contextType = PopupContext;
    constructor(props, context) {
        super(props, context);
        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'), "pazirik77").toString(CryptoENC)) : "",
            tasks: [],
            name: '',
            description: '',
            active: true,
            error: '',
            id: this.props.match.params.id,

            moduleContent: {},
            selectedModuleType: "",
            selectedPreviewModuleType: "",
            previewModuleContent: {},
            selectedPreviewModuleId: 0,
            warning: true
        };

        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.createModule = this.createModule.bind(this);
        this.saveModule = this.saveModule.bind(this);
        this.savePreviewModule = this.savePreviewModule.bind(this);
        this.deletePreview = this.deletePreview.bind(this);
        this.deleteModuleType = this.deleteModuleType.bind(this);
        this.delete = this.delete.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.checkDelete = this.checkDelete.bind(this);
        this.cancel = this.cancel.bind(this);
        this.quit = this.quit.bind(this);
        this.checkSave = this.checkSave.bind(this);

    }

    checkDelete() {
        this.context.setPopup({id: this.state.id, name: this.state.name, ok: this.context.unSetPopup, title: <div style={{textAlign: "center", paddingBottom: "20px"}}><span style={{fontSize: "20px", textAlign: "center"}}> Biztosan törli? </span></div>, type: "delete", delete: this.delete});

    }

    checkSave() {

    }
    cancel() {
        this.context.setPopup({ok: this.context.unSetPopup, title: <div style={{textAlign: "center", paddingBottom: "20px"}}><span style={{fontSize: "20px", textAlign: "center"}}> Biztosan kilép? </span></div>, type: "confirm", message: "(Az elvégzett módosítások nem kerülnek mentésre.)", confirm: this.quit});

    }

    quit(canQuit) {
        this.context.unSetPopup();
        if (canQuit) {
            this.setState({warning: false});
            this.props.history.push("/adminmodulelist");

        }
    }
    changeStatus(index, id, status) {
        this.setState({active: status});
    }

    delete(canDelete) {
        this.context.unSetPopup();
        if (canDelete) {
            this.setState({warning: false});
            fetch("/module/delete?moduleId=" + this.state.id + "&userId=" + this.state.user.id + "&institutionId=" + localStorage.getItem("institution") + "&name=" + this.state.name)
                    .then(response => response.json())
                    .then(response => {
                        this.props.history.push("/adminmodulelist");
                    })
        }
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});

        if (this.state.name && this.state.description)
            document.getElementById("table-title-basic").classList.add("selected-table-title");

    }

    saveModule(moduleType, moduleContent) {

        this.setState({"selectedModuleType": moduleType, "moduleContent": moduleContent});
        document.getElementById("table-title-module").classList.add("selected-table-title");
    }

    savePreviewModule(modulePreviewType, modulePreviewContent) {

        this.setState({"selectedPreviewModuleType": modulePreviewType, "previewModuleContent": modulePreviewContent});
        document.getElementById("table-title-modulepreview").classList.add("selected-table-title");
    }

    deletePreview() {
        fetch("/modulepreview/delete/" + this.state.selectedPreviewModuleId)
                .then(response => response.json())
                .then(response => {

                    this.setState({"selectedPreviewModuleType": "", selectedPreviewModuleId: 0})
                    ReactDOM.unmountComponentAtNode(document.getElementById("modal-container"));
                    document.body.classList.remove("modal-admin-body");
                    document.body.classList.remove('display-block');
                });

        document.getElementById("table-title-modulepreview").classList.remove("selected-table-title");
    }
    deleteModuleType() {

        this.setState({"selectedModuleType": ""})
        ReactDOM.unmountComponentAtNode(document.getElementById("modal-container"));
        document.body.classList.remove("modal-admin-body");
        document.body.classList.remove('display-block');
        document.getElementById("table-title-module").classList.remove("selected-table-title");

    }

    save(e) {
        const {name, description, selectedModuleType, id, active} = this.state;

        ModuleService.save(name, description, selectedModuleType, id, active)
                .then(
                        response => {


                            if (Object.keys(this.state.moduleContent).length > 0) {
                                if (this.state.selectedModuleType === "Quiz") {

                                    ModuleService.saveQuiz(this.state.moduleContent, response);
                                }

                                if (this.state.selectedModuleType === "PictureQuiz" || this.state.selectedModuleType==="QuizPictureAnswers") {

                                    ModuleService.saveQuiz(this.state.moduleContent, response);
                                }


                                if (this.state.selectedModuleType === "Memory") {

                                    ModuleService.saveMemory(this.state.moduleContent, response);
                                }

                                if (this.state.selectedModuleType === "Pairing") {

                                    ModuleService.savePairing(this.state.moduleContent, response);
                                }
                                  if (this.state.selectedModuleType === "PairingMemory") {

                                    ModuleService.savePairingMemory(this.state.moduleContent, response);
                                }

                                if (this.state.selectedModuleType === "PairingText") {

                                    ModuleService.savePairing(this.state.moduleContent, response);
                                }
                            }

                            if (Object.keys(this.state.previewModuleContent).length > 0) {
                                 if (this.state.selectedPreviewModuleType === "LinkScore") {
                                    
                                    ModuleService.saveLinkScore(this.state.previewModuleContent, this.state.selectedPreviewModuleId, response)
                                }
                                 
                                  if (this.state.selectedPreviewModuleType === "BonusScore") {
                                   
                                    ModuleService.saveBonusScore(this.state.previewModuleContent, this.state.selectedPreviewModuleId, response)
                                }

                                if (this.state.selectedPreviewModuleType === "Video") {
                                    ModuleService.saveVideo(this.state.previewModuleContent, this.state.selectedPreviewModuleId, response)
                                }

                                if (this.state.selectedPreviewModuleType === "Text") {
                                    ModuleService.saveModulePreview(this.state.previewModuleContent, this.state.selectedPreviewModuleId, response, "Text")
                                }

                                if (this.state.selectedPreviewModuleType === "PanoramaTour") {
                                    ModuleService.saveModulePreview(this.state.previewModuleContent, this.state.selectedPreviewModuleId, response, "PanoramaTour")
                                }


                                if (this.state.selectedPreviewModuleType === "Picture") {
                                    ModuleService.saveModulePreview(this.state.previewModuleContent, this.state.selectedPreviewModuleId, response, "Picture")
                                }

                                if (this.state.selectedPreviewModuleType === "Swipe") {
                                    ModuleService.saveSwipe(this.state.previewModuleContent, response, this.state.selectedPreviewModuleId);
                                }
                                
                                if (this.state.selectedPreviewModuleType === "3D") {
                                    ModuleService.saveThreeDModel(this.state.previewModuleContent, response, this.state.selectedPreviewModuleId);
                                }
                            }
                            this.setState({warning: false});
                            this.props.history.push("/adminmodulelist");
                        },
                        error => this.setState({error, loading: false})
                );

    }

    onUnload = (e) => { // the method that will be used for both add and remove event
        e.preventDefault();
        e.returnValue = '';
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
    }

    componentDidMount() {

        document.body.classList.remove('login-body');
        document.body.classList.remove('welcome-body');
        document.body.classList.add('body');
        document.body.classList.add('admin-body');

        window.addEventListener("beforeunload", this.onUnload);


        let module = [];
        localStorage.setItem("moduleId", this.state.id);
        if (this.state.id !== 0) {
            ModuleService.findById(this.state.id)
                    .then(response => response.json())
                    .then(module => {
                        if (module) {
                            fetch("/modulepreview/find/" + this.state.id)
                                    .then(response => response.json())
                                    .then(data => {

                                        if (data.length > 0) {
                                            this.setState({"selectedPreviewModuleType": data[0].moduleType, "selectedPreviewModuleId": data[0].id})

                                            document.getElementById("table-title-modulepreview").classList.add("selected-table-title");

                                        }
                                    })
                            this.setState({
                                name: module.moduleName,
                                description: module.description,
                                active: module.active,
                                selectedModuleType: module.moduleType
                            });

                            if (this.state.name && this.state.description) {
                                document.getElementById("table-title-basic").classList.add("selected-table-title");
                            }

                            if (this.state.selectedModuleType) {
                                document.getElementById("table-title-module").classList.add("selected-table-title");
                            }
                        }
                    })
        } else {
            ModuleService.save("", "", "", this.state.id)
                    .then(
                            response => {

                                this.setState({"id": response});

                            },
                            error => this.setState({error, loading: false})
                    );
        }


    }

    createModule(module) {
        localStorage.setItem("scrollTop", window.pageYOffset);

        ReactDOM.render(
                React.createElement(module, {"saveModule": this.saveModule, "contextModule": this.context, "delete": this.deleteModuleType, "deletePreview": this.deletePreview, "moduleContent": this.state.moduleContent, "moduleId": this.state.id, "savePreviewModule": this.savePreviewModule, "modulePreviewId": this.state.selectedPreviewModuleId}),
                document.getElementById('modal-container')
                );


        setTimeout(function () {
            let element = document.getElementsByClassName("modal");
            element[0].classList.remove("display-none");
            element[0].classList.add("display-block");
            document.getElementsByClassName("admin-body")[0].classList.add("modal-admin-body");
            window.scrollTo(0, 0);
        }, 300)

    }
    render() {
        const {user, isOn, handleToggle, onColor, name, description, active} = this.state;
        if (!user) {
            return <Redirect to="/admin" />
        }
        return (
                <React.Fragment> 
                
                
                    <div id="content" className="wrapper">  
                        <div id="modal-container" className="modal admin-modal quiz-modal display-none"></div>
                        <AdminSideBar activeMenu="module"/> 
                        <div className="container">
                            <FormHeader checkSave={this.save} checkDelete={this.checkDelete} cancel={this.cancel}  title="MODUL" />
                
                
                            <div className="row" style={{marginTop: '40px'}}>
                                <table class="table table-dark form-table " id="muzped-admin-table">
                                    <tbody>
                                        <tr className="table-title" id="table-title-basic">
                                            <td scope="col" className="form-section-title"  colspan="2"><b>01</b>   <b>Alap</b> információk
                
                                            </td>
                
                
                                        </tr>
                
                
                
                                        <tr> 
                                            <td className="table-detail">
                                    <SwitchComponent id={this.state.id} active={this.state.active} index={0} changeStatus = {this.changeStatus} />
                                    <input type="text" name="name" value={name} className="item-form-input" placeholder="MODUL MEGNEVEZÉSE" onChange={this.handleChange}/>
                
                                    </td>
                
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="form-input-title left_border" style={{width: "230px",verticalAlign : "top"}}><span class="small-text  not-required" style={{fontSize: "10px"}}>NYILVÁNOS MODUL MEGJELENÉSE ESETÉN MEGJELENŐ RÖVID ISMERTETŐ SZÖVEG (OPCIONÁLIS)</span></div>
                                           
                                            <textarea name="description" id="description" style={{width: "650px", marginLeft: "15px"}} className="item-form-input not-required" value={description} placeholder="MODUL RÖVID LEÍRÁSA" onChange={this.handleChange} />
                
                                        </td>
                                    </tr>
                                    <tr className="table-title" id="table-title-modulepreview">
                                        <td scope="col" className="form-section-title"  colspan="2"><b>02</b>   <b>Média elem</b> szeményreszabása
                
                                        </td>
                
                
                                    </tr>
                                    <tr>
                                        <td >
                                            <span className={"left_border clickable  " + (this.state.selectedPreviewModuleType !== "" && this.state.selectedPreviewModuleType !== "Picture" ? "disable-module" : this.state.selectedPreviewModuleType === "Picture" ? "select-module" : "")} id="picture" onClick={() => this.createModule(ModulePictureForm)} >GALÉRIA</span>
                                            <span className={"left_border clickable " + (this.state.selectedPreviewModuleType !== "" && this.state.selectedPreviewModuleType !== "Text" ? "disable-module" : this.state.selectedPreviewModuleType === "Text" ? "select-module" : "")} id="text" onClick={() => this.createModule(ModuleTextForm)} >SZÖVEG</span>
                                            <span className={"left_border clickable " + (this.state.selectedPreviewModuleType !== "" && this.state.selectedPreviewModuleType !== "Video" ? "disable-module" : this.state.selectedPreviewModuleType === "Video" ? "select-module" : "")} id="video" onClick={() => this.createModule(ModuleVideoForm)}>VIDEÓ</span>
                                            <span className={"left_border clickable " + (this.state.selectedPreviewModuleType !== "" && this.state.selectedPreviewModuleType !== "Swipe" ? "disable-module" : this.state.selectedPreviewModuleType === "Swipe" ? "select-module" : "")} id="swipe" onClick={() => this.createModule(ModuleSwipeForm)}>SWIPE</span>
                                            <span className={"left_border clickable " + (this.state.selectedPreviewModuleType !== "" && this.state.selectedPreviewModuleType !== "PanoramaTour" ? "disable-module" : this.state.selectedPreviewModuleType === "PanoramaTour" ? "select-module" : "")} id="swipe" onClick={() => this.createModule(ModulePanoramaTourForm)}>GÖMBPANORÁMA / VIRTUÁLIS TÚRA</span>
                                            <span className={"left_border clickable " + (this.state.selectedPreviewModuleType !== "" && this.state.selectedPreviewModuleType !== "3D" ? "disable-module" : this.state.selectedPreviewModuleType === "3D" ? "select-module" : "")} id="3D" onClick={() => this.createModule(Module3DForm)}>3D modell</span>        
                                            <span className={"left_border clickable " + (this.state.selectedPreviewModuleType !== "" && this.state.selectedPreviewModuleType !== "LinkScore" ? "disable-module" : this.state.selectedPreviewModuleType === "LinkScore" ? "select-module" : "")} id="linkScore" onClick={() => this.createModule(ModuleLinkScoreForm)}>PONTGYŰJTŐ LINK</span>                                 
                                             <span className={"left_border clickable " + (this.state.selectedPreviewModuleType !== "" && this.state.selectedPreviewModuleType !== "BonusScore" ? "disable-module" : this.state.selectedPreviewModuleType === "BonusScore" ? "select-module" : "")} id="bonusScore" onClick={() => this.createModule(ModuleBonusScoreForm)}> KÓD PONTGYŰJTŐ</span>                                 
                                       
        </td>
                                    </tr>
                                    <tr className="table-title" id="table-title-module">
                                        <td scope="col" className="form-section-title"  colspan="2"><b>03</b>   <b>Játék-modul</b> szeményreszabása
                
                                        </td>
                
                
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={"left_border clickable " + (this.state.selectedModuleType !== "" && this.state.selectedModuleType !== "Quiz" && this.state.selectedModuleType !== "" ? "disable-module" : this.state.selectedModuleType === "Quiz" ? "select-module" : "")} id="quiz" onClick={() => this.createModule(ModuleQuizForm)}>KVÍZ</span>
                                            <span className={"left_border clickable " + (this.state.selectedModuleType !== "" && this.state.selectedModuleType !== "Memory" && this.state.selectedModuleType !== "" ? "disable-module" : this.state.selectedModuleType === "Memory" ? "select-module" : "")} id="memory" onClick={() => this.createModule(ModuleMemoryForm)}>MEMÓRIA</span>
                                            <span className={"left_border clickable " + (this.state.selectedModuleType !== "" && this.state.selectedModuleType !== "Pairing" && this.state.selectedModuleType !== "" ? "disable-module" : this.state.selectedModuleType === "Pairing" ? "select-module" : "")} id="pairing" onClick={() => this.createModule(ModulePairingForm)}>PÁROSÍTÓ</span>
                                            <span className={"left_border clickable " + (this.state.selectedModuleType !== "" && this.state.selectedModuleType !== "PairingText" && this.state.selectedModuleType !== "" ? "disable-module" : this.state.selectedModuleType === "PairingText" ? "select-module" : "")} id="pairingtext" onClick={() => this.createModule(ModulePairingTextForm)}>SZÖVEG PÁROSÍTÓ</span>   
                                            <span className={"left_border clickable " + (this.state.selectedModuleType !== "" && this.state.selectedModuleType !== "PictureQuiz" && this.state.selectedModuleType !== "" ? "disable-module" : this.state.selectedModuleType === "PictureQuiz" ? "select-module" : "")} id="picturequiz" onClick={() => this.createModule(ModulePictureQuizForm)}>KÉP KVÍZ</span> 
                                            <span className={"left_border clickable " + (this.state.selectedModuleType !== "" && this.state.selectedModuleType !== "PairingMemory" && this.state.selectedModuleType !== "" ? "disable-module" : this.state.selectedModuleType === "PairingMemory" ? "select-module" : "")} id="pairingmemory" onClick={() => this.createModule(ModulePairingMemoryForm)}>PÁROSÍTÓ MEMÓRIA</span>
                                            <span className={"left_border clickable " + (this.state.selectedModuleType !== "" && this.state.selectedModuleType !== "QuizPictureAnswers" && this.state.selectedModuleType !== "" ? "disable-module" : this.state.selectedModuleType === "QuizPictureAnswers" ? "select-module" : "")} id="quizpictureanswer" onClick={() => this.createModule(ModuleQuizPictureAnswersForm)}> KVÍZ KÉP VÁLASZOKKAL</span> 
                
                                        </td>
                                    </tr>
                                    </tbody>
                
                                </table>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                );
    }
}

export default withRouter(ModuleForm);






