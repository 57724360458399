import React, { Component} from "react";
import { withRouter } from "react-router";
import { Redirect, Link } from 'react-router-dom';
import muzpedIcon from "../../images/muzped_icon.png"
import "../../style/user.css";
import muzpedText from "../../images/muzped_logo_text.png"
import {authService} from "../../_services/authService"
import ThemeSelection from "../components/ThemeSelection"
import ThemeModal from "./ThemeModal";
import {ModuleService} from "../../_services/ModuleService";
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';

    class ThemeSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'), "pazirik77").toString(CryptoENC)) : "",
            subThemeId: localStorage.getItem("subThemeId"),
            theme: [],
            age: [],
            ageId: localStorage.getItem("ageId")

        };

        this.logout = this.logout.bind(this);
        this.backToList = this.backToList.bind(this);
        this.getSelectedTheme = this.getSelectedTheme.bind(this);
        this.goToModules = this.goToModules.bind(this);
        
          localStorage.setItem("moduleIndex", 0);
        localStorage.setItem("activeModule", "");
        localStorage.setItem("activeModuleId", "");
        localStorage.setItem("nextPageLink", "");
        localStorage.setItem("activeQuestionIndex", 0);
        localStorage.setItem("modules", []);

    }

    getSelectedTheme(){
        this.props.history.push("/modules")
    }
    logout() {
        authService.logout();
        this.props.history.push("/")
    }
    
    goToModules(){
        
        this.props.history.push("/modules")
    }
    
    backToList (){
     const themeId = localStorage.getItem("themeId");
        fetch("/subtheme/find/" + themeId)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 1) {
                        this.props.history.push({
                            pathname: '/subThemeList',
                            data: themeId // your data array of objects
                        })

                    } else {
                        localStorage.setItem("subThemeId", data[0].id);
                        this.props.history.push({
                            pathname: '/themeList',
                           
                        })
                    }

                })


    }
    
   


    componentDidMount() {


        fetch("/subtheme/select/" + this.state.subThemeId)
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    this.setState({theme: data})
                })

        fetch("/age/select/" + this.state.ageId)
                .then(response => response.json())
                .then(data => {

                    this.setState({age: data})
                })
    }

    componentWillMount() {

        let host = window.location.host;

        let parts = host.split(".");
        let subdomain = "";

        if (parts.length >= 3) {
            subdomain = parts[0];

        }

        document.body.classList.add(subdomain + 'muzped_back');
        document.body.classList.remove(subdomain + 'theme_back');

        document.body.classList.add('muzped_back');
        document.body.classList.remove('theme_back');

    }
    render() {
        const {user} = this.state;
        if (!user) {
            return <Redirect to="/login" />
        }
        return (
                <div className="container" >
                    <div className="row header">
                        <div className="col-sm text-left">
                            <i className="muzped-icon"></i>
                            <label class="black_border text-uppercase" style={{borderLeft: "none"}}><b>{this.state.age.ageFrom + " - " + this.state.age.ageTo}</b> éves korosztály </label>
                        </div>
                
                        <div className="col-sm text-center align-baseline">
                            <h3 className="align-text-bottom"><b>ALTEMATIKA</b>-VÁLASZTÓ</h3>
                        </div>
                        <div className="col-sm text-right">
                            <i className="muzped-text"></i><br/>
                            <button className="btn-logout btn" onClick={this.logout}></button>
                
                        </div>
                    </div>
                
                  <ThemeSelection theme={this.state.theme} cancelSelectTheme={this.backToList} getSelectedTheme={this.getSelectedTheme} type="subTheme" goToModules={this.goToModules}/>
                  
                
                
                </div>

                );
    }
}

export default withRouter(ThemeSelect);