


import React, { Component} from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import { Redirect, Link } from 'react-router-dom';
import muzpedIcon from "../../images/muzped_icon.png"
import "../../style/user.css";
import muzpedText from "../../images/muzped_logo_text.png"
import ThemeModal from "./ThemeModal";
import {ModuleService} from "../../_services/ModuleService";
import {authService} from "../../_services/authService"
import ThemeDescription from "../components/ThemeDescription"


class SubThemeDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            themeId: localStorage.getItem("subThemeId"),
            theme: [],
            age: [],
            show: false

        };
        this.updateAllModuleActive();
        this.goToModules = this.goToModules.bind(this);
        this.showModal = this.showModal.bind(this);
        this.updateModuleList = this.updateModuleList.bind(this);
        this.logout= this.logout.bind(this);
        localStorage.setItem("moduleIndex", 0);
        localStorage.setItem("activeModule", "");
        localStorage.setItem("activeModuleId", "");
        localStorage.setItem("nextPageLink", "");
        localStorage.setItem("activeQuestionIndex", 0);
        localStorage.setItem("modules", []);

    }
    
    
logout(){
        authService.logout();
        this.props.history.push("/")
    }

    showModal() {
       
        let element = document.getElementsByClassName("modal");
        element[0].classList.remove("display-none");
        element[0].classList.add("display-block");
    }
    
    goToModules() {
        this.props.history.push("/modules");
    }


    updateAllModuleActive() {
        fetch("/thememodule/find/" + localStorage.getItem("subThemeId"))
                .then(response => response.json())
                .then(modules => {
                    modules.map((module, index) => {

                        ModuleService.setActiveStatus(module.id, localStorage.getItem("subThemeId"), true).then(response =>{
                            this.updateModuleList();
                                    this.themeModal.updateModules();
                                }
                        );
                    })


                });

        
    }

    updateModuleList() {

        fetch("/thememodule/getActives/" + localStorage.getItem("subThemeId"))
                .then(response => response.json())
                .then(data => {
                    this.setState({"modules": data});
                    localStorage.setItem("modules", data);
                });

    }

    componentDidMount() {
        
           let host = window.location.host;

        let parts = host.split(".");
        let subdomain = "";

        if (parts.length >= 3) {
            subdomain = parts[0];

        }
     
        document.body.classList.add(subdomain+'muzped_back');
        document.body.classList.remove(subdomain+'theme_back');
         
        document.body.classList.add('theme_back');
        document.body.classList.remove('muzpad_back');

        fetch("/subtheme/select/" + this.state.themeId)
                .then(response => response.json())
                .then(data => {

                    this.setState({theme: data});

                    console.log(data);
                    localStorage.setItem("subTheme", JSON.stringify(data));
                })

        fetch("/age/select/" + this.state.ageId)
                .then(response => response.json())
                .then(data => {

                    this.setState({age: data})
                })
    }

    render() {
        const {user, isOn, handleToggle, onColor} = this.state;
        if (!user) {
            return <Redirect to="/login" />
        }
        return (
                <div id="content" className="wrapper">  
                <div className="container" >
                  
                    <div className="row description-head">
                        <div className="col-sm text-left">
                            <i className="muzped-icon"></i>
                        </div>
                
                        <div className="col-sm text-center align-baseline">
                            <h3 className="align-text-bottom" >{this.state.theme.subThemeName}</h3>
                        </div>
                        <div className="col-sm text-right">
                
                           <i className="muzped-text"></i><br/>
                             <button className="btn-logout btn" onClick={this.logout}></button>
                        </div>
                    </div>
                
                <ThemeDescription theme={this.state.theme} goToModules=""/>
                   
        </div>
                
                    <footer className="muzped-footer" >
                        <div className="container" style={{marginTop : "-10px"}}>
                            <div class="row">
                                <div className="col-md-3">
                                    <Link to="/subThemeSelect"><button className="btn-backward btn"></button></Link>
                                    <button onClick={this.goToModules} className="btn-forward btn"></button>
                                    <button className="btn-module btn" onClick={this.showModal}></button>
                                    <div id="modal-container">
                                        <ThemeModal onRef={ref => (this.themeModal = ref)} updateModuleList={this.updateModuleList} showButtons={false} draggable={true}/> 
                                    </div>
                                </div>
                                <div className="col-md-6 text-center">
                                    <button onClick={this.goToModules} className="theme_button theme_green_button goto-modules" ><b>Tovább</b> az első feladathoz</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <Link to="/dashboard"><button className="btn-home btn"></button></Link>
                                </div>
                            </div>
                        </div>
                    </footer>
                
                </div>
                );
    }
}

export default withRouter(SubThemeDescription);