import React from 'react';

import { withRouter } from "react-router";
import {ModuleService }from "../../_services/ModuleService"

        class ModuleTextPairing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            pairs: []

        };


        this.handleDrop = this.handleDrop.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.checkElements = this.checkElements.bind(this);
        this.removeCircle = this.removeCircle.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.disableDragStart = this.disableDragStart.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.removeRedCircle = this.removeRedCircle.bind(this);
    }

    componentDidMount() {
        ModuleService.getPairingItems(localStorage.getItem('activeModuleId'))
                .then(pairs => {
                    this.setState({"pairs": pairs})
                    this.moveRandomPlaces();
                }
                )

    }

    moveRandomPlaces() {
        var divs = document.getElementsByClassName('game-pictures');

        var winWidth = document.getElementById('game-container').offsetWidth;
        var winHeight = document.getElementById('game-container').offsetHeight;


        for (var i = 0; i < divs.length; i++) {

            var thisDiv = divs[i];

            var randomTop = this.getRandomNumber(0, 80);
            var randomLeft = this.getRandomNumber(0, 100);

            thisDiv.style.marginTop = randomTop + "px";

            thisDiv.style.marginLeft = randomLeft + "px";
            thisDiv.style.zIndex = 1;


        }
    }

    getRandomNumber(min, max) {

        return Math.random() * (max - min) + min;

    }

    checkElements() {

        var x = document.getElementById("pairing_container").getElementsByTagName("img").length + document.getElementById("pairing_container").getElementsByClassName("pairing_text").length;
        var y = document.getElementById("good_pairs").getElementsByTagName("img").length + document.getElementById("good_pairs").getElementsByClassName("pairing_text").length;

        if (this.state.pairs.length === y) {

            var pairs = "";
            var mainPairs = this.state.pairs.filter(pair => pair.main_pair === true);
            var statePairs = this.state.pairs;
            mainPairs.map(function (pair) {

                const pairPairs = statePairs.filter(pairPair => pairPair.pair_sign === pair.pair_item_sign);

                pairs += '<div class="endpair text_pairing_end">' +
                        '<div class="game-pictures"  ><div class="endlabel  pair-label"><label class="label-number">' + pair.pair_sign + '</label></div><img    src="' + "/file/download?fileId=" + pair.img_url + '" /></div><br>' +
                        '<div class="game-pictures"  ><div class="endlabel"><label class="label-number">' + pairPairs[0].pair_sign + '</label></div><div class="pair pairing_text" >"' + pairPairs[0].img_url + '" </div></div>' +
                        '</div>'
            });
            document.getElementById("game-container").innerHTML = '<div class="endpairs">' + pairs + '</div>';
            document.getElementById("root").classList.add("gameend-body");
        }
    }

    removeCircle(event, circleDiv) {
        event.removeChild(circleDiv);

        var pairs = document.getElementsByClassName("paired_images");

        document.getElementById("good_pairs").appendChild(document.getElementById("paired_images"));

        this.checkElements(pairs);
    }

    removeRedCircle(event, circleDiv, droppedImg, label, id) {
        event.removeChild(circleDiv);
        if (document.getElementsByClassName("moving").length > 0) {
            let moving = document.getElementsByClassName("moving")[0];
            document.getElementById("pairing_container").removeChild(moving);
            document.getElementById(document.getElementById(this.state.touchElement.id).parentNode.getAttribute("id")).classList.remove("invisible");
        }

        if (id) {
           //  document.getElementById(id).appendChild(label);
            document.getElementById("game-picture-div-" + id).appendChild(droppedImg);
           
        }

    }
    handleDrop(event) {
        event.preventDefault();
        event.target.classList.remove("drag-over");

        var data = event.dataTransfer.getData("text");
        var pair = event.dataTransfer.getData("pair");
        var circleDiv = document.createElement("div");
        var droppedImg = document.getElementById(data);

        circleDiv.setAttribute("id", "circle");
        if (event.target.getAttribute("id") == pair) {
            circleDiv.setAttribute("class", "green_circle");
            event.target.parentElement.appendChild(circleDiv);
            //  droppedImg.parentElement.removeChild(droppedImg.parentElement.getElementsByClassName("label")[0])
            var copiedElement = document.getElementById(data).cloneNode(true);
            document.getElementById(data).classList.add("invisible");
            event.target.parentElement.appendChild(copiedElement);

            event.target.parentElement.setAttribute("id", "paired_images");
            event.target.parentElement.style.marginTop = 0;
            event.target.parentElement.style.marginLeft = 0;
            event.target.parentElement.style.display = "inherit";

            setTimeout(this.removeCircle, 500, event.target.parentElement, circleDiv);

        } else {
            var label = droppedImg.parentElement.getElementsByClassName("label")[0]
            //  droppedImg.parentElement.removeChild(droppedImg.parentElement.getElementsByClassName("label")[0]);

            event.target.parentElement.appendChild(document.getElementById(data));


            circleDiv.setAttribute("class", "red_circle");
            event.target.parentElement.appendChild(circleDiv);
            setTimeout(this.removeRedCircle, 700, event.target.parentElement, circleDiv, droppedImg, label, data);
        }



    }

    handleDragOver(event) {

        event.stopPropagation();
        event.preventDefault();

        if (event.target.tagName === "IMG" || event.target.classList.contains("pairing_text"))
            event.target.classList.add("drag-over");

    }

    handleDragLeave(event) {
        event.target.classList.remove("drag-over");
    }

    handleDragStart(event) {

        if (event.target.tagName === "IMG" || event.target.classList.contains("pairing_text")) {
            event.dataTransfer.setData('text/html', event.target.innerHTML);
            event.dataTransfer.setDragImage(document.getElementById(event.target.parentNode.getAttribute("id")), 0, 0);
            event.dataTransfer.effectAllowed = "move";
            event.dataTransfer.setData("text", event.target.parentNode.getAttribute("id"));
            event.dataTransfer.setData("Text", event.target.parentNode.getAttribute("id"));
            event.dataTransfer.setData("pair", event.target.parentNode.getAttribute("pair"));
            event.dataTransfer.setData('text/html', event.target.innerHTML);
        } else {
            event.preventDefault();
        }
    }
    handleTouchEnd(event) {
        //  let moving = document.getElementById(document.getElementById(this.state.touchElement.id).parentNode.getAttribute("id"));
        let moving = document.getElementsByClassName("moving")[0];

        var endTarget = document.elementFromPoint(
                event.changedTouches[0].pageX,
                event.changedTouches[0].pageY
                );

        var circleDiv = document.createElement("div");

        circleDiv.setAttribute("id", "circle");
        endTarget.classList.remove("drag-over");
        if (endTarget.tagName === "IMG" || endTarget.classList.contains("pairing_text")) {
            if (endTarget.getAttribute("pair") == this.state.touchElement.id) {
                circleDiv.setAttribute("class", "green_circle");
                moving.classList.remove("moving");
                moving.style.top = "";
                moving.style.left = "";
                moving.style.position = "relative";
                moving.style.display = "inline-block";

                endTarget.parentElement.appendChild(circleDiv);
                endTarget.parentElement.appendChild(moving);
                endTarget.parentElement.setAttribute("id", "paired_images");
                endTarget.parentElement.style.top = "";
                endTarget.parentElement.style.left = "";
                endTarget.parentElement.style.display = "inherit";
                 endTarget.parentElement.style.height = "100px";


                setTimeout(this.removeCircle, 500, endTarget.parentElement, circleDiv);

            } else {
                circleDiv.setAttribute("class", "red_circle");
                endTarget.parentElement.appendChild(circleDiv);

                /*   moving.style.left = "";
                 moving.style.top = "";
                 moving.style.marginLeft = this.state.touchElement.marginLeft + "px";
                 moving.style.marginTop = this.state.touchElement.marginTop + "px";
                 moving.style.position = "relative";*/

                setTimeout(this.removeRedCircle, 700, endTarget.parentElement, circleDiv, moving, "", "");
            }

        } else {
            document.getElementById("pairing_container").removeChild(moving);
            document.getElementById(document.getElementById(this.state.touchElement.id).parentNode.getAttribute("id")).classList.remove("invisible");
            /*  moving.style.left = "";
             moving.style.top = "";
             moving.style.marginLeft = this.state.touchElement.marginLeft + "px";
             moving.style.marginTop = this.state.touchElement.marginTop + "px";
             moving.style.position = "relative";*/
        }
    }

    handleTouchMove(event) {

        /* let moving = document.getElementById(document.getElementById(this.state.touchElement.id).parentNode.getAttribute("id")).cloneNode(true);
         document.getElementById("pairing_container").appendChild(moving);*/
        let moving = document.getElementsByClassName("moving")[0];

        moving.style.left = event.changedTouches[0].pageX + "px";
        moving.style.top = event.changedTouches[0].pageY + "px";
        moving.style.marginLeft = 0;
        moving.style.marginTop = 0;
        moving.style.position = "fixed";
        moving.style.zIndex = "0";
        moving.classList.remove("invisible");
        document.getElementById(document.getElementById(this.state.touchElement.id).parentNode.getAttribute("id")).classList.add("invisible");
        var endTarget = document.elementFromPoint(
                event.changedTouches[0].pageX,
                event.changedTouches[0].pageY
                );
        var dragged = document.getElementsByClassName("drag-over");
        for (var i = 0; i < dragged.length; i++) {
            dragged[i].classList.remove("drag-over");
        }

        if (endTarget.tagName === "IMG" || endTarget.classList.contains("pairing_text"))
            endTarget.classList.add("drag-over");


    }
    handleTouchStart(event) {


        if (event.target.tagName === "IMG" || event.target.classList.contains("pairing_text")) {
            //let moving = document.getElementById(document.getElementById(event.target.id).parentNode.getAttribute("id"));
            document.getElementById(document.getElementById(event.target.id).parentNode.getAttribute("id")).classList.add("invisible");
            let moving = document.getElementById(document.getElementById(event.target.id).parentNode.getAttribute("id")).cloneNode(true);
            document.getElementById("pairing_container").appendChild(moving);

            moving.classList.add("moving")


            let touchElement = {};
            touchElement.id = event.target.id;
            touchElement.marginTop = moving.style.marginTop;
            touchElement.marginLeft = moving.style.marginLeft;

            this.setState({"touchElement": touchElement});
        } else {
            event.preventDefault();
        }
    }
    disableDragStart(event) {
        event.preventDefault();
    }

    render() {
        /*  const modulePreview = JSON.parse(localStorage.getItem("modulePreview"));*/

        return (
                <div className="" id="game-container">
                    <div className="row pairing_game_container" id="pairing_container">
                        { this.state.pairs.length > 0 ? this.state.pairs.map((pair, index) =>
                                        <div className="game-pictures"    id={"game-picture-div-" + pair.pair_sign} onTouchEnd={e => this.handleTouchEnd(e)} key={index} onDrop={e => this.handleDrop(e)} onDragLeave={e => this.handleDragLeave(e)}  onDragOver={e => this.handleDragOver(e)}>
                                            <div className="pairing-drag-div"  id={pair.pair_sign} draggable={false}  onTouchStart={e => this.handleTouchStart(e)} onDragStart={e => this.handleDragStart(e)} pair={pair.pair_item_sign}> 
                                                <div draggable={false}  className="label" onDragStart={e => this.disableDragStart(e)} >
                                                    <label className="label-number">{pair.pair_sign}</label>
                                
                                                </div>
                                                {pair.main_pair ? <img  draggable={true} id={pair.pair_sign}  alt="img"  pair={pair.pair_item_sign} onTouchMove ={e => this.handleTouchMove(e)} className={pair.main_pair ? "" : "pair"}   src={"/file/download?fileId=" + pair.img_url} /> :
                                                                            <div  draggable={true} id={pair.pair_sign}  pair={pair.pair_item_sign} onTouchMove ={e => this.handleTouchMove(e)}  className="pairing_text">{pair.img_url}</div>
                                                }
                                            </div>
                                        </div>
                                    ) : ""}
                
                    </div>
                    <div id="good_pairs" ></div>
                </div>



                );
    }
}
;

export default ModuleTextPairing;







