import React, { Component}from "react";
import {ModuleService}from "../../_services/ModuleService";
import ModuleFormsHeader from"./ModuleFormsHeader"

class ModuleLinkScoreForm extends Component{
    
     constructor(props) {
        super(props);
        this.state = {
            
            itemNo: 0,
            linkScore: [],
            text : "",
            link :"",
            linkText : "",
            id : 0,
            score : ""
            
            
        }
        this.subThemeThemeModal = React.createRef();
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.ModuleFormsHeader = React.createRef();

    }

    handleChange(e) {
       
        const {name, value} = e.target;
        this.setState({[name]: value});
    }
   

    save() {
        var error = false;

       
        if (!error) {
            var linkScore=[];
            linkScore.text = this.state.text;
            linkScore.link= this.state.link;
            linkScore.linkText = this.state.linkText;
            linkScore.id=this.state.id;
            linkScore.score= this.state.score;
            console.log(linkScore);
            this.props.savePreviewModule("LinkScore", linkScore);
            this.ModuleFormsHeader.current.hideModal();
           
        }  
    }
    
     componentDidMount() {

        if (this.props.moduleId != 0) {
             
        fetch("/linkscore/getLinkScore/" + this.props.modulePreviewId)
                 .then(response => response.json())
                .then(items => {
                   
                  if(items.length>0)
                    this.setState({"text" :  items[0].text,linkText : items[0].linkText, link : items[0].link, id : items[0].id,score : items[0].score});
                    
                  
                    });

        }


    }

   
    render() {



        return (
                <div  className="modal-dialog admin-modal quiz-modal ">
                    <section  className="admin-form-modal modal-content" >
                        
                            <ModuleFormsHeader ref={this.ModuleFormsHeader} title="SZÖVEG" save={this.save} delete={this.props.deletePreview} context={this.props.contextModule}/>
                
                
                            <div className="  modal-body" style={{padding: "40px"}} >
                                <input type="text" className="item-input" name="linkText"onChange={this.handleChange} placeholder="LINK szövege" value={this.state.linkScore.linkText} value={this.state.linkText}/>
                                <input type="text" className="item-input" name="link" onChange={this.handleChange} placeholder="LINK" value={this.state.link}/>
                                <input type="text" className="item-input" name="score" onChange={this.handleChange} placeholder="PONTSZÁM" value={this.state.score}/>
                               <textarea  type="text" className="item-input" style={{border : "2px solid #252629", marginTop : "40px",height : "400px"}} onChange={this.handleChange} name="text" id="module_text" value={this.state.text} placeholder="LEÍRÁS" />
                            </div>
                
                
                        
                
                    </section>
                
                </div>
                );
    }
}

export default ModuleLinkScoreForm;