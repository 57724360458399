import React from 'react';
import { withRouter } from "react-router";
import ReactAudioPlayer from 'react-audio-player';

class ModuleVoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            record: false
        }
    }

    render() {
        const modulePreview = JSON.parse(localStorage.getItem("modulePreview"));
      
        return (
                <div style={{margin: "0 auto",marginTop : '100px'}}>  
                    <ReactAudioPlayer
                        src="https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3"
                        autoPlay
                        controls
                        />
                </div>


                );
    }
}
;

export default ModuleVoice;