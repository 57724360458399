import React, { Component }from "react";
import { withRouter }from "react-router";
import { Redirect }from 'react-router-dom';
import "../../style/admin.css";
import AdminSideBar from '../AdminSideBar';
import SearchComponent from '../components/SearchComponent'
import FilterComponent from '../components/FilterComponent'
import SwitchComponent from '../components/SwitchComponent'

import ListHeader from "../components/ListHeader"
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';

        class Directories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.hasOwnProperty("user") && !localStorage.getItem("user").id ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'),"pazirik77").toString(CryptoENC)):"",
            modules: [],
            filters: [],
            images: [],
            selectedDirectory: [],
            directoryList: [],
            mainDirectory: [],
        };
        this.getImages = this.getImages.bind(this);
        this.getSubDirs = this.getSubDirs.bind(this);
       
        this.getSubDirs = this.getSubDirs.bind(this);

        this.onDragOver = this.onDragOver.bind(this);
        this.editDirectory = this.editDirectory.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);



    }
    
     editDirectory(id,name){
        
         document.getElementById("add-directory").classList.remove("d-none");
          document.getElementById("directory-name").value= name;
          this.props.editDirectoryName();
     
    }

    onDragOver(event, directoryId) {

        event.preventDefault();
        document.getElementById("directoryLabel_" + directoryId).classList.add("dragdirectory")
    }

    onDragLeave(event, directoryId) {
        document.getElementById("directoryLabel_" + directoryId).classList.remove("dragdirectory")
    }

    onDrop(event, directory) {

        fetch("/file/updateFileDirectory", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({fileId: event.dataTransfer.getData("fileId"), directoryId: directory.id})
        })
                .then(response => response.json())
                .then(response => {

                    this.getImages(directory, event);
                });

        document.getElementById("directoryLabel_" + directory.id).classList.remove("dragdirectory")
    }

   

    addImageToList(fileId) {
        if (this.state.images.indexOf(fileId) === -1) {
            this.state.images.push(fileId);
            this.setState({images: this.state.images});
        }
    }

    updateImages(fileId) {
        var images = this.state.images;
        this.state.images = this.state.images.filter(image => image !== fileId);
        this.setState({images: []});
        this.state.images.push(fileId);
        this.setState({images: images});
    }

    deleteImage(fileId) {
        this.state.images = this.state.images.filter(image => image !== fileId);
        this.setState({images: this.state.images});
    }

    deleteActives() {
        var actives = document.getElementsByClassName("media-table")[0].getElementsByClassName("active");
        for (var i = 0; i < actives.length; i++) {
            actives[i].classList.remove("active");
        }
    }

    getImages(dir, e) {
        if (e) {
            e.stopPropagation();
        }
        fetch("/file/getfilelist/" + dir.id +"/" + localStorage.getItem("institution"))
                .then(response => response.json())
                .then(data => {

                    this.setState({images: data, selectedDirectory: dir})
                    this.props.changeDirectory(data, dir);
                })

        var activeDirectory = document.getElementsByClassName("media-table")[0].getElementsByClassName("active");
        var i = 0;
        for (i; i < activeDirectory.length; i++) {
            activeDirectory[i].classList.remove("active");
        }

        if (document.getElementById("directory_" + dir.id))
            document.getElementById("directory_" + dir.id).getElementsByTagName("span")[0].classList.add("active");

    }

    goToAllDirectory(directories, directory) {

        directories.map((dir, index) => {

            if (directory.directoryId === index) {
                dir["subDir"][directory.id] = [];
                dir["subDir"][directory.id]["name"] = directory.name;
                dir["subDir"][directory.id]["directoryId"] = directory.directoryId;
                dir["subDir"][directory.id]["id"] = directory.id;
                dir["subDir"][directory.id]["path"] = directory.path;
                dir["subDir"][directory.id]["subDir"] = [];
            } else {
                if (dir["subDir"].length > 0) {
                    return this.goToAllDirectory(dir["subDir"], directory);
                }
            }
        });
        this.setState({"directoryList": directories})

    }

   directoryCreate(directory){
        var directoryLi = ""
       
        //  directoryLi+='<li id="directory_'+directory.id+'">'+directory.name+'</li>'
        if (document.getElementById("directory_" + directory.directoryId) && !document.getElementById("directory_" + directory.id)) {
            //  document.getElementById("directory_"+directory.directoryId).innerHTML+=directoryLi;
            var li = document.createElement("li");
           
            li.id = "directory_" + directory.id;
            var span = document.createElement("span");
            span.id="directoryLabel_"+directory.id;
            li.addEventListener("click", (e) => this.getImages(directory, e));
            span.addEventListener("dragover", (e)=> this.onDragOver(e,directory.id));
            span.addEventListener("dragleave", (e)=> this.onDragLeave(e,directory.id));
            span.addEventListener("drop", (e)=> this.onDrop(e,directory));
            li.appendChild(span);
            span.innerHTML=directory.name;
            if (document.getElementById("directory_" + directory.directoryId).getElementsByTagName("ul").length > 0) {
                document.getElementById("directory_" + directory.directoryId).getElementsByTagName("ul")[0].appendChild(li);
            } else {
                document.getElementById("directory_" + directory.directoryId).appendChild(li);
            }
            var editElement = document.createElement("span");
            editElement.addEventListener("click",(e) => this.editDirectory(directory.id,directory.name));
            editElement.className="edit-directory";
            document.getElementById("directory_" + directory.id).getElementsByTagName("span")[0].appendChild(editElement);
            
        }
        
    }
    
    addEditLabel(directoryName){
        console.log(this.state.selectedDirectory.id);
          var editElement = document.createElement("span");
            editElement.addEventListener("click",(e) => this.editDirectory(this.state.selectedDirectory.id,directoryName));
            editElement.className="edit-directory";
            document.getElementById("directoryLabel_" + this.state.selectedDirectory.id).appendChild(editElement);
            this.setState({editDirectory : false})
    }

    getSubDirs(directory, index) {
         this.directoryCreate(directory);
        directory.subDir.map(dir => {

            return this.getSubDirs(dir);
        })

        if (this.state.selectedDirectory.id !== 0) {
            var activeDirectory = document.getElementsByClassName("media-table")[0].getElementsByClassName("active");
            var i = 0;
            for (i; i < activeDirectory.length; i++) {
                activeDirectory[i].classList.remove("active");
            }

            if (document.getElementById("directory_" + this.state.selectedDirectory.id))
                document.getElementById("directory_" + this.state.selectedDirectory.id).getElementsByTagName("span")[0].classList.add("active");
            return;
        }

    }

    componentDidMount() {
      this.props.onRef(this);
        fetch("/file/getDirectories/" + localStorage.getItem("institution"))
                .then(response => response.json())
                .then(directories => {
                    this.setState({"mainDirectory": directories[0]});
                    this.getImages(directories[0]);
                    directories.map(directory => {
                        if (this.state.directoryList.length > 0) {

                            this.goToAllDirectory(this.state.directoryList, directory);
                        } else {
                            this.state.directoryList[directory.id] = [];
                            this.state.directoryList[directory.id]["name"] = directory.name;
                            this.state.directoryList[directory.id]["subDir"] = [];
                            this.state.directoryList[directory.id]["directoryId"] = directory.directoryId;
                            this.state.directoryList[directory.id]["id"] = directory.id;
                            this.state.directoryList[directory.id]["path"] = directory.path;
                        }
                        this.setState({"directoryList": this.state.directoryList})
                    })
                });

    }

    render() {


        return (
                <tbody>
                    <tr>
                
                        <td id={"directory_" + this.state.mainDirectory.id}><span onClick={(e) => this.getImages(this.state.mainDirectory, e)} style={{marginLeft: "-20px", width: "calc(100% + 20px)"}} >FŐ MAPPA<span className="edit-directory" onClick={() => this.editDirectory(this.state.mainDirectory.id,this.state.mainDirectory.name)}></span></span>
                        </td>
                    </tr>
                    {this.state.directoryList[this.state.mainDirectory.id] ? this.state.directoryList[this.state.mainDirectory.id]["subDir"].map((directory, index) =>
                                <tr key={index}  id={index}>
                            
                                    <td id={"directory_" + directory.id} ><span id={"directoryLabel_" + directory.id} onDragLeave={(e, ) => this.onDragLeave(e, directory.id)} onDrop={(e) => this.onDrop(e, directory)} onDragOver={(e) => this.onDragOver(e, directory.id)} onClick={(e) => this.getImages(directory, e)}>{directory.name}<span className="edit-directory" onClick={() => this.editDirectory(directory.id,directory.name)}></span></span>
                                        <ul>
                                            { directory.subDir.map((subDir, i) =>
                                                        {
                                                            this.getSubDirs(subDir, index)
                                                        }

                                                        )}
                                        </ul>
                            
                                    </td>
                            
                                </tr>


                                            ) : ""
                                }
                            
                            </tbody>
                        );
            }
        }

        export default Directories;


