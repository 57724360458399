import React, { Component}from "react";
import FileUpload from "../components/FileUpload"
import ReactDOM from "react-dom";
import FilterComponent from './FilterComponent'
import SwitchComponent from './SwitchComponent'
import SearchComponent from './SearchComponent'

class ListHeader extends Component {

  
     

    render() {



        return (
              <div className="row page-header" id="modal-header">
                
                            <div className="col-sm  align-baseline">
                                <i className="fa" id="info-label-header"  ></i>
                                <SearchComponent />
                            </div>
                            <div className="col-sm text-left">
                
                               <h2 className="align-text-bottom ">{this.props.title}</h2>
                            </div>
                            <div className="col-sm text-right">
                                 <FilterComponent filters={this.props.filters} filter={this.props.filter} filterItems ={this.props.filteritems}/>
                                  <i className="fa close-label" id="info-label"  for="" onClick={(e) => this.props.hideModal(e)}></i>
                            </div>
                
                        </div>

                );
    }
}

export default ListHeader;
