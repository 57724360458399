
import React, { Component}from "react";
import {ModuleService}from "../../_services/ModuleService";
import ModuleFormsHeader from"./ModuleFormsHeader"

        class ModulePanoramaTourForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            videoLink: ""
        }
        this.subThemeThemeModal = React.createRef();
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.ModuleFormsHeader = React.createRef();

    }

    handleChange(e) {

        const {name, value} = e.target;
        if (e.target===""){
            this.setState({"link": ""});
        } else {
            this.setState({"link": value});
        }
        
    }

    save() {
        var error = false;


        if (!error) {
            this.props.savePreviewModule("PanoramaTour", this.state.link);
            this.ModuleFormsHeader.current.hideModal();

        }
    }
    componentDidMount() {

        if (this.props.moduleId != 0) {

            fetch("/modulepreview/find/" + this.props.moduleId)
                    .then(response => response.json())
                    .then(items => {
                        if (items.length > 0)
                            this.setState({"link": items[0]["link"]});


                    });

        }


    }

    render() {



        return (
                <div  className="modal-dialog">
                    <section  className="modal-content" >
                        <div className="">
                            <ModuleFormsHeader ref={this.ModuleFormsHeader} title="GÖMBPANORÁMA / VIRTUÁLIS TÚRA" save={this.save} delete={this.props.deletePreview} context={this.props.contextModule}/>
                
                
                            <div className="modal-body"  >
                               
                                <input  type="text" className="item-input" style={{ marginTop: "40px"}} onChange={this.handleChange} name="link" id="link" value={this.state.link} placeholder="Gömpanorámához / Virtuális túrához tartozó link" />
                                <div  style={{width: "80%", height: "500px", margin: "0 auto", textAling: "center"}}>
                
                                    <iframe width='100%' height='400' src={this.state.link} frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
                                </div>
                            </div>
                
                
                
                
                        </div>
                
                    </section>
                
                </div>
                );
    }
}


export default ModulePanoramaTourForm;









