
import React from "react";
import ModuleFormsHeader from"./ModuleFormsHeader"
import ReactDOM from "react-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

class OnlineStatistics extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            scores: [],
            pagedItems: [],
            rightAnswers: [],
            wrongAnswers: [],
            commonAnswers: [],
            moduleType : "",
            nameList : []
        }
        this.hideModal = this.hideModal.bind(this);
        this.exportToXLSX = this.exportToXLSX.bind(this);

    }

    hideModal() {


        document.body.classList.remove("modal-admin-body");
        let element = document.getElementsByClassName("modal");
        element[0].classList.add("display-none");
        element[0].classList.remove("display-block");
        ReactDOM.unmountComponentAtNode(document.getElementById("modal-container"));

    }

    exportToXLSX() {
         const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  
  
  this.state.scores.map((score,index) =>{
                   this.state.nameList.push({"Név" : score[1],Email : score[7],"Pontszám" : score[3]});
                     this.setState({nameList : this.state.nameList});      
                 })
  
        const ws = XLSX.utils.json_to_sheet(this.state.nameList);
        const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
        const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'proba.xlsx');
    }

    mostCommonAnswersForQuestion(array) {

        var mf = 1;
        var m = 0;
        var item;
        for (var i = 0; i < array.length; i++)
        {
            for (var j = i; j < array.length; j++)
            {
                if (array[i] == array[j])
                    m++;
                if (mf < m)
                {
                    mf = m;
                    item = array[i];
                }
            }
            m = 0;
        }

        return item;
    }
    componentDidMount() {

        if (this.props.type === "module") {
            var rightAnswers = new Array();
            var wrongAnswers = [];
            fetch("/score/getScoresByModule/" + this.props.itemId)
                    .then(scores => scores.json())
                    .then(scores => {
                        this.setState({scores: scores});


                        var answers = [];
                        scores.map(score => {

                            var results = JSON.parse(score[0]);
                            this.setState({moduleType : score[2]});
                            if (score[2] === "Quiz") {
                                score["wronganswers"] = score[3] - score[3];
                              
                                fetch("/quizquestion/find/" + this.props.itemId)
                                        .then(response => response.json())
                                        .then(questions => {
                                            questions.map((question, index) => {
                                                if (question.rightAnswerSign === results[index]) {
                                                    rightAnswers[index] = (rightAnswers[index]) ? rightAnswers[index] + 1 : 1;
                                                    this.setState({rightAnswers : rightAnswers});
                                                } else {

                                                    wrongAnswers[index] = (wrongAnswers[index] ? wrongAnswers[index] + 1 : 1);
                                                    this.setState({wrongAnswers : wrongAnswers});
                                                }
                                                answers[index] = answers[index] ? [...answers[index], results[index]] : [results[index]];
                                                this.state.commonAnswers[index] = this.mostCommonAnswersForQuestion(answers[index]);
                                                this.setState({commonAnswers: this.state.commonAnswers});
                                            })



                                        });

                            }

                        })

                    });

        }

        if (this.props.type === "theme") {
            fetch("/score/getScoresByTheme/" + this.props.itemId)
                    .then(scores => scores.json())
                    .then(scores => {

                        this.setState({scores: scores});


                    });
        }
        console.log(this.props.type);
        if (this.props.type === "subtheme") {
            fetch("/score/getScoresBySubTheme/" + this.props.itemId)
                    .then(scores => scores.json())
                    .then(scores => {

                        this.setState({scores: scores});


                    });
        }
    }

    render() {

        return(
                <div  className="modal-dialog">
                    <section  className="modal-content" >
                        <div className="">
                            <div className="modal-header">
                                <div className="col-sm text-left">
                
                                    <i className="fa info-label" id="info-label"  for=""></i>
                                    <h2 className="" style={{marginLeft: "20px", fontSize: "16px", fontWeight: "normal", display: "inline"}}> <b>STATISZTIKA</b> MODULE CÍME[TÍPUSA</h2>
                                </div>
                
                
                                <div className="col-sm text-right">
                                    <a href="#" className="blue-back-link" style={{fontWeight: "none"}} onClick={this.exportToXLSX}>NÉVSOR <b>MENTÉSE</b></a>
                
                                    <i className="fa close-label" id="info-label"  for="" onClick ={this.hideModal}></i>
                
                                </div>
                
                
                
                            </div>
                
                
                            <div className="modal-body" >
                            { this.props.type==="module" ? 
                            <div>
                                <div className="score-info-div">
                                    <div>
                                    <span> <b>HELYES</b> válasz</span>
                                    <p></p>
                                    </div >
                                     
                                    <div className="right-answers">
                                     {this.state.rightAnswers}
                                    </div>
                                </div>
                                <div className="score-info-div">
                                    <div>
                                     <span>Legtöbb <b>HELYTELEN</b> válasz</span>
                                    <p></p>
                                    </div>
                                    <div className="wrong-anwswers">
                                    {this.state.wrongAnswers}
                                    </div>
                                </div>
                                </div>
                                :""}
                                <div style={{padding: "35px", overflow: "auto", maxHeight: "320px", paddingTop: "unset", background: "#c4c4c4"}}>
                                    <table class="table statistic-table  " >  
                                        <thead>
                                        <th width="300"><b>Név</b></th>
                                        {  this.props.type !== "theme" &&    <th width="140">Helyes</th>}
                                     {  this.props.type !== "theme" && <th width="140">Helytelen</th>}
                                        <th width="140">Pontszám</th>
                                        <th>Időpont</th>
                                        </thead>
                                        <tbody>
                                            {this.state.scores.map((score, index) =>
                                <tr>
                                    <td width="300">{score[1]}</td> 
                                    {  this.props.type !== "theme" &&   <td width="140" className="score-cell green-cell">{score[0]==="Rákattintott a linkre" ? "LINK" :score[4]}</td>}
                                 {   this.props.type !== "theme" && <td width="140" className="score-cell red-cell"> {score[0]==="Rákattintott a linkre" ? "LINK" :score[6]}</td>}
                                    <td width="140" className="score-cell blue-cell">{score[3]}</td>
    
                                    <td>{new Date(score[5]).toLocaleString('hu-HU')}</td>
                                </tr>

                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                );

    }

}

export default OnlineStatistics;