import React, { Component } from 'react';
import '../style/adminwelcome.css';
import logo from '../images/logo.png'
import { withRouter } from "react-router";

class AdminWelcome extends Component {

    constructor(props) {
        super(props)
        this.continueLogin = this.continueLogin.bind(this)
    }

    continueLogin() {
        this.props.history.push("/adminlogin");
    }

    componentDidMount() {
        document.body.classList.add('admin-welcome-body');
        document.body.classList.remove('admin-body');
        document.body.classList.remove('body');
    }
    render() {
       

        return (
                <div id="welcome" className="container">
                  <div className="row" >
                    <div id="left-side" className="col-md" style={{marginTop:"40px"}}>
                        <div id="logo">
                            <img src={logo} />
                            <p style={{marginTop :"200px"}}>TEGYÜK <br/> A <b>MÚZ</b>EUM-<b><br/>PED</b>AGÓGIÁT SZÓRAKOZTATÓVÁ <u>EGYÜTT</u>.</p>
                        </div>
                    </div>
                    <div id="right-side" className="col-md">
                        <button id="loginButton" class=" muzped-admin-button" onClick={this.continueLogin} style={{marginTop: "calc(50% + 50px)"}}>Bejelentkezés</button><br/>
                        <a href="/forgetPassword" class="muzped-white-link">Elfelejtettem a jelszavam</a>
                        <p class="footer" >@MÚZPED 2020</p>
                    </div>
                    </div>
                </div>
                );
    }
}

export default withRouter(AdminWelcome);
