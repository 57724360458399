import React, { Component} from "react";
import { withRouter } from "react-router";
import { Redirect, Link } from 'react-router-dom';
import muzpedIcon from "../../images/muzped_icon.png"
import "../../style/user.css";
import muzpedText from "../../images/muzped_logo_text.png";
import {ThemeService} from "../../_services/ThemeService";
import {authService} from "../../_services/authService"
import ThemesList from "../components/ThemeList"
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';

class ThemeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'), "pazirik77").toString(CryptoENC)) : "",
            ageId: localStorage.getItem("ageId"),
            themes: [],
            age: []

        };


        this.goToTheme = this.goToTheme.bind(this);
        this.logout = this.logout.bind(this);
    }

    goToTheme(e, themeId) {

        localStorage.setItem("themeId", themeId);

        fetch("/subtheme/find/" + themeId)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 1) {
                        this.props.history.push({
                            pathname: '/themeSelect',
                            data: themeId // your data array of objects
                        })

                    } else {
                        localStorage.setItem("subThemeId", data[0].id);
                        this.props.history.push({
                            pathname: '/subThemeSelect',
                           
                        })
                    }

                })



    }

    componentDidMount() {
        fetch("/age/select/" + this.state.ageId)
                .then(response => response.json())
                .then(data => {

                    this.setState({age: data})
                })

        ThemeService.findUserThemes(this.state.ageId, this.state.user.id)
                .then(themes => {
                   
                    this.state.themes=themes;
                    this.setState({"themes": this.state.themes})
                   
                });



    }
    
    logout(){
        authService.logout();
        this.props.history.push("/")
    }

    componentWillMount() {

        let host = window.location.host;

        let parts = host.split(".");
        let subdomain = "";

        if (parts.length >= 3) {
            subdomain = parts[0];

        }

        document.body.classList.add(subdomain + 'muzped_back');
        document.body.classList.add('muzped_back');
    }
    render() {
        const {user} = this.state;
        if (!user) {
            return <Redirect to="/login" />
        }
        return (
                <div id="content" className="wrapper">   
                    <div className="container" >
                        <div className="row header">
                            <div className="col-sm text-left">
                                 <i className="muzped-icon"></i>
                                <label className="black_border text-uppercase" style={{borderLeft: "none"}}><b>{this.state.age.ageFrom + " - " + this.state.age.ageTo}</b> éves korosztály </label>
                            </div>
                
                            <div className="col-sm text-center align-baseline">
                                <h3 className="align-text-bottom"><b>TEMATIKA</b>VÁLASZTÓ</h3>
                            </div>
                            <div className="col-sm text-right">
                
                                <i className="muzped-text"></i><br/>
                                <button className="btn-logout btn" onClick={this.logout}></button>
                            </div>
                        </div>
                
                       <ThemesList themes={this.state.themes} selectTheme={this.goToTheme} type="theme"  />
                    </div>
                    <footer className="muzped-footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <Link to="/dashboard"><button className="btn-backward btn"></button></Link>
                
                                </div>
                                <div className="col-md-6 text-center">
                
                                </div>
                                <div className="col-md-3 text-right">
                                    <Link to="/dashboard"><button className="btn-home btn"></button></Link>
                                </div>
                            </div>
                        </div>
                    </footer>
                
                </div>
                );
    }
}

export default withRouter(ThemeList);