
import React, { Component } from 'react'


import { withRouter } from "react-router";

class ModuleMultiSwipe extends Component {
    constructor(props) {
        super(props)
        this.state = {
            actualSwipeIndex: 0,
            swipes: []
        }
      
        this.initComparisons = this.initComparisons.bind(this);

    }

    componentDidMount() {
       
       const modulePreview = JSON.parse(localStorage.getItem("modulePreview"));
       const imageArray = modulePreview.link;
       
       const imageMap=imageArray.split(";");
       const images=[];
       imageMap.map((image,index) =>{
           if (index===3){
               images.push({ "original": image});
           } else {
              images.push({ "original": '/file/download?fileId='+image});
             }
       });
       
        this.setState({"swipes" : images});
           console.log(this.state.swipes);
           this.initComparisons();


    }

    initComparisons() {
        var x, i;

        x = document.getElementsByClassName("active")[0].getElementsByClassName("img-comp-overlay");
        if (document.getElementsByClassName("img-comp-slider").length > 0) {
            document.getElementsByClassName("img-comp-slider")[0].remove();
        }
        for (i = 0; i < x.length; i++) {

            this.compareImages(x[i],i,x.length);
        }

    }

    compareImages(img,index, no) {
        var slider, img, clicked = 0, w, h;
        /*get the width and height of the img element*/
        w = img.offsetWidth;
        h = img.offsetHeight;
        /*set the width of the img element to 50%:*/
       
        img.style.width = ((w/(no+1))*(no-index)) + "px";
        /*create slider:*/
        slider = document.createElement("DIV");
        slider.setAttribute("class", "img-comp-slider");
         slider.setAttribute("index", index);
        /*insert slider*/
        img.parentElement.insertBefore(slider, img);
        /*position the slider in the middle:*/
        slider.style.top = (h / 2) - (slider.offsetHeight / 2) + "px";
        slider.style.left =(((w/(no+1))*(no-index))-(slider.offsetWidth/(no+1))*(no-index)) + "px";
        const  slideReady = (e) => {
            /*prevent any other actions that may occur when moving over the image:*/
            e.preventDefault();
            /*the slider is now clicked and ready to move:*/
            clicked = 1;
            /*execute a function when the slider is moved:*/
            window.addEventListener("mousemove", slideMove);
            window.addEventListener("touchmove", slideMove);
        };
        const slideFinish = () => {
            /*the slider is no longer clicked:*/
            clicked = 0;
        };
        const  slideMove = (e) => {
            var pos;
            /*if the slider is no longer clicked, exit this function:*/
            if (clicked === 0)
                return false;
            /*get the cursor's x position:*/
            pos = getCursorPos(e);
            /*prevent the slider from being positioned outside the image:*/
            if (pos < 0)
                pos = 0;
            if (pos > w)
                pos = w;
            /*execute a function that will resize the overlay image according to the cursor:*/
            slide(pos);
        };
         const getCursorPos = (e) => {
            var a, x = 0;
           
            e = e || window.event;
           
            a = img.getBoundingClientRect();
          
               
           if(e.type==="touchmove"){
                x =e.changedTouches[0].pageX - a.left;
           } else{
                x =e.pageX - a.left;
           }
           
            x = x - window.pageXOffset;
            return x;
        }
        const slide = (x) => {
            /*resize the image:*/
            var i=0;
             var sliders = document.getElementsByClassName("img-comp-slider");
            for (i = 0; i < sliders.length; i++) {
                
                 if(slider.getAttribute("index")> sliders[i].getAttribute("index") && sliders[i].offsetLeft<x){
                     sliders[i].style.left= x + "px";
                     document.getElementsByClassName("img-comp-overlay")[i].style.width = x+"px";
                 }
                 
                  if(slider.getAttribute("index")< sliders[i].getAttribute("index") && sliders[i].offsetLeft>x){
                     sliders[i].style.left= x + "px";
                     document.getElementsByClassName("img-comp-overlay")[i].style.width = x+"px";
                 }
              
            }
            img.style.width = x + "px";
            /*position the slider:*/
            slider.style.left = img.offsetWidth - (slider.offsetWidth / ((no+1)-index)) + "px";
           // var sliders = document.getElementsByClassName("img-comp-slider");
            
        }
        /*execute a function when the mouse button is pressed:*/
        slider.addEventListener("mousedown", slideReady);
        /*and another function when the mouse button is released:*/
        window.addEventListener("mouseup", slideFinish);
        /*or touched (for touch screens:*/
        slider.addEventListener("touchstart", slideReady);
        /*and released (for touch screens:*/
        window.addEventListener("touchend", slideFinish);

    }

   

    render() {

        return (
              
                <div className="swipe-container multi-swipe-container" style={{margin: "0 auto"}}>
                 
                    <div style={{margin: "0 auto", width: "1200px", height: "600px", display: "inline-block"}}>
                
                        <div className={"img-comp-container active"}>
                            <div className="img-comp-img">
                                <img src={this.state.swipes[0] ? this.state.swipes[0].original :""} width="1200" height="600"/>
                            </div>
                            <div className="img-comp-img img-comp-overlay" >
                                <img src={this.state.swipes[1] ? this.state.swipes[1].original :""} width="1200" height="600"/>
                            </div>
                              <div className="img-comp-img img-comp-overlay" >
                                <img src={this.state.swipes[2] ?this.state.swipes[2].original :""} width="1200" height="600"/>
                            </div>
                              <label className="swipe-label">{this.state.swipes[3] ?this.state.swipes[3].original :""}</label>
                        </div>
                        
                     </div>
                 
                  
                </div>
               
             
                )

    }
}

export default ModuleMultiSwipe;