import React, { useCallback, useEffect, useState, useStateWithPromise } from 'react'

        const PopupContext = React.createContext();

const Popup = ({ popup, unSetPopup }) => {
    useEffect(() => {
        const bind = e => {
            console.log("useeffect");
            if (e.keyCode !== 27) {
                return
            }

            if (document.activeElement && ['INPUT', 'SELECT'].includes(document.activeElement.tagName)) {
                return
            }

            unSetPopup()

        }

        document.addEventListener('keyup', bind)
        return () => document.removeEventListener('keyup', bind)
    }, [popup, unSetPopup])

    document.getElementsByClassName("container")[0].classList.add("popup_body");
    if(document.getElementById("sidebar"))
    document.getElementById("sidebar").classList.add("popup_body");

    return (
            <div className={"popup_" + popup.type} id="popup">
                <i className="fa close-label" onClick={popup.ok} />
            
                <div className="popup_content">
                    { popup.type === "error" ? <i className="fa fa-popup-error" /> : ""}
                    {popup.title}
                    <div className="message">
                        {popup.message}
                    </div>
                    {popup.type === "delete" ?
                            <div className="popup_buttons">
                                <button className="delete-button" onClick = {() => popup.delete(true,popup.id, popup.name)}> IGEN</button>
                                <button onClick = {() => popup.delete(false)}> MÉGSEM</button>
                            </div>

                                : ""}   
                           {popup.type === "confirm" ?
                            <div className="popup_buttons">
                                <button className="confirm-button" onClick = {() => popup.confirm(true)}> IGEN</button>
                                <button onClick = {() => popup.confirm(false)}> MÉGSEM</button>
                            </div>

                                : ""} 
                </div>
            
            
            </div>
            )
}



const PopUpProvider = props => {
    const [popup, setPopup] = useState();

    const unSetPopup = useCallback((message) => {
        setPopup();
        document.getElementsByClassName("container")[0].classList.remove("popup_body");
       if(document.getElementById("sidebar"))
        document.getElementById("sidebar").classList.remove("popup_body");
       
       
    }, [setPopup])


    return (
            <PopupContext.Provider value={{unSetPopup, setPopup}} {...props} ref={unSetPopup} >
                {props.children}
                {popup && <Popup popup={popup} unSetPopup={unSetPopup} />}
            </PopupContext.Provider>
            )
}



const UsePopup = () => {

    const context = React.useContext(PopupContext);

    if (context === undefined) {

        throw new Error('useCountDispatch must be used within a CountProvider')

    }

    return context;

}

export { PopUpProvider, UsePopup, PopupContext }