
import React from  'react';
class EndQuiz extends React.Component {

    render() {

        var answers = JSON.parse(localStorage.getItem("quizAnswers"));
        if (localStorage.hasOwnProperty("onlineGame")) {
            document.getElementById("forward_module_button").classList.remove("d-none");
            if (localStorage.getItem("activeModule") === "ModulePictureQuiz")
            {

                console.log(document.getElementById("forward_module_button"));
                console.log(document.getElementById("forward_module_button"));
            }
        }

        function showComment(show) {

            if (show)
                document.getElementsByClassName("picture-comment")[0].classList.remove("d-none");
            else
                document.getElementsByClassName("picture-comment")[0].classList.add("d-none");
        }
        return (
                <React.Fragment>
                    {this.props.questions.map((question, index) =>
                                        <React.Fragment>
                                            {   localStorage.getItem("activeModule") === "ModulePictureQuiz" ?
                                                                            <React.Fragment>
                                                                                <div onMouseEnter={() => showComment(true)}
                                                                                     onMouseLeave={() => showComment(false)} className="col endpicturequiz endquiz" style={{textAlign: "center", margin: "0 auto"}}>
                                                                    
                                                                                    <img className="picturequiz-img endpicturequiz-img" src={"/file/download?fileId=" + question.question} />
                                                                                    <div className="picture-comment d-none">
                                                                                        {question.pictureComment}
                                                                                    </div>
                                                                                    <div className={"col " + (question.comment ? "" : "d-none")}><div className={"simple_quiz_comment " + (question.comment ? "" : "d-none")} style={{fontSize: "15px"}}><p>{question.comment}</p></div></div>
                                                                    
                                                                    
                                                                                </div>
                                                                    
                                                                    
                                                                    
                                                                    
                                                                    
                                                                                <div className="col" >
                                                                                    { question.pictureQuestion ? <label className="black_border font-weight-bold quiz_question">{question.pictureQuestion}</label> : ""}
                                                                    
                                                                    
                                                                                    {  this.props.answers[index].findIndex(p => p.answerSign == "A") !== -1 ?
                                                                                                                    <div  id="answer_A" className={"black_border font-weight-bold quiz_answear " + (this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "A")].rightAnswer ? "quiz_right_answer" : answers[index] == "A" ? "quiz_wrong_answer" : "")}> 
                                                                                                                        <div className=" answer-sign font-weight-bold">A</div> {this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "A")].answer}</div> : ""}
                                                                    
                                                                    
                                                                                    {   this.props.answers[index].findIndex(p => p.answerSign == "B") !== -1 ?
                                                                                                                    <div id="answer_B" className={"black_border font-weight-bold quiz_answear " + (this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "B")].rightAnswer ? "quiz_right_answer" : answers[index] == "B" ? "quiz_wrong_answer" : "")}> 
                                                                                                                        <div className=" answer-sign font-weight-bold">B</div> {this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "B")].answer}</div> : ""}
                                                                    
                                                                    
                                                                                    { this.props.answers[index].findIndex(p => p.answerSign == "C") !== -1 ?
                                                                                                                    <div  id="answer_C" className={"black_border font-weight-bold quiz_answear " + (this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "C")].rightAnswer ? "quiz_right_answer" : answers[index] == "C" ? "quiz_wrong_answer" : "")}>
                                                                                                                        <div className=" answer-sign font-weight-bold">C</div>{this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "C")].answer}</div> : ""}
                                                                    
                                                                                    { this.props.answers[index].findIndex(p => p.answerSign == "D") !== -1 ?
                                                                                                                    <div  id="answer_D" className={"black_border font-weight-bold quiz_answear " + (this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "D")].rightAnswer ? "quiz_right_answer" : answers[index] == "D" ? "quiz_wrong_answer" : "")}>
                                                                                                                        <div className=" answer-sign font-weight-bold">D</div>{this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "D")].answer}</div> : ""}
                                                                    
                                                                    
                                                                                </div>
                                                                    
                                                                            </React.Fragment>

                                                            : localStorage.getItem("activeModule") === "ModuleQuiz" ?
                                                                            <div className="col text-center endquiz" id="quiz_container">
                                                                                <div>
                                                                    
                                                                                    <label className="black_border font-weight-bold quiz_question">{question.question}</label>
                                                                                </div>
                                                                    
                                                                    
                                                                                <div className="row" >
                                                                    
                                                                                    <div className="col" align="right" >
                                                                    
                                                                                        {  this.props.answers[index].findIndex(p => p.answerSign == "A") !== -1 ?
                                                                                                                    <div  id="answer_A" className={"black_border font-weight-bold quiz_answear " + (this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "A")].rightAnswer ? "quiz_right_answer" : answers[index] == "A" ? "quiz_wrong_answer" : "")}> 
                                                                                                                        <div className=" answer-sign font-weight-bold">A</div> {this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "A")].answer}</div> : ""}
                                                                                    </div>
                                                                                    <div className="col" align="left" >
                                                                    
                                                                                        { this.props.answers[index].findIndex(p => p.answerSign == "B") !== -1 ?
                                                                                                                    <div  id="answer_B" className={"black_border font-weight-bold quiz_answear answer_b " + (this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "B")].rightAnswer ? "quiz_right_answer" : answers[index] == "B" ? "quiz_wrong_answer" : "")}>
                                                                                                                        <div className=" answer-sign font-weight-bold">B</div>{this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "B")].answer}</div> : ""}
                                                                    
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col " align="right">
                                                                                        {   this.props.answers[index].findIndex(p => p.answerSign == "C") !== -1 ?
                                                                                                                    <div id="answer_C" className={"black_border font-weight-bold quiz_answear answer_c " + (this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "C")].rightAnswer ? "quiz_right_answer" : answers[index] == "C" ? "quiz_wrong_answer" : "")}> 
                                                                                                                        <div className=" answer-sign font-weight-bold">C</div> {this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "C")].answer}</div> : ""}
                                                                    
                                                                    
                                                                                    </div>
                                                                                    <div className="col" align="left" >
                                                                                        { this.props.answers[index].findIndex(p => p.answerSign == "D") !== -1 ?
                                                                                                                    <div  id="answer_D" className={"black_border font-weight-bold quiz_answear " + (this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "D")].rightAnswer ? "quiz_right_answer" : answers[index] == "D" ? "quiz_wrong_answer" : "")}>
                                                                                                                        <div className=" answer-sign font-weight-bold">D</div>{this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "D")].answer}</div> : ""}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="w-100"></div>
                                                                                <div className={"col " + (question.comment ? "" : "d-none")}><div className="simple_quiz_comment"style={{fontSize: "15px"}}><p>{question.comment}</p></div></div>
                                                                    
                                                                            </div>

                                                            :
                                                            localStorage.getItem("activeModule") === "ModuleQuizPictureAnswers" ?
                                                                            <div className="col text-center endquiz" id="quiz_container">
                                                                                <div>
                                                                    
                                                                                    <label className="black_border font-weight-bold quiz_question">{question.question}</label>
                                                                                </div>
                                                                    
                                                                                <div className="row quizpictureanswers">
                                                                    
                                                                    
                                                                                    {  this.props.answers[index].findIndex(p => p.answerSign == "A") !== -1 ?
                                                                                                                    <div className="col" >     <div  id="answer_A" className={" font-weight-bold quiz_answear " + (this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "A")].rightAnswer ? "quiz_right_answer" : answers[index] == "A" ? "quiz_wrong_answer" : "")}> 
                                                                                                                            <div className=" answer-sign font-weight-bold">A</div> <img src={"/file/download?fileId=" + this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "A")].answer}/></div></div> : ""}
                                                                    
                                                                    
                                                                                    {   this.props.answers[index].findIndex(p => p.answerSign == "B") !== -1 ?
                                                                                                                    <div className="col" >   <div id="answer_B" className={" font-weight-bold quiz_answear " + (this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "B")].rightAnswer ? "quiz_right_answer" : answers[index] == "B" ? "quiz_wrong_answer" : "")}> 
                                                                                                                            <div className=" answer-sign font-weight-bold">B</div> <img src={"/file/download?fileId=" + this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "B")].answer}/></div> </div> : ""}
                                                                    
                                                                    
                                                                                    { this.props.answers[index].findIndex(p => p.answerSign == "C") !== -1 ?
                                                                                                                    <div className="col" >     <div  id="answer_C" className={" font-weight-bold quiz_answear " + (this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "C")].rightAnswer ? "quiz_right_answer" : answers[index] == "C" ? "quiz_wrong_answer" : "")}>
                                                                                                                            <div className=" answer-sign font-weight-bold">C</div><img src={"/file/download?fileId=" + this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "C")].answer}/></div></div> : ""}
                                                                    
                                                                                    { this.props.answers[index].findIndex(p => p.answerSign == "D") !== -1 ?
                                                                                                                    <div className="col" >      <div  id="answer_D" className={" font-weight-bold quiz_answear " + (this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "D")].rightAnswer ? "quiz_right_answer" : answers[index] == "D" ? "quiz_wrong_answer" : "")}>
                                                                                                                            <div className=" answer-sign font-weight-bold">D</div><img src={"/file/download?fileId=" + this.props.answers[index][ this.props.answers[index].findIndex(p => p.answerSign == "D")].answer}/></div></div> : ""}
                                                                                </div> 
                                                                                <p style={{width: "100%", fontSize: "15px"}}>{question.comment}</p>
                                                                    
                                                                            </div>
                                                            : ""  }
                                        </React.Fragment>
                                )}
                
                </React.Fragment>
                );

    }
}

export default EndQuiz;