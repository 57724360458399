import React, { Component } from 'react';
import './style/welcome.css';
import logo from './images/black_logo.png'
import { withRouter } from "react-router";
import i18n from './i18n';

class App extends Component {

    constructor(props) {
        super(props)
        this.continueLogin = this.continueLogin.bind(this)
    }

    continueLogin() {
        this.props.history.push("/login");
    }

    componentWillMount() {
        document.body.classList.remove('login-body');
        document.body.classList.add('welcome-body');
         document.body.classList.remove('theme_back');
          document.body.classList.remove('muzped_back');
           document.body.classList.remove('gameend-body');
           
           
        let host = window.location.host;

        let parts = host.split(".");
        let subdomain = "";

        if (parts.length >= 3) {
            subdomain = parts[0];
            document.body.classList.add(subdomain);

        }
        
        
    }
    
    
    render() {

 
        return (
                <div id="welcome" className="container">
                  <div className="row" >
                    <div id="left-side" className="col-md" style={{marginTop:"40px"}}>
                        <div id="logo">
                            <i className="muzped-logo"></i>
                            <p>TEGYÜK <br/> A <b>MÚZ</b>EUM-<b><br/>PED</b>AGÓGIÁT SZÓRAKOZTATÓVÁ <u>EGYÜTT</u>.</p>
                        </div>
                    </div>
                    <div id="right-side" className="col-md">
                        <button id="loginButton" class=" muzped-button" onClick={this.continueLogin} style={{marginTop: "calc(50% + 50px)"}}>Bejelentkezés</button><br/>
                        <a href="/forgetPassword" class="muzped-black-link">Elfelejtettem a jelszavam</a>
                        <p class="footer" >@MÚZPED 2020</p>
                    </div>
                    </div>
                </div>

                );
    }
}

export default withRouter(App);
