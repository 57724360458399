
import React, { Component }from "react";
import { withRouter }from "react-router";
import { Redirect, useParams, Link }from 'react-router-dom';
import "../../style/admin.css";
import AdminSideBar from '../AdminSideBar';
import {ThemeService} from '../../_services/ThemeService'
import {UsePopup, PopupContext}  from '../../context/popup-context'
import SwitchComponent from "../components/SwitchComponent"
import FileUpload from "../components/FileUpload"
import ReactDOM from "react-dom";
import FormHeader from "../components/FormHeader"
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';

        class ThemeForm extends Component {

    static contextType = PopupContext;
    constructor(props, context) {
        super(props, context);
        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'), "pazirik77").toString(CryptoENC)) : "",
            isOn: true,
            handleToogle: true,
            onColor: "#fff",
            tasks: [],
            name: '',
            description: '',
            active: true,
            error: '',
            id: this.props.match.params.id,
            ages: [],
            selectedAge: "",
            selectedAgeId: 0,
            selectedImgUrl : "",
            selectedImgId :0
            

        };
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.ageSelect = this.ageSelect.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.delete = this.delete.bind(this);
        this.deletePicture = this.deletePicture.bind(this);
        this.checkSave = this.checkSave.bind(this);
        this.checkDelete = this.checkDelete.bind(this);
        this.cancel = this.cancel.bind(this);
        this.quit = this.quit.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.openFileList = this.openFileList.bind(this);
    }
    
    selectFile(path,fileName,id){
       //  document.getElementById("img-url").value = path;
       this.setState({selectedImgUrl : path, selectedImgId : id});
                    document.getElementById("show-image").src = "/file/download?fileId=" + id;
                    document.getElementById("image-div").classList.remove("d-none");
                    document.getElementsByClassName("image-label")[0].innerHTML = fileName;

    }
    
    openFileList(){
        ReactDOM.render(
                React.createElement(FileUpload, {selectFile : this.selectFile}),
                document.getElementById('file-container')
                );
        // document.getElementsByClassName("file-popup")[0].classList.add("d-none");
    }
    
     changeStatus(index, id, status) {
        this.setState({active: status});
    }

    
    cancel(){
          this.context.setPopup({ok:this.context.unSetPopup,title: <div style={{textAlign : "center", paddingBottom : "20px"}}><span style={{fontSize : "20px", textAlign : "center"}}> Biztosan kilép? </span></div>, type: "confirm",message : "(Az elvégzett módosítások nem kerülnek mentésre.)", confirm : this.quit});
   
    }
    
    quit(canQuit){
        this.context.unSetPopup();
        if(canQuit){
             this.props.history.push("/adminthemelist");
        }
    }

    deletePicture() {
        document.getElementById("img-url").value = "";
        document.getElementById("image-div").classList.add("d-none");
    }
    
    checkDelete(){
         this.context.setPopup({id : this.state.id, name : this.state.name,ok:this.context.unSetPopup,title: <div style={{textAlign : "center", paddingBottom : "20px"}}><span style={{fontSize : "20px", textAlign : "center"}}> Biztosan törli? </span></div>, type: "delete", delete : this.delete});
   
    }

    delete(canDelete) {
        this.context.unSetPopup();
       if(canDelete){
        fetch("/theme/delete?themeId=" + this.state.id + "&userId=" + this.state.user.id + "&institutionId=" + localStorage.getItem("institution") + "&name=" + this.state.name)
                .then(response => response.json())
                .then(response => {
                    this.props.history.push("/adminthemelist");
                })
            }
    }
    onFileChange(e) {

    /*    const fileName = e.target.files[0].name;
        const formData = new FormData()
        formData.append('file', e.target.files[0]);
        formData.append('path', '/themes/' + this.state.id + "/");
        fetch("/file/save", {
            method: 'POST',
            body: formData
        })
                .then(response => response.json())
                .then(response => {


                    document.getElementById("img-url").value = "uploads/themes/" + this.state.id + "/" + fileName;
                    document.getElementById("show-image").src = "/file/download?fileId="+id;
                    document.getElementById("image-div").classList.remove("d-none");
                    document.getElementsByClassName("image-label")[0].innerHTML = fileName;
                });*/
    }
    
     getFileName(fileId){
        
        
          fetch("/file/getname/"+fileId)
                .then(response => response.text())
                .then(response => {
                  
                   document.getElementById("imagename").innerHTML= response;

                });
    }

    ageSelect(ageId) {
        var selectedAge = this.state.ages.filter(age => age.id == ageId);
     //   document.getElementById("selected_age").innerHTML = '<span >' + selectedAge[0].ageFrom + " - " + selectedAge[0].ageTo + '</span>';
        this.setState({selectedAge: ageId});
        document.getElementById("table-title-age").classList.add("selected-table-title");
    }
    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
        document.getElementById("table-title-" + name).classList.add("selected-table-title");
    }

    checkSave() {
        var error = false;
        var notice = false;
        if (this.state.selectedAge === "") {

            error = true;
            this.context.setPopup({ok:this.context.unSetPopup,title: <span> Hiányzó elem : <b>Koroszályhoz csatolás</b></span>, type: "error"});
            document.getElementById("table-title-age").classList.add("error-table-title");
        }

        if (!error) {
            if (this.state.selectedImgId === 0) {

                 notice = true;
                
                this.context.setPopup({ok: this.save, title: <span> Ajánlott elem : <b>Kép csatolás</b></span>, type: "message", message: "Ajánlott képet csatolni a tematikához, amennyiben szeretnék ha az megjelenne a felhasználói felületen."});
            }
            
            if (!notice){
                this.save();
            }
        }
    }
    save(e) {
        const {name, description, selectedAge} = this.state;
        this.context.unSetPopup();


        ThemeService.save(name, description, selectedAge, this.state.selectedImgId, this.state.id, this.state.active)
                .then(
                        response => {

                            this.props.history.push("/adminthemelist");
                        },
                        error => this.setState({error, loading: false})
                );

    }

    async componentDidMount() {
        document.body.classList.remove('login-body');
        document.body.classList.remove('welcome-body');
        document.body.classList.add('body');
        document.body.classList.add('admin-body');
        console.log(this.context);
        let tasks = [];
        if (this.state.id !== 0) {
            ThemeService.findById(this.state.id)
                    .then(response => response.json())
                    .then(task => {
                        if (task) {

                            this.setState({
                                name: task.themeTitle,
                                description: task.description,
                                selectedAge: task.ageId,
                                active : task.active,
                                selectedImgId : task.fileId
                            });
                            if (task.themeTitle)
                                document.getElementById("table-title-name").classList.add("selected-table-title");
                            if (task.description)
                                document.getElementById("table-title-description").classList.add("selected-table-title");
                            if (task.ageId)
                                document.getElementById("table-title-age").classList.add("selected-table-title");
                            if (task.fileId) {
                              //  document.getElementById("img-url").value = task.img_url;
                              this.getFileName(task.fileId);
                                document.getElementById("show-image").src = "/file/download?fileId=" + task.fileId;
                                document.getElementById("image-div").classList.remove("d-none");
                                document.getElementsByClassName("image-label")[0].innerHTML = task.img_url;
                            }
                        }
                    })
        } else {

            ThemeService.save("", "", "", "", 0)
                    .then(
                            response => {

                                this.setState({id: response});
                            },
                            error => this.setState({error, loading: false})
                    );
        }

        fetch("/age/all")
                .then(response => response.json())
                .then(data => {

                    this.setState({ages: data})
                    if (this.state.selectedAge)
                    {
                        var selectedAge = this.state.ages.filter(age => age.id == this.state.selectedAge);
                      //  document.getElementById("selected_age").innerHTML = '<span >' + selectedAge[0].ageFrom + " - " + selectedAge[0].ageTo + '</span>';
                    }

                })
    }

    render() {
        const {user, isOn, handleToggle, onColor, name, description, active} = this.state;
        if (!user.name) {
            return <Redirect to="/" />
        }
        return (
                <React.Fragment>  <AdminSideBar /> 
                    <div className="container">
                    <FormHeader checkSave={this.checkSave} checkDelete={this.checkDelete} cancel={this.cancel} title="TEMATIKA" />
                        
                        <div className="row" style={{marginTop: '40px'}}>
                            <table className="table table-dark form-table " id="muzped-admin-table">
                                <tbody>
                                    <tr className="table-title" id="table-title-name" >
                                        <td scope="col" className="form-section-title"  colSpan="2"><b>01</b>   <b>Alap</b> információk
                
                                        </td>
                
                
                                    </tr>
                
                
                
                                    <tr> 
                                        <td colSpan="2">
                                              <SwitchComponent id={this.state.id} active={this.state.active} index={0} changeStatus = {this.changeStatus} />
                                            <input type="text" name="name" value={name} className="item-form-input" placeholder="TEMATIKA MEGNEVEZÉSE" onChange={this.handleChange}/>
                
                                        </td>
                
                                    </tr>
                                    <tr className="table-title" id="table-title-description">
                                        <td scope="col" className="form-section-title "  ><span style={{width: "360px"}}><b>02</b>   <b>Tematika</b> bemutatása</span>
                
                                        </td>
                                        <td className="table-title" style={{background : "#26547e",textAlign : "center"}}>
                                        <label style={{fontSize: "18px", display: "inline", color :"white"}} onClick={this.openFileList}>+ <b style={{paddingLeft: "0"}}>KÉP</b> CSATOLÁSA</label>
                                         
                                           </td>
                
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="form-input-title left_border"><span className="small-text  not-required">OPCIONÁLIS</span></div>
                                            <label htmlFor="description" style={{marginLeft: "15px", fontSize: "20px"}}>TEMATIKA <b>RÖVID LEÍRÁSA</b></label>
                
                                            <div className="form-input-title left_border"><span className="small-text  not-required"></span></div>
                                            <div className="left_border " style={{float: "right", fontSize: "20px", borderLeft: "3px solid white"}}></div>
                                            <input type="hidden" id="img-url" />
                                            <br/>
                                             <div className="form-input-title form-input-title-eye "> <i className="fa" id="eye-i"  ></i></div>
                                            <textarea placeholder="Tematika rövid leírása" name="description" id="description" style={{}} className="item-form-input " value={description}  onChange={this.handleChange} />
                
                                        </td>
                                        <td>
                                             <div className="image-div d-none" id="image-div">
                                                <img id="show-image" src="" /><br/>
                                                <span className="image-label" id="imagename"></span><br/><br/>
                                                <span className="small-text" onClick={this.deletePicture}>TÖRLÉS</span>
                                                <span className="small-text " style={{marginLeft: "15px", marginRight: "15px", borderLeft: "2px solid white"}}></span>
                                                <label className="small-text" onClick={this.openFileList}>CSERE</label>
                                            </div>
                
                                        </td>
                                    </tr>
                                    <tr className="table-title" id="table-title-age">
                                        <td scope="col" className="form-section-title"  colSpan="2"><b>03</b>   <b>korosztályhoz</b> csatolás
                
                                        </td>
                
                
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <div className="form-input-title left_border" id="selected_age"><span className="small-text  not-required">KÖTELEZŐ</span></div>
                                            <span type="text" name="age" className="item-form-input " style={{width: "420px"}}  ><b>KOROSZTÁLY</b>(OK) KIVÁLASZTÁSA</span>
                                            <div id="ages" style={{display: "inline-block", float: "right"}}>
                                                {this.state.ages.map(age =>
                                <div key={age.id} className={"age-div "+(this.state.selectedAge===age.id ? "active_age" : "")} style={{display: "inline-block"}}><span style={{cursor: "grabbing"}} onClick={() => this.ageSelect(age.id)}>{age.ageFrom + " - " + age.ageTo}</span></div>
                    )}
                                            </div> 
                
                                        </td>
                                    </tr>
                                </tbody>
                
                            </table>
                        </div>
                    </div>
                </React.Fragment>
                );
    }
}

export default withRouter(ThemeForm);




