import React, { Component}from "react";

class FilterComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filters: this.props.filters ? this.props.filters :[],
            selectedFilters: [],
            selectedFiltersLabels: [],
            filterItems : this.props.filterItems

        };


        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.selectFilter = this.selectFilter.bind(this);
        this.filterContetnt = this.filterContent.bind(this);


    }

    open() {

        document.getElementById("header_popup_filter").style.display = "inherit";
    }

    close() {
        document.getElementById("header_popup_filter").style.display = "none";
    }

    selectFilter(filter, e) {
       
        if (!e.target.classList.contains("font-weight-bold")) {
            this.state.selectedFilters.push(filter);
            this.state.selectedFiltersLabels.push(filter.label);


            e.target.classList.add("font-weight-bold");
        } else {

            const deletedIndex = this.state.selectedFilters.findIndex(selectedFilter => selectedFilter.value === filter.value);

            this.state.selectedFilters.splice(deletedIndex, 1);
            this.state.selectedFiltersLabels.splice(deletedIndex, 1);
            e.target.classList.remove("font-weight-bold");
        }

       
        this.setState({selectedFilters: this.state.selectedFilters, selectedFiltersLabels: this.state.selectedFiltersLabels})
        
         this.props.filter(this.filterContent());
    }

    filterContent() {
        
        const filters = this.state.selectedFilters;
        var filterItems = this.props.filterItems;
        var filteredContent =[];
        
        filterItems.map( (item) =>{
            for (var i=0; i< filters.length;i++) {
            
                if ( item[filters[i].key] == filters[i].value){
                    filteredContent.push(item);
                }
            }
        });
        
       
        return filteredContent;
    }
    render() {



        return (
                <div style={{display: "inline-block"}}>
                    <input type="text" id="filter" placeholder="SZŰRÉS"  onClick={this.open} className="muzped-material-input"/>
                    <i class="fa" id="filter_label" class="material-input-icon" onClick={this.open} for="filter" style={{marginRight: '25px'}}></i>
                    <div className="header_popup" id="header_popup_filter" style={{display: "none", height: "160px"}}>
                        <div className="header_popup_content">
                            <div style={{textAlign: "left"}} >
                                <span ><b>SZŰRŐK</b></span>
                                <div style={{display: "inline", marginLeft: "130px"}}>
                                    {this.state.selectedFiltersLabels.join(", ")}
                                </div>
                                <i className="fa " id="white_filter_label"  for="" onClick={this.close}></i>
                            </div>
                            <div className="filters">
                                {this.state.filters.length>0 ? this.state.filters.map((filter, index) =>
                                <span onClick={(e) => this.selectFilter(filter, e)}>{filter.label}</span>
                                    ) :""
                                }
                            </div>
                        </div>
                    </div>
                </div>

                );
    }
}

export default FilterComponent;