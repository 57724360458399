import React, { Component }from "react";
import { withRouter }from "react-router";
import { Redirect }from 'react-router-dom';
import "../../style/admin.css";
import AdminSideBar from '../AdminSideBar';

import SwitchComponent from '../components/SwitchComponent'
import Table from '../components/Table'
import ListHeader from "../components/ListHeader"
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';

class SubThemeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'), "pazirik77").toString(CryptoENC)) : "",
            isOn: true,
            handleToogle: true,
            onColor: "#fff",
            subThemes: [],
            filteredSubThemes : [],
            filters : [{"value" : true , "label" : "Aktív", "key" : "active" },{"value" : false , "label" : "Inaktív","key" : "active"} ]

        };
        
        this.deleteSubTheme = this.deleteSubTheme.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.filter = this.filter.bind(this);
        
    }
    
    

    componentDidMount() {
       localStorage.setItem("activeMenu" , "subTheme");
        let tasks = [];
        fetch("/subtheme/allinstitution/"+localStorage.getItem("institution"))
                .then(response => response.json())
                .then(data => {
                data.map(subTheme => {
                    subTheme.name = subTheme.subThemeName;
                })
                    this.setState({subThemes: data, filteredSubThemes : data})
                })
    }
    
    changeStatus(index,id, status){
       
        this.state.filteredSubThemes[index].active= status;
        this.setState({filteredSubThemes : this.state.filteredSubThemes});
        
        fetch("/subtheme/setActive", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({subThemeId: parseInt(id), active: status})
    })

            .then(response => {
               
                return response;
            });

    }
    
    filter(filteredContent){
        
        this.setState({filteredSubThemes : filteredContent});
    }
    
    deleteSubTheme(subThemeId,subThemeName){
          fetch("/subtheme/delete/"+subThemeId)
                .then(response => response.json())
                .then(response => {
                     const deletedIndex = this.state.subThemes.findIndex(subTheme =>   subTheme.id===subThemeId);
                   
                     this.state.subThemes.splice(deletedIndex,1);
                    this.setState({subThemes : this.state.subThemes})
                })
        
    }

    componentWillMount() {
        document.body.classList.remove('login-body');
        document.body.classList.remove('welcome-body');
        document.body.classList.add('admin-body');
        document.body.classList.add('body');
    }
    render() {
        const {user, isOn, handleToggle, onColor} = this.state;
        if (!user) {
            return <Redirect to="/admin" />
        }
        return (
                <React.Fragment>  <AdminSideBar activeMenu="subTheme"/> 
                    <div className="container">
                       <ListHeader title="ALTEMATIKA" filteritems={this.state.subThemes} filter={this.filter} filters={this.state.filters} AddItem="/subthemeform/0" />
                       
                
                        <div className="row " style={{marginTop: '40px'}}>
                         <Table type="subtheme" tableName = "ALTEMATIKA" edit = "/subthemeform/" delete = {this.deleteSubTheme} itemList = {this.state.filteredSubThemes}  changeStatus = {this.changeStatus}/>
                           
                        </div>
                    </div>
                </React.Fragment>
                );
    }
}

export default withRouter(SubThemeList);


