import React, { Component} from "react";
import { withRouter } from "react-router";
import { Redirect } from 'react-router-dom';
import muzpedIcon from "../images/muzped_icon.png"
import "../style/user.css";
import muzpedText from "../images/muzped_logo_text.png"
import {authService} from "../_services/authService"
import AgeSelection from "./components/AgeSelection"
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'), "pazirik77").toString(CryptoENC)) : "",
            ages: []

        };
        this.selectAge = this.selectAge.bind(this);
        this.goToThemeList = this.goToThemeList.bind(this);
        this.logout = this.logout.bind(this);
    }

    goToThemeList(e, ageId) {

       
        this.props.history.push({
            pathname: '/themelist',
            data: localStorage.getItem("ageId")
        });


    }
    selectAge(e) {
        //console.log(e.target.classList);
        var inactives = document.getElementsByClassName('ages_div');

        for (var y = 0; y < inactives.length; y++) {
          
            inactives[y].classList.remove("inactive_ages_div");
        }

        let element = document.getElementsByClassName('active_age');

        if (element.length > 0) {

            let buttons = element[0].parentElement.querySelectorAll('.ages_button ');

            if (buttons.length > 0) {
                buttons[0].classList.add("ages_button_not_display");
                buttons[0].classList.remove("ages_button_display");
            }
            element[0].classList.remove("active_age");
        }





        e.currentTarget.classList.add("active_age");
        let buttons = e.currentTarget.parentElement.querySelectorAll('.ages_button ');

        if (buttons.length > 0) {
            buttons[0].classList.remove("ages_button_not_display");
            buttons[0].classList.add("ages_button_display");
        }

        let  inactiveAges = document.getElementsByClassName('ages_div');
        var i;
       
        for (i = 0; i < inactiveAges.length; i++) {
            if (!inactiveAges[i].classList.contains("active_age")) {
                inactiveAges[i].classList.add("inactive_ages_div");
            }
        }

    }
    componentDidMount() {

        let tasks = [];
        fetch("/age/all")
                .then(response => response.json())
                .then(data => {

                    this.setState({ages: data})
                })
    }
    
     logout(){
        authService.logout();
        this.props.history.push("/")
    }

    componentWillMount() {
        document.body.classList.remove('login-body');
        document.body.classList.remove('welcome-body');
        document.getElementById("root").classList.remove("gameend-body");
       
        let host = window.location.host;

        let parts = host.split(".");
        let subdomain = "";

        if (parts.length >= 3) {
            subdomain = parts[0];

        }
        
        document.body.classList.remove('theme_back');
        document.body.classList.add('muzped_back');
        document.body.classList.remove(subdomain+'theme_back');
        document.body.classList.add(subdomain+'muzped_back');
    }
    render() {
        const {user} = this.state;
        if (!user) {
            return <Redirect to="/login" />
        }
        return (
                <div className="container" >
                    <div className="row header">
                        <div className="col-sm text-left">
                              <i className="muzped-icon"></i>
                        </div>
                
                        <div className="col-sm text-center align-baseline">
                            <h3 className="align-text-bottom"><b>ÉLETKOR</b>VÁLASZTÓ</h3>
                        </div>
                        <div className="col-sm text-right">
                            <i className="muzped-text"></i><br/>
                             <button className="btn-logout btn" onClick={this.logout}></button>
                
                        </div>
                    </div>
                
                    <AgeSelection goToThemeList={this.goToThemeList}/>
                </div>

                );
    }
}

export default withRouter(Dashboard);