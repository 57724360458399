import React from 'react';

class ModuleLinkScore extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      modulePreview: JSON.parse(localStorage.getItem("modulePreview")),
      linkScore: []
    }

    this.addScore = this.addScore.bind(this);

  }

  addScore() {
    
    this.props.saveScore(this.state.linkScore.score, "Rákattintott a linkre.", this.state.linkScore.score);
    this.props.nextModule();

  }

  componentDidMount() {
    if (document.getElementById("forward_module_button"))
      document.getElementById("forward_module_button").innerHTML = "Nem kérem a plussz pontokat";
    fetch("/linkscore/getLinkScore/" + this.state.modulePreview.id)
      .then(response => response.json())
      .then(items => {

        if (items.length > 0)
          this.setState({ linkScore: items[0] });


      });
  }

  componentWillUnmount() {
    if (document.getElementById("forward_module_button"))
       document.getElementById("forward_module_button").innerHTML = "<b>KÖVETKEZŐ</b> kérdés";
  }

  render() {

    return (
      <div style={{ margin: "0 auto", textAlign: "center" }}>
        <h1 className="congratulation-text" style={{ marginTop: "unset" }}>Psszt!</h1>
        <p style={{ fontSize: "26px" }}>A kérdések közé plusz pontokat rejtettünk - apróságokért cserébe.</p>

        <p style={{ fontSize: "26px", marginTop: "50px", marginBottom: "80px;" }}>{this.state.linkScore.text}</p>
        <a href={this.state.linkScore.link} target="_blank" className="linkbutton" onClick={this.addScore}>{this.state.linkScore.linkText}</a>

      </div>

    )
  }
}

export default ModuleLinkScore;