import React from 'react';

import castle from "../../images/var_2.jpg"


        class secondQuiz extends React.Component {
    constructor(props, context) {

        super(props, context);
        this.state = {

        }
    }

    render() {

        return(
                <div class="row" style={{position: "relative"}} id="second-little-quiz">
                    <img  src={castle} style={{width: "100%", height: "100vh"}} />
                
                
                    <div style={{width: "9%", height: "20%", top: "13%", left: "23%", position: "absolute"}} id="question_0" onClick={(e) => this.props.questionClick(e, 0)}>  </div>
                    <div  style={{width: "11%", height: "18.3%", top: "49%", left: "57.9%", position: "absolute"}} id="question_1" onClick={(e) => this.props.questionClick(e, 1)}>     </div>
                    <div style={{width: "12%", height: "41%", top: "52.2%", left: "68.4%", position: "absolute"}}  id="question_2" onClick={(e) => this.props.questionClick(e, 2)}>      </div>
                    <div style={{width: "13%", height: "25%", top: "60%", left: "0%", position: "absolute"}}  id="question_3" onClick={(e) => this.props.questionClick(e, 3)}>      </div>
                    <div style={{width: "9%", height: "19%", top: "54%", left: "39%", position: "absolute"}}  id="question_4" onClick={(e) => this.props.questionClick(e, 4)}>      </div>
                    <div style={{width: "16%", height: "36%", top: "46.2%", left: "79.68%", position: "absolute"}}  id="question_5" onClick={(e) => this.props.questionClick(e, 5)}>      </div>
                    <div style={{width: "9%", height: "29%", top: "50%", left: "30.3%", position: "absolute"}}  id="question_6" onClick={(e) => this.props.questionClick(e, 6)}>      </div>
                    <div style={{width: "11.7%", height: "40%", top: "56.4%", left: "47.3%", position: "absolute"}}  id="question_7" onClick={(e) => this.props.questionClick(e, 7)}>      </div>
                    <div style={{width: "12%", height: "29%", top: "55%", left: "12%", position: "absolute"}}  id="question_8" onClick={(e) => this.props.questionClick(e, 8)}>      </div>
                    <div style={{width: "7.3%", height: "27%", top: "47%", left: "23.5%", position: "absolute"}}  id="question_9" onClick={(e) => this.props.questionClick(e, 9)}>      </div>
                </div>
                );
    }

}
;
export default secondQuiz;

