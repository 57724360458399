import React, { Component} from "react";
import { withRouter } from "react-router";
import { Redirect } from 'react-router-dom';

class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startTouch: 0,
            endTouch: 0,
            activeItemIndex: 0
        };
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.moveLeft = this.moveLeft.bind(this);
        this.moveRight = this.moveRight.bind(this);
        this.move = this.move.bind(this);
    }

    handleTouchStart(e) {
      //  this.setState({startTouch: e.changedTouches[0].pageX})
        // document.getElementById("carousel-item-" + this.state.activeItemIndex).style.left =e.changedTouches[0].pageX +"px";
    }

    isInViewport(elem) {
        var distance = elem.getBoundingClientRect();
        //  console.log(width); 
     //   var div=document.getElementById("content");
       /* console.log("list left : "+div.scrollLeft);
        console.log("elem left : "+distance.left);*/
        /*console.log(elem);
          console.log("elem left "+distance.left);
          console.log("elem right "+distance.right);*/
        
               if(  -20 < distance.left &&
                   parseInt(window.innerWidth+50)> distance.right ){
           
               return true;
                   } else {
                     
                       return false;
                       
                   }
                


    }

    handleTouchMove(e) {
       
        var findMe = document.querySelectorAll('.carousel-element');
        var activeBullets= document.querySelectorAll('.active.carousel-bullet');
        
        findMe.forEach(element => {
        
            if (this.isInViewport(element)) {
                 activeBullets.forEach(activeBullet=>{
                     
                     activeBullet.classList.remove("active");
                 })
                   document.getElementById(element.id+"-bullet").classList.add("active");
              
            }
        });

        //   document.getElementById("carousel-list").style.marginLeft =e.changedTouches[0].pageX +"px";
        /*  this.setState({endTouch: e.changedTouches[0].pageX})
         console.log(e.changedTouches[0].pageX);*/
        /*   document.getElementById("carousel-item-" + this.state.activeItemIndex).style.left =e.changedTouches[0].pageX +"px";
         var activeItemIndex = this.state.activeItemIndex;
         document.getElementById("carousel-item-" + (parseInt(activeItemIndex) +1)).classList.add("active");
         document.getElementById("carousel-item-" + (parseInt(activeItemIndex) +1)).style.left=(400+e.changedTouches[0].pageX )+"px";
         document.getElementById("carousel-item-" + (parseInt(activeItemIndex) +1)).style.top=-400+"px";*/
    }

    handleTouchEnd() {

        /*   if (this.state.startTouch - this.state.endTouch > 150) {
         
         this.moveRight();
         }
         
         if (this.state.startTouch - this.state.endTouch < -150) {
         
         this.moveLeft();
         }*/

    }

    move() {
        document.getElementById("carousel-item-" + this.state.activeItemIndex).classList.add("active");
    }
    moveLeft() {
        document.getElementById("carousel-item-" + this.state.activeItemIndex).classList.add("left-dis");

        var activeItemIndex = this.state.activeItemIndex;

        setTimeout(function () {
            document.getElementById("carousel-item-" + (parseInt(activeItemIndex) - 1)).classList.add("left-come");
            document.getElementById("carousel-item-" + (parseInt(activeItemIndex) - 1)).classList.add("active");
            document.getElementById("carousel-item-" + activeItemIndex).classList.remove("active");
            document.getElementById("carousel-item-" + activeItemIndex).classList.remove("left-dis");




        }, 500);
        this.setState({"activeItemIndex": activeItemIndex - 1})
    }

    moveRight() {
        document.getElementById("carousel-item-" + this.state.activeItemIndex).classList.add("left-dis");

        var activeItemIndex = this.state.activeItemIndex;

        setTimeout(function () {
            document.getElementById("carousel-item-" + (parseInt(activeItemIndex) + 1)).classList.add("left-come");
            document.getElementById("carousel-item-" + (parseInt(activeItemIndex) + 1)).classList.add("active");
            document.getElementById("carousel-item-" + activeItemIndex).classList.remove("active");
            document.getElementById("carousel-item-" + activeItemIndex).classList.remove("left-dis");



        }, 500);
        this.setState({"activeItemIndex": activeItemIndex + 1})

    }
    
    componentDidUpdate(newProps,prevProps) {
        var items = document.querySelector(".carousel-list").childNodes;
        console.log("componentupdate");
        console.log(newProps);
        console.log(prevProps);

        for (var i = 0; i < items.length; i++)
        {
            if (i === 0) {
                items[i].classList.add("active");
                 document.getElementById("carousel-item-0-bullet").classList.add("active");
            }
            items[i].classList.add("carousel-element");
            items[i].id = "carousel-item-" + i;

        }

    }

    render() {
        return(
                <div>
                    <div className="row carousel-list" id="carousel-list" onTouchStart={e => this.handleTouchStart(e)}
                         onTouchMove={e => this.handleTouchMove(e)}
                         onTouchEnd={() => this.handleTouchEnd()}>
                        {this.props.children}
                
                
                    </div>
                    <div className="row">
                        <div className="col carousel-bullets">
                            {
                    [...Array(this.props.children.length), ].map((value, index) =>
                                            <div key={index} className="carousel-bullet" id={"carousel-item-"+index+"-bullet"}></div>
                    )
                            }
                        </div> 
                    </div>
                </div>
                )
    }
}

export default Carousel;