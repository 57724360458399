import React, { Component} from "react";
import { withRouter } from "react-router";
import { Redirect, Link } from 'react-router-dom';

import {ThemeService} from "../../_services/ThemeService";
import {authService} from "../../_services/authService"
import Carousel from './Carousel';
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';

class ThemesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'), "pazirik77").toString(CryptoENC)) : "",
            ageId: localStorage.getItem("ageId"),
            themes: [],
            age: []

        };


        this.goToTheme = this.goToTheme.bind(this);

    }

    goToTheme(e, themeId) {

        if (this.props.theme === "theme")
            localStorage.setItem("themeId", themeId);

        this.props.selectTheme(e, themeId, this.props.type);



    }
    
    componentDidMount(){
          this.state.themes= this.props.themes;
          this.setState({themes : this.state.themes});
        
    }
    
        componentDidUpdate(newProps){
        /*  console.log(newProps);
          console.log("propschanged");
         this.state.themes= this.props.themes;
        this.setState({themes : this.state.themes});*/
    }

    render() {

        return (
                <div className="  theme-list-div" style={{marginTop: '70px'}} >
                
                    <Carousel>
                
                        { this.props.themes.map((theme) =>
                                <div key={theme.id} className="col-md-4" style={{marginBottom: " 30px"}}>
                        
                                    <div className="" onClick={() => this.goToTheme(this, theme.id)} >
                        
                        
                                        <div className="element-with-background-image" >
                        
                                            <img src={"/file/download?fileId=" + theme.fileId} />            
                        
                                            <p className="theme_title">{this.props.type == "theme" ? theme.themeTitle : theme.subThemeName}</p>
                        
                                        </div>
                                    </div>
                        
                        
                        
                                </div>

                    )
                        }
                
                
                
                    </Carousel>
                </div>




                );
    }
}

export default ThemesList;

