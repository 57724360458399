import React from 'react';

import castle from "../../images/var_1.jpg"


        class firstQuiz extends React.Component {
    constructor(props, context) {

        super(props, context);
        this.state = {

        }
    }

    render() {

        return(
                <div class="row" style={{position: "relative"}} id="first-little-quiz">
                    <img  src={castle} style={{width: "100%", height: "100vh"}} />
                   
                    <div style={{width: "13%", height: "20.4%", top: "13.6%", left: "28.8%", position: "absolute"}} id="question_5" onClick={(e) => this.props.questionClick(e, 5)}>  </div>
                    <div  style={{width: "8%", height: "25%", top: "45%", left: "53%", position: "absolute"}} id="question_1" onClick={(e) => this.props.questionClick(e, 1)}>     </div>
                    <div style={{width: "9%", height: "27%", top: "46.8%", left: "68.1%", position: "absolute"}}  id="question_3" onClick={(e) => this.props.questionClick(e, 3)}>      </div>
                    <div style={{width: "13%", height: "17.2%", top: "31.2%", left: "7.4%",  position: "absolute"}}  id="question_7" onClick={(e) => this.props.questionClick(e, 7)}>      </div>
                    <div style={{width: "14%", height: "20%", top: "55%", left: "5%", position: "absolute"}}  id="question_9" onClick={(e) => this.props.questionClick(e, 9)}>      </div>
                    <div style={{width: "4.5%", height: "18%", top: "52%", left: "21.5%", position: "absolute"}}  id="question_2" onClick={(e) => this.props.questionClick(e, 2)}>      </div>
                    <div style={{width: "4.5%", height: "18%", top: "52%", left: "27.5%", position: "absolute"}}  id="question_6" onClick={(e) => this.props.questionClick(e, 6)}>      </div>
                    <div style={{width: "7.3%", height: "33%", top: "45%", left: "89.2%", position: "absolute"}}  id="question_8" onClick={(e) => this.props.questionClick(e, 8)}>      </div>
                    <div style={{width: "9%", height: "27%", top: "44.2%", left: "33.3%",  position: "absolute"}}  id="question_0" onClick={(e) => this.props.questionClick(e, 0)}>      </div>
                    <div style={{width: "8%", height: "20%", top: "51%", left: "77%", position: "absolute"}}  id="question_4" onClick={(e) => this.props.questionClick(e, 4)}>      </div>
                    
                </div>
                );
    }

}
;
export default firstQuiz;
