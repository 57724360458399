
import React from 'react';

import { authService }from '../_services/authService';
import { withRouter }
from "react-router";
import "../style/adminlogin.css";
import logo from '../images/logo.png';
import openEye from '../images/open_eye.png';
import closedEye from '../images/closed_eye.png';


class AdminLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            error: '',
            type: 'password',
            eyeClass: 'closed-eye',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showPassword = this.showPassword.bind(this);
        this.closeLogin = this.closeLogin.bind(this);
    }
    ;
            showPassword(e) {
        this.setState({
            type: this.state.type === 'input' ? 'password' : 'input',
            eyeClass: this.state.type === 'input' ? 'closed-eye' : 'open-eye'
        })


    }
    closeLogin(e) {
        this.props.history.push("/");
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({submitted: true});
        const {username, password} = this.state;
        // stop here if form is invalid
        if (!(username && password)) {
            return;
        }

        this.setState({loading: true});
        authService.login(username, password)
                .then(
                        user => {

                            this.props.history.push("/AdminDashboard");
                        },
                        error => this.setState({error, loading: false})
                );
    }

    componentWillMount() {
        document.body.classList.add('admin-login-body');
         document.body.classList.remove('admin-body');
    }

    render() {
        const {username, password, submitted, loading, error, type, eyeClass} = this.state;
        return (
                <div id="login" className="container">
                    <div className="row">
                        <div id="left-side" className="col-md">
                            <div id="logo">
                                <img src={logo} />
                                <p style={{marginTop : "300px"}}>TEGYÜK <br/> A <b>MÚZ</b>EUM<b>-<br/>PED</b>AGÓGIÁT SZÓRAKOZTATÓVÁ <u>EGYÜTT</u>.</p>
                            </div>
                        </div>
                        <div id="right-side" className="col-md">
                
                        <div style={{textAlign: 'center'}}>
                        <button type="button" className="close float-left" aria-label="Close" onClick={this.closeLogin} >
                            <i class="login_close" aria-hidden="true"></i>
                          </button>
                            <label id="login_label" className=" muzped-white-border" style={{width : "260px"}}>BEJELENTKEZÉS</label><br/>
                
                            <form name="form" className="form" onSubmit={this.handleSubmit} style={{marginLeft : "25px", marginTop : "65px"}}>
                                <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                    <div className="row justify-content-center">
                                        <div className="col-md" style={{maxWidth : "80px",padding : 0}}></div>
                                        <div className="col-md" style={{maxWidth : "260px",padding :"0"}}>
                                            <input type="text" className=" muzped-input" name="username" value={username} placeholder="Felhasználónév" onChange={this.handleChange} />
                                            {submitted && !username &&
                                    <div className="help-block">Username is required</div>

                                            }
                                        </div>
                                        <div className="col-md" style={{maxWidth : "80px"}}></div>
                                    </div>
                                </div>
                                <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                    <div className="row justify-content-center">
                                        <div className="col-md" style={{background: '#fff',maxWidth : "80px"}}>
                                            <div className={eyeClass} onClick={this.showPassword} ></div>
                                        </div>
                                        <div className="col-md" style={{padding : "0",maxWidth : "260px"}}>                            
                                            <input type={this.state.type} className="muzped-input" name="password"  value={password} placeholder="Jelszó" onChange={this.handleChange} />
                                        </div>
                                        <div className="col-md" style={{background: '#fff',maxWidth : "80px"}}>   
                                            <button className="muzped-white-button button-login" disabled={loading}></button>
                                        </div>
                                        {submitted && !password &&
                                    <div className="help-block">Password is required</div>
                                        }
                                    </div>
                                </div>
                
                                {error &&
                                    <div className={'alert alert-danger'}>{error}</div>
                                }
                            </form>
        
                        </div>
                    </div>
                    </div>
                </div>
                );
    }
}

export default  withRouter(AdminLogin);


