import React from 'react';

import { withRouter } from "react-router";

 class ModuleText extends React.Component{
  render(){ 
      const modulePreview = JSON.parse(localStorage.getItem("modulePreview"));
     
   
  return (
          
          
              <div style={{margin : "0 auto", width : "80%", height : "500px"}}>
                   <p style={{fontSize : "28px", whiteSpace : "break-spaces"}}>
                   {modulePreview.link}
                   </p>
                
              </div>
          
     );
  }
};

export default ModuleText;


