import React from 'react';
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import {UsePopup, PopupContext}  from '../../context/popup-context'


class ModuleFormHeader extends React.Component {
 static contextType = PopupContext;
    constructor(props,context) {
        super(props,context);
      
          this.checkDelete = this.checkDelete.bind(this);
          this.delete = this.delete.bind(this);
        }
        
        hideModal(){
               window.scrollTo({
            top: 400,
            behavior: 'smooth',
        })
         ReactDOM.unmountComponentAtNode(document.getElementById("modal-container"));
        document.body.classList.remove('display-block');
        let element = document.getElementsByClassName("modal");
        element[0].classList.add("display-none");
        document.body.classList.remove("modal-admin-body");
        //window.scrollTo(0,localStorage.getItem("scrollTop"));
        }
        
        checkDelete(){
              document.getElementsByClassName("modal")[0].classList.add("popup_body");
              this.props.context.setPopup({id: 0, name: 0, ok: this.props.context.unSetPopup, title: <div style={{textAlign: "center", paddingBottom: "20px"}}><span style={{fontSize: "20px", textAlign: "center"}}> Biztosan törli? </span></div>, type: "delete", delete: this.delete});

            
        }
        
        delete(canDelete){
             document.getElementsByClassName("modal")[0].classList.remove("popup_body");
            this.props.context.unSetPopup();
            if(canDelete){
                this.props.delete();
            }
        }
        
        
        
        render()
        {


        return (
                <div className="modal-header">
                    <div className="col-sm text-left">
                
                        <i className="fa info-label" id="info-label"  for=""></i>
                        <h2 className="" style={{marginLeft: "20px", fontSize: "16px", fontWeight: "normal", display: "inline"}}><b>{this.props.title}</b> MODUL <b>LÉTREHOZÁSA</b></h2>
                    </div>
                
                
                    <div className="col-sm text-right">
                        <a href="#" className="blue-back-link" onClick={this.props.save}>HOZZÁADÁS</a>
                        <a href="#" className="black-link delete-link" onClick={this.checkDelete}>Törlés</a>
                        <i className="fa close-label" id="info-label"  for="" onClick ={this.hideModal}></i>
                
                    </div>
                
                
                
                </div>
                )
    }
    ;
}

export default ModuleFormHeader;