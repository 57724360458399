import React from 'react';
import ReactPlayer from 'react-player'
import { withRouter } from "react-router";
import screenfull from 'screenfull'
import { findDOMNode } from 'react-dom'

     class ModulePanoramaTour extends React.Component {
         
         componentDidMount(){
             
             document.getElementsByTagName("footer")[0].classList.add("tour_footer");
         }
         
         componentWillUnmount(){
             document.getElementsByTagName("footer")[0].classList.remove("tour_footer");
             
         }
    render() {
      
         const modulePreview = JSON.parse(localStorage.getItem("modulePreview"));
         
         
        return (
                <div  className="tour_div">
                   
                  <iframe width='100%' height='100%' src={modulePreview.link} frameBorder='0'  allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"></iframe>
                </div>

                );
    }
}
;

export default ModulePanoramaTour;