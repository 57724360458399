
import React, { Component}from "react";
import {ModuleService}from "../../_services/ModuleService";
import ModuleFormsHeader from"./ModuleFormsHeader"

class ModuleQuizForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
          
            questionNo: 0,
            questions: []
        }
        this.subThemeThemeModal = React.createRef();
        this.hideModal = this.hideModal.bind(this);
        this.addQuestion = this.addQuestion.bind(this);
        this.handleQuestionChange = this.handleQuestionChange.bind(this);
        this.handleAnswerChange = this.handleAnswerChange.bind(this);
        this.handleRightAnswerChange = this.handleRightAnswerChange.bind(this);
        this.saveQuiz = this.saveQuiz.bind(this);
        this.ModuleFormsHeader = React.createRef();
        this.deleteQuestion  = this.deleteQuestion.bind(this);

    }

    addQuestion() {
        this.setState({"questionNo": this.state.questionNo + 1});
    }

    deleteQuestion(index){
         this.setState({"questionNo": this.state.questionNo - 1});
         this.state.questions.splice(index,1);
         this.setState({questions : this.state.questions});
    }
    
    
    saveQuiz() {
        var error = false;
        for (var i = 0; this.state.questionNo > i; i++) {
            if (!this.state.questions[i]["right"]) {
                alert("Helyes válasz megadása kötelező");
                error = true;
            }

        }
        if (!error) {
            
            var questions = {};
            
            this.state.questions.map((question , index) => {
                questions[index] = question;
            })
            this.props.saveModule("Quiz", questions);
            // ModuleService.saveQuiz(this.state.questions);
            this.hideModal();
        }
    }
    componentDidMount() {
      
        if (this.props.moduleId != 0) {
            fetch("/quizquestion/find/" + this.props.moduleId)
                    .then(response => response.json())
                    .then(questions => {
                       
                      this.setState({"questionNo":0});
                       questions.map((question,index) =>{
                        
                           this.state.questions[index] = {};
                           this.state.questions[index]["question"] = question.question;
                            this.state.questions[index]["comment"] = question.comment;
                            this.state.questions[index]["right"] = question.rightAnswerSign;
                            this.state.questions[index]["id"]= question.id;
                             fetch("/quizitem/find/" + question.id)
                                .then(response => response.json())
                                .then(data => {
                                  
                                        data.map(answer =>{
                                            this.state.questions[index][answer.answerSign] = answer.answer;
                                            this.state.questions[index][answer.answerSign+"id"] = answer.id;
                                            this.setState({"questions" : this.state.questions});
                                            this.setState({"questionNo":this.state.questionNo});
                                        })
                                }
                                );
                        this.setState({"questionNo":this.state.questionNo+1});
                       })
                      
                    });
                 
        }


    }

    hideModal() {
        window.scrollTo({
            top: 400,
            behavior: 'smooth',
        })
        document.body.classList.remove('display-block');
        let element = document.getElementsByClassName("modal");
        element[0].classList.add("display-none");
        document.body.classList.remove("modal-admin-body");
        //window.scrollTo(0,localStorage.getItem("scrollTop"));
       
    }

    handleQuestionChange(e, questionIndex) {

        if (this.state.questions[questionIndex]) {
            this.state.questions[questionIndex]["question"] = e.target.value;
        } else {
            this.state.questions[questionIndex] = {"question": e.target.value};
        }
        this.setState({"questions": this.state.questions});
       
    }

    handleAnswerChange(e, questionIndex, answerIndex) {
        if (this.state.questions[questionIndex]) {
            this.state.questions[questionIndex][answerIndex] = e.target.value;
        } else {
            this.state.questions[questionIndex] = {[answerIndex]: e.target.value, };

        }
        this.setState({"questions": this.state.questions});

    }

    handleRightAnswerChange(e, questionIndex, answerIndex) {
        //var checked = document.getElementById()
        if (e.target.checked) {
            document.getElementById("checked_" + answerIndex + questionIndex).classList.add("checked");
            if (this.state.questions[questionIndex]) {
                this.state.questions[questionIndex]["right"] = answerIndex;
            } else {
                this.state.questions[questionIndex] = {"right": answerIndex};

            }
        } else {
            document.getElementById("checked_" + answerIndex + questionIndex).classList.remove("checked");
        }

        this.setState({"questions": this.state.questions});

    }

    render() {



        return (
                <div  className="modal-dialog admin-modal quiz-modal ">
                    <section  className="admin-form-modal modal-content" >
                       
                          <ModuleFormsHeader ref={this.ModuleFormsHeader} title="KVÍZ" save={this.saveQuiz} delete={this.props.delete} context={this.props.contextModule} />
                
                
                            <div className=" modal-body" >
                                <table className="table quiz-admin-table"  style={{width: "800px", padding: "40px",paddingTop : "0",display : "inline-block"}}>
                
                                    <tbody>
                                        {[...Array(this.state.questionNo), ].map((value, index) => (
                                        <Question deleteQuestion={this.deleteQuestion} key={index} index={index} question={this.state.questions[index] ? this.state.questions[index] :[]}  handleQuestionChange = {this.handleQuestionChange} handleAnswerChange={this.handleAnswerChange}  handleRightAnswerChange = {this.handleRightAnswerChange}/>

                                                    )
                                        )
                                    }
                
                
                                    </tbody>
                                </table>
                                <button className={"btn btn-add-question "+ (this.state.questionNo>=10 ? "disabled-add-item" : "") } onClick={this.addQuestion}></button>
                            </div>
                
                
                        
                
                    </section>
                
                </div>
                );
    }
}

class Question extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
                <React.Fragment>
                    <tr>
                        <td scope="col" colSpan="3" className="form-section-title"  > </td>
                
                
                    </tr>
                    <tr className="quiz-question-tr">
                        <td colSpan="2">
                            <div className="form-input-title left_border" style={{width: "95px"}} > <i className="fa question-icon" id="info-label"  for=""></i></div>
                
                            <div className="form-input-title left_border" ><span className="small-text  ">{this.props.index+1}</span></div>
                            <input  id={"question" + this.props.index} required  onChange={(e) => this.props.handleQuestionChange(e, this.props.index)} value={this.props.question && this.props.question["question"] ? this.props.question["question"] : "" }  className="item-form-input "  placeholder="KÉRDÉS"  />
                        </td>
                        <td width="70px"><button className="btn btn-question-delete" onClick={() => this.props.deleteQuestion(this.props.index)}></button></td>
                    </tr>
                    <tr>
                        <td style={{width: "95px", border: "none"}}></td>
                        <td>
                
                
                            <div className="form-input-title left_border" ><span className="small-text  ">A</span></div>
                            <input name="description" id="description" required  className="item-form-input " onChange={(e) => this.props.handleAnswerChange(e, this.props.index, "A")} value={this.props.question && this.props.question["A"] ? this.props.question["A"] : "" }  placeholder="Válasz"  />
                            <div class="squaredThree">
                                <input type="checkbox" id={"checkbox_A" + this.props.index}   name="check"  checked={this.props.question && this.props.question["right"] && this.props.question.right==="A" ? "checked" : ""}   onChange={(e) => this.props.handleRightAnswerChange(e, this.props.index, "A")}  />
                                <label for={"checkbox_A" + this.props.index} id={"checked_A" + this.props.index} className={this.props.question && this.props.question["right"] && this.props.question.right==="A" ? "checked" : ""}  ></label>
                            </div>
                        </td>
                        <td style={{width: "95px", border: "none"}} className="quiz-black-td"></td>
                    </tr>
                    <tr>
                        <td style={{width: "95px", border: "none"}}></td>
                        <td>
                
                
                            <div className="form-input-title left_border" ><span className="small-text  ">B</span></div>
                            <input name="description" id="description" required className="item-form-input "  onChange={(e) => this.props.handleAnswerChange(e, this.props.index, "B")} value={this.props.question && this.props.question["B"] ? this.props.question["B"] : "" }   placeholder="Válasz"  />
                            <div class="squaredThree">
                                <input type="checkbox" id={"checkbox_B" + this.props.index}  name="check"  checked={this.props.question && this.props.question["right"] && this.props.question.right==="B" ? "checked" : ""}    onChange={(e) => this.props.handleRightAnswerChange(e, this.props.index, "B")} />
                                <label for={"checkbox_B" + this.props.index} id={"checked_B" + this.props.index} className={this.props.question && this.props.question["right"] && this.props.question.right==="B" ? "checked" : ""} ></label>
                            </div>
                        </td>
                        <td style={{width: "95px", border: "none"}} className="quiz-black-td"></td>
                    </tr>
                    <tr>
                        <td style={{width: "95px", border: "none"}}></td>
                        <td>
                
                
                            <div className="form-input-title left_border" ><span className="small-text  ">C</span></div>
                            <input name="description" id="description"  className="item-form-input " onChange={(e) => this.props.handleAnswerChange(e, this.props.index, "C")} value={this.props.question && this.props.question["C"] ? this.props.question["C"] : "" }   placeholder="Válasz"  />
                            <div class="squaredThree">
                                <input type="checkbox" id={"checkbox_C" + this.props.index}  name="check" checked={this.props.question && this.props.question["right"] && this.props.question.right==="C" ? "checked" : ""}     onChange={(e) => this.props.handleRightAnswerChange(e, this.props.index, "C")} />
                                <label for={"checkbox_C" + this.props.index} id={"checked_C" + this.props.index} className={this.props.question && this.props.question["right"] && this.props.question.right==="C" ? "checked" : ""} ></label>
                            </div>
                        </td>
                        <td style={{width: "95px", border: "none"}} className="quiz-black-td"></td>
                    </tr>
                    <tr>
                        <td style={{width: "95px", border: "none"}}></td>
                        <td>
                
                
                            <div className="form-input-title left_border" ><span className="small-text  ">D</span></div>
                            <input name="description" id="description"  className="item-form-input " onChange={(e) => this.props.handleAnswerChange(e, this.props.index, "D")} value={this.props.question && this.props.question["D"] ? this.props.question["D"] : "" }   placeholder="Válasz"  />
                            <div class="squaredThree">
                                <input type="checkbox" id={"checkbox_D" + this.props.index}  name="check" checked={this.props.question && this.props.question["right"] && this.props.question.right==="D" ? "checked" : ""}      onChange={(e) => this.props.handleRightAnswerChange(e, this.props.index, "D")} />
                                <label for={"checkbox_D" + this.props.index} id={"checked_D" + this.props.index} className={this.props.question && this.props.question["right"] && this.props.question.right==="D" ? "checked" : ""} ></label>
                            </div>
                        </td>
                        <td style={{width: "95px", border: "none"}} className="quiz-black-td"></td>
                    </tr>
                     <tr>
                        <td style={{width: "95px", border: "none"}}></td>
                        <td>
                
                
                            <div className="form-input-title left_border" ><span className="small-text  "></span></div>
                            <input name="description" id="description"  className="item-form-input " onChange={(e) => this.props.handleAnswerChange(e, this.props.index, "comment")} value={this.props.question && this.props.question["comment"] ? this.props.question["comment"] : "" }   placeholder="Magyarázat"  />
                           
                        </td>
                        <td style={{width: "95px", border: "none"}} className="quiz-black-td"></td>
                    </tr>
                </React.Fragment>

                )
    }
}
export default ModuleQuizForm;




