import React, { Component }
from "react";
import { withRouter }
from "react-router";
import { Redirect }
from 'react-router-dom';

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {

            totalItems: this.props.totalItems,
            currentPage: 1,
            itemsPerPage: 5,
            pagedItems: [],
            pages: [],
        };
        this.pagination = this.pagination.bind(this);
    }

    componentWillReceiveProps(props) {
      
       console.log("pagination_comp");
       console.log(props);
        if (props.totalItems > this.state.totalItems) {
         
            this.setState({totalItems: this.props.totalItems});
            this.pagination(this.state.currentPage);
        }
    }

    pagination(page) {
       
        let pageNumber = this.props.totalItems > this.state.itemsPerPage ? Math.ceil(this.props.totalItems / this.state.itemsPerPage) : 1;
        let pages = [];
           

        if (pageNumber > 10) {
            if (page < 5) {
                let i = page;

                pages.push(1);
                pages.push(2);
                pages.push(3);
                pages.push(4);
                pages.push(5);
                pages.push("...");
                pages.push(pageNumber);
            }


            if ((page >= 5 && page <= pageNumber - 5) ) {
                pages.push(1);
                pages.push(2);
                pages.push("...");
                let i = page;

                for (i; i < page + 3; i++) {
                    pages.push(i);
                }

                if (pageNumber - 1 > page + 3)
                    pages.push("...");
                pages.push(pageNumber - 1);
                pages.push(pageNumber);


            }

            if (page > pageNumber - 5) {
                pages.push(1);
                pages.push(2);
                pages.push("...");
                 pages.push(pageNumber - 5);
                pages.push(pageNumber - 4);
                pages.push(pageNumber - 3);
                pages.push(pageNumber - 2);
                pages.push(pageNumber - 1);
                pages.push(pageNumber);

            }

        } else {
            let y = 0;
            for (y; y < pageNumber; y++) {
                pages.push(y + 1);
            }



        }

        this.setState({"pages": pages, currentPage: page});
        this.props.pagination(page, this.state.itemsPerPage);


    }
    render() {

        return (
                <div className="row" id="pagination">
                    <div style={{margin: "0 auto", height: "50px"}}>
                        {this.state.pages.map((value, index) => (
                                        <span className={this.state.currentPage == value ? "active-page" : ""} onClick={() => this.pagination(value)}>{value}</span>
                                ))
                        }
                    </div>
                </div>
                )

    }

}

export default Pagination;