import React, { Component}from "react";
import SwitchComponent from '../components/SwitchComponent'
import {UsePopup, PopupContext}  from '../../context/popup-context'

        class ModalTable extends Component {
    static contextType = PopupContext;
    constructor(props, context) {
        super(props, context);
        this.state = {
             itemList : []
        };




    }
    
    componentDidUpdate(prevProps, prevState){
      
       if(prevProps.itemList !==this.props.itemList)
        this.setState({itemList : this.props.itemList})
    }

    render() {

    

        return (
                <table class="table table-dark modal-table " id="muzped-admin-table">
                    <thead>
                        <tr>
                            <th scope="col" ></th>
                            <th scope="col">{this.props.tableName} <b>Megnevezés</b></th>
                            <th scope="col"> Kiválasztás</th>
                            <th scope="col"></th>
                
                        </tr>
                    </thead>
                    <tbody>
                
                        {this.state.itemList.length>0 ?  this.state.itemList.map((item, index) =>
                                        <tr key={item.id} className="search-tr " id={"module_"+item.id} >
                                            <td width="150px" className="list-table-left">  
                                                   <a className="blue-link table-link" >Előnézet  </a>
                                            </td>
                                            <td width="50%" className="list-table-title" dangerouslySetInnerHTML={{__html: item.name}}></td>
                                            <td width="300px"></td>
                                            <td className="list-table-right">
                                                      <a className="blue-back-link table-link" onClick={() => this.props.select(item.id)}>Kiválasztás  </a>
                                            </td>
                                
                                        </tr>


                                    ) :""
                        }
                
                
                
                    </tbody>
                </table>

                );
    }
}

export default ModalTable;