import React from 'react';
import ImageGallery from 'react-image-gallery';
import { withRouter } from "react-router";
class ModulePicture extends React.Component {

    _renderItem(item) {
        return(
                <div className="image-container-div image-gallery-image" >
                    <div className="background" style={{backgroundImage: "url('" + item.original + "')"}}> </div>            
                             
                        <img src={item.original} />
                        {item.name && <div className="picture-comment">{item.name}</div>}
                   
                </div>
                )


    }

    componentWillUnmount() {

        /*  if (document.getElementById("game-container")) {
         document.getElementById("game-container").style.marginTop = "-50px";
         }*/
    }

    componentDidMount() {
        /* if (document.getElementById("game-container")) {
         document.getElementById("game-container").style.marginTop = "-80px";
         }*/
    }
    render() {
        const modulePreview = JSON.parse(localStorage.getItem("modulePreview"));
        const imageArray = modulePreview.link;
        const imageMap = imageArray.split(";");
        const images = [];
        imageMap.map(image => {
            const imageWithName = image.split(",");
            images.push({"original": '/file/download?fileId=' + imageWithName[0], name: imageWithName[1] ? imageWithName[1] : ""});
        })



        return (
                <div id="image-module-container" >
                    <ImageGallery showPlayButton={false}  showBullets={true} showFullscreenButton={false} infinite="false" items={images} showThumbnails={false} renderItem={this._renderItem}/>
                
                </div>

                );
    }
}
;
export default ModulePicture;


