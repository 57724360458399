import React from 'react';
import SockJsClient from 'react-stomp';
import logo from '../images/black_logo.png';
import SockJS from 'sockjs-client'
import Stomp from "webstomp-client";
import SpringSocket from "react-spring-websocket"
import '../style/livegame.css';
import ReactDOM from "react-dom";

class LiveGameDashboard extends React.Component {

    constructor(props, context) {

        super(props, context);
        this.state = {
            roomId: 0,
            socket: '',
            stomClient: '',
            moduleId: 51
        }

        this.getMessage = this.getMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.createComponent = this.createComponent.bind(this);
    }

    getMessage(e) {

        console.log("message:" + e);
        fetch("/module/find/" + this.state.moduleId)
            .then(response => response.json())
            .then(module => {
                localStorage.setItem("activeModule", "Module" + module.moduleType);
                this.createComponent(window.components["Module" + module.moduleType], {})
            });
    }

    createComponent(component, params) {
        localStorage.setItem("activeModuleId", this.state.moduleId);
        ReactDOM.render(
            React.createElement(component, params),
            document.getElementById('module-container'),
            () => {

            }
        );
    }
    sendMessage() {
        console.log("sendMessage");
        this.state.stompClient.send('/room/' + this.state.roomId, "Hello websocket");
        // this.state.socket.send("/room/" + this.state.roomId, { message: "message" })
        // this.clientRef.sendMessage('/room/'+this.state.roomId, "küldött üzenet");
    }

    componentDidMount() {
        document.body.classList.add('muzped_back');
        /* this.state.socket = new SpringSocket(
             "liveGameMessages",
             ["getGames/" + this.state.roomId], this.getMessage
             
         )*/

        this.state.socket = new SockJS('/liveGameMessages/');

        this.state.stompClient = Stomp.over(this.state.socket);
        this.state.stompClient.connect(
            {},
            frame => {
                console.log("connected" + frame);
                //  connected = true;
                this.state.stompClient.subscribe("/getGames/" + this.state.roomId, this.getMessage);
            },
            error => {
                console.log(error);
                //connected = false;
            }
        );
    }

    render() {
        return (
            <div className="container livegame-container" >
                <div className="row ">
                    <div className="col-sm ">
                        <i className="muzped-logo"></i>
                    </div>



                </div>
                <div id="module-container">
                    <div className="row" >
                        <input className="muzped-input" placeholder="TEREM KÓDJA"></input>
                    </div>
                    <div className="row">
                        <button onClick={this.sendMessage} className="submit">Tovább</button>
                    </div>
                </div>
            </div>
        )
    }

}

export default LiveGameDashboard;