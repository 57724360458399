import React from 'react';
import { Redirect, useParams, Link, Prompt, Route }from 'react-router-dom';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

class getLittleQuizNames extends React.Component{
    
    componentDidMount(){
        fetch("/score/getLittleQuizNames/" )
                    .then(scores => scores.json())
                    .then(scores => {
                         const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  console.log(scores);
  var nameList =[];
  scores.map((score,index) =>{
                   nameList.push({"Név" : score[1],Email : score[7]});
                          
                 })
  
        const ws = XLSX.utils.json_to_sheet(nameList);
        const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
        const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'proba.xlsx');

        });
    }
    
    render(){
        
        var user =JSON.parse(localStorage.getItem('user'));
        
         if (!user) {
            return <Redirect to="/admin" />
        }
        
        
        return(
                <div>Névsor letöltve</div>
                );
    }
}

export default getLittleQuizNames;

