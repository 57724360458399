import React from 'react';
import ReactPlayer from 'react-player'
import { withRouter } from "react-router";
import screenfull from 'screenfull'
import { findDOMNode } from 'react-dom'

   class ModuleVideo extends React.Component {
    render() {
        const modulePreview = JSON.parse(localStorage.getItem("modulePreview"));
        console.log("")
        document.addEventListener('keydown', function (event) {
            console.log(event.which);
            console.log("key");
        });


       

        function fullScreen() {
            screenfull.request(document.getElementById("video-wrapper"));
        }

        function exitFullScreen() {
            screenfull.exit();
        }
       
        return (
                <div id="video-wrapper" style={{width: "100%", height: "600px"}}>
                    <ReactPlayer url={modulePreview.link} width="100%" height="600px" controls wrapper="video-wrapper" onPlay={fullScreen} />
                    <button onClick={exitFullScreen} style={{position: "absolute", bottom: "0", right: "0", width: "70px", height: "50px", background: "transparent", border: "unset", outline: "none"}}></button>
                </div>

                );
    }
}
;

export default ModuleVideo;