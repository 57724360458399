import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import './style/custom.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch, useHistory,Prompt } from "react-router-dom";

import UserHome from './dashboardComponents/Dashboard';
import Login from './Login';
import ForgetPassword from './ForgetPassword';
import AdminDashboard from './adminComponents/AdminDashboard';
import TaskList from './adminComponents/theme/ThemeList';
import AdminSubThemeList from './adminComponents/theme/SubThemeList';
import AdminModuleList from './adminComponents/module/ModuleList';
import AdminModuleForm from './adminComponents/module/ModuleForm';
import TaskForm from './adminComponents/theme/ThemeForm';
import SubThemeForm from './adminComponents/theme/SubThemeForm';
import AdminWelcome from './adminComponents/AdminWelcomePage';
import AdminLogin from './adminComponents/AdminLogin';
import ThemeList from './dashboardComponents/theme/ThemeList';
import SubThemeList from './dashboardComponents/theme/SubThemeList';
import ThemeSelect from './dashboardComponents/theme/ThemeSelect';
import SubThemeSelect from './dashboardComponents/theme/SubThemeSelect';
import SubThemeDescription from './dashboardComponents/theme/SubThemeDescription';
import Modules from "./dashboardComponents/modules/ModuleDashboard";
import ModuleVideo from "./dashboardComponents/modules/ModuleVideo";
import ModuleQuiz from "./dashboardComponents/modules/ModuleQuiz";
import MediaDashboard from "./adminComponents/media/MediaDashboard"
import Events from "./adminComponents/Events"
import { PopUpProvider, UsePopup } from './context/popup-context'
import publicDashboard from "./publicTasks/publicDashboard"
import ModuleSwipe from "./dashboardComponents/modules/ModuleSwipe";
import ModulePairingMemory from "./dashboardComponents/modules/ModulePairingMemory";
import Module3D from "./dashboardComponents/modules/Module3D";
import ModulePictureQuiz from "./dashboardComponents/modules/ModulePictureQuiz";
import ModuleMultiSwipe from "./dashboardComponents/modules/ModuleMultiSwipe";
import ModuleText from "./dashboardComponents/modules/ModuleText";
import ModulePairingText from "./dashboardComponents/modules/ModuleTextPairing";
import ModulePanoramaTour from "./dashboardComponents/modules/ModulePanoramaTour";
import ModuleVoice from "./dashboardComponents/modules/ModuleVoice";
import ModulePairing from "./dashboardComponents/modules/ModulePairing";
import ModuleMemory from "./dashboardComponents/modules/ModuleMemory";
import ModulePicture from "./dashboardComponents/modules/ModulePicture";
import PrivacyPolicy from "./publicTasks/PrivacyPolicy"
import ModuleLinkScore from "./dashboardComponents/modules/ModuleLinkScore"
import ModuleBonusScore from "./dashboardComponents/modules/ModuleBonusScore"
import littleQuiz from "./publicTasks/littleQuiz"
import getLittleQuizNames from "./publicTasks/getLittleQuizNames"
import LiveGame from "./LiveGame/LiveGameDashboard"
const Loader = () => <div>loading...</div>;

window.components = {"ModuleBonusScore" :ModuleBonusScore ,"ModuleLinkScore": ModuleLinkScore, "ModuleSwipe": ModuleSwipe,"ModulePairingMemory" : ModulePairingMemory,"Module3D" : Module3D,"ModulePictureQuiz" : ModulePictureQuiz, "ModuleMultiSwipe": ModuleMultiSwipe,"ModulePanoramaTour" : ModulePanoramaTour, "ModuleText": ModuleText, "ModuleVideo": ModuleVideo, "ModuleQuiz": ModuleQuiz, "ModuleVoice": ModuleVoice, "ModulePairing": ModulePairing, "ModulePairingText": ModulePairing, "ModuleMemory": ModuleMemory, "ModulePicture": ModulePicture,"ModuleQuizPictureAnswers": ModuleQuiz};
window.label = {"ModuleBonusScore": "Kód pontgyűjtő","ModulePairingMemory" : "Párosító memória","ModuleLinkScore" : "Pontgyűjtő link","ModulePictureQuiz" : "Kép kvíz","ModuleQuizPictureAnswers" : "Kvíz képes válaszokkal","ModuleSwipe": "Swipe","Module3D" : "3D modell","ModulePanoramaTour" : "Gömpanoráma / Virtuális túra", "ModuleMultiSwipe": "Multi-Swipe", "ModuleVideo": "Videó", "ModuleText": "Leírás", "ModuleQuiz": "Kvíz", "ModuleVoice": "Hang", "ModulePairing": "Párosító", "ModuleMemory": "Memória", "ModulePicture": "Képek", "ModulePairingText": "Párosító"};
          
window.setCookie=function(name, value, days) {
        if (days) {
                var date = new Date();
                date.setTime(date.getTime()+(days*24*60*60*1000));
                var expires = "; expires="+date.toGMTString();
            }
            else var expires = "";
            document.cookie = name+"="+value+expires+"; path=/";
      }
 window.getCookie = function(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }

ReactDOM.render(
        <PopUpProvider>
        <React.Fragment>
               
         
            
                <Router history={useHistory}>

                 <Suspense fallback={<Loader />}>
                    <Switch>
                          

                    <Route exact path="/" component={App} />
                    <Route   path="/dashboard" component={UserHome} />
                    <Route   path="/login" component={Login} />
                    <Route   path="/forgetpassword" component={ForgetPassword} />
                    <Route   path="/admindashboard" component={AdminDashboard} />
                    <Route   path="/adminthemelist" component={TaskList} />
                    <Route   path="/adminmodulelist" component={AdminModuleList} />
                    <Route   path="/adminmoduleform/:id" component={AdminModuleForm} />
                    <Route   path="/adminsubthemelist" component={AdminSubThemeList} />
                    <Route   path="/themeform/:id" component={TaskForm} />
                    <Route   path="/subthemeform/:id" component={SubThemeForm} />
                    <Route   path="/admin" component={AdminWelcome} />
                    <Route   path="/adminlogin" component={AdminLogin} />
                    <Route   path="/themelist" component={ThemeList} />
                     <Route   path="/subThemeList" component={SubThemeList} />
                    <Route   path="/themeSelect" component={ThemeSelect} />
                    <Route   path="/subThemeSelect" component={SubThemeSelect} />
                    <Route   path="/subThemeDescription" component={SubThemeDescription} />
                    <Route   path="/modules" component={Modules} />
                    <Route   path="/modulevideo" component={ModuleVideo} />
                    <Route   path="/moduleQuiz" component={ModuleQuiz} />
                    <Route   path="/media" component={MediaDashboard} />
                    <Route   path="/events" component={Events} />
                      <Route   path="/hadfiak" component={publicDashboard} />
                    <Route  path="/privacypolicy" component={PrivacyPolicy} />
                    <Route path="/kicsikviz" component={littleQuiz} />
                     <Route path="/liveGame" component={LiveGame} />
                    <Route path="/kicsikvizjatekosok" component={getLittleQuizNames} />
                  
                    </Switch>
                    </Suspense>
                </Router>
           
        </React.Fragment>
         </PopUpProvider>
        ,
        document.getElementById('root')
        );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
