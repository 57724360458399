
import React, { Component}from "react";

class SwitchComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: this.props.active,
            id : this.props.id,
            index : this.props.index

        };
         this.handleChange = this.handleChange.bind(this);
    }
    
    handleChange(){
      
        this.props.changeStatus(this.state.index,this.state.id, !this.state.active);
          this.setState({active : !this.state.active});
    }
    

    render() {



        return (
                <div  className="switch-button left_border" ><span className={this.props.active ? "font-weight-bold" : ""}>Aktív</span> <label className="switch">
                        <input type="checkbox" checked={!this.props.active ? "checked" :false } onChange={this.handleChange}/>
                        <span className="slider round"></span>
                    </label>
                    <span className={!this.props.active ? "font-weight-bold" : ""}>Inaktív</span>
                </div>

                );
    }
}

export default SwitchComponent;

