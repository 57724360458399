import React, { Component}from "react";

class SearchComponent extends Component {
    
     constructor(props) {
        super(props);
        this.state = {
          

        };

        this.searchinTable = this.searchinTable.bind(this);
        this.openSearch = this.openSearch.bind(this);
        this.close = this.close.bind(this);

        
    }
    
    openSearch(){
        document.getElementById("header_popup_search").style.display ="inherit";
    }
    
    close(){
        document.getElementById("header_popup_search").style.display ="none";
    }
    
      searchinTable() {
           
            var input, filter, table, tr, td, i;
            input = document.getElementById("search");
            filter = input.value.toUpperCase();
            table = document.getElementById("muzped-admin-table");
            tr = table.getElementsByClassName("search-tr");
            for (var i = 0; i < tr.length; i++) {
                var tds = tr[i].getElementsByTagName("td");
                var flag = false;
                for (var j = 0; j < tds.length; j++) {
                    var td = tds[j];
                    if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
                        flag = true;
                    }
                }
                if (flag ) {
                    tr[i].style.display = "";
                 
                  //  tr[i].nextSibling.style.display = "";
                } else {
                   
                        tr[i].style.display = "none";
                     //   tr[i].nextSibling.style.display = "none";
                }
            }
        }

    render() {

       

        return (
                <div style={{display : "inline-block"}}>
                    <input type="text"  placeholder="KERESÉS" onClick={this.openSearch} id="search-input"   className="input-field muzped-material-input" />
                    <i class="fa" id="search_label" onClick={this.openSearch}   class="material-input-icon" for="search"></i>
                     <div className="header_popup" id="header_popup_search" style={{display : "none", textAlign : "left"}}>
                     <div className="header_popup_content">
                     <i class="fa" id="white_search_label"  class="material-input-icon" for="search"></i>
                     <span><b>MIT KERES? SEGÍTÜNK.</b></span>
                     <input type="text" id="search" placeholder="Kezdjen el gépelni." onChange={this.searchinTable}  className="header_popup_input" />
                              <i className="fa close-label" id="info-label"  for="" onClick={this.close}></i>
                              </div>
                     </div>
                </div>
               
                );
    }
}

export default SearchComponent