import React, { Component} from "react";
import { withRouter } from "react-router";
import { Redirect, Link } from 'react-router-dom';
import muzpedIcon from "../../images/muzped_icon.png"
import "../../style/user.css";
import muzpedText from "../../images/muzped_logo_text.png"
import {authService} from "../../_services/authService"
import {ModuleService} from "../../_services/ModuleService"
import ThemeModal from "../theme//ThemeModal";
import {UsePopup, PopupContext}  from '../../context/popup-context'

 
 
  class ThemeSelection extends Component {
       static contextType = PopupContext;
    constructor(props,context) {
        super(props,context);
        this.state = {
           
            themeId: localStorage.getItem("themeId"),
            
            ageId: localStorage.getItem("ageId")

        };
      
      this.goBack= this.goBack.bind(this);
      this.goToModules = this.goToModules.bind(this);
      this.goToHome = this.goToHome.bind(this);
     
    }
   
  
    componentDidMount() {
       
        fetch("/theme/select/" + this.state.themeId)
                .then(response => response.json())
                .then(data => {
                    
                    this.setState({theme: data})
                })

        fetch("/age/select/" + this.state.ageId)
                .then(response => response.json())
                .then(data => {

                    this.setState({age: data})
                })
    }
    
    showModal() {
       
        let element = document.getElementsByClassName("modal");
        element[0].classList.remove("display-none");
        element[0].classList.add("display-block");
    }
    
    goBack(){
        this.props.history.push("/subThemeSelect")
    }
    
    goToModules() {
       
        if(this.props.type==="theme"){
            this.props.getSelectedTheme(this.props.type, this.props.theme)
        } else {
        localStorage.setItem("subTheme",JSON.stringify(this.props.theme));
        this.props.goToModules();
       }
    }
    
    goToHome(){
        this.props.history.push("/Dashboard");
    }


    updateAllModuleActive() {
        fetch("/thememodule/find/" + localStorage.getItem("subThemeId"))
                .then(response => response.json())
                .then(modules => {
                    modules.map((module, index) => {

                        ModuleService.setActiveStatus(module.id, localStorage.getItem("subThemeId"), true).then(response =>{
                            this.updateModuleList();
                                    this.themeModal.updateModules();
                                }
                        );
                    })


                });

        
    }

    updateModuleList() {

        fetch("/thememodule/getActives/" + localStorage.getItem("subThemeId"))
                .then(response => response.json())
                .then(data => {
                    this.setState({"modules": data});
                    localStorage.setItem("modules", data);
                });

    }
  

    render() {
      
        return (
               
                <React.Fragment>
                    <div className=" theme_select_div" style={{marginTop: '120px', height : "73%" , position : "relative"}} >
                        <div className="element-with-background-image" >
                            <img src={"/file/download?fileId="+this.props.theme.fileId} />            
                            <div className="row color-overlay" >
                
                
                                <div className="col" >
                                    <button className="mobile-close-button" onClick={()=> this.props.cancelSelectTheme(this.props.type)}></button>
                                    <p className="theme_title">{this.props.type=="theme" ? this.props.theme.themeTitle : this.props.theme.subThemeName}</p>
                                    <div className="theme_select_description">
                
                                        <p className="theme_description">{this.props.theme.description }</p>
                                    </div>
                                    <p className="mobile-theme-title">{this.props.type=="theme" ? this.props.theme.themeTitle : this.props.theme.subThemeName}</p>
                                     <button className="mobile-forward-button" onClick={this.goToModules}></button>
                                </div>
                
                
                
                                <div className="col align-right " style={{textAlign : "right"}} >
                                <div className="theme-selection-button-div" > 
                                   <button className="theme_grey_button theme_button" onClick={ ()=> this.props.cancelSelectTheme(this.props.type)}>Mégsem</button><br/>
                                    <button className="theme_green_button theme_button" onClick={this.goToModules}>{this.props.type==="theme" ? "Kiválasztás" : "Kezdés"}</button>
                                    </div>
                                </div>
                            </div>
                
                
                        </div>
                        </div>
                        {this.props.type=="subTheme" ?
                          <footer className="muzped-footer mobilesubtheme-footer " >
                        <div className="container" style={{marginTop : "-10px"}}>
                            <div class="row">
                                <div className="col-md-3">
                                    <button onClick={this.goBack} className={"btn-backward btn " +(localStorage.getItem("onlineGame") ? "d-none" : "")}></button>
                                    <button onClick={this.goToModules} className={"btn-forward btn " +(localStorage.getItem("onlineGame") ? "d-none" : "")}></button>
                                    <button className={"btn-module btn " +(localStorage.getItem("onlineGame") ? "d-none" : "")} onClick={this.showModal}></button>
                                    <div id="modal-container">
                                        <ThemeModal onRef={ref => (this.themeModal = ref)} updateModuleList={this.updateModuleList} showButtons={false} draggable={true}/> 
                                    </div>
                                </div>
                                <div className="col-md-6 text-center">
                                   
        </div>
                                <div className="col-md-3 text-right">
                                    <button className="btn-home btn subtheme-home" onClick={this.goToHome}></button>
                                </div>
                            </div>
                        </div>
                    </footer>
                    :""}
                    
                            
        </React.Fragment>
                 

                );
    }
}

export default ThemeSelection;

