import React, { Component}from "react";
import {ModuleService}from "../../_services/ModuleService";
import ModuleFormsHeader from"./ModuleFormsHeader"

class ModuleBonusScoreForm extends Component{
    
     constructor(props) {
        super(props);
        this.state = {
            
            itemNo: 0,
            bonusScore: [],
            text : "",
            code :"",
            
            id : 0,
            score : ""
            
            
        }
        this.subThemeThemeModal = React.createRef();
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.ModuleFormsHeader = React.createRef();

    }

    handleChange(e) {
       
        const {name, value} = e.target;
        this.setState({[name]: value});
    }
   

    save() {
        var error = false;

       
        if (!error) {
            var bonusScore=[];
            bonusScore.text = this.state.text;
            bonusScore.code= this.state.code;
           
            bonusScore.id=this.state.id;
            bonusScore.score= this.state.score;
         
            this.props.savePreviewModule("BonusScore", bonusScore);
            this.ModuleFormsHeader.current.hideModal();
           
        }  
    }
    
     componentDidMount() {

        if (this.props.moduleId != 0) {
             
        fetch("/bonusscore/geBonusScore/" + this.props.modulePreviewId)
                 .then(response => response.json())
                .then(items => {
                   
                  if(items.length>0)
                    this.setState({"text" :  items[0].text, code : items[0].code, id : items[0].id,score : items[0].score});
                    
                  
                    });

        }


    }

   
    render() {



        return (
                <div  className="modal-dialog admin-modal quiz-modal ">
                    <section  className="admin-form-modal modal-content" >
                        
                            <ModuleFormsHeader ref={this.ModuleFormsHeader} title="SZÖVEG" save={this.save} delete={this.props.deletePreview} context={this.props.contextModule}/>
                
                
                            <div className="  modal-body bonus-modal" style={{padding: "40px"}} >
                                
                                <input type="text" className="item-input" name="code" onChange={this.handleChange} placeholder="KÓD" value={this.state.link}/>
                                <input type="text" className="item-input" name="score" onChange={this.handleChange} placeholder="PONTSZÁM" value={this.state.score}/>
                               <textarea  type="text" className="item-input" style={{border : "2px solid #252629", marginTop : "40px",height : "400px"}} onChange={this.handleChange} name="text" id="module_text" value={this.state.text} placeholder="LEÍRÁS" />
                            </div>
                
                
                        
                
                    </section>
                
                </div>
                );
    }
}

export default ModuleBonusScoreForm;