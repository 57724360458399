


import React, { Component} from "react";

class ThemeDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            

        };
       
    }
    
    
    
   

    render() {
       
        return (
                     <div > 
                      <img style={{top : "150px"}} className="description-image" src={"/file/download?fileId="+ this.props.theme.fileId}/>
                    <div className="row description-text " style={{marginTop: '450px'}} >
                
                         
                        <p  style={{minWidth: "100%", wordWrap: "break-word", zIndex: "0", height: "200px", overflowY: "scroll",whiteSpace : "break-spaces",marginBottom:"45px"}} >{this.props.theme.description}</p>
                         <button onClick={this.props.goToModules} className={"theme_button theme_green_button goto-modules " + (!localStorage.getItem("onlineGame") ? "d-none" : "")} ><b>Tovább</b> az első feladathoz</button>
                    </div>
                </div>
       
                );
    }
}

export default ThemeDescription;