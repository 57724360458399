import React, { Component} from "react";
import { withRouter } from "react-router";
import { Redirect, Link } from 'react-router-dom';
import muzpedIcon from "../../images/muzped_icon.png"
import "../../style/user.css";
import muzpedText from "../../images/muzped_logo_text.png"
import {authService} from "../../_services/authService"
import ThemeSelection from "../components/ThemeSelection"

  class ThemeSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            themeId: localStorage.getItem("themeId"),
            theme: [],
            age: [],
            ageId: localStorage.getItem("ageId")

        };

      this.logout = this.logout.bind(this);
      this.cancelSelectTheme= this.cancelSelectTheme.bind(this);
      this.getSelectedTheme = this.getSelectedTheme.bind(this);

    }
   
   cancelSelectTheme(){
       this.props.history.push("/themelist")
   }
   
   getSelectedTheme(){
       this.props.history.push("/subThemeList")
   }
    logout() {
        authService.logout();
        this.props.history.push("/")
    }
    
    
    componentDidMount() {
       
        fetch("/theme/select/" + this.state.themeId)
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    this.setState({theme: data})
                })

        fetch("/age/select/" + this.state.ageId)
                .then(response => response.json())
                .then(data => {

                    this.setState({age: data})
                })
    }

    componentWillMount() {
        
         let host = window.location.host;

        let parts = host.split(".");
        let subdomain = "";

        if (parts.length >= 3) {
            subdomain = parts[0];

        }
     
        document.body.classList.add(subdomain+'muzped_back');
         document.body.classList.remove(subdomain+'theme_back');
        document.body.classList.add('muzped_back');

        document.body.classList.remove('theme_back');

    }
    render() {
        const {user} = this.state;
        if (!user) {
            return <Redirect to="/login" />
        }
        return (
                <div className="container" >
                    <div className="row">
                        <div className="col-sm text-left">
                            <i className="muzped-icon"></i>
                            <label class="black_border text-uppercase" style={{borderLeft: "none"}}><b>{this.state.age.ageFrom + " - " + this.state.age.ageTo}</b> éves korosztály </label>
                        </div>
                
                        <div className="col-sm text-center align-baseline">
                            <h3 className="align-text-bottom"><b>TEMATIKA</b>VÁLASZTÓ</h3>
                        </div>
                        <div className="col-sm text-right">
                             <i className="muzped-text"></i><br/>
                             <button className="btn-logout btn" onClick={this.logout}></button>
                
                        </div>
                    </div>
                
                    <ThemeSelection theme={this.state.theme} cancelSelectTheme={this.cancelSelectTheme} getSelectedTheme={this.getSelectedTheme} type="theme"/>
                
                
                </div>

                );
    }
}

export default withRouter(ThemeSelect);