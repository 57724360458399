

import React, { Component}from "react";
import {ModuleService}from "../../_services/ModuleService";
import ModuleFormsHeader from"./ModuleFormsHeader"
import AddFile from "../components/AddFile"
        class ModulePictureForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
          
            itemNo: 0,
            items: []
        }
        this.subThemeThemeModal = React.createRef();
        this.save = this.save.bind(this);
        this.ModuleFormsHeader = React.createRef();
        this.addItem = this.addItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.addImage = this.addImage.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    deleteItem(index) {


        document.getElementById("item-div-" + index).remove();

    }
    
    handleChange(e,index){
        this.state.items[index].name= e.target.value;
        this.setState({items : this.state.items});
    }

    addItem() {
        this.state.items.push({"original": ''});
        this.setState({"itemNo": this.state.itemNo + 1, items: this.state.items});
    }

    addImage(id, index) {
           console.log(index);
      /*  const fileName = e.target.files[0].name;
        const formData = new FormData()
        formData.append('file', e.target.files[0]);

        formData.append('path', '/modulepreviews/' + localStorage.getItem("moduleId") + "/");
        fetch("/file/save", {
            method: 'POST',

            body: formData
        })
                .then(response => response.json())
                .then(response => {


                    document.getElementById("item-content-" + index).style.backgroundImage = "url('/file/download?fileName=uploads/modulepreviews/" + localStorage.getItem("moduleId") + "/" + fileName + "')";
                    document.getElementById("item-content-" + index).style.backgroundSize = "cover";

                    document.getElementById("item-url-" + index).value = "uploads/modulepreviews/" + localStorage.getItem("moduleId") + "/" + fileName;

                });*/
        
                   document.getElementById("item-content-" + index).style.backgroundImage ="url('/file/download?fileId=" + id + "')";
                    document.getElementById("item-content-" + index).style.backgroundSize = "cover";

                    document.getElementById("item-url-" + index).value = id;
                    
                    this.state.items[index].url = id;
                    this.setState({items : this.state.items});
    }

    save() {
        var error = false;
        let items = "";
        for (var i = 0; i < this.state.itemNo; i++) {
            if (document.getElementById("item-div-" + i)) {
                if (items === "") {
                    items = document.getElementById("item-url-" + i).value+ ","+document.getElementById("item-name-" + i).value;
                } else {
                    items += ";" + document.getElementById("item-url-" + i).value+ ","+document.getElementById("item-name-" + i).value;
                }
            }
        }
        if (!error) {
            this.props.savePreviewModule("Picture", items);
            this.ModuleFormsHeader.current.hideModal();

        }
    }
    componentDidMount() {

        if (this.props.moduleId != 0) {

            fetch("/modulepreview/find/" + this.props.moduleId)
                    .then(response => response.json())
                    .then(items => {
                        if (items.length > 0) {
                            const imageArray = items[0].link
                            const imageMap = imageArray.split(";");
                            const images = [];
                            imageMap.map(image => {
                                const imageWithName= image.split(",");
                                images.push({"original": '/file/download?fileId=' + imageWithName[0], url: imageWithName[0],name : imageWithName[1]});

                            })

                            this.setState({"items": images, "itemNo": images.length  });
                        }

                    });

        }


    }

    render() {



        return (
                <div  className="modal-dialog ">
                    <section  className="modal-content admin-form-modal" >
                       
                            <ModuleFormsHeader ref={this.ModuleFormsHeader} title="GALÉRIA" save={this.save} delete={this.props.deletePreview} context={this.props.contextModule}/>
                
                
                            <div className="modal-body" >
                                <div  id="picture-item-container" style={{width: "800px", padding: "40px", marginTop: "100px",display:"inline-flex" , overflowY : "auto"}}>
                                    {[...Array(this.state.itemNo), ].map((value, index) => (
                                        <div className="add-memory-item" key={index} id={"item-div-" + index} >
                                              <input value={this.state.items[index].name} onChange={(e) =>this.handleChange(e,index)} id={"item-name-" + index} type="text" className="item-input" placeholder="MEGNEVEZÉS" style={{height: "50px", marginBottom : "unset"}}/>
                                            <div className="item-content" id ={"item-content-" + index} style={{backgroundImage: "url('" + this.state.items[index].original + "')", backgroundSize: "cover"}}>
                    
                    
                                                <input type="hidden" id={"item-url-" + index} value={this.state.items[index].url  } />
                    
                                               
                                              <AddFile selectFile = {this.addImage} index={index}/>
                    
                                            </div>
                                            <a href="#" className="delete-link black-link" onClick={() => this.deleteItem(index)} >Törlés</a>
                                        </div>
                                                    )
                                        )
                                    }
                                </div>
                                <a className="btn btn-add-item" onClick={this.addItem}>Új elem hozzáadása</a>
                            </div>
                
                
                      
                
                    </section>
                
                </div>
                );
    }
}


export default ModulePictureForm;







