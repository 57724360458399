import React from 'react';

import { withRouter } from "react-router";
import {ModuleService }from "../../_services/ModuleService"

        class ModulePairing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
            pairs: [],
            touchElement: "",
            time: 0,
            wrongOpenedPairs: 0,
            startDate: new Date(),
        };


        this.handleDrop = this.handleDrop.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.checkElements = this.checkElements.bind(this);
        this.removeCircle = this.removeCircle.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.disableDragStart = this.disableDragStart.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.removeRedCircle = this.removeRedCircle.bind(this);
        this.preventDefaultHandler = this.preventDefaultHandler.bind(this);
    }

    componentWillUnmount() {

    }
    componentDidMount() {

        ModuleService.getPairingItems(localStorage.getItem('activeModuleId'))
                .then(pairs => {
                    this.setState({"pairs": this.randomArrayShuffle(pairs)})
                    if (!localStorage.hasOwnProperty("onlineGame")) {
                        this.moveRandomPlaces();
                    }
                }
                )

    }

    randomArrayShuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    moveRandomPlaces() {
        var divs = document.getElementsByClassName('game-pictures');

        var winWidth = document.getElementById('content').offsetWidth;
        var winHeight = document.getElementById('content').offsetHeight;


        for (var i = 0; i < divs.length; i++) {

            var thisDiv = divs[i];

            var randomTop = this.getRandomNumber(0, 80);
            var randomLeft = this.getRandomNumber(0, 100);

            thisDiv.style.marginTop = randomTop + "px";

            thisDiv.style.marginLeft = randomLeft + "px";

            thisDiv.style.zIndex = "1";
        }
    }

    getRandomNumber(min, max) {

        return Math.random() * (max - min) + min;

    }

    checkElements() {

        var x = document.getElementById("pairing_container").getElementsByTagName("img").length + document.getElementById("pairing_container").getElementsByClassName("pairing_text").length;
        var y = document.getElementById("good_pairs").getElementsByTagName("img").length + document.getElementById("good_pairs").getElementsByClassName("pairing_text").length;

        if (this.state.pairs.length === y) {
            var endDate = new Date();
            var seconds = (endDate.getTime() - this.state.startDate.getTime()) / 1000;

            document.getElementById("root").classList.add("gameend-body");
            var score = 2;

            /*  switch (true) {
             case (seconds < 30):
             score = 100;
             if (this.state.wrongOpenedPairs > 3)
             score = score - this.state.wrongOpenedPairs;
             break;
             case (seconds < 60):
             score = 70;
             if (this.state.wrongOpenedPairs > 3)
             score = score - this.state.wrongOpenedPairs;
             break;
             case (seconds < 90):
             score = 50;
             if (this.state.wrongOpenedPairs > 3)
             score = score - 10;
             break;
             
             default :
             if (this.state.wrongOpenedPairs < 5)
             score = 20;
             else
             score = 0;
             
             }*/

            if (localStorage.hasOwnProperty("onlineGame")) {
                this.props.saveScore(2, JSON.stringify([{"time": seconds, "wrongedPairs": this.state.wrongOpenedPairs}]), score);

            }
            var pairs = "";
            var mainPairs = this.state.pairs.filter(pair => pair.main_pair === true);
            var statePairs = this.state.pairs;
            mainPairs.map(function (pair) {

                const pairPairs = statePairs.filter(pairPair => pairPair.pair_sign === pair.pair_item_sign);

                if (localStorage.getItem("activeModule") !== "ModulePairingText") {
                    pairs += '<div class="endpair" >' +
                            '<div class="game-pictures"  ><img    src="' + "/file/download?fileId=" + pair.img_url + '" /><div class="label  pair-label twisted-label ' + (pair.pair_label_name ? "" : "inside-label-nubmer-bottom") + '"><label class="label-name">' + pair.pair_label_name + '</label><div class="label-number"><div class="label-number-flex">' + pair.pair_sign + '</div></div></div></div><br>' +
                            '<div class="game-pictures"  ><div class="label twisted-label ' + (pair.pair_label_name ? "" : "inside-label-nubmer-bottom") + '"><div class="label-number"><div class="label-number-flex">' + pairPairs[0].pair_sign + '</div></div><label class="label-name label-name-changed">' + pairPairs[0].pair_label_name + '</label></div><img class="pair"  src="' + "/file/download?fileId=" + pairPairs[0].img_url + '" /></div>' +
                            '</div>'
                } else {
                    pairs += '<div class="endpair text_pairing_end">' +
                            '<div class="game-pictures"  ><img    src="' + "/file/download?fileId=" + pair.img_url + '" /><div class="label  pair-label twisted-label ' + (pair.pair_label_name ? "" : "inside-label-nubmer-bottom") + '"><label class="label-name">' + pair.pair_label_name + '</label><div class="label-number"><div class="label-number-flex">' + pair.pair_sign + '</div></div></div></div><br>' +
                            '<div class="game-pictures"  ><div class="label twisted-label inside-label-nubmer-top"> <div class="label-number"><div class="label-number-flex">' + pairPairs[0].pair_sign + '</div></div></div><div class="pair pairing_text" >"' + pairPairs[0].img_url + '" </div></div>' +
                            '</div>'
                }

            });

            document.getElementById("pairing_container").innerHTML = '<div class="endpairs" style="margin : 0 auto;">' + pairs + '</div>';
            document.getElementById("root").classList.add("gameend-body");
            document.getElementById("good_pairs").classList.add("d-none");
            if (localStorage.hasOwnProperty("onlineGame")) {
                document.getElementById("forward_module_button").classList.remove("d-none")
            }
        }
    }

    removeCircle(event, circleDiv) {
        event.removeChild(circleDiv);

        event.setAttribute("id", "paired_images");
        event.style.marginTop = 0;
        event.style.marginLeft = 0;
        event.style.display = "inherit";
        event.style.height = "115px";
        event.setAttribute("draggable", false);
        var pairs = document.getElementsByClassName("paired_images");

        document.getElementById("good_pairs").appendChild(document.getElementById("paired_images"));

        this.checkElements(pairs);
    }

    removeRedCircle(event, circleDiv, droppedImg, label, id) {

        event.removeChild(circleDiv);
        if (document.getElementsByClassName("moving").length > 0) {
            let moving = document.getElementsByClassName("moving")[0];
            document.getElementById("pairing_container").removeChild(moving);
            document.getElementById(document.getElementById(this.state.touchElement.id).parentNode.getAttribute("id")).classList.remove("invisible");
        }
        if (id) {
            //   document.getElementById("game-picture-div-" + id).appendChild(label);
            document.getElementById("game-picture-div-" + id).appendChild(droppedImg);
        }

    }

    handleDrop(event) {
        event.preventDefault();
        event.target.classList.remove("drag-over");

        var data = event.dataTransfer.getData("text");
        var pair = event.dataTransfer.getData("pair");
        var circleDiv = document.createElement("div");
        var droppedImg = document.getElementById(data);

        circleDiv.setAttribute("id", "circle");

        if (event.target.getAttribute("draggable") && document.getElementById("pairing_container").contains(event.target)) {
            if (event.target.getAttribute("id") == pair) {
                circleDiv.setAttribute("class", "green_circle");
                event.target.parentElement.parentElement.appendChild(circleDiv);
                //  droppedImg.parentElement.removeChild(droppedImg.parentElement.getElementsByClassName("label")[0])
                //  event.target.parentElement.appendChild(document.getElementById(data));
                var copiedElement = document.getElementById(data).cloneNode(true);

                document.getElementById(data).classList.add("invisible");
                event.target.parentElement.parentElement.appendChild(copiedElement);
                /* event.target.parentElement.setAttribute("id", "paired_images");
                 event.target.parentElement.style.marginTop = 0;
                 event.target.parentElement.style.marginLeft = 0;
                 event.target.parentElement.style.display = "inherit";
                 event.target.setAttribute("draggable", false);*/

                setTimeout(this.removeCircle, 500, event.target.parentElement.parentElement, circleDiv);

            } else {
                var label = droppedImg.parentElement.getElementsByClassName("label")[0]
                //  droppedImg.parentElement.removeChild(droppedImg.parentElement.getElementsByClassName("label")[0]);

                event.target.parentElement.parentElement.appendChild(document.getElementById(data));


                circleDiv.setAttribute("class", "red_circle");
                this.setState({wrongOpenedPairs: this.state.wrongOpenedPairs + 1});
                event.target.parentElement.appendChild(circleDiv);
                setTimeout(this.removeRedCircle, 700, event.target.parentElement, circleDiv, droppedImg, label, data);
            }

        }

    }

    handleDragOver(event) {

        event.stopPropagation();
        event.preventDefault();

        if (event.target.tagName === "IMG" && document.getElementById("pairing_container").contains(event.target))
            event.target.classList.add("drag-over");

    }

    handleDragLeave(event) {
        event.target.classList.remove("drag-over");
    }

    handleDragStart(event) {

        if (event.target.tagName === "IMG" || event.target.classList.contains("pairing_text")) {
            event.dataTransfer.setData('text/html', event.target.innerHTML);
            event.dataTransfer.setDragImage(document.getElementById(event.target.parentNode.getAttribute("id")), 0, 0);
            event.dataTransfer.effectAllowed = "move";
            event.dataTransfer.setData("text", event.target.parentNode.getAttribute("id"));
            event.dataTransfer.setData("Text", event.target.parentNode.getAttribute("id"));
            event.dataTransfer.setData("pair", event.target.parentNode.getAttribute("pair"));
            event.dataTransfer.setData('text/html', event.target.innerHTML);
        } else {
            event.preventDefault();
        }
    }

    handleTouchEnd(event) {
        window.removeEventListener("touchmove", this.preventDefaultHandler, {passive: false});
        let moving = document.getElementsByClassName("moving")[0];
        var endTarget = document.elementFromPoint(
                event.changedTouches[0].pageX,
                event.changedTouches[0].pageY
                );

        var circleDiv = document.createElement("div");

        circleDiv.setAttribute("id", "circle");
        endTarget.classList.remove("drag-over");
        if (endTarget.tagName === "IMG" || endTarget.classList.contains("pairing_text")) {
            if (endTarget.getAttribute("pair") == this.state.touchElement.id) {
                circleDiv.setAttribute("class", "green_circle");
                moving.style.top = "";
                moving.style.left = "";
                moving.style.position = "relative";
                moving.style.display = "inline";
                endTarget.parentElement.style.display = "inline";
                endTarget.parentElement.appendChild(circleDiv);
                endTarget.parentElement.appendChild(moving);
                endTarget.parentElement.setAttribute("id", "paired_images");
                endTarget.parentElement.style.top = 0;
                endTarget.parentElement.style.left = 0;
                endTarget.parentElement.style.display = "inherit";


                setTimeout(this.removeCircle, 500, endTarget.parentElement, circleDiv);

            } else {
                circleDiv.setAttribute("class", "red_circle");
                endTarget.parentElement.appendChild(circleDiv);
                this.setState({wrongOpenedPairs: this.state.wrongOpenedPairs + 1});
                /*  moving.style.left = "";
                 moving.style.top = "";
                 moving.style.marginLeft = this.state.touchElement.marginLeft + "px";
                 moving.style.marginTop = this.state.touchElement.marginTop + "px";
                 moving.style.position = "relative";*/
                setTimeout(this.removeRedCircle, 700, endTarget.parentElement, circleDiv, moving, "", "");
            }

        } else {

            document.getElementById("pairing_container").removeChild(moving);
            document.getElementById(document.getElementById(this.state.touchElement.id).parentNode.getAttribute("id")).classList.remove("invisible");
            /* moving.style.left = "";
             moving.style.top = "";
             moving.style.marginLeft = this.state.touchElement.marginLeft + "px";
             moving.style.marginTop = this.state.touchElement.marginTop + "px";
             moving.style.position = "relative";*/
        }
    }

    preventDefaultHandler(e) {

        e.preventDefault();
    }
    handleTouchMove(event) {


        //   event.preventDefault();
        // let moving = document.getElementById(document.getElementById(this.state.touchElement.id).parentNode.getAttribute("id"));
        let moving = document.getElementsByClassName("moving")[0];

        moving.style.left = event.changedTouches[0].pageX + "px";
        moving.style.top = event.changedTouches[0].pageY + "px";
        if (!localStorage.hasOwnProperty("onlineGame")) {
            moving.style.marginLeft = 0;
            moving.style.marginTop = 0;
        }
        moving.style.position = "fixed";
        moving.style.zIndex = "0";
        moving.classList.remove("invisible");

        var endTarget = document.elementFromPoint(
                event.changedTouches[0].pageX,
                event.changedTouches[0].pageY
                );
        var dragged = document.getElementsByClassName("drag-over");
        for (var i = 0; i < dragged.length; i++) {
            dragged[i].classList.remove("drag-over");
        }

        if ((endTarget.tagName === "IMG" || endTarget.classList.contains("pairing_text")))
            endTarget.classList.add("drag-over");


    }
    handleTouchStart(event) {
        window.addEventListener("touchmove", this.preventDefaultHandler, {passive: false});
        event.stopPropagation();
        event.preventDefault();

        if (event.target.tagName === "IMG" || event.target.classList.contains("pairing_text")) {
            //   let moving = document.getElementById(document.getElementById(event.target.id).parentNode.getAttribute("id"));

            document.getElementById(document.getElementById(event.target.id).parentNode.getAttribute("id")).classList.add("invisible");
            let moving = document.getElementById(document.getElementById(event.target.id).parentNode.getAttribute("id")).cloneNode(true);
            document.getElementById("pairing_container").appendChild(moving);

            moving.classList.add("moving")

            let touchElement = {};
            touchElement.id = event.target.id;
            if (!localStorage.hasOwnProperty("onlineGame")) {
                touchElement.marginTop = moving.style.marginTop;
                touchElement.marginLeft = moving.style.marginLeft;
            }
            this.setState({"touchElement": touchElement});
        } else {
            event.preventDefault();
        }
    }

    disableDragStart(event) {
        event.preventDefault();
    }

    render() {

        return (
                <React.Fragment>
                
                    <div className="row pairing_game_container" id="pairing_container" >
                        {localStorage.getItem("moduleName") && <label className="black_border" style={{fontSize: "2vw", padding: "unset", lineHeight: "40px", position: "absolute", minWidth: "300px", height: "40px", margin: "0 auto", fontWeight: "bold", textAlign: "center", top: "-15px"}}>{localStorage.getItem("moduleName")}</label>
                        } 
                        { this.state.pairs.length > 0 ? this.state.pairs.map((pair, index) =>
                                <div className="game-pictures"    id={"game-picture-div-" + pair.pair_sign} key={index} onTouchEnd={e => this.handleTouchEnd(e)} onDrop={e => this.handleDrop(e)} onDragLeave={e => this.handleDragLeave(e)}  onDragOver={e => this.handleDragOver(e)}>
                                    <div className="pairing-drag-div"  id={pair.pair_sign} draggable={false} onTouchStart={e => this.handleTouchStart(e)} onDragStart={e => this.handleDragStart(e)} pair={pair.pair_item_sign}> 
                                        {!pair.main_pair && <div draggable={false}  className={"label " + (pair.pair_label_name ? "" : "inside-label-nubmer-top")}  onDragStart={e => this.disableDragStart(e)} >
                                            <label className="label-name">{pair.pair_label_name}</label> <div className="label-number "><div className="label-number-flex">{pair.pair_sign}</div></div>
                        
                                        </div>}
                                        {pair.main_pair || localStorage.getItem("activeModule") !== "ModulePairingText" ? <img  draggable={true} id={pair.pair_sign}  alt="img"  pair={pair.pair_item_sign} onTouchMove ={e => this.handleTouchMove(e)} className={pair.main_pair ? "" : "pair"}   src={"/file/download?fileId=" + pair.img_url} /> :
                                                            <div  draggable={true} id={pair.pair_sign}  pair={pair.pair_item_sign} onTouchMove ={e => this.handleTouchMove(e)}  className="pairing_text">{pair.img_url}</div>
                                        }
                        
                                        {pair.main_pair && <div draggable={false}  className={"label twisted-label " + (pair.pair_label_name ? "" : "inside-label-nubmer-bottom")}  onDragStart={e => this.disableDragStart(e)} >
                                            <label className="label-name">{pair.pair_label_name}</label> <div className="label-number "><div className="label-number-flex">{pair.pair_sign}</div></div>
                        
                                        </div>}
                                    </div>
                                </div>
                            ) : ""}
                
                    </div>
                    <div id="good_pairs" ></div>
                </React.Fragment>



                );
    }
}
;

export default ModulePairing;





