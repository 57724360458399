import React from 'react';
import { Redirect, useParams, Link, Prompt, Route, BrowserRouter }
from 'react-router-dom';
class RegistrationForm extends React.Component {

    constructor(props, context) {

        super(props, context);
        this.state = {

            name: "",
            email: "",
            submitted: false,
            privacyPolicy: false,
            isValidEmail: true,
            validName: true


        };
        this.startGame = this.startGame.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChecked(e) {

        this.setState({privacyPolicy: e.target.checked});
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    startGame() {

        this.setState({submitted: true});
        const {name, email, privacyPolicy} = this.state;
        if ((!name || !email || !privacyPolicy)) {
            return;
        }

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        var namePattern = new RegExp(/^[a-z áéúőóüűöí.-]+\s[a-z áéúőóűüöí.]+$/i);

        if (!namePattern.test(this.state.name)) {
          
            this.state.validName = false;
            this.setState({validName: false});
            return;
        } else {
            this.setState({validName: true});
        }


        if (!pattern.test(this.state.email)) {
            this.state.isValidEmail = false;
            this.setState({isValidEmail: false});
            return;
        } else {
            this.setState({isValidEmail: true});
        }


        fetch("/onlineUser/save", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email: this.state.email, name: this.state.name})
        })
                .then(response => response.text())
                .then(response => {

                    localStorage.setItem("onlineUserId", response);
                    document.cookie = "onlineUserId="+response;
                    window.setCookie("onlineUserId2",response,1);
                    fetch("/age/all")
                            .then(response => response.json())
                            .then(data => {

                                this.props.afterStartGame(data);

                            });

                });

        if (document.getElementById("game-container")) {
            document.getElementById("game-container").classList.remove("d-none");
        } else {
            document.getElementById("littlegame-container").classList.remove("d-none");
        }

        document.getElementById("online-header").classList.remove("d-none");
        document.getElementById("content").classList.remove("login-body");
        document.getElementById("login").classList.add("d-none");
    }
    render() {

        return(
                <div className="row "  id="login" style={{height: "100%"}}>
                    <div id="left-side" className="col-md" style={{marginTop: "40px"}}>
                        <div id="logo">
                
                            <i className="tha-logo"></i>
                            <p> <b style={{fontSize: "70px"}}>HADFIAK</b><br/>TÖRTÉNELMI<br/> HADI AKADÉMIA<br/>ALAPÍTVÁNY </p>
                        </div>
                    </div>
                    <div id="right-side" className="col-md" style={{height: "100%"}} >
                
                        <div style={{textAlign: 'center'}}>
                
                            <label id="login_label" className=" muzped-white-border" >REGISZTRÁCIÓ</label><br/>
                
                            <div style={{position: "absolute", width: "100%", top: "20px"}}>
                                <div className={"help-block " + (this.state.submitted && (!this.state.name || !this.state.email || !this.state.privacyPolicy || !this.state.validName || !this.state.isValidEmail) ? "" : "invisible") }>
                                    { (!this.state.name || !this.state.email || !this.state.privacyPolicy) ? " A regisztráció véglegesítéséhez töltse ki az összes mezőt" : !this.state.validName ? "Kérjük valós / teljes nevet adjon meg." : !this.state.isValidEmail ? "Kérjük létező email címet adjon meg." : ""  }
                                </div>
                            </div>
                
                            <div id="registration-form" style={{position: "absolute", width: "100%", top: "30%"}}>
                                <div className={'form-group'}>
                                    <div className="row justify-content-center">
                
                                        <div className={"col-md " + (this.state.submitted && !this.state.name ? "error_reg" : "")} style={{maxWidth: "300px", padding: "0"}}>
                
                                            <input type="text" className="muzped-input" onChange={this.handleChange} required name="name" placeholder="teljes név"/>
                
                
                                        </div>
                
                                    </div>
                                </div>
                                <div className={'form-group'}>
                                    <div className="row justify-content-center">
                
                                        <div  className={"col-md " + (this.state.submitted && !this.state.email ? "error_reg" : "")} style={{padding: "0", maxWidth: "300px"}}>      
                
                
                                            <input type="text" className="muzped-input" onChange={this.handleChange} required name="email" placeholder="e-mail"/>
                                        </div>
                
                                    </div>
                                </div>
                                <div className={'form-group'}>
                                    <div className="row justify-content-center">
                
                                        <label className={"checkbox-container " + (this.state.submitted && !this.state.privacyPolicy ? "error_reg" : "")}>
                                            Hozzájárulok ahhoz, hogy a Történelmi Hadi Akadémia Alapítvány az itt megadott személyes adataimat az <Link to="privacypolicy" target="_blank"><b> Adatkezelési Tájékoztató</b></Link>ban rögzített céllal kezelje.
                                            <input type="checkbox" required  onChange={this.handleChecked} />
                                            <span class="checkmark"></span>
                                        </label>
                
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md" style={{padding: "0", maxWidth: "300px"}}>   
                                        <button className="muzped-input" id="registration-btn"  onClick= {this.startGame} >REGISZTRÁLOK</button>
                                    </div>
                                </div>
                
                            </div>
                        </div>
                    </div>
                    <div id="emmet">
                    </div>
                </div>
                );
    }
}

export default RegistrationForm;


