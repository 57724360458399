import React, { Component }
from "react";
import { withRouter }
from "react-router";
import { Redirect }
from 'react-router-dom';
import "../style/admin.css";
import AdminSideBar from './AdminSideBar';
import SearchComponent from './components/SearchComponent'
import FilterComponent from './components/FilterComponent'
import SwitchComponent from './components/SwitchComponent'
import ListHeader from "./components/ListHeader"
import Pagination from "./components/Pagination"
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';

        class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user:  localStorage.hasOwnProperty("user") && !localStorage.getItem("user").id ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'),"pazirik77").toString(CryptoENC)):"",
            events: [],
            totalItems: 0,         
            pagedItems: [],
            
        };
        this.pagination = this.pagination.bind(this);
    }

    pagination(page,itemPerPage) {
    // console.log(this.state.events);
       this.setState({ pagedItems: this.state.events.slice((page-1)*itemPerPage, page * itemPerPage)}) 


    }

    componentDidMount() {

        let tasks = [];
        fetch("/allevent/" + localStorage.getItem("institution"))
                .then(response => response.json())
                .then(data => {
                   
                   
                    data.map(event => {
                        fetch("/user/get/" + event.userId)
                                .then(response => response.json())
                                .then(user => {

                                    event.userName = user.name;

                                    // this.state.events.push(event);
                                    this.setState({events: [...this.state.events, event]});
                                   this.pagination(1,5);
                                   

                                })

                    })
                    
                     this.setState({totalItems: data.length})

                })


    }

    componentWillMount() {
        document.body.classList.remove('admin-login-body');
        document.body.classList.remove('admin-welcome-body');
        document.body.classList.add('admin-body');
        document.body.classList.add('body');
    }
    render() {
        const {user} = this.state;
        if (!user) {
            return <Redirect to="/adminlogin" />
        }
        return (
                <React.Fragment>  <AdminSideBar activeMenu="events"/> 
                    <div className="container">
                        <ListHeader title="ESEMÉNYEK" filteritems={this.state.events} filter={this.filter} filters={this.state.filters} AddItem="" />
                
                
                        <div className="row" style={{marginTop: '40px'}}>
                
                            <table className="table table-dark" id="muzped-admin-table">
                                <tr>
                                    <th></th>
                                    <th><b>Felhasználó</b></th>
                                    <th>Esemény</th>
                                </tr>
                                { this.state.pagedItems.map((event) =>
                                <tr className="search-tr">
                                    <td>{new Date(event.createDateTime).toLocaleDateString("hu-HU") + " " + new Date(event.createDateTime).toLocaleTimeString("hu-HU") }</td>
                                    <td>{event.userName}</td>
                                    <td>{event.event}</td>
                                </tr>


                                    ) }
                            </table>
                        </div>
                      
                            <Pagination  totalItems={this.state.totalItems} pagination={this.pagination}/>
                
                    </div>
                </React.Fragment>
                );
    }
}

export default withRouter(Events);
