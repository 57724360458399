
import React, { Component}from "react";
import {ModuleService}from "../../_services/ModuleService";
import ModuleFormsHeader from"./ModuleFormsHeader"

class ModuleTextForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
          
            itemNo: 0,
            moduleText: ""
        }
        this.subThemeThemeModal = React.createRef();
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.ModuleFormsHeader = React.createRef();

    }

    handleChange(e) {
       
        const {name, value} = e.target;
        this.setState({"moduleText": value});
    }
   

    save() {
        var error = false;

       
        if (!error) {
            this.props.savePreviewModule("Text", this.state.moduleText);
            this.ModuleFormsHeader.current.hideModal();
           
        }  
    }
    componentDidMount() {

        if (this.props.moduleId != 0) {
             
        fetch("/modulepreview/find/" + this.props.moduleId)
                 .then(response => response.json())
                .then(items => {
                  if(items.length>0)
                    this.setState({"moduleText" :  items[0]["link"]});
                    
                  
                    });

        }


    }

   
    render() {



        return (
                <div  className="modal-dialog admin-modal quiz-modal ">
                    <section  className="admin-form-modal modal-content" >
                        
                            <ModuleFormsHeader ref={this.ModuleFormsHeader} title="SZÖVEG" save={this.save} delete={this.props.deletePreview} context={this.props.contextModule}/>
                
                
                            <div className="  modal-body" style={{padding: "40px"}} >
                                
                               <textarea  type="text" className="item-input" style={{border : "2px solid #252629", marginTop : "40px",height : "400px"}} onChange={this.handleChange} name="module_text" id="module_text" value={this.state.moduleText} placeholder="LEÍRÁS" />
                            </div>
                
                
                        
                
                    </section>
                
                </div>
                );
    }
}


export default ModuleTextForm;







