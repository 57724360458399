


import React, { Component, ReactDOM} from "react";
import { withRouter } from "react-router";
import { Redirect, Link } from 'react-router-dom';
import muzpedIcon from "../../images/muzped_icon.png"
import "../../style/user.css";
import muzpedText from "../../images/muzped_logo_text.png"
import {authService} from "../../_services/authService"
import ThemesList from "../components/ThemeList"

        class SubThemeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            ageId: localStorage.getItem("ageId"),
            themes: [],
            age: [],
            themeId: localStorage.getItem("themeId")

        };


        this.goToTheme = this.goToTheme.bind(this);
        this.logout = this.logout.bind(this);
    }

    logout() {
        authService.logout();
        this.props.history.push("/")
    }
    
    goToTheme(e, themeId) {

        localStorage.setItem("subThemeId", themeId);

        this.props.history.push({
            pathname: '/subThemeSelect',
            data: themeId // your data array of objects
        })


    }

    componentDidMount() {


        fetch("/subtheme/find/" + this.state.themeId)
                .then(response => response.json())
                .then(data => {
                  console.log("subthemes");
                    this.setState({themes: data})
                })

        fetch("/age/select/" + this.state.ageId)
                .then(response => response.json())
                .then(data => {

                    this.setState({age: data})
                })
    }

    componentWillMount() {

        let host = window.location.host;

        let parts = host.split(".");
        let subdomain = "";

        if (parts.length >= 3) {
            subdomain = parts[0];

        }

        document.body.classList.add(subdomain + 'muzped_back');
        document.body.classList.remove(subdomain + 'theme_back');
        document.body.classList.add('muzped_back');
    }
    render() {
        const {user, isOn, handleToggle, onColor} = this.state;
        if (!user) {
            return <Redirect to="/login" />
        }
        return (
                <div id="content" className="wrapper">   
                    <div className="container" >
                        <div className="row header">
                            <div className="col-sm text-left">
                                <i className="muzped-icon"></i>
                                <label class="black_border" style={{borderLeft: "none"}}><b>{this.state.age.ageFrom + " - " + this.state.age.ageTo}</b> éves korosztály </label>
                            </div>
                
                            <div className="col-sm text-center align-baseline">
                                <h3 className="align-text-bottom"><b>ALTEMATIKA</b>-VÁLASZTÓ</h3>
                            </div>
                            <div className="col-sm text-right">
                
                               <i className="muzped-text"></i><br/>
                             <button className="btn-logout btn" onClick={this.logout}></button>
                            </div>
                        </div>
                
                        <ThemesList themes={this.state.themes} selectTheme={this.goToTheme} type="subTheme"  />
                    </div>
                
                    <footer className="muzped-footer">
                        <div className="container">
                            <div class="row">
                                <div className="col-md-3">
                                    <Link to="/themeSelect"><button className="btn-backward btn"></button></Link>
                
                                </div>
                                <div className="col-md-6 text-center">
                
                                </div>
                                <div className="col-md-3 text-right">
                                    <Link to="/dashboard"><button className="btn-home btn"></button></Link>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>

                );
    }
}

export default withRouter(SubThemeList);