
import React, { Component}from "react";
import {ModuleService}from "../../_services/ModuleService";
import ModuleFormsHeader from"./ModuleFormsHeader"
import {UsePopup, PopupContext}  from '../../context/popup-context'
import AddFile from "../components/AddFile"

        class ModuleMemoryForm extends Component {

    static contextType = PopupContext;
    constructor(props, context) {

        super(props, context);

        this.state = {
           
            itemNo: 0,
            itemCount : 0,
            memories: {}
        }
        this.subThemeThemeModal = React.createRef();
        this.hideModal = this.hideModal.bind(this);
        this.addMemoryItem = this.addMemoryItem.bind(this);
        this.save = this.save.bind(this);
        this.addImage = this.addImage.bind(this);
        this.ModuleFormsHeader = React.createRef();
        this.deleteItem = this.deleteItem.bind(this);

    }

    deleteItem(index) {
        console.log(index);
        console.log(document.getElementById("memory-item-" + index));
        document.getElementById("memory-item-" + index).remove();
        this.setState({itemCount : this.state.itemCount-1});
    }

    addImage(id, index) {


        document.getElementById("memory-item-" + index).getElementsByClassName("add-memory-item-content")[0].style.backgroundImage = "url('/file/download?fileId=" + id + "')";
        document.getElementById("memory-item-" + index).getElementsByClassName("add-memory-item-content")[0].style.backgroundSize = "cover";
        document.getElementById("memory-item-" + index).getElementsByClassName("btn-add-image")[0].style.display = "none";
        document.getElementById("memory-url-" + index).value = id;
    }

    addMemoryItem() {
        this.setState({"itemNo": this.state.itemNo + 1,"itemCount": this.state.itemCount + 1});
    }

    save() {
        var error = false;

        for (var i = 0; i < this.state.itemNo; i++) {
            if (document.getElementById("memory-item-" + i)) {
                this.state.memories[i] = {};
                this.state.memories[i]["name"] = document.getElementById("memory-label-" + i).value;
                this.state.memories[i]["url"] = document.getElementById("memory-url-" + i).value;
                this.state.memories[i]["id"] = document.getElementById("memory-id-" + i).value === "" ? 0 : document.getElementById("memory-id-" + i).value;
                this.setState({"memories": this.state.memories});
            }
        }

        if (!error) {
            this.props.saveModule("Memory", this.state.memories);
            this.hideModal();
        }
    }
    componentDidMount() {

        if (this.props.moduleId != 0) {
            fetch("/memoryitem/find/" + this.props.moduleId)
                    .then(response => response.json())
                    .then(items => {

                        this.setState({"itemNo": items.length, itemCount : items.length});

                        for (var i = 0; i < items.length; i++) {
                            document.getElementById("memory-item-" + i).getElementsByClassName("add-memory-item-content")[0].style.backgroundImage = "url('/file/download?fileId=" + items[i].image_url + "')";
                            document.getElementById("memory-label-" + i).value = items[i].card_name;
                            document.getElementById("memory-url-" + i).value = items[i].image_url;
                            document.getElementById("memory-id-" + i).value = items[i].id;
                            document.getElementById("memory-item-" + i).getElementsByClassName("add-memory-item-content")[0].style.backgroundSize = "cover";
                            document.getElementById("memory-item-" + i).getElementsByClassName("btn-add-image")[0].style.display = "none";
                        }

                    });

        }


    }

    hideModal() {
        window.scrollTo({
            top: 400,
            behavior: 'smooth',
        })
        document.body.classList.remove('display-block');
        let element = document.getElementsByClassName("modal");
        element[0].classList.add("display-block");
        element[0].classList.add("display-none");
         
        document.body.classList.remove("modal-admin-body");
        //window.scrollTo(0,localStorage.getItem("scrollTop"));

    }

    render() {



        return (
                
                    <div  className=" modal-dialog" >
                        <div className="modal-content">
                            <ModuleFormsHeader ref={this.ModuleFormsHeader} title="MEMÓRIA" save={this.save} delete={this.props.delete} context={this.props.contextModule}/>
                
                
                            <div className=" modal-body" >
                                <div  id="memory-item-container" style={{width: "900px", padding: "40px", display:"inline-flex" , overflowY : "auto" , marginTop : "100px"}}>
                                    {[...Array(this.state.itemNo), ].map((value, index) => (
                                        <div key={index} className="memory-item-admin" id={"memory-item-"+index}>     
                                            <div className="input-div">
                                                <input id={"memory-label-" + index} type="text" placeholder="MEGNEVEZÉS" />
                                                <input type="hidden" id={"memory-url-" + index} />
                                                <input type="hidden" id={"memory-id-" + index}  />
                                            </div>
                                            <div className="add-memory-item" key={index} >
                                                <div className="add-memory-item-content">
                    
                    
                                                    <AddFile selectFile = {this.addImage} index={ index}/>
                                                </div>
                    
                                            </div>
                                            <div className="memory-item-admin-back">
                                                <a  className="delete-link black-link" onClick={() => this.deleteItem(index)} >Törlés</a>
                                            </div>
                                           
                                        </div>
                                                    )
                                        )
                                    }
                                </div>
                                <a className={"btn btn-add-item " + (this.state.itemCount >= 6 ? "disabled-add-item" : "") } onClick={this.addMemoryItem}>Új elem hozzáadása</a>
                            </div>
                
                
                        </div>
                
                    </div>
                
                
                );
    }
}

class MemoryItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
                <React.Fragment>
                
                </React.Fragment>

                )
    }
}
export default ModuleMemoryForm;




