import React, { Component}from "react";
import {ModuleService} from "../../_services/ModuleService";
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';


class ThemeModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'), "pazirik77").toString(CryptoENC)) : "",
            activeModule: localStorage.getItem("activeModuleId"),
            modules: [],
            nextModuleId: ''

        };
        this.changeModule = this.changeModule.bind(this);
        this.nextModule = this.nextModule.bind(this);
        this.updateModules = this.updateModules.bind(this);
        this.themeModal = React.createRef();
        this.handleDrop = this.handleDrop.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.changeModuleShort = this.changeModuleShort.bind(this);
        this.getModule = this.getModule.bind(this);
        this.getNextModule = this.getNextModule.bind(this);

        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);


    }

    updateModules() {
        const moduleList = [];
        this.setState({"modules": []});
        this.getNextModule();
        fetch("/thememodule/find/" + localStorage.getItem("subThemeId"))
                .then(response => response.json())
                .then(modules => {
                    this.setState({"modules": []});
                    modules.map((module, index) => {


                        this.getModule(module.moduleId, module.active, module.shorter);


                    })



                });


    }

    async getModule(moduleId, active, shorter) {

        const moduleList = this.state.modules;
        const response = await fetch("module/find/" + moduleId);

        const moduleResponse = await response.json();

        moduleResponse.short = shorter;
        moduleResponse.themeModuleActive = active;
        moduleList.push(moduleResponse);
        moduleList.sort(function (a, b) {
            return a.short - b.short
        })


        this.setState({"modules": moduleList});

    }
    componentDidMount() {

        this.props.onRef(this);
        var moduleList = [];

        fetch("/thememodule/find/" + localStorage.getItem("subThemeId"))
                .then(response => response.json())
                .then(modules => {


                    modules.map((module, index) => {
                        this.getModule(module.moduleId, module.active, module.shorter);


                    })


                });

        this.getNextModule();
    }

    nextModule() {

        document.body.classList.remove('display-block');
        let element = document.getElementsByClassName("modal");

        element[0].classList.add("display-none");

        this.props.loadNextModule();
    }

    changeModule(id, active) {

        let e = document.getElementById("module-list-element_" + id);
        if (e.classList.contains("active-module")) {

            ModuleService.setActiveStatus(id, localStorage.getItem("subThemeId"), false).then(response =>
            {
                this.props.updateModuleList()
                this.getNextModule();
            }
            );
            e.classList.remove("active-module");


        } else {
            ModuleService.setActiveStatus(id, localStorage.getItem("subThemeId"), true).then(reponse =>
            {
                this.props.updateModuleList()
                this.getNextModule();
            });
            e.classList.add("active-module");

        }

        //  this.setState("nextModuleId", this.state.nextModuleId+1);

    }

    changeModuleShort(id, short) {

        ModuleService.setShort(id, localStorage.getItem("subThemeId"), short).then(response => {
            this.updateModules();
            this.props.updateModuleList();

        }

        );



    }
    handleDrop(event) {
        event.preventDefault();

        document.getElementById('modal').setAttribute("style", "height:" + document.getElementById('modal').offsetHeight + "px");

        if (event.currentTarget.getAttribute("index") !== event.dataTransfer.getData("index")) {
            //  insertAfter(event.currentTarget,event.dataTransfer.getData("element"));
            var droppedLi = document.getElementById("module-list-element_" + event.dataTransfer.getData("moduleId"));
            event.currentTarget.parentElement.insertBefore(droppedLi, event.currentTarget);

            this.changeModuleShort(event.dataTransfer.getData("moduleId"), event.currentTarget.getAttribute("index"));
            if (parseInt(event.currentTarget.getAttribute("index")) < parseInt(event.dataTransfer.getData("index"))) {
                this.changeModuleShort(event.currentTarget.getAttribute("moduleId"), parseInt(event.currentTarget.getAttribute("index")) + 1);
            } else {
                this.changeModuleShort(event.currentTarget.getAttribute("moduleId"), parseInt(event.currentTarget.getAttribute("index")) - 1);
            }

            var higherIndexes = this.state.modules.filter(module => module.short > event.currentTarget.getAttribute("index") && module.short != event.dataTransfer.getData("index") && module.short < this.state.modules.length);

            higherIndexes.map(module => this.changeModuleShort(module.id, parseInt(module.short) + 1))

            var shorterIndexes = this.state.modules.filter(module => module.short < event.currentTarget.getAttribute("index") && module.short != event.dataTransfer.getData("index") && module.short != 1);
            shorterIndexes.map(module => this.changeModuleShort(module.id, parseInt(module.short) - 1))
        }
        event.currentTarget.classList.remove("dragabove");
        event.currentTarget.classList.remove("dragunder");


    }

    handleDragOver(event) {

        event.stopPropagation();
        event.preventDefault();


        if (event.currentTarget.getAttribute("index") < event.dataTransfer.getData("index")) {
            event.currentTarget.classList.add("dragabove");
        }


        if (event.currentTarget.getAttribute("index") > event.dataTransfer.getData("index")) {
            event.currentTarget.classList.add("dragunder");
        }
    }

    handleDragStart(e) {


        e.dataTransfer.setData('moduleId', e.target.getAttribute('moduleid'));
        e.dataTransfer.setData('index', e.target.getAttribute('index'));
    }

    handleTouchStart(e) {
        const moving = document.getElementById(e.target.parentNode.getAttribute("id")).cloneNode(true);

        e.target.parentNode.classList.add("invisible");

        moving.classList.add("moving");
        if (moving.getElementsByClassName("modal-button").length > 0) {
            moving.getElementsByClassName("modal-button")[0].classList.add("invisible");
        }
        document.getElementById("module-list").appendChild(moving);
    }

    handleTouchMove(e) {
        const moving = document.getElementsByClassName("moving")[0];
        moving.style.left = (e.changedTouches[0].pageX-200 ) + "px";
       
        moving.style.top = ((e.changedTouches[0].pageY-(document.getElementsByClassName("modal-main")[0].offsetTop+65  ) ))+ "px";
    /*    moving.style.marginLeft = 0;
        moving.style.marginTop = 0;*/

        var endTarget = document.elementFromPoint(
                e.changedTouches[0].pageX-30,
                e.changedTouches[0].pageY-40
                );



        var dragged = document.getElementsByClassName("dragabove-touch");
        for (var i = 0; i < dragged.length; i++) {
            dragged[i].classList.remove("dragabove-touch");
        }

        var dragged_under = document.getElementsByClassName("dragunder-touch");
        for (var i = 0; i < dragged_under.length; i++) {
            dragged_under[i].classList.remove("dragunder-touch");
        }


        if (endTarget.tagName === "LI") {
            if (endTarget.getAttribute("index") < moving.getAttribute("index")) {
                endTarget.classList.add("dragabove-touch");
            }


            if (endTarget.getAttribute("index") > moving.getAttribute("index")) {
                endTarget.classList.add("dragunder-touch");
            }
        }
    }

    handleTouchEnd(e) {
        const moving = document.getElementsByClassName("moving")[0];
        var endTarget = document.elementFromPoint(
                e.changedTouches[0].pageX-30,
                e.changedTouches[0].pageY-40
                );

        document.getElementById("module-list").removeChild(moving);
        document.getElementById("module-list-element_" + moving.getAttribute("moduleid")).classList.remove("invisible");
        document.getElementById('modal').setAttribute("style", "height:" + document.getElementById('modal').offsetHeight + "px");
        if (endTarget.tagName === "LI") {
            if (endTarget.getAttribute("index") !== moving.getAttribute("index")) {
                //  insertAfter(event.currentTarget,event.dataTransfer.getData("element"));
                var droppedLi = document.getElementById("module-list-element_" + moving.getAttribute("moduleId"));
                endTarget.parentElement.insertBefore(droppedLi, endTarget);

                this.changeModuleShort(moving.getAttribute("moduleid"), endTarget.getAttribute("index"));
                if (parseInt(endTarget.getAttribute("index")) < parseInt(moving.getAttribute("index"))) {
                    this.changeModuleShort(endTarget.getAttribute("moduleId"), parseInt(endTarget.getAttribute("index")) + 1);
                } else {
                    this.changeModuleShort(endTarget.getAttribute("moduleId"), parseInt(endTarget.getAttribute("index")) - 1);
                }

                var higherIndexes = this.state.modules.filter(module => module.short > endTarget.getAttribute("index") && module.short != moving.getAttribute("index") && module.short < this.state.modules.length);

                higherIndexes.map(module => this.changeModuleShort(module.id, parseInt(module.short) + 1))

                var shorterIndexes = this.state.modules.filter(module => module.short < endTarget.getAttribute("index") && module.short != moving.getAttribute("index") && module.short != 1);
                shorterIndexes.map(module => this.changeModuleShort(module.id, parseInt(module.short) - 1))
            }
        }
        endTarget.classList.remove("dragabove");
        endTarget.classList.remove("dragunder");
    }

    handleDragLeave(e) {
        e.currentTarget.classList.remove("dragabove");
        e.currentTarget.classList.remove("dragunder");
    }

    getNextModule() {

        let activeModule = localStorage.getItem("activeModuleId");

        this.setState({"activeModule": activeModule});
        fetch("/thememodule/getActives/" + localStorage.getItem("subThemeId"))
                .then(response => response.json())
                .then(modules => {
                    let activeModuleShort = modules.filter(module => module.moduleId == activeModule);
                    if (activeModule) {
                        if (modules[activeModuleShort[0].shorter])
                            this.setState({"nextModuleId": modules[activeModuleShort[0].shorter].moduleId})
                        else
                            this.setState({"nextModuleId": ""})
                    } else {
                        var active = modules.filter(module => module.shorter === 1);
                        var next = modules.filter(module => module.shorter === 2);
                        if( active[0])
                        this.setState({"activeModule": active[0].moduleId});
                       if(next[0])
                        this.setState({"nextModuleId": next[0].moduleId});
                    }

                });
    }

    render() {
        const show = true;
        const showHideClassName = show ? "modal display-block" : "modal display-none";


        const hideModal = (e) => {

            if (!document.getElementById('modal').contains(e.target)) {
                document.body.classList.remove('display-block');
                let element = document.getElementsByClassName("modal");

                element[0].classList.add("display-none");
            }
        };

        const actualModul = () => {

            document.body.classList.remove('display-block');
            let element = document.getElementsByClassName("modal");

            element[0].classList.add("display-none");
        }


        return (
                <div onClick= {(e) => hideModal(e)} className="modal display-none">
                    <section  className="modal-main" >
                        <div className="row"  id="modal">
                
                            <div className="col-md-6">
                                <ul id="module-list" className="list-group" style={{marginLeft: "200px"}}>
                                    {
                                        this.state.modules.length ? this.state.modules.map((module, i) =>
                                <li key={module.id} moduleid={module.id}  onTouchEnd={e => this.handleTouchEnd(e)} onTouchStart={e => this.handleTouchStart(e)}
                                onTouchMove ={e => this.handleTouchMove(e)} index={module.short }
                                style={this.state.activeModule != module.id && this.state.nextModuleId != module.id && this.props.showButtons === true ? {marginLeft: "170px"} : {marginLeft: "0"}} 
                                draggable={this.props.draggable ? true : false} onDragLeave={e => this.handleDragLeave(e)} onDrop={e => this.handleDrop(e)} onDragStart={e => this.handleDragStart(e)} 
                                onDragOver={e => this.handleDragOver(e)} id={"module-list-element_" + module.id} className={module.themeModuleActive ? 'active-module list-group-item' : 'list-group-item'} >
                                    {this.state.activeModule == module.id && this.props.showButtons === true ? <button className="theme_green_button modal-button"  onClick={actualModul}>Aktuális</button> : ""}  
                                    {this.state.nextModuleId == module.id && this.props.showButtons === true ? <button className="theme_grey_button modal-button"  onClick={this.nextModule}>Következő</button> : ""}  
                                    =<div className="eye" onClick={() => this.changeModule(module.id, module.themeModuleActive, this)} ></div><div>{module.short }
                                    </div>
                                    <div className="module-list-name">{module.moduleName}</div></li>

                                        ) : ""
                                    }
                
                
                                </ul>
                            </div>
                        </div>
                
                    </section>
                </div>
                );
    }
}

export default ThemeModal;