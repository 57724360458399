import React, { Component }
from "react";
import { withRouter }
from "react-router";
import { Redirect }
from 'react-router-dom';
import "../../style/admin.css";
import AdminSideBar from '../AdminSideBar';
import SearchComponent from '../components/SearchComponent'
import FilterComponent from '../components/FilterComponent'
import SwitchComponent from '../components/SwitchComponent'
import FileUpload from "../components/FileUpload"
import ReactDOM from "react-dom";
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';

        class MediaList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.hasOwnProperty("user") && !localStorage.getItem("user").id ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'),"pazirik77").toString(CryptoENC)):"",
             count :0 ,
        };

        this.changeImage = this.changeImage.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.createNewDirectory = this.createNewDirectory.bind(this);
        this.addDirectory = this.addDirectory.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
    }

    componentDidMount() {
        document.body.classList.remove('login-body');
        document.body.classList.remove('welcome-body');
        document.body.classList.add('admin-body');
        document.body.classList.add('body');

    }
    
    deleteImage(imageId){
        
        fetch("/file/delete/"+imageId)
              
                .then(response => {
                  
                     this.props.deleteImage(imageId);
                   

                });
       
       
    }
    
    changeImage(imageId,e){
         const fileName = e.target.files[0].name;
        const formData = new FormData()
        formData.append('file', e.target.files[0]);
        formData.append("fileId", imageId);
        formData.append("path",this.props.selectedDirectory.path);
        formData.append("directoryId", this.props.selectedDirectory.id);
        //formData.append('path', '/modules/' + this.state.id + "/");
        fetch("/file/update", {
            method: 'POST',
           
            body: formData
        })
                .then(response => response.json())
                .then(response => {
                  
                this.props.updateImages(imageId);
                   

                });

    }
    
    
    
    createNewDirectory(){
        document.getElementById("add-directory").classList.remove("d-none");
    }
    
    addDirectory(){
        document.getElementById("add-directory").classList.add("d-none");
        if(this.props.editDirectory){
            this.props.updateDirectory(document.getElementById("directory-name").value)
        } else {
             this.props.addDirectory(document.getElementById("directory-name").value)
        }
       
    }
    
    getName(fileId){
        
        
          fetch("/file/getname/"+fileId)
                .then(response => response.text())
                .then(response => {
                  
                   document.getElementById("imagename-"+fileId).innerHTML= response;

                });
    }
    
    handleDragStart(event){
        event.dataTransfer.setData('text/html', event.target.innerHTML);
        console.log("drag");
    }

    render() {
        const {user} = this.state;
        if (!user.name) {
            return <Redirect to="/" />
        }
        return (
                <div  style={{textAlign: "right"}}>
                   <div style={{marginTop : '100px'}} className="media-table-functions">
                   <input className="item-form-input d-none" name="images" id="images" type="file" placeholder="+ KÉP CSATOLÁSA" onChange={(e) => this.props.onFileChange(e)}></input>
                    <label  htmlFor="images" >FILE <b> FELTÖLTÉSE</b> </label>
                    <span style={{marginLeft : "30px"}} onClick={this.createNewDirectory}>MAPPA <b> HOZZÁADÁSA</b> </span>
                   </div>
                    <div style={{borderTop: "2px solid white", borderBottom: "2px solid white", paddingTop: "20px"}} className="row">
                        <div className="col-2"  style={{textAlign: "left"}}>
                         
                            <span style={{verticalAlign: "top"}}><b>KÉP</b>({this.props.images.length})</span>
                        </div>
                        <div className="images col-10"  style={{textAlign: "left"}}>
                            {
                    this.props.images.map((image,index) =>
                                <div key = {index} className="image-div" >
                                    <img draggable={true} onDragStart={(e) => this.props.handleDragStart(e,image.id)}  key={(new Date()).getTime()}  src={'/file/download?fileId=' + image.id+ '&' + new Date()}/>
                                    <label onClick={() => this.deleteImage(image.id)} className="image-delete"></label>  
                                    <label htmlFor={"changeimage"+image.id} className="image_change"></label>
                                             <input className="item-form-input d-none" name={"changeimage"+image.id} id={"changeimage"+image.id} type="file" placeholder="+ KÉP CSATOLÁSA" onChange={(e) => this.changeImage(image.id,e)}></input>
                                                     
                                        <br/> <span id={"imagename-"+image.id}>{this.getName(image.id)}</span>            
                                </div>
                    )
                            }
                        </div>
                    </div>
                    <div id="add-directory" className="d-none">
                       
                        <input type="text" id="directory-name" name="directory-name"/>
                        <button style={{border : "none", fontSize : "12px"}} className="blue-back-link" onClick={this.addDirectory}>HOZZÁAD</button>
                    </div>
                </div>
                );
    }
}

export default withRouter(MediaList);



