


import React, { Component} from "react";
import { withRouter } from "react-router";
import { Redirect } from 'react-router-dom';
import Carousel from './Carousel';
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';



class AgeSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'), "pazirik77").toString(CryptoENC)) : "",
            ages: []

        };
        this.selectAge = this.selectAge.bind(this);
        this.goToThemeList = this.goToThemeList.bind(this);

    }

    goToThemeList(e, ageId) {

        localStorage.setItem("ageId", ageId);
        this.props.goToThemeList();


    }
    selectAge(e) {
        //console.log(e.target.classList);
        var inactives = document.getElementsByClassName('ages_div');

        for (var y = 0; y < inactives.length; y++) {

            inactives[y].classList.remove("inactive_ages_div");
        }

        let element = document.getElementsByClassName('active_age');

        if (element.length > 0) {

            let buttons = element[0].parentElement.querySelectorAll('.ages_button ');

            if (buttons.length > 0) {
                buttons[0].classList.add("ages_button_not_display");
                buttons[0].classList.remove("ages_button_display");
            }
            element[0].classList.remove("active_age");
        }





        e.currentTarget.classList.add("active_age");
        let buttons = e.currentTarget.parentElement.querySelectorAll('.ages_button ');

        if (buttons.length > 0) {
            buttons[0].classList.remove("ages_button_not_display");
            buttons[0].classList.add("ages_button_display");
        }

        let  inactiveAges = document.getElementsByClassName('ages_div');
        var i;

        for (i = 0; i < inactiveAges.length; i++) {
            if (!inactiveAges[i].classList.contains("active_age")) {
                inactiveAges[i].classList.add("inactive_ages_div");
            }
        }

    }
    componentDidMount() {

        let tasks = [];
        fetch("/age/all")
                .then(response => response.json())
                .then(data => {

                    this.setState({ages: data})
                })
    }

    render() {

        return (
                <div  style={{marginTop: '110px'}} >
                
                    <Carousel>
                
                        {this.state.ages.length ? this.state.ages.map((age) =>
                                <div key={age.id} className="col-md ">
                                    <div className="ages_div" onClick={this.selectAge}>
                                        <p className="age-border">{age.ageFrom}</p>
                                        <p>{age.ageTo}</p>
                                        <b><span>ÉVES KOROSZTÁLY</span></b>
                        
                                    </div>
                                    <div className="ages_button ages_button_not_display">
                                        <a href="#" onClick={() => this.goToThemeList(this, age.id)} data-ageid={age.id} className="age_con theme_button theme_green_button"> Tovább </a>
                                        <a href="" className="age_cancel theme_button theme_grey_button"> Mégsem</a>
                                    </div>
                        
                                </div>

                    ) : ""
                        }
                
                
                
                    </Carousel>
                </div>


                );
    }
}

export default AgeSelection;