


import React, { Component } from "react";
import { withRouter } from "react-router";
import { Redirect } from 'react-router-dom';
import "../style/adminsidebar.css";
import profile  from "../images/admin-menu/profile.jpg"
import {authService} from "../_services/authService"
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';


class AdminSideBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'),"pazirik77").toString(CryptoENC)):"",
            activeMenu : this.props.activeMenu ? this.props.activeMenu : localStorage.getItem("activeMenu")
        };
          this.logOut = this.logOut.bind(this);  
          
    }

    logOut(){
         authService.logout();
        this.props.history.push("/admin")
    }
    render() {
        const {user} = this.state;

        if (!user.name) {
            return <Redirect to="/" />
        }
        return (
                <div id="sidebar-div" >
                <nav id="sidebar" style={{minHeight : "100%"}}>
                    <div className="sidebar-header">
                        <img className="profile-img" src={profile}/>
                        <h3 style={{display : "inline-block",wordWrap : "break-word",maxWidth : "110px", verticalAlign : "middle"}}>{user.name}</h3><br/>
                         <i className=" fa-forview"></i> <a href={window.location.origin+"/Dashboard"} target="blank">MúzPed <b>előnézet</b></a>
                    </div>
                
                    <ul className="list-unstyled components">
                        <li className={this.state.activeMenu==="theme" ? "active" : ""} >
                            <a href="/adminthemelist" >
                                <i className=" fa-theme"></i>
                                Tematika
                            </a>
                        </li>
                        <li  className={this.state.activeMenu==="subTheme" ? "active" : ""} >
                           <i className=" fa-subtheme"></i> <a href="/adminsubthemelist">Altematika</a>
                        </li>
                        <li  className={this.state.activeMenu==="module" ? "active" : ""}>
                           <i className=" fa-module"></i> <a href="/adminmodulelist">Modul</a>
                        </li>  
                
                
                    </ul>
                    
                     <ul className="list-unstyled components">
                        <li  className={this.state.activeMenu==="media" ? "active" : ""}>
                            <a href="/media"  >
                                <i className=" fa-media"></i>Médiatár
                                
                            </a>
                        </li>
                      
                
                    </ul>
                    
                    
                    <ul className="list-unstyled components">
                        <li  className="d-none" >
                            <a href="http://forum.muzped.hu" target="blank" >
                                <i className=" fa-forum"></i> Fórum
                                
                            </a>
                        </li>
                        <li className="d-none">
                           <i className=" fa-help "></i> <a href="/tasklist">Súgó</a>
                        </li>
                        <li  className={this.state.activeMenu==="events" ? "active" : ""}>
                         
                           <i className=" fa-history"></i> <a href="/events">Előzmények</a>
                        </li>  
                
                
                    </ul>
                
                    <ul className="list-unstyled components">
                
                
                        <li className="d-none">
                           <i className=" fa-settings"></i> <a href="#">Beállítás</a>
                        </li>
                        <li>
                           <i className=" fa-logout"> </i><a href="#" onClick={this.logOut}>Kijelentkezés</a>
                        </li>  
                
                
                    </ul>
                
                </nav>
                </div>

                );
    }
}

export default withRouter(AdminSideBar);