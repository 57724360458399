import React from 'react';
import firstQuiz from "./littleQuizComponents/firstQuiz"
import secondQuiz from "./littleQuizComponents/secondQuiz";
import RegistrationForm from "./components/RegistrationForm"
import Tour from "./littleQuizComponents/Tour"
import ReactDOM from "react-dom";
import img1 from "../images/kicsi_kviz_1/142067.jpg"
import img2 from "../images/kicsi_kviz_1/133639.jpg"
import img3 from "../images/kicsi_kviz_1/57670.jpg"
import img4 from "../images/kicsi_kviz_1/137858.jpg"
import img5 from "../images/kicsi_kviz_1/140921.jpg"
import img6 from "../images/kicsi_kviz_1/BAL-69948.jpg"
import img7 from "../images/kicsi_kviz_1/135100.jpg"
import img8 from "../images/kicsi_kviz_1/INT-00343895.jpg"
import img9 from "../images/kicsi_kviz_1/szigetvar.jpg"
import img10 from "../images/kicsi_kviz_1/Eger.jpg"
import img11 from "../images/kicsi_kviz_1/nandorfehervar.jpg"
import img12 from "../images/kicsi_kviz_1/135267.jpg"
import img13 from "../images/kicsi_kviz_1/AKG-1013151.jpg"
import img14 from "../images/kicsi_kviz_1/137835.jpg"
import img15 from "../images/kicsi_kviz_1/135191.jpg"
import ResendEmail from "./components/resendEmail"

import "../style/littlequiz.css"
class littleQuiz extends React.Component {

    constructor(props, context) {

        super(props, context);
        this.state = {
            firstQuestions: [
                { question: "Kikkel harcolt a mondáinkban Szent László király? ", answers: ["kunokkal", "franciákkal", "németekkel", "oroszokkal"], rightAnswer: 0, img: img1 },
                { question: "Melyik magyar királyt nevezték „második honalapítónak”?", answers: ["I. Szent István", "IV. Béla", "Nagy Lajos", "Luxemburgi Zsigmond"], rightAnswer: 1, img: img2 },
                { question: "Kiről írja a magyar himnusz, hogy „nyögte … bús hadát, Bécsnek büszke vára”?", answers: ["Kossuth Lajos", "II. Rákóczi Ferenc", "Mátyás király", "I. Szent István"], rightAnswer: 2, img: img4 },
                { question: "Melyik csatában halt meg Petőfi Sándor, az 1848-as szabadságharc költője?", answers: ["Segesvárnál, Erdélyben", "Nagysallónál, Csallóközben", "Pákozdnál, a Velencei tónál", "Szolnoknál, a Tisza mentén"], rightAnswer: 0 },
                { question: "Mikor volt a diadalmas tavaszi hadjárat?", answers: ["1711 tavaszán", "1815 tavaszán", "1848 tavaszán", "1849 tavaszán"], rightAnswer: 3 },
                { question: "Ki alapította meg a 13. században a mongol birodalmat?  ", answers: ["Batu kán", "Dzsingisz kán", "Gürkán", "Timur Lenk"], rightAnswer: 1, img: img6 },
                { question: "Találd ki az emojik alapján, melyik török kori várra gondoltunk!", answers: ["Eger", "Nándorfehérvár", "Szigetvár",], rightAnswer: 2, img: img9 },
                { question: "Találd ki az emojik alapján, melyik török kori várra gondoltunk!", answers: ["Eger", "Nándorfehérvár", "Szigetvár",], rightAnswer: 0, img: img10 },
                { question: "Találd ki az emojik alapján, melyik török kori várra gondoltunk!", answers: ["Eger", "Nándorfehérvár", "Szigetvár",], rightAnswer: 1, img: img11 },
                { question: "Magyarországon kívül mely ország uralkodója volt még I. Nagy Lajos? ", answers: ["Lengyelország", "Csehország", "Német-római Birodalom", "Velence"], rightAnswer: 0, img: img14 },
            ],
            answerdQuestions: 0,

            secondQuestions: [
                { question: "Melyik ország koronáján ferde a kereszt?", answers: ["Svédország", "Nagy Britannia", "Magyarország", "Spanyolország"], rightAnswer: 2, img: img5 },
                { question: "A középkori magyar történetíró, Anonymus szerint hol érkezett Árpád népe a Kárpát-medencébe?", answers: ["Törcsvári szoros", "Vöröstorony-hágó", "Békás-szoros", "Vereckei-hágó"], rightAnswer: 3, img: img7 },
                { question: "Melyik uralkodó hajóit süllyesztette el Búvár Kund Pozsonynál? ", answers: ["a velencei dózse", "Ba bizánci császár", "a szicíliai király", "a német-római császár"], rightAnswer: 3, img: img15 },
                { question: "Mikor evett paprikás krumplit Mátyás király? ", answers: ["vacsorára", "ebédre", "születésnapjn", "soha"], rightAnswer: 3, img: img3 },
                { question: "Melyik külföldi állam pénzének mintájára verték a magyar aranyforintot?", answers: ["Spanyol Királyság", "Velencei Köztársaság", "Firenzei Köztársaság", "Anglia"], rightAnswer: 2, img: img13 },
                { question: "Mi volt a 16. századi magyar lovasság legtipikusabb fegyvere? ", answers: ["kelevéz", "tornalándzsa", "dárda", "kopja"], rightAnswer: 3 },
                { question: "Melyik magyar király veretett először aranypénzt? ", answers: ["IV. Béla", "Anjou I. Károly", "Luxemburgi Zsigmond", "Hunyadi Mátyás"], rightAnswer: 1, img: img12 },
                { question: "Ki vagyok én?Amiről biztosan felismersz, hogy a legenda szerint malomkeréken szolgáltam fel az italt királyomnak. Egyébként a nagyvázsonyi várban laktam, és a legendás diadal után három halott törökkel táncoltam a győzelmi lakomán. Ulolsó hadjáratomon már nem tudtam lóra ülni és beszélni a szélütés miatt, ezért szekéren kellett vitetnem magam, de még így is legyőztem a törököket!? ", answers: ["Búvár Kund", "Thury György", "Kinizsi Pál", "IV. Béla"], rightAnswer: 2, style: [{ marginLeft: "10px", fontSize: "12px", marginTop: "-10px" }] },
                { question: "Ki alapította meg a 14. században a török birodalmat?", answers: ["Oszmán szultán", "Bajazid szultán", "Szulejmán szultán", "Murád szultán"], rightAnswer: 0, img: img8 },
                { question: "Melyik kontinensre terjedt ki a török birodalom a mohácsi csata idején, 1526-ban? ", answers: ["Ázsiára", "Európára", "Afrikára", "Ázsiára, Európára, Afrikára"], rightAnswer: 3 },
            ],
            stepIndex: 0,
            question: [],
            questionIndex: 0

        }
        this.questionClick = this.questionClick.bind(this);
        this.startGame = this.startGame.bind(this);
        this.createComponent = this.createComponent.bind(this);
        this.showPopUp = this.showPopUp.bind(this);
        this.closePopUp = this.closePopUp.bind(this);
        this.answerClick = this.answerClick.bind(this);
        this.endSession = this.endSession.bind(this);
        this.endGame = this.endGame.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.exit = this.exit.bind(this);
        this.reSendEmail = this.reSendEmail.bind(this);
    }

    exit() {

        window.location.href = 'https://www.facebook.com/thakademia';
    }

    reSendEmail(){
        document.getElementById("resend-email").classList.remove("d-none");
    }

    createComponent(component, params) {
        ReactDOM.render(
            React.createElement(component, params),
            document.getElementById('littlegame-container'),
            () => {
            }
        );
    }

    endSession(closingPopup, nextSession, nextPopup = "") {

        this.closePopUp(closingPopup);
        this.createComponent(nextSession, { questionClick: this.questionClick, endGame: this.endGame })
        this.setState({ stepIndex: this.state.stepIndex + 1 });
    }

    answerClick(selectedAnswer) {

        document.getElementById("question").classList.add("avoid-clicks");
        this.state.answerdQuestions = this.state.answerdQuestions + 1;
        this.setState({ answerdQuestions: this.state.answerdQuestions })
        document.getElementById("answer_" + this.state.question.rightAnswer).classList.add("right_answer");
        if (selectedAnswer === this.state.question.rightAnswer) {
            document.getElementById("question_" + this.state.questionIndex).classList.add("d-none");

        } else {
            document.getElementById("question_" + this.state.questionIndex).classList.add("avoid-clicks");
            document.getElementById("answer_" + selectedAnswer).classList.add("wrong_answer");
        }

        //  this.closePopUp("question_popup");

        if (this.state.answerdQuestions == 10) {
            this.closePopUp("question_popup");
            this.setState({ answerdQuestions: 0 });
            if (this.state.stepIndex === 0) {
                this.showPopUp("first_quiz_popup");
            }

            if (this.state.stepIndex === 1) {
                this.showPopUp("tour_popup");
            }
        }

    }

    questionClick(e, index) {

        if (this.state.stepIndex === 0) {
            this.state.question = this.state.firstQuestions[index];
            this.setState({ question: this.state.question, questionIndex: index });
        } else {
            this.state.question = this.state.secondQuestions[index];
            this.setState({ question: this.state.question, questionIndex: index });
        }

        var popup = document.getElementById("question_popup");
        popup.classList.remove("d-none");

        /* if ((e.pageY - (document.getElementById("question_popup").offsetHeight)) > 0) {
         popup.style.top = e.pageY - (document.getElementById("question_popup").offsetHeight) + "px";
         } else {
         popup.style.top = e.pageY + "px";
         }
         
         if (document.body.offsetWidth < e.pageX + (document.getElementById("question_popup").offsetWidth)){
         console.log("itt");
         if(((e.pageX - document.getElementById("question_popup").offsetWidth))>0)
         popup.style.left = e.pageX - (document.getElementById("question_popup").offsetWidth) + "px";
         else {
         if (e.pageX >0)
         popup.style.left = "0px";
         else 
         popup.stlye.left = "0px";
         }
         } else {
         
         popup.style.left = e.pageX + "px";
         }*/
    }

    startGame() {
        //  this.endGame();
        this.showPopUp("start_popup");
        this.createComponent(firstQuiz, { questionClick: this.questionClick })
    }

    closePopUp(popup) {
        if (document.getElementById("question"))
            document.getElementById("question").classList.remove("avoid-clicks");

        var rightAnswers = document.getElementsByClassName("right_answer");
        for (var i = 0; i < rightAnswers.length; i++) {
            rightAnswers[i].classList.remove("right_answer");
        }

        var wrongAnswers = document.getElementsByClassName("wrong_answer");
        for (var i = 0; i < wrongAnswers.length; i++) {
            wrongAnswers[i].classList.remove("wrong_answer");
        }
        document.body.classList.remove("modal-admin-body");
        document.getElementById(popup).classList.add("d-none");
    }

    showPopUp(popup) {
        document.getElementById(popup).classList.remove("d-none");
        document.body.classList.add("modal-admin-body");
    }
    componentDidMount() {



        if (window.innerHeight > window.innerWidth) {
            alert("A jobb megjelenés érdekében , kérjük fekvő módba tartsa telefonját!");
        }
        window.addEventListener("beforeunload", this.onUnload);
        document.body.classList.remove('login-body');
        document.body.classList.remove('welcome-body');
        document.getElementById("root").classList.remove("gameend-body");
        localStorage.setItem("onlineGame", true);
        let host = window.location.host;
        let parts = host.split(".");
        let subdomain = "";
        if (parts.length >= 3) {
            subdomain = parts[0];
        }


        document.body.classList.remove('theme_back');
        document.body.classList.add('muzped_back');
        document.body.classList.remove(subdomain + 'theme_back');
        document.body.classList.add(subdomain + 'muzped_back');
        document.body.classList.add('akademia-muzpedmuzped_back');
    }

    sendEmail(reSend = false) {
       
            fetch("/onlineUser/sendEmail", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ onlineUserId: localStorage.getItem("onlineUserId"), score: 0, totalScore: 0, themeId: 0, reSend: reSend })
            })
                .then(response => response.text())
                .then(response => {

                });
        
    }
    endGame() {

        this.showPopUp("end_popup");
      //  this.sendEmail();
        fetch("/score/save", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ totalScore: 0, maxScore: 0, moduleId: 0, onlineUserId: parseInt(localStorage.getItem("onlineUserId")), results: "littleQuiz", score: 0, ageId: 0 })
        })

            .then(response => {

            });
    }

    render() {
        return (
            <div id="content" className="login-body registration-body little_quiz" >
                <div className="container-fluid" >
                    <RegistrationForm afterStartGame={this.startGame} />
                    <div className="row online-header header d-none" id="online-header" style={{ position: "absolute", width: '100%', padding: "20px" }} >
                        <div className="col-sm text-left">


                        </div>

                        <div className="col-sm text-center align-baseline">
                            <h3 className="align-text-bottom"><b></b> </h3>
                        </div>
                        <div className="col-sm text-right">
                            <label className="muzped-text black_border" style={{ borderColor: "black", color: "black" }}>TÖRTÉNELMI HADI AKADÉMIA ALAPÍTVÁNY</label>

                        </div>
                    </div>
                    <div id="littlegame-container" style={{ position: "relative", maxHeight: "100vh", maxHeight: "100vh", overflow: "hidden" }} className="d-none game-container-littleQuiz" >

                    </div>

                </div>

                <div id="question_popup" class="d-none little-popup">

                    <div className={"little-popup-body " + (this.state.question.img ? "little-popup-body-img" : "")}>


                        <div className="row">
                            {this.state.question.img && <div className="col question-img-div" style={{ maxWidth: "44%" }} >
                                <img src={this.state.question.img} />
                            </div>}
                            <div className={"col " + (this.state.question.img ? "question-witdh-image" : "")} id="question" style={this.state.question.style ? this.state.question.style[0] : {}}   >
                                <label className="question_label">{this.state.question.question} </label>
                                {this.state.question.answers ? this.state.question.answers.map((answer, index) =>
                                    <label className="answer_label" id={"answer_" + index} onClick={() => this.answerClick(index)}>{answer} </label>
                                ) : ""}
                            </div>
                        </div>
                        <button id="questionpopup_close" onClick={() => this.closePopUp("question_popup")}></button>
                    </div>

                </div>

                <div id="congratulation_popup" class="d-none little-popup">
                    <p>Congratulation</p>

                </div>

                <div id="start_popup" class="d-none little-popup">
                    <div className="little-popup-body">
                        <p>Szia!</p>
                        <p>A várkapitány vagyok, örülök, hogy itt vagy!<br />
                            Az ellenség ágyúi megsebezték a várat! Csak úgy tudjuk felépíteni, amit leromboltak, ha megfejtjük a feladványokat!
                        </p>
                        <p>Segíts megjavítani a vár egyes részeit, mielőtt újabb ostrom kezdődik!</p>
                        <p>Számíthatunk rád?</p>
                        <button className="little_button" onClick={() => this.closePopUp("start_popup")}>Építsük fel a várat</button>
                    </div>
                </div>

                <div id="first_quiz_popup" class="d-none little-popup">
                    <div className="little-popup-body">
                        <p>Szép munka!</p>
                        <p>Sikerült felépíteni a várat!<br />
                            Engem, a várkapitányt már ismersz, de ismered vajon a várudvar lakóit is?
                        </p>

                        <button className="little_button" style={{ marginTop: "70px" }} onClick={() => this.endSession("first_quiz_popup", secondQuiz)}>Belépés a várudvarba</button>
                    </div>
                </div>

                <div id="second_quiz_popup" class=" d-none little-popup">
                    <div className="little-popup-body">
                        <p>Szép munka!</p>
                        <p>Sikerült felépíteni a várat!<br />
                            Engem, a várkapitányt már ismersz, de ismered vajon a várudvar lakóit is?
                        </p>

                        <button className="little_forward" style={{ position: "absolute", left: "-350px", bottom: "60px" }} onClick={() => this.endSession("second_quiz_popup", Tour, "tour_popup")}></button>
                    </div>
                </div>

                <div id="end_popup" class="d-none little-popup">
                    <div className="little-popup-body">
                        <p>Köszönjük, hogy velünk játszottál!</p>
                       
                        <p>
                        A kilépés gombra kattintva újból a regisztrációs felületre jutsz, hogy annyiszor kezdhesd újra a játékot amennyiszer csak szeretnéd, hiszen tudod: <br/>
Játék a történelem!

                        </p>
                       <ResendEmail />
                        <button className="little_button" onClick={this.exit}>Kilépés</button>
                    </div>
                </div>

                <div id="tour_popup" class="d-none little-popup">
                    <div className="little-popup-body">

                        <p>Gyere, fedezd fel az egri várat, és tudj meg izgalmas titkokat az 1552-es ostromról! <br />
                            Nézz körül és keresd a pöttyöket!
                        </p>

                        <button className="little_button" onClick={() => this.endSession("tour_popup", Tour)}>Irány az egri vár!</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default littleQuiz;