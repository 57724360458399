import React from 'react';
import AgeSelection from '../dashboardComponents/components/AgeSelection';
import ReactDOM from "react-dom";
import { withRouter, Lifecycle, browserHistory }
from "react-router";
import { Redirect, useParams, Link, Prompt, Route, BrowserRouter }
from 'react-router-dom';
import muzpedText from "../images/muzped_logo_text.png"
import muzpedIcon from "../images/muzped_icon.png"
import {ThemeService}
from "../_services/ThemeService"
import ThemesList from "../dashboardComponents/components/ThemeList"
import ThemeSelection from "../dashboardComponents/components/ThemeSelection"
import ThemeDescription from "../dashboardComponents/components/ThemeDescription"
import PublicModuleDashboard from "./PublicModuleDashboard"
import PrivacyPolicy from "./PrivacyPolicy"
import {UsePopup, PopupContext}  from '../context/popup-context'
import RegistrationForm from "./components/RegistrationForm"
import Dashboard from "./components/Dashboard"

        class publicDashboard extends React.Component {
    static contextType = PopupContext;

    constructor(props, context) {

        super(props, context);
        this.state = {
            age: localStorage.getItem("ageId"),
            name: "",
            email: "",
            submitted: false,
            privacyPolicy: false,
            isValidEmail: true,
            visibleNumbers: false,
            moduleIndex: 0,
            ageThemeNumber: 0,
            score : 0

        };
        this.startGame = this.startGame.bind(this);
        this.goToThemeList = this.goToThemeList.bind(this);
        this.getSelectedTheme = this.getSelectedTheme.bind(this);
        this.createComponent = this.createComponent.bind(this);
        this.selectTheme = this.selectTheme.bind(this);
        this.getSelectedTheme = this.getSelectedTheme.bind(this);
        this.cancelSelectTheme = this.cancelSelectTheme.bind(this);
        this.goToModules = this.goToModules.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.childRef = React.createRef();
        this.updateModuleIndex = this.updateModuleIndex.bind(this);
        this.quit = this.quit.bind(this);
        this.logout = this.logout.bind(this);
        this.updateScore = this.updateScore.bind(this);
    }

    updateScore(score){
        this.setState({score  : this.state.score+score});
    }
    logout() {
        this.context.setPopup({ok: this.context.unSetPopup, title: <div style={{textAlign: "center", paddingBottom: "20px"}}><span style={{fontSize: "20px", textAlign: "center"}}> Biztosan kilép? </span></div>, type: "delete", message: "", delete: this.quit});

    }

    handleChecked(e) {

        this.setState({privacyPolicy: e.target.checked});
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    cancelSelectTheme(type = "theme") {

        if (localStorage.hasOwnProperty("onlineGame") && type === "subTheme") {
            this.logout();
        } else {
            this.goToThemeList();
    }

    }

    quit(canQuit) {
        this.context.unSetPopup();
        if (canQuit) {
            this.setState({warning: false});
            window.location.reload(false);

        }
    }

    goToModules() {
        this.createComponent(PublicModuleDashboard, {ref: this.childRef, updateModuleIndex: this.updateModuleIndex, updateScore : this.updateScore});

    }

    updateModuleIndex(moduleIndex) {

        this.setState({moduleIndex: moduleIndex})
    }

    onUnload = (e) => { // the method that will be used for both add and remove event
        e.preventDefault();
        e.returnValue = '';
    }
    getSelectedTheme(type, theme) {

        if (type === "theme") {
            fetch("/subtheme/find/" + localStorage.getItem("themeId"))
                    .then(response => response.json())
                    .then(data => {

                        this.createComponent(ThemesList, {themes: data, selectTheme: this.selectTheme, type: "subTheme"})

                    })

        }

        if (type === "subTheme") {

//this.createComponent(ThemeDescription, {theme: theme, goToModules: this.goToModules})
            this.createComponent(Dashboard, {theme: theme, getSelectedTheme: this.getSelectedTheme, cancelSelectTheme: this.cancelSelectTheme, type: "subTheme", goToModules: this.goToModules})
        }
    }

    selectTheme(e, themeId, type) {

        if (type === "theme") {
            fetch("/theme/select/" + themeId)
                    .then(response => response.json())
                    .then(theme => {



                        fetch("/subtheme/find/" + themeId)
                                .then(response => response.json())
                                .then(data => {
                                    if (data.length > 1) {

                                        this.createComponent(ThemeSelection, {theme: theme, getSelectedTheme: this.getSelectedTheme, cancelSelectTheme: this.cancelSelectTheme, type: "theme"})

                                    } else {
                                        if (data.length === 1) {
                                            localStorage.setItem("subThemeId", data[0].id);
                                            localStorage.setItem("subTheme", JSON.stringify(data[0]));
                                            this.createComponent(ThemeSelection, {theme: data[0], getSelectedTheme: this.getSelectedTheme, cancelSelectTheme: this.cancelSelectTheme, type: "subTheme", goToModules: this.goToModules})
                                        }
                                    }

                                })
                    })

        }

        if (type === "subTheme") {
            fetch("/subtheme/select/" + themeId)
                    .then(response => response.json())
                    .then(data => {
                        localStorage.setItem("subThemeId", themeId);
                        localStorage.setItem("subTheme", JSON.stringify(data));
                        this.createComponent(ThemeSelection, {theme: data, getSelectedTheme: this.getSelectedTheme, cancelSelectTheme: this.cancelSelectTheme, type: "subTheme", goToModules: this.goToModules})
                    })

        }
    }

    goToThemeList() {
      
        fetch("/age/select/" + localStorage.getItem("ageId"))
                .then(response => response.json())
                .then(data => {

                    this.setState({age: data})
                })

        ThemeService.findUserThemes(localStorage.getItem("ageId"), 4)
                .then(themes => {
                    if (themes.length === 1) {
                        fetch("/subtheme/find/" + themes[0].id)
                                .then(response => response.json())
                                .then(data => {
                                    if (data.length > 1) {

                                        this.createComponent(ThemeSelection, {theme: themes[0], getSelectedTheme: this.getSelectedTheme, cancelSelectTheme: this.cancelSelectTheme, type: "theme"})

                                    } else {
                                        if (data.length === 1) {

                                            localStorage.setItem("themeId", themes[0].id);
                                            localStorage.setItem("subThemeId", data[0].id);
                                            localStorage.setItem("subTheme", JSON.stringify(data[0]));
                                            // this.createComponent(ThemeDescription, {theme: data[0], goToModules: this.goToModules})
                                            this.createComponent(Dashboard, {theme: data[0], getSelectedTheme: this.getSelectedTheme, cancelSelectTheme: this.cancelSelectTheme, type: "subTheme", goToModules: this.goToModules})
                                        }
                                    }

                                })
                    } else {
                        this.createComponent(ThemesList, {themes: themes, selectTheme: this.selectTheme, type: "theme"})
                    }
                });
    }

    createComponent(component, params) {
        ReactDOM.render(
                React.createElement(component, params),
                document.getElementById('game-container'),
                () => {
            if (component === PublicModuleDashboard)
                this.setState({visibleNumbers: true})
        }
        );
    }

    startGame(ages) {

        this.afterStartGame(ages);
    }
    afterStartGame = async(ages) => {

        const promises = await Promise.all(ages.map(async age => {

            const themes = await  ThemeService.findUserThemes(age.id, 4);
          
            if (themes.length > 0) {
                this.state.ageThemeNumber = parseInt(this.state.ageThemeNumber) + 1;
                this.setState({ageThemeNumber: this.state.ageThemeNumber});
                localStorage.setItem("ageId", age.id);
            }
            return themes;
        }))
        if (Promise.all(promises)) {


            if (this.state.ageThemeNumber > 1) {
                this.createComponent(AgeSelection, {goToThemeList: this.goToThemeList})
            } else {
                this.goToThemeList();
            }
        }
    }

    componentDidMount() {
        localStorage.clear();
        window.addEventListener("beforeunload", this.onUnload);
        document.body.classList.remove('login-body');
        document.body.classList.remove('welcome-body');
        document.getElementById("root").classList.remove("gameend-body");
        localStorage.setItem("onlineGame", true);
        let host = window.location.host;
        let parts = host.split(".");
        let subdomain = "";
        if (parts.length >= 3) {
            subdomain = parts[0];
        }


        document.body.classList.remove('theme_back');
        document.body.classList.add('muzped_back');
        document.body.classList.remove(subdomain + 'theme_back');
        document.body.classList.add(subdomain + 'muzped_back');
        document.body.classList.add('akademia-muzpedmuzped_back');
    }

    render() {
        return(
                <div id="content"  className="login-body registration-body" >
                    <div className="container-fluid" >
                        <RegistrationForm  afterStartGame={this.startGame}/>
                        <div className="row online-header header d-none" id="online-header" >
                
                            <div className="col text-right">
                
                                {  this.state.visibleNumbers ? <div style={{display: "inline"}}>
                                    <label className="black_border module-number module-number-online" >KÉRDÉS <b>{parseInt(this.state.moduleIndex + 1)}</b><span>
                                            {this.childRef.current.state.modules.length}</span></label>
                                    <label className="black_border module-number module-number-online modul-type-name" id="module-type-name" style={{ textTransform: "uppercase", borderRight: "none"}}>PONTSZÁM  <b>{ this.state.score}</b><span>{localStorage.getItem("totalScore")}</span></label>
                                </div> : ""}
                
                                <label className="muzped-text black_border">TÖRTÉNELMI HADI AKADÉMIA ALAPÍTVÁNY</label>
                
                
                
                            </div>
                        </div>
                        <div  id="game-container" className="d-none" >
                
                        </div>
                
                    </div>
                </div>
                );
    }
}

export default withRouter(publicDashboard);
