
import React, { Component}from "react";
import {ModuleService}from "../../_services/ModuleService";
import ModuleFormsHeader from"./ModuleFormsHeader"
import ReactPlayer from 'react-player'

        class ModuleVideoForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            itemNo: 0,
            videoLink: ""
        }
        this.subThemeThemeModal = React.createRef();
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.ModuleFormsHeader = React.createRef();

    }

    handleChange(e) {

        const {name, value} = e.target;
        this.setState({"videoLink": value});
    }

    save() {
        var error = false;


        if (!error) {
            this.props.savePreviewModule("Video", this.state.videoLink);
            this.ModuleFormsHeader.current.hideModal();

        }
    }
    componentDidMount() {

        if (this.props.moduleId != 0) {

            fetch("/modulepreview/find/" + this.props.moduleId)
                    .then(response => response.json())
                    .then(items => {
                        if (items.length > 0)
                            this.setState({"videoLink": items[0]["link"]});


                    });

        }


    }

    render() {



        return (
                <div  className="modal-dialog">
                    <section  className="modal-content" >
                        
                            <ModuleFormsHeader ref={this.ModuleFormsHeader} title="VIDEÓ" save={this.save} delete={this.props.deletePreview} context={this.props.contextModule}/>
                
                
                            <div className="modal-body" style={{ }} >
                              
                                <input  type="text" className="item-input" style={{ marginTop: "40px"}} onChange={this.handleChange} name="video_url" id="video_url" value={this.state.videoLink} placeholder="VIDEO YOUTUBE LINK MEGADÁSA" />
                
                                <div id="video-wrapper" style={{width: "100%", height: "400px"}}>
                                    <ReactPlayer url={this.state.videoLink} width="100%" height="600px" controls wrapper="video-wrapper"  />
                                    
                                </div>
                
                            </div>
                
                
                
                    </section>
                
                </div>
                );
    }
}


export default ModuleVideoForm;







