
import React, { Component}from "react";
import {ModuleService}from "../../_services/ModuleService";
import ModuleFormsHeader from"./ModuleFormsHeader"
import {UsePopup, PopupContext}  from '../../context/popup-context'
import FileUpload from "../components/FileUpload"
import * as THREE from "three";

import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {MTLLoader} from "three/examples/jsm/loaders/MTLLoader";
import ReactDOM from "react-dom";
const style = {
    height: 500,
    width: 900,
    marginTop: "20px"
};

class Module3DForm extends Component {

    static contextType = PopupContext;
    constructor(props, context) {

        super(props, context);

        this.state = {
            
            fileType: "",
            mtlFileId: 0,
            textFileId: 0,
            objFileId: 0,
            zoom: 50,
            camera: 100,
            distance: -1,
            objFileId_name: "",
            mtlfileId_name: "",
            textFileId_name: "",
            loadPercentage: 0,
            id: 0

        }

        this.openFileList = this.openFileList.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.save = this.save.bind(this);
        this.load3D = this.load3D.bind(this);
    }

    selectFile(path, fileName, id) {

        this.state[this.state.fileType] = id;
        this.setState({[this.state.fileType]: this.state[this.state.fileType]});

        this.state[this.state.fileType + "_name"] = fileName;
        this.setState({[this.state.fileType + "_name"]: this.state[this.state.fileType + "_name"]});

        this.load3D();

    }

    changeHandler(e) {
        const {name, value} = e.target;
        this.state[name] = value;
        this.setState({[name]: value});
        this.load3D();
    }

    save() {
        var error = false;

        let threeDModel = {};
        threeDModel["moduleId"] = this.props.modulePreviewId;
        threeDModel["objFileId"] = this.state.objFileId;
        threeDModel["mtlFileId"] = this.state.mtlFileId;
        threeDModel["textFileId"] = this.state.textFileId;
        threeDModel["zoom"] = this.state.zoom;
        threeDModel["camera"] = this.state.camera;
        threeDModel["distance"] = this.state.distance;
        threeDModel["id"] = this.state.id;
        if (!error) {
            this.props.savePreviewModule("3D", threeDModel);
            this.hideModal();
        }
    }

    load3D() {
        console.log(this.state.zoom);
        if (this.state.objFileId !== 0 && this.state.textFileId !== 0) {
            document.getElementById("model-div").innerHTML = "";
            this.sceneSetup();
            this.addLights();
            this.loadTheModel();
            this.startAnimationLoop();

        }
    }

    hideModal() {
        window.scrollTo({
            top: 400,
            behavior: 'smooth',
        })
        document.body.classList.remove('display-block');
        let element = document.getElementsByClassName("modal");
        element[0].classList.add("display-none");
        document.body.classList.remove("modal-admin-body");
        //window.scrollTo(0,localStorage.getItem("scrollTop"));

    }

    openFileList(fileType) {
        this.setState({"fileType": fileType})

        ReactDOM.render(
                React.createElement(FileUpload, {selectFile: this.selectFile}),
                document.getElementById('file-container')
                );
        if (document.getElementsByClassName("file-popup")) {
            document.getElementsByClassName("file-popup")[0].classList.remove("d-none");
        }
    }

    sceneSetup = () => {

        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0xc4c4c4);
        ;
        this.camera = new THREE.PerspectiveCamera(
                this.state.zoom,
                0.9, // aspect ratio
                10, // near plane
                1000
                );
        this.camera.position.z = this.state.camera;

        this.controls = new OrbitControls(this.camera, this.mount);
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(width, height);
        this.mount.appendChild(this.renderer.domElement); // mount using React ref
    }

    loadTheModel = () => {


        const texture = new THREE.TextureLoader().load('/file/downloadFile?fileId=' + this.state.textFileId);
        const text = new THREE.MeshBasicMaterial({
            map: texture,

        });

    if(this.state.mtlFileId!==0){
        const mtlLoader = new MTLLoader();
        mtlLoader.load('/file/downloadFile?fileId=' + this.state.mtlFileId,
                (materials) => {
            materials.preload();
          
            const loader = new OBJLoader();
            loader.setMaterials(materials);
            loader.load(
                    '/file/downloadFile?fileId=' + this.state.objFileId,
                    (object) => {
                object.traverse(function (node) {

                    if (node.isMesh)
                        node.material = text;

                });
                object.position.y = this.state.distance;
                this.scene.add(object);

                const el = this.scene.getObjectByName("");

                el.translateZ(1);

                this.model = el;
            },
                    // called when loading is in progresses
                            (xhr) => {

                        this.state.loadPercentage = Math.ceil(xhr.loaded / xhr.total * 100);
                        this.setState({"loadPercentage": this.state.loadPercentage})
                        //console.log((loadingPercentage) + '% loaded');

                    },
                            (error) => {


                    }
                    );

        })
    } else {
          const loader = new OBJLoader();
           
            loader.load(
                    '/file/downloadFile?fileId=' + this.state.objFileId,
                    (object) => {
                object.traverse(function (node) {

                    if (node.isMesh)
                        node.material = text;

                });
                object.position.y = this.state.distance;
                this.scene.add(object);

                const el = this.scene.getObjectByName("");

                el.translateZ(1);

                this.model = el;
            },
                    // called when loading is in progresses
                            (xhr) => {

                        this.state.loadPercentage = Math.ceil(xhr.loaded / xhr.total * 100);
                        this.setState({"loadPercentage": this.state.loadPercentage})
                        //console.log((loadingPercentage) + '% loaded');

                    },
                            (error) => {


                    }
                    );
        
    }



    }

    addLights = () => {
        const lights = [];

        // set color and intensity of lights
        lights[ 0 ] = new THREE.PointLight(0xffffff, 1, 0);
        lights[ 1 ] = new THREE.PointLight(0xffffff, 1, 0);
        lights[ 2 ] = new THREE.PointLight(0xffffff, 1, 0);

        // place some lights around the scene for best looks and feel
        lights[ 0 ].position.set(0, 2000, 0);
        lights[ 1 ].position.set(1000, 2000, 1000);
        lights[ 2 ].position.set(-1000, -2000, -1000);

    }

    startAnimationLoop = () => {
        // slowly rotate an object
        /*  if (this.model)
         this.model.rotation.y += 0.005;*/

        this.renderer.render(this.scene, this.camera);

        this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
    }

    componentDidMount() {

        if (this.props.moduleId != 0) {

            fetch("/threed/find/" + this.props.moduleId)
                    .then(response => response.json())
                    .then(threeDModel => {
                        this.state.objFileId = threeDModel.objFile;
                        this.state.mtlFileId = threeDModel.mtlFile;
                        this.state.textFileId = threeDModel.textFile;
                        this.state.zoom = threeDModel.zoom;
                       this.getName(threeDModel.objFile, "objFileId");
                       this.getName(threeDModel.mtlFile, "mtlFileId");
                       this.getName(threeDModel.textFile, "textFileId");
                        this.setState({id: threeDModel.id, objFileId: this.state.objFileId, mtlFileId: this.state.mtlFileId, 
                            textFileId: this.state.textFileId,
                            zoom: this.state.zoom,
                            camera: threeDModel.camera, 
                            distance: threeDModel.distance,
                            objFileId_name : this.state.objFileId_name
                        });
                       setTimeout(this.load3D,300);
                    });

        }


    }
    
    getName(fileId,fileType){
        
        
          fetch("/file/getname/"+fileId)
                .then(response => response.text())
                .then(response => {
                   this.state[fileType+"_name"]= response;
                   this.setState({[fileType+"_name"] : response});
                      
                });
    }

    render() {



        return (
                <div  className="modal-dialog">
                    <section  className="modal-content" >
                
                        <ModuleFormsHeader ref={this.ModuleFormsHeader} title="3D" save={this.save} delete={this.props.delete} context={this.props.contextModule}/>
                
                
                        <div className="modal-body " >
                            <div  id="memory-item-container" style={{width: "1400px", padding: "40px"}}>
                                <div className="threed-form">
                                    <label className="threed-addfile" onClick={() => this.openFileList("objFileId")}> <span style={{fontSize: "18px", fontWeight: "normal", color: "black"}}>+ OBJ fájl feltöltése</span></label><label>{this.state.objFileId_name}</label><br/>
                                    <label className="threed-addfile" onClick={() => this.openFileList("mtlFileId")}> <span style={{fontSize: "18px", fontWeight: "normal", color: "black"}}>+ MTL fájl feltöltése</span></label><label>{this.state.mtlFileId_name}</label><br/>
                                    <label className="threed-addfile" onClick={() => this.openFileList("textFileId")}> <span style={{fontSize: "18px", fontWeight: "normal", color: "black"}}>+ TEXT fájl feltöltése</span></label><label>{this.state.textFileId_name}</label><br/>
                                    <label for="zoom">ZOOM</label>  <input onChange={this.changeHandler} value={this.state.zoom} className="item-form-input" type="text" name="zoom" id="zoom"/><br/>
                                    <label for="camera">Kamera pozició</label>  <input onChange={this.changeHandler} value={this.state.camera} className="item-form-input" type="text" name="camera" id="camera"/><br/>
                                    <label for="distance">Objektum távolsága </label>  <input onChange={this.changeHandler} value={this.state.distance} className="item-form-input" type="text" name="distance" id="distance"/>
                                </div>
                                <div>
                                    <label>{"Betöltés :" + this.state.loadPercentage + " %"}</label>
                                    <div style={style} id="model-div" ref={ref => (this.mount = ref)} />
                                </div>
                            </div>
                
                        </div>
                
                
                    </section>
                
                </div>
                );
    }
}


export default Module3DForm;




