
import React from "react";
import {ModuleService} from "../../_services/ModuleService";

class ModuleMemory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            memoryCards: [],
            duplicateMemoryCards: [],
            randomizedMemoryCards: [],
            finalizedMemoryCards: [],
            openedMemoryCards: [],
            memory: [],
            time: 0,
            wrongOpenedPairs: 0,
            startDate: new Date(),
        }

    }

    componentDidMount() {
        ModuleService.getMemoryItems(localStorage.getItem('activeModuleId'))
                .then(items => {

                    this.setState({memoryCards: items});
                    this.start();


                }
                )
    }

    handleClick(name, index) {
        if (this.state.openedMemoryCards.length === 2) {
            setTimeout(() => {
                this.check()
            }, 750)
        } else {
            let memoryCard = {
                name,
                index
            }
            let finalizedMemoryCards = this.state.finalizedMemoryCards
            let memoryCards = this.state.openedMemoryCards
            finalizedMemoryCards[index].close = false
            memoryCards.push(memoryCard)
            this.setState({
                openedMemoryCards: memoryCards,
                finalizedMemoryCards: finalizedMemoryCards
            })
            if (this.state.openedMemoryCards.length === 2) {
                setTimeout(() => {
                    this.check()
                }, 750)
            }
        }
    }
    check() {
        let finalizedMemoryCards = this.state.finalizedMemoryCards
        if ((this.state.openedMemoryCards[0].name === this.state.openedMemoryCards[1].name) && (this.state.openedMemoryCards[0].index !== this.state.openedMemoryCards[1].index)) {
            finalizedMemoryCards[this.state.openedMemoryCards[0].index].complete = true
            finalizedMemoryCards[this.state.openedMemoryCards[1].index].complete = true
        } else {
            finalizedMemoryCards[this.state.openedMemoryCards[0].index].close = true
            finalizedMemoryCards[this.state.openedMemoryCards[1].index].close = true

            this.setState({wrongOpenedPairs: this.state.wrongOpenedPairs + 1});
        }
        this.setState({
            finalizedMemoryCards,
            openedMemoryCards: []
        })

        if (this.state.finalizedMemoryCards.length === this.state.finalizedMemoryCards.filter(card => card.complete === true).length) {
            var endDate = new Date();
            var seconds = (endDate.getTime() - this.state.startDate.getTime()) / 1000;

            document.getElementById("root").classList.add("gameend-body");
            var score = 0;

            switch (true) {
                case (seconds < 30) :
                    score = 100;
                    if (this.state.wrongOpenedPairs > 3)
                        score = score - this.state.wrongOpenedPairs;
                    break;
                case (seconds < 60):
                    score = 70;
                    if (this.state.wrongOpenedPairs > 3)
                        score = score - this.state.wrongOpenedPairs;
                    break;
                case (seconds < 90):
                    score = 50;
                    if (this.state.wrongOpenedPairs > 3)
                        score = score - 10;
                    break;

                default :
                    if (this.state.wrongOpenedPairs < 5)
                        score = 20;
                    else
                        score = 0;

            }

            fetch("/score/save", {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({totalScore: localStorage.getItem("totalScore"), maxScore: 100, moduleId: localStorage.getItem("activeModuleId"), onlineUserId: parseInt(localStorage.getItem("onlineUserId")), results: JSON.stringify([{"time": seconds, "wrongedPairs": this.state.wrongOpenedPairs}]), score: score, ageId: localStorage.getItem("ageId")})
            })

                    .then(response => {


                    });
            var cards = "";
            this.state.memoryCards.map(card => {
                cards += '<div class="memory-end-pic"  ><div class="endlabel"><label class="label-name">' + card.card_name + '</label></div><img    src="' + "/file/download?fileId=" + card.image_url + '" /></div>';
            })
            document.getElementById("playground").innerHTML = '<div style="margin : 0 auto;">' + score + ' ' + cards + '</div>';
            if (localStorage.hasOwnProperty("onlineGame")) {
                document.getElementById("forward_module_button").classList.remove("d-none")
            }
        }
    }
    start() {
        let finalizedMemoryCards = [];
        this.state.duplicateMemoryCards = this.state.memoryCards.concat(this.state.memoryCards)
        this.state.randomizedMemoryCards = this.shuffle(this.state.duplicateMemoryCards)
        this.state.randomizedMemoryCards.map((name, index) => {
            finalizedMemoryCards.push({
                name: name.card_name,
                image_url: name.image_url,
                close: true,
                complete: false,
                fail: false
            })
        })

        this.setState({"finalizedMemoryCards": finalizedMemoryCards});
    }
    shuffle(array) {
        let currentIndex = array.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array
    }
    render() {

        return (
                <div className="playground" id="playground">
                    {
                        this.state.finalizedMemoryCards.map((memoryCard, index) => {
                            return <Card key={index} memoryCardIndex={index} memoryCard={memoryCard} click={() => {
                                                        this.handleClick(memoryCard.image_url, index)
                                                }} close={memoryCard.close} complete={memoryCard.complete}/>
                    })
                    }
                </div>
                )
    }
}

class Card extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    clicked(memoryCard) {
        this.props.click(memoryCard)
    }
    render() {
        return (
                <div className={"memory-card" + (!this.props.close ? ' opened' : '') + (this.props.complete ? ' matched' : '')} onClick={() => this.clicked(this.props.memoryCard)}>
                    <div className="front">
                        <label>{this.props.memoryCardIndex + 1}</label>
                
                    </div>
                    <div className="back">
                        <img src={"/file/download?fileId=" + this.props.memoryCard.image_url}/>
                    </div>
                </div>
                )
    }
}


export default ModuleMemory;