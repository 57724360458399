


import React, { Component}
from "react";
import ReactDOM from "react-dom";
import { withRouter }
from "react-router";
import { Redirect, Link}
from 'react-router-dom';
import ModuleVideo from "../dashboardComponents/modules/ModuleVideo";
import ModuleQuiz from "../dashboardComponents/modules/ModuleQuiz";
import ModulePairing from "../dashboardComponents/modules/ModulePairing";
import ModulePairingText from "../dashboardComponents/modules/ModulePairing";
import ModuleVoice from "../dashboardComponents/modules/ModuleVoice";
import ModuleSwipe from "../dashboardComponents/modules/ModuleSwipe";
import ModuleMultiSwipe from "../dashboardComponents/modules/ModuleMultiSwipe";
import ModuleText from "../dashboardComponents/modules/ModuleText";
import ModuleMemory from "../dashboardComponents/modules/ModuleMemory";
import ModulePicture from "../dashboardComponents/modules/ModulePicture";
import muzpedMinta from "../images/gameend_back.png"

import ModulePanoramaTour from "../dashboardComponents/modules/ModulePanoramaTour"
import Module3D from "../dashboardComponents/modules/Module3D"
import ModulePictureQuiz from "../dashboardComponents/modules/ModulePictureQuiz"
import ModulePairingMemory from "../dashboardComponents/modules/ModulePairingMemory"
import ModuleLinkScore from "../dashboardComponents/modules/ModuleLinkScore"
import ModuleBonusScore from "../dashboardComponents/modules/ModuleBonusScore"
import ResendEmail from "./components/resendEmail"

        class PublicModuleDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            themeId: localStorage.getItem("subThemeId"),
            theme: JSON.parse(localStorage.getItem('subTheme')),
            modules: [],
            moduleCounts: 0,
            modulePreview: [],
            activeModule: "",
            nextPageLink: localStorage.getItem("nextPageLink") ? localStorage.getItem("nextPageLink") : "",
            moduleIndex: localStorage.getItem("moduleIndex") ? localStorage.getItem("moduleIndex") : 0,
            module: [],
            hasModulePreview: true,
            totalScore: 0,
            score : 0

        };
        this.playComponents = ["ModuleQuiz", "ModulePairing", "ModuleMemory", "ModulePairingText", "ModulePictureQuiz", "ModulePairingMemory", "ModulePictureQuiz", "ModuleQuizPictureAnswers"];
        this.goToNextPage = this.goToNextPage.bind(this);
        this.loadNextModule = this.loadNextModule.bind(this);
        this.goToPreviosPage = this.goToPreviosPage.bind(this);
        this.loadPreviosModule = this.loadPreviosModule.bind(this);
        this.openQuizFinal = this.openQuizFinal.bind(this);
        this.backToThemes = this.backToThemes.bind(this);
        this.createModule = this.createModule.bind(this);
        this.childRef = React.createRef();
        this.nextBtnVisibility = this.nextBtnVisibility.bind(this);
        this.setForwardBtn = this.setForwardBtn.bind(this);
        this.saveScore = this.saveScore.bind(this);
        this.exit = this.exit.bind(this);
        this.reload = this.reload.bind(this);
        this.reSendEmail = this.reSendEmail.bind(this);
    }

    reSendEmail(){
        document.getElementById("resend-email").classList.remove("d-none");
    }
   reload(){
       localStorage.clear();
       window.location.href = '/hadfiak';
   }
    exit() {
        localStorage.clear();
        window.location.href = 'https://www.facebook.com/thakademia';
    }

    saveScore(maxScore, results, score) {
        this.state.score= parseInt(this.state.score) + score;
        this.setState({score : this.state.score})
         localStorage.setItem("scores", parseInt(localStorage.getItem("scores")) + parseInt(score));
         this.props.updateScore(score);
        fetch("/score/save", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({totalScore: this.state.score, maxScore: maxScore, moduleId: localStorage.getItem("activeModuleId"), onlineUserId: parseInt(localStorage.getItem("onlineUserId")), results: results, score: score, ageId: localStorage.getItem("ageId")})
        })

                .then(response => {
                  
                  //  localStorage.setItem("scores", parseInt(localStorage.getItem("scores")) + parseInt(score));
                });


    }
    backToThemes() {
        const themeId = localStorage.getItem("themeId");
        document.getElementById("root").classList.remove("gameend-body");
        fetch("/subtheme/find/" + themeId)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 1) {
                        this.props.history.push({
                            pathname: '/subThemeList',
                            data: themeId // your data array of objects
                        })

                    } else {
                        localStorage.setItem("subThemeId", data[0].id);
                        this.props.history.push({
                            pathname: '/themeList',
                        })
                    }

                })

    }

    openQuizFinal() {

        var answers = document.getElementsByClassName('final_question_container');
        if (document.getElementsByClassName('final_question_container_closed').length > 0) {
            for (var y = 0; y < answers.length; y++) {

                answers[y].classList.add("final_question_container_open");
                answers[y].classList.remove("final_question_container_closed");
                document.getElementById("final-quiz-button").classList.add("final-quiz-button-open");
            }
        } else {
            for (var y = 0; y < answers.length; y++) {

                answers[y].classList.remove("final_question_container_open");
                answers[y].classList.add("final_question_container_closed");
                document.getElementById("final-quiz-button").classList.remove("final-quiz-button-open");
            }
        }
    }

    loadNextModule() {
        ReactDOM.unmountComponentAtNode(document.getElementById("module-container"));
        localStorage.setItem("activeModule", '');
        this.setState({"activeModule": ''});
        document.getElementById("module-next-question").classList.add("d-none");
        document.getElementById("final-quiz-button").classList.add("d-none");
        const index = parseInt(this.state.moduleIndex) + 1;

        localStorage.setItem("activeQuestionIndex", 0);
        localStorage.setItem("quizAnswers", JSON.stringify({}));

        document.getElementById("root").classList.remove("gameend-body");
        if (this.state.modules[index]) {
            fetch("/module/find/" + this.state.modules[index].moduleId)
                    .then(response => response.json())
                    .then(module => {
                        //   ReactDOM.unmountComponentAtNode(document.getElementById('module-container'));
                        this.setState({"module": module});
                        localStorage.setItem("moduleName", module.description);
                        fetch("/modulepreview/find/" + this.state.modules[index].moduleId)
                                .then(response => response.json())
                                .then(data => {

                                    this.setState({"modulePreview": data})

                                    localStorage.setItem("nextPageLink", "Module" + module.moduleType);
                                    localStorage.setItem("activeModuleId", this.state.modules[index].moduleId)
                                    this.setState({"nextPageLink": "Module" + module.moduleType});
                                    if (data[0]) {
                                        localStorage.setItem("modulePreview", JSON.stringify(data[0]));
                                        this.setState({"activeModule": 'Module' + data[0].moduleType});
                                        localStorage.setItem("activeModule", 'Module' + data[0].moduleType);
                                        this.nextBtnVisibility();
                                    } else {
                                        localStorage.setItem("modulePreview", []);

                                        this.setState({"activeModule": 'Module' + module.moduleType});
                                     //  document.getElementById("module-type-name").innerHTML=window.label[this.state.activeModule];
                                        this.nextBtnVisibility();
                                        localStorage.setItem("activeModule", 'Module' + module.moduleType);
                                        this.setState({"modulePreview": []})
                                        this.setState({"hasModulePreview": false})
                                        this.nextBtnVisibility();
                                    }

                                    this.setState({"moduleIndex": index});
                                    localStorage.setItem("moduleIndex", index);
                                    this.props.updateModuleIndex(index);
                                    this.nextBtnVisibility();

                                })
                        this.nextBtnVisibility();
                    })

        } else {
            this.nextBtnVisibility();
            document.getElementById("root").classList.add("gameend-body");
            document.getElementById("end-module-container").classList.remove("d-none");
            document.getElementById("module-container").classList.add("d-none");


            document.getElementById("end-modules-button").classList.remove("d-none");
            document.getElementById("end-modules-button2").classList.remove("d-none");
            // document.getElementById("module-middle-footer").innerHTML = '<a class="black_border gameend-back" style="text-decoration:none; padding:20px;" href="/subThemeList"><b>VISSZA</b> A TÉMA VÁLASZTÓHOZ</a>'
            // document.getElementById("module-name").innerHTML = "<span>Elvégezted az összes feladatot<span>";
            document.getElementById("end-module-container").innerHTML = '<div class="end-online-game" style="margin : auto;font-weight:bold;text-align : center;margin-top : -50px"><h1 style="font-weight:bold;font-size:63px;">...éééééééééés kész!</h1><p>Köszönjük, hogy velünk játszottál! </br>' +
                    'Hűha, te aztán otthon vagy a magyar történelemben! </p><div class="end-module-scores"><label>' + this.state.score + '</label><br/><label class="totalscore">' + localStorage.getItem("totalScore") + '</label></div><div class="label-div">* Elért pontszám</div><p>Gratulálunk! </br>' +
                    'Ugye, hogy Játék a történelem? </br> ' +
                    'Kezdd újra annyiszor a játékot, amennyiszer csak szeretnéd, hiszen tudod: </br> '+
                    'Játék a történelem! ';

            let resendEmail = document.createElement("a");
            resendEmail.textContent = "Nem kaptam meg az emailt, küldjétek újra!";
            resendEmail.classList.add("clickable");
            resendEmail.addEventListener("click",()=> this.reSendEmail());
            document.getElementById("resenddiv").appendChild(resendEmail);

          //  this.sendEmail();
            if (document.getElementsByClassName("gameend-img").length) {
                document.getElementsByClassName("gameend-img")[0].setAttribute("src", muzpedMinta)
            } else
            {

                let img = document.createElement("img");
                img.src = muzpedMinta;
                img.className = "gameend-img";
                document.getElementById("root").appendChild(img);
            }

            document.getElementById("forward_module_button").classList.add("d-none");
        }


    }

    sendEmail(reSend= false) {
        fetch("/onlineUser/sendEmail", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({reSend : reSend,onlineUserId: localStorage.getItem("onlineUserId"), score: this.state.score, totalScore : localStorage.getItem("totalScore"), themeId : localStorage.getItem("themeId")})
        })
                .then(response => response.text())
                .then(response => {

                });

    }
    loadPreviosModule() {
        ReactDOM.unmountComponentAtNode(document.getElementById("module-container"));
        localStorage.setItem("activeModule", '');
        this.setState({"activeModule": ''});
        if (document.getElementById("final-quiz-button")) {
            document.getElementById("final-quiz-button").classList.add("d-none");
        }
        const index = parseInt(this.state.moduleIndex) - 1;
        document.getElementById("root").classList.remove("gameend-body");
        document.getElementById("module-next-question").classList.add("d-none");
        localStorage.setItem("activeQuestionIndex", 0);
        localStorage.setItem("quizAnswers", JSON.stringify({}));
        if (this.state.modules[index]) {
            fetch("/module/find/" + this.state.modules[index].moduleId)
                    .then(response => response.json())
                    .then(module => {
                        // ReactDOM.unmountComponentAtNode(document.getElementById('module-container'));
                        localStorage.setItem("moduleName", module.description);
                        this.setState({"module": module});
                        fetch("/modulepreview/find/" + this.state.modules[index].moduleId)
                                .then(response => response.json())
                                .then(data => {



                                    localStorage.setItem("nextPageLink", "Module" + module.moduleType);
                                    localStorage.setItem("activeModuleId", this.state.modules[index].moduleId)


                                    if (data[0]) {
                                        this.setState({"modulePreview": data})
                                        localStorage.setItem("modulePreview", JSON.stringify(data[0]));
                                        this.setState({"nextPageLink": "Module" + module.moduleType});
                                        this.setState({"activeModule": 'Module' + data[0].moduleType});
                                        localStorage.setItem("activeModule", 'Module' + data[0].moduleType);
                                    } else {
                                        this.setState({"nextPageLink": "Module" + module.moduleType});
                                        this.setState({"activeModule": 'Module' + module.moduleType});
                                        localStorage.setItem("activeModule", 'Module' + module.moduleType);
                                        localStorage.setItem("modulePreview", []);
                                        this.setState({"hasModulePreview": false})
                                        this.setState({"modulePreview": []})
                                    }
                                    this.setState({"moduleIndex": index});
                                    localStorage.setItem("moduleIndex", index);
                                    this.props.updateModuleIndex(index);

                                })
                    })

        } else {
            // this.props.history.push("/subThemeDescription");
        }
    }
    setForwardBtn() {
        document.getElementById("forward_module_button").classList.remove("picture_quiz_next_button");
        if (this.playComponents.indexOf(localStorage.getItem('activeModule')) === -1 && document.getElementsByClassName("end-online-game").length === 0) {

            document.getElementById("forward_module_button").classList.remove("d-none")
        } else {

            document.getElementById("forward_module_button").classList.add("d-none");
        }

    }
    nextBtnVisibility() {
        setTimeout(this.setForwardBtn, 0)


    }

    goToNextPage() {
        if (document.getElementById("final-quiz-button")) {
            document.getElementById("final-quiz-button").classList.add("d-none");
        }
        
         if (document.getElementById("forward_module_button")) {
            document.getElementById("forward_module_button").classList.add("d-none");
        }

        document.getElementById("root").classList.remove("gameend-body");
        if (this.playComponents.indexOf(localStorage.getItem('activeModule')) !== -1 || localStorage.getItem("nextPageLink") == "Module") {
            this.loadNextModule();
            this.nextBtnVisibility();
        } else {
            
            this.setState({activeModule:localStorage.getItem("nextPageLink") });
            localStorage.setItem("activeModule", localStorage.getItem("nextPageLink"));
         //   document.getElementById("module-type-name").innerHTML=window.label[localStorage.getItem("nextPageLink")];
            this.setState({"activeModule": localStorage.getItem("nextPageLink")});
            this.nextBtnVisibility();
        }


    }

    goToPreviosPage() {
        if (document.getElementById("final-quiz-button")) {
            document.getElementById("final-quiz-button").classList.add("d-none");
        }
        document.getElementById("root").classList.remove("gameend-body");
        document.getElementById("module-next-question").classList.add("d-none");
        document.getElementById("end-module-container").classList.add("d-none");
        document.getElementById("module-container").classList.remove("d-none");

        //   document.getElementById("forward_button").classList.remove("invisible");
        document.getElementById("end-modules-button").classList.add("d-none");
        if (this.playComponents.indexOf(localStorage.getItem('activeModule')) !== -1 && localStorage.getItem("modulePreview")) {

            document.getElementById("end-module-container").classList.add("d-none");
            document.getElementById("module-container").classList.remove("d-none");
            document.getElementById("end-modules-button").classList.add("d-none");

            // document.getElementById("forward_button").classList.remove("invisible");
            //  document.getElementById("module-middle-footer").innerHTML = "";
            var modulePreview = JSON.parse(localStorage.getItem("modulePreview"));
            localStorage.setItem("activeModule", "Module" + modulePreview.moduleType);
            this.setState({"activeModule": "Module" + modulePreview.moduleType});
        } else {

            this.loadPreviosModule()

        }

        this.nextBtnVisibility();
    }

    componentDidMount() {

        localStorage.setItem("moduleIndex", 0);
        localStorage.setItem("activeModule", "");
        localStorage.setItem("activeModuleId", "");
        localStorage.setItem("nextPageLink", "");
        localStorage.setItem("activeQuestionIndex", 0);
        localStorage.setItem("modules", []);
        localStorage.setItem("scores", 0);
        localStorage.setItem("quizAnswers", JSON.stringify({}));
        this.state.moduleIndex = 0;
        this.setState({moduleIndex: this.state.moduleIndex});
        
         if (document.getElementById("forward_module_button")) {
            document.getElementById("forward_module_button").classList.add("d-none");
        }


        fetch("/onlineUser/getTotalScores/" + this.state.themeId)
        .then(response => response.json())
                .then(data => {
                    
                    localStorage.setItem("totalScore", data);
                });
        fetch("/thememodule/getActives/" + this.state.themeId)
                .then(response => response.json())
                .then(data => {
                    /*itt kéne össz pontszámot számolni*/
                   /* data.map(moduleData => {
                        fetch("/module/find/" + moduleData.moduleId)
                                .then(response => response.json())
                                .then(module => {
                                    fetch("/modulepreview/find/" + module.id)
                                            .then(response => response.json())
                                            .then(data => {

                                                if (data.length > 0) {

                                                    if (data[0].moduleType === "LinkScore") {
                                                        fetch("/linkscore/getLinkScore/" + data[0].id)
                                                                .then(response => response.json())
                                                                .then(items => {

                                                                    if (items.length > 0)
                                                                        this.setState({totalScore: parseInt(this.state.totalScore) + items[0].score});
                                                                        localStorage.setItem("totalScore", this.state.totalScore);


                                                                });

                                                    }
                                                    
                                                    if (data[0].moduleType === "BonusScore") {
                                                        fetch("/bonusscore/geBonusScore/" + data[0].id)
                                                                .then(response => response.json())
                                                                .then(items => {

                                                                    if (items.length > 0)
                                                                        this.setState({totalScore: parseInt(this.state.totalScore) + items[0].score});
                                                                        localStorage.setItem("totalScore", this.state.totalScore);


                                                                });

                                                    }
                                                }
                                            });

                                    if (module.moduleType === "Quiz" || module.moduleType === "PictureQuiz" || module.moduleType === "QuizPictureAnswers") {
                                        fetch("/quizquestion/find/" + module.id)
                                                .then(response => response.json())
                                                .then(questions => {

                                                    this.setState({totalScore: parseInt(this.state.totalScore) + (parseInt(questions.length) * 2)});
                                                    localStorage.setItem("totalScore", this.state.totalScore);
                                                })

                                    } else {

                                        this.setState({totalScore: parseInt(this.state.totalScore) + 2});
                                        localStorage.setItem("totalScore", this.state.totalScore);


                                    }
                                });


                    })*/
                    /*itt kéne össz pontszámot számolni*/

                    this.setState({"modules": data});
                    localStorage.setItem("modules", data);
                    if (this.state.modules.length) {

                        fetch("/module/find/" + this.state.modules[this.state.moduleIndex].moduleId)
                                .then(response => response.json())
                                .then(module => {

                                    this.setState({"module": module});
                                   localStorage.setItem("moduleName", module.description);
                                    fetch("/modulepreview/find/" + this.state.modules[this.state.moduleIndex].moduleId)
                                            .then(response => response.json())
                                            .then(data => {

                                                this.setState({"modulePreview": data})

                                                localStorage.setItem("nextPageLink", "Module" + module.moduleType);
                                                localStorage.setItem("activeModuleId", this.state.modules[this.state.moduleIndex].moduleId)

                                                if (data[0])
                                                    localStorage.setItem("modulePreview", JSON.stringify(data[0]));
                                                else
                                                    localStorage.setItem("modulePreview", []);

                                                if (this.state.activeModule == "") {
                                                    if (data[0]) {
                                                        this.setState({"activeModule": 'Module' + data[0].moduleType});
                                                        localStorage.setItem("activeModule", 'Module' + data[0].moduleType);
                                                    } else {
                                                        this.setState({"activeModule": 'Module' + module.moduleType});
                                                        this.setState({"nextPageLink": "Module" + module.moduleType});
                                                        localStorage.setItem("modulePreview", []);
                                                        this.setState({"modulePreview": []});
                                                        this.setState({"hasModulePreview": false})
                                                        localStorage.setItem("activeModule", 'Module' + module.moduleType);
                                                    }

                                                    this.nextBtnVisibility();
                                                    localStorage.setItem("moduleIndex", this.state.moduleIndex);
                                                    this.props.updateModuleIndex(this.state.moduleIndex);

                                                }

                                            })
                                })
                    }
                });
    }

    createModule() {
        const components = {"ModuleQuizPictureAnswers": ModuleQuiz,"ModuleBonusScore" : ModuleBonusScore, "ModuleLinkScore": ModuleLinkScore, "ModuleSwipe": ModuleSwipe, "ModulePairingMemory": ModulePairingMemory, "Module3D": Module3D, "ModulePictureQuiz": ModulePictureQuiz, "ModuleMultiSwipe": ModuleMultiSwipe, "ModulePanoramaTour": ModulePanoramaTour, "ModuleText": ModuleText, "ModuleVideo": ModuleVideo, "ModuleQuiz": ModuleQuiz, "ModuleVoice": ModuleVoice, "ModulePairing": ModulePairing, "ModulePairingText": ModulePairingText, "ModuleMemory": ModuleMemory, "ModulePicture": ModulePicture};
        const Component = components[this.state.activeModule];
        return <Component ref="childRef" saveScore={this.saveScore} nextModule={this.goToNextPage} onRef={ref => (this.childRef = ref)}/>;
    }

    render() {
        const {user, isOn, handleToggle, onColor} = this.state;
        const label = {"ModuleSwipe": "Swipe", "ModulePairingMemory": "Párosító memória", "ModulePictureQuiz": "Kép Kvíz", "ModulePanoramaTour": "Gömbpanoráma / Virtuális túra", "Module3D": "3D modell", "ModuleMultiSwipe": "Multi-Swipe", "ModuleVideo": "Videó", "ModuleText": "Leírás", "ModuleQuiz": "Kvíz", "ModuleVoice": "Hang", "ModulePairing": "Párosító", "ModuleMemory": "Memória", "ModulePicture": "Képek", "ModulePairingText": "Párosító"};


        return (
                <React.Fragment>
                    <div className="row d-none" style={{marginTop: '40px'}} id="end-module-container" >
                
                
                    </div>
                    <div className="row " id="module-container" >
                
                
                        {this.state.activeModule !== '' ?
                            this.createModule()
                            : ''}
                
                    </div>
                    <footer className="muzped-footer">
                    <ResendEmail />
                        <div className="container">
                            <div className="row">
                                <div className="col " id="module-middle-footer">
                                    <button onClick={this.goToPreviosPage} style={{display: "none"}}  className="btn-backward btn"></button>
                
                
                
                
                
                
                
                                    <button className="btn black_border d-none" id="final-quiz-button" style={{display: "none"}}  onClick={() => this.openQuizFinal()} >?</button>
                                    <a className="black_border gameend-back d-none" id="end-modules-button" style={{textDecoration: "none", padding: "20px",marginRight : "20px"}} href="#" onClick={this.exit}><b>KILÉPÉS</b></a>
                                     <a className="black_border gameend-back d-none"  id="end-modules-button2" style={{textDecoration: "none", padding: "20px"}} href="#" onClick={this.reload}><b>KILÉPÉS ÉS ÚJRAKEZDÉS</b></a>
                                    <div id="module-next-question" className="d-none" style={{display: "none"}}>
                                        <button  id="quiz_before_question" onClick={() => this.refs.childRef.beforeQuestion("")} style={{marginTop: "0", height: "53px", padding: "6px", }} className="theme_button theme_green_button">Előző</button>
                                        <button  id="quiz_nextquiz_question" onClick={() => this.refs.childRef.nextQuestion("")} style={{marginTop: "0", height: "53px", padding: "6px", marginLeft: "20px"}} className="theme_button theme_green_button">Következő</button>
                                    </div>
                
                                    <button  onClick={this.goToNextPage} id="forward_module_button" className="black_border"><b>Következő</b> kérdés</button>
                                </div>
                
                            </div>
                        </div>
                
                    </footer>
                </React.Fragment>

                );
    }
}

export default PublicModuleDashboard;