import React, { Component} from "react";
import { withRouter } from "react-router";
import { Redirect, Link } from 'react-router-dom';

import {UsePopup, PopupContext}  from '../../context/popup-context'

 
 
  class Dashboard extends Component {
       static contextType = PopupContext;
    constructor(props,context) {
        super(props,context);
        this.state = {
           
            themeId: localStorage.getItem("themeId"),
         

        };
      
      this.goBack= this.goBack.bind(this);
      this.goToModules = this.goToModules.bind(this);
     
     
    }
   
  
    componentDidMount() {
       
    }
    
   
    
    goBack(){
        this.props.history.push("/subThemeSelect")
    }
    
    goToModules() {
       
        if(this.props.type==="theme"){
            this.props.getSelectedTheme(this.props.type, this.props.theme)
        } else {
        localStorage.setItem("subTheme",JSON.stringify(this.props.theme));
        this.props.goToModules();
       }
    }
   
    
  

    render() {
      
        return (
               
                <div>
                <div className="dashboard-header">
                <p style={{fontSize : "40px"}}>Szia!</p>
                 <p>Üdv a Hadfiak történelmi játékban,<br/>örülök, hogy itt vagy! :)</p>
                 </div>
                   <div className="row online-dashboard-text">
                      <div className="col-9">
                      <p style={{fontSize : "22px"}}>Kezdés előtt pár dolog, <br/> amit fontos tudnod</p>
                      <p>-	a játék nem időre megy</p>
<p>-	bármilyen segédeszközt használhatsz (internetet, térképet, történelemkönyvet, egyéb könyveket, forrásokat, stb.). Ha internetet használsz, fontos, 
hogy keresés közben NE zárd be ezt az ablakot, amelyikben a játékot megnyitottad!</p>
<p>-	A kérdések közé pár különlegesebb feladatot is elrejtettünk. Ezeket nem kötelező megcsinálnod, azonban érdemes, hiszen plusz pont jár érte, nem is kevés!</p>


<p>-	TIPP: hívd segítségül <a target="_blank" href="https://www.facebook.com/thakademia/">Facebook </a> oldalunkat, ott számos kérdésre megtalálod a választ valamelyik posztunkban a #hadfiak hashtag alatt..</p>

   <p className="plus_one" style={{fontSize : "22px"}}>  <span  style={{fontSize : "35px", position:"absolute" , left : "-30px"}}>+1</span> ami a legfontosabb:<br/> érezd jól magad, hiszen tudod:<br/><b> Játék a történelem!</b></p>
                      </div>
                      <div className="col online-dashboard-buttons">
                       <button style={{paddingTop : '10px',fontWeight : "normal"}} className="theme_green_button theme_button" onClick={this.goToModules}>Mindent értek<br/><b>vágjunk bele!</b></button>
                           <button style={{background : "#0B2945",boxShadow : "rgba(0, 0, 0, 0.2) 0px 0px 10px inset !important"}} className="theme_grey_button theme_button online-tha-button" onClick={ ()=> this.props.cancelSelectTheme(this.props.type)}>Mégsem</button><br/>
                           
                      </div>
                    </div>
                            
            </div>
                 

                );
    }
}

export default Dashboard;




