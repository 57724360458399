import React, { Component }from "react";
import { withRouter }from "react-router";
import { Redirect, useParams, Link }from 'react-router-dom';
import "../../style/admin.css";
import AdminSideBar from '../AdminSideBar';
import {ThemeService} from '../../_services/ThemeService'
import {ModuleService} from '../../_services/ModuleService'
import SubThemeThemeSelectModal from './SubThemeThemeSelectModal'
import SubThemeModuleSelectModal from './SubThemeModuleSelectModal'
import SwitchComponent from "../components/SwitchComponent"
import {UsePopup, PopupContext}  from '../../context/popup-context'
import FileUpload from "../components/FileUpload"
import ReactDOM from "react-dom";
import FormHeader from "../components/FormHeader"
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';


        class SubThemeForm extends Component {
    static contextType = PopupContext;
    constructor(props, context) {
        super(props, context);
        this.state = {
            user: localStorage.hasOwnProperty("user") ? JSON.parse(CryptoAES.decrypt(localStorage.getItem('user'), "pazirik77").toString(CryptoENC)) : "",
            tasks: [],
            name: '',
            description: '',
            short_description: '',
            active: true,
            error: '',
            id: this.props.match.params.id,
            selectedTheme: [],
            selectedModules: [],
            selectedImage: null,
            selectedImgId: 0

        };

        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addTheme = this.addTheme.bind(this);
        this.selectTheme = this.selectTheme.bind(this);
        this.addModule = this.addModule.bind(this);
        this.selectModule = this.selectModule.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.deleteModule = this.deleteModule.bind(this);
        this.delete = this.delete.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.checkDelete = this.checkDelete.bind(this);
        this.cancel = this.cancel.bind(this);
        this.quit = this.quit.bind(this);
        this.checkSave = this.checkSave.bind(this);
        this.deletePicture = this.deletePicture.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.openFileList = this.openFileList.bind(this);
        this.changeModuleShort = this.changeModuleShort.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
    }

    changeModuleShort(id, short) {

        ModuleService.setShort(id, localStorage.getItem("subThemeId"), short).then(response => {
            //  this.updateModules();
            // this.props.updateModuleList();

        }

        );



    }

    handleDragLeave(e) {
        e.currentTarget.classList.remove("dragabove");
        e.currentTarget.classList.remove("dragunder");
    }
    handleDrop(event) {
        event.preventDefault();

        //  document.getElementById('modal').setAttribute("style", "height:" + document.getElementById('modal').offsetHeight + "px");

        if (event.currentTarget.getAttribute("index") !== event.dataTransfer.getData("index")) {
            //  insertAfter(event.currentTarget,event.dataTransfer.getData("element"));
            var droppedLi = document.getElementById("module-list-element_" + event.dataTransfer.getData("moduleId"));
            if (event.currentTarget.getAttribute("index") > event.dataTransfer.getData("index")) {
                event.currentTarget.parentElement.insertBefore(droppedLi, event.currentTarget.nextSibling);
            } else {

                event.currentTarget.parentElement.insertBefore(droppedLi, event.currentTarget);
            }
            this.changeModuleShort(event.dataTransfer.getData("moduleId"), event.currentTarget.getAttribute("index"));
            document.getElementById("module-list-element_" + event.dataTransfer.getData("moduleId")).setAttribute("index", event.currentTarget.getAttribute("index"));
             var changeElementIndex = event.dataTransfer.getData("index"); //2
             var spaceElementIndex = event.currentTarget.getAttribute("index"); //0
            if (parseInt(spaceElementIndex) <= parseInt(changeElementIndex)) {
              
               var listElements= document.getElementsByClassName("module-list-element");
               for(var i=0; i<listElements.length; i++){
               
                   if(parseInt(spaceElementIndex) <parseInt(listElements[i].getAttribute("index")) &&parseInt(listElements[i].getAttribute("index")) <parseInt(changeElementIndex)){
                      
                         this.changeModuleShort(listElements[i].getAttribute("moduleId"), parseInt(listElements[i].getAttribute("index")) + 1);
                      document.getElementById("module-list-element_" + listElements[i].getAttribute("moduleId")).setAttribute("index",parseInt(listElements[i].getAttribute("index")) + 1);
                       
                   }
               }
                 this.changeModuleShort(event.currentTarget.getAttribute("moduleId"), parseInt(event.currentTarget.getAttribute("index")) + 1);
                document.getElementById("module-list-element_" + event.currentTarget.getAttribute("moduleId")).setAttribute("index", parseInt(event.currentTarget.getAttribute("index")) + 1);
                
               
            } else {
                  var listElements= document.getElementsByClassName("module-list-element");
               for(var i=0; i<listElements.length; i++){
                 if(parseInt(changeElementIndex) <parseInt(listElements[i].getAttribute("index")) &&parseInt(listElements[i].getAttribute("index")) <parseInt(spaceElementIndex)){
                      
                         this.changeModuleShort(listElements[i].getAttribute("moduleId"), parseInt(listElements[i].getAttribute("index")) - 1);
                      document.getElementById("module-list-element_" + listElements[i].getAttribute("moduleId")).setAttribute("index",parseInt(listElements[i].getAttribute("index")) - 1);
                       
                   }
               }
                this.changeModuleShort(event.currentTarget.getAttribute("moduleId"), parseInt(event.currentTarget.getAttribute("index")) - 1);
                document.getElementById("module-list-element_" + event.currentTarget.getAttribute("moduleId")).setAttribute("index", parseInt(event.currentTarget.getAttribute("index")) - 1);
            }

            var higherIndexes = this.state.selectedModules.filter(module => module.short > event.currentTarget.getAttribute("index") && module.short != event.dataTransfer.getData("index") && module.short < this.state.selectedModules.length);

            higherIndexes.map(module => this.changeModuleShort(module.id, parseInt(module.short) + 1))

            var shorterIndexes = this.state.selectedModules.filter(module => module.short < event.currentTarget.getAttribute("index") && module.short != event.dataTransfer.getData("index") && module.short != 1);
            shorterIndexes.map(module => this.changeModuleShort(module.id, parseInt(module.short) - 1))
        }
        event.currentTarget.classList.remove("dragabove");
        event.currentTarget.classList.remove("dragunder");


    }

    handleDragOver(event) {

        event.stopPropagation();
        event.preventDefault();


        if (event.currentTarget.getAttribute("index") < event.dataTransfer.getData("index")) {
            event.currentTarget.classList.add("dragabove");
        }


        if (event.currentTarget.getAttribute("index") > event.dataTransfer.getData("index")) {
            event.currentTarget.classList.add("dragunder");
        }
    }

    handleDragStart(e) {


        e.dataTransfer.setData('moduleId', e.target.getAttribute('moduleid'));
        e.dataTransfer.setData('index', e.target.getAttribute('index'));
    }

    selectFile(path, fileName, id) {
        //  document.getElementById("img-url").value = path;
        this.setState({selectedImgUrl: path, selectedImgId: id});
        document.getElementById("show-image").src = "/file/download?fileId=" + id;
        document.getElementById("image-div").classList.remove("d-none");
        document.getElementsByClassName("image-label")[0].innerHTML = fileName;

    }

    openFileList() {
        ReactDOM.render(
                React.createElement(FileUpload, {selectFile: this.selectFile}),
                document.getElementById('file-container')
                );
        // document.getElementsByClassName("file-popup")[0].classList.add("d-none");
    }

    changeStatus(index, id, status) {
        this.setState({active: status});
    }

    delete(canDelete) {
        this.context.unSetPopup();
        if (canDelete) {
            fetch("/subtheme/delete/" + this.state.id)
                    .then(response => response.json())
                    .then(response => {
                        this.props.history.push("/adminsubthemelist");
                    })
        }
    }
    onFileChange(e) {

        const fileName = e.target.files[0].name;
        const formData = new FormData()
        formData.append('file', e.target.files[0]);

        formData.append('path', '/modules/' + this.state.id + "/");
        fetch("/file/save", {
            method: 'POST',

            body: formData
        })
                .then(response => response.json())
                .then(response => {

                    document.getElementById("img-url").value = "uploads/modules/" + this.state.id + "/" + fileName;
                    document.getElementById("show-image").src = "/file/download?fileName=uploads/modules/" + this.state.id + "/" + fileName;
                    document.getElementById("image-div").classList.remove("d-none");
                    document.getElementsByClassName("image-label")[0].innerHTML = fileName;

                });


    }

    deletePicture() {
        document.getElementById("img-url").value = "";
        document.getElementById("image-div").classList.add("d-none");
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});

        if (this.state.name && this.state.selectedTheme.id)
            document.getElementById("table-title-basic").classList.add("selected-table-title");

        if (this.state.short_description) {
            document.getElementById("table-title-description").classList.add("selected-table-title");
        }
    }

    deleteModule(index) {

        this.state.selectedModules.splice(index, 1);

        this.setState({"selectedModules": this.state.selectedModules});

    }
    checkDelete() {
        this.context.setPopup({id: this.state.id, name: this.state.name, ok: this.context.unSetPopup, title: <div style={{textAlign: "center", paddingBottom: "20px"}}><span style={{fontSize: "20px", textAlign: "center"}}> Biztosan törli? </span></div>, type: "delete", delete: this.delete});

    }

    cancel() {
        this.context.setPopup({ok: this.context.unSetPopup, title: <div style={{textAlign: "center", paddingBottom: "20px"}}><span style={{fontSize: "20px", textAlign: "center"}}> Biztosan kilép? </span></div>, type: "confirm", message: "(Az elvégzett módosítások nem kerülnek mentésre.)", confirm: this.quit});

    }

    quit(canQuit) {
        this.context.unSetPopup();
        if (canQuit) {
            this.props.history.push("/adminsubthemelist");
        }
    }

    checkSave() {
        var error = false;
        if (this.state.selectedTheme.length === 0) {

            error = true;
            this.context.setPopup({ok: this.context.unSetPopup, title: <span> Hiányzó elem : <b>Tematikához csatolás</b></span>, type: "error"});
            document.getElementById("table-title-basic").classList.add("error-table-title");
        }

        if (!error) {
            this.save();
        }
    }

    getFileName(fileId) {


        fetch("/file/getname/" + fileId)
                .then(response => response.text())
                .then(response => {

                    document.getElementById("imagename").innerHTML = response;

                });
    }
    save(e) {

        const {name, description, short_description, active} = this.state;
        var subTheme = JSON.stringify({
            subThemeName: name,
            subtheme_description: short_description,
            description: description,
            active: active,
            themeId: this.state.selectedTheme.id,
            img_url: document.getElementById("img-url").value,
            id: this.state.id,
            userId: this.state.user.id,
            fileId: this.state.selectedImgId,
            institutionId: localStorage.getItem("institution"),
            modules: JSON.stringify({...this.state.selectedModules})})


        ThemeService.saveSubTheme(subTheme)
                .then(
                        response => {

                            this.props.history.push("/adminsubthemelist");
                        },
                        error => this.setState({error, loading: false})
                );

    }
    async componentDidMount() {

        let tasks = [];

        if (this.state.id !== 0) {
            ThemeService.findSubThemeById(this.state.id)
                    .then(response => response.json())
                    .then(subTheme => {
                        if (subTheme) {
                            ModuleService.getSubThemeModules(this.state.id).then(modules => {
                                if (modules) {

                                    this.setState({"selectedModules": modules});
                                    document.getElementById("table-title-modules").classList.add("selected-table-title");

                                }

                            });

                            ThemeService.findById(subTheme.themeId)
                                    .then(response => response.json())
                                    .then(theme => {
                                        this.setState({"selectedTheme": theme});
                                    });

                            // document.getElementById("img-url").value = subTheme.img_url;

                            if (subTheme.fileId) {
                                document.getElementById("show-image").src = "/file/download?fileId=" + subTheme.fileId;
                                document.getElementById("image-div").classList.remove("d-none");
                                document.getElementsByClassName("image-label")[0].innerHTML = subTheme.fileId;
                                this.getFileName(subTheme.fileId);
                            }
                            this.setState({
                                name: subTheme.subThemeName,
                                description: subTheme.description,
                                short_description: subTheme.subtheme_description,
                                active: subTheme.active,
                                selectedImgId: subTheme.fileId
                            });

                            if (this.state.name && this.state.short_description && subTheme.themeId) {
                                document.getElementById("table-title-basic").classList.add("selected-table-title");
                            }

                            if (this.state.description) {
                                document.getElementById("table-title-description").classList.add("selected-table-title");
                            }
                        }
                    })
        }
    }

    componentWillMount() {
        document.body.classList.remove('login-body');
        document.body.classList.remove('welcome-body');
        document.body.classList.add('body');
        document.body.classList.add('admin-body');
    }

    addTheme() {
        let element = document.getElementsByClassName("theme-modal");
        element[0].classList.remove("display-none");
        element[0].classList.add("display-block");
    }

    addModule() {
        let element = document.getElementsByClassName("module-modal");
        element[0].classList.remove("display-none");
        element[0].classList.add("display-block");
        document.body.classList.add("modal-admin-body");
    }
    selectTheme(themeId) {

        ThemeService.findById(themeId)
                .then(response => response.json())
                .then(theme => {


                    // this.state.selectedThemes.push(theme);
                    this.setState({"selectedTheme": theme});

                    if (this.state.name && this.state.selectedTheme.id)
                        document.getElementById("table-title-basic").classList.add("selected-table-title");

                });

    }

    selectModule(moduleId) {


        ModuleService.getModule(moduleId)

                .then(module => {

                    this.state.selectedModules.push(module);
                    this.setState({"selectedModules": this.state.selectedModules});
                    document.getElementById("table-title-modules").classList.add("selected-table-title");
                });


    }
    render() {
        const {user, name, description, short_description, active} = this.state;
        if (!user.name) {
            return <Redirect to="/" />
        }

        return (
                <React.Fragment>  
                    <div id="content" className="wrapper">  
                        <AdminSideBar /> 
                        <div className="container">
                            <FormHeader checkSave={this.checkSave} checkDelete={this.checkDelete} cancel={this.cancel} title="ALTEMATIKA" />
                
                
                            <div className="row" style={{marginTop: '40px'}}>
                                <table class="table table-dark form-table " id="muzped-admin-table">
                                    <tbody>
                                        { this.state.selectedTheme.themeTitle ?
                                    <tr className="table-title" id="table-title-basic">
                                        <td scope="col" className="form-section-title"  colSpan="2"><b>01</b>   <b>Alap</b> információk
            
                                        </td>
            
            
                                    </tr>
                                                    : <tr className="table-title" id="table-title-basic">
                                                        <td scope="col" className="form-section-title"  ><b>01</b>   <b>Alap</b> információk
                            
                                                        </td>
                                                        <td className="table-title" style={{background: "#26547e", textAlign: "center"}}>
                                                            <label style={{textAlign: "right", fontSize: "18px", display: "inline", color: "white"}} onClick={this.addTheme}><b style={{paddingLeft: "0"}}>TEMATIKÁHOZ</b> CSATOLÁS</label>
                            
                                                        </td>
                            
                                                    </tr>}
                                        { this.state.selectedTheme.themeTitle ?
                                    <tr style={{background: "white"}}>
                                        <td colSpan="2">
                                            <div className="form-input-title left_border" style={{borderColor: "black"}} id="selected_age"><span className="small-text  not-required" style={{color: "black"}}>Tematikához csatolva</span></div>
                                            <label for="description" style={{marginLeft: "15px", fontSize: "20px", fontWeight: "bold", color: "black"}}>{this.state.selectedTheme.themeTitle }</label>
                                            <div className="" style={{float: "right", fontSize: "15px", height: "30px", background: "#bf4d4d", lineHeight: "30px", width: "250px", marginRight: "20px", textAlign: "center"}}><span onClick={this.addTheme}><b>MÁSIK TEMATIKÁT </b>VÁLASZTOK</span></div>
            
            
            
                                        </td>
                                    </tr>
                                                    : "" }
                                    <SubThemeThemeSelectModal onRef={ref => (this.subThemeThemeModal = ref)} selectTheme={this.selectTheme}/> 
                                    <tr> 
                                        <td colSpan="2">
                                    <SwitchComponent id={this.state.id} active={this.state.active} index={0} changeStatus = {this.changeStatus} />
                                    <input type="text" name="name" value={name} className="item-form-input" placeholder="ALTEMATIKA MEGNEVEZÉSE" onChange={this.handleChange}/>
                
                                    </td>
                
                                    </tr>
                
                
                
                                    <tr className="table-title" id="table-title-description">
                                        <td scope="col" className="form-section-title" ><b>02</b>   <b>bevezető</b> személyreszabása
                
                                        </td>
                                        <td className="table-title" style={{background: "#26547e", textAlign: "center"}}>
                                            <label style={{textAlign: "right", fontSize: "18px", display: "inline", color: "white"}} onClick={this.openFileList}>+ <b style={{paddingLeft: "0"}}>KÉP</b> CSATOLÁSA</label>
                
                                        </td>
                
                                    </tr>
                                    <tr>
                                        <td >
                                            <div className="form-input-title left_border"><span class="small-text  not-required">OPCIONÁLIS</span></div>
                                            <label for="description" style={{marginLeft: "15px", fontSize: "20px"}}>ALTEMATIKA BEVEZETŐ SZÖVEG</label>
                
                
                                            <input type="hidden" id="img-url" />
                                            <br/>
                                            <div className="form-input-title "></div>
                                            <textarea name="short_description" id="short_description" style={{width: "90%", height: "200px", marginLeft: "15px"}} className="item-form-input " value={short_description} placeholder="ALTEMATIKA BEVEZETŐ SZÖVEG" onChange={this.handleChange} />
                
                                        </td>
                                        <td>
                                            <div className="image-div d-none" id="image-div" style={{marginTop: "-175px"}}>
                                                <img id="show-image" src="" /><br/>
                                                <span className="image-label"  id="imagename"></span><br/><br/>
                                                <span className="small-text" onClick={this.deletePicture}>TÖRLÉS</span>
                                                <span className="small-text " style={{marginLeft: "15px", marginRight: "15px", borderLeft: "2px solid white"}}></span>
                                                <label className="small-text"onClick = {this.openFileList}>CSERE</label>
                                            </div>
                
                                        </td>
                                    </tr>
                                    <tr className="table-title" id="table-title-modules">
                                        <td scope="col" className="form-section-title" ><b>03</b>   <b>modulok</b> személyreszabása
                
                                        </td>
                                        <td className="table-title" style={{background: "#26547e", textAlign: "center"}}>
                                            <label style={{textAlign: "right", fontSize: "18px", display: "inline", color: "white"}} onClick={this.addModule}><b style={{paddingLeft: "0"}}>MODULOK</b> HOZZÁADÁSA</label>
                
                                        </td>
                
                                    </tr>
                                    <tr  style={{background: "white"}} className={this.state.selectedModules.length == 0 ? "display-none" : ""}>
                                        <td colSpan="2">
                                            <div id="selected-themes">
                                                { this.state.selectedModules.length ?
                                                            this.state.selectedModules.map((selectedModule, index) =>
                                        <div  id={"module-list-element_" + selectedModule.id}  index={index} draggable={true} key={selectedModule.id} 
                                              onDragLeave={e => this.handleDragLeave(e)} onDrop={e => this.handleDrop(e)} onDragStart={e => this.handleDragStart(e)} 
                                              onDragOver={e => this.handleDragOver(e)}  moduleid={selectedModule.id} className="list-table-title module-list-element" style={{marginLeft: "20px", marginRight: "20px"}}>
                                            <div className="module-list-element-title"  key={selectedModule.id} style={{width: "80%", display: "inline-block", paddingTop: "15px", paddingBottom: "15px", color: "black"}} dangerouslySetInnerHTML={{__html: selectedModule.name}}></div>
        
                                            <div className="black-link delete-link" style={{fontSize: "15px", display: "inline-block", cursor: "default"}}><span onClick={() => this.deleteModule(index)} >TÖRLÉS</span></div>
                                        </div>
                                                            ) : ""
                                                }
                                            </div>
                
                
                
                
                                        </td>
                                    </tr>
                                    <SubThemeModuleSelectModal onRef={ref => (this.subThemeModuleModal = ref)} selectedModules={this.state.selectedModules} selectModule={this.selectModule}/>  
                                    </tbody>
                
                                </table>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                );
    }
}

export default withRouter(SubThemeForm);



