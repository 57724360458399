
import React, { Component}from "react";
import {ThemeService} from "../../_services/ThemeService";
import {ModuleService} from "../../_services/ModuleService"
import ModalHeader from "../components/ModalHeader"
import ModalTable from "../components/ModalTable"

        class SubThemeModuleSelectModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
          
            modules: [],
            notSelectedModules: [],
            selectedModules : []
        }
        this.subThemeModalSelect = React.createRef();
        this.selectModule = this.selectModule.bind(this);
        this.hideModal = this.hideModal.bind(this);
       
    }

    selectModule(moduleId) {

        this.props.selectModule(moduleId);
        //this.hideModal();
        document.getElementById("module_" + moduleId).classList.add("d-none");
    }
    
     componentDidUpdate(prevProps, prevState) {
        var notSelectedModules;
      
        if (prevProps.selectedModules !== this.props.selectedModules || prevState.modules !== this.state.modules || prevState.selectedModules !== this.props.selectedModules) {
          this.setState({selectedModules : this.props.selectedModules});
        
            notSelectedModules = this.state.modules.filter(module => {
                return !this.props.selectedModules.find(element => {
                    return element.id === module.id;
                });

            });
        
            this.setState({notSelectedModules: notSelectedModules});
        }
    }

        componentWillReceiveProps(prevProps, prevState) {
        var notSelectedModules;
         
       
        if (prevProps.selectedModules !== this.props.selectedModules || prevState.modules !== this.state.modules || prevState.selectedModules !== this.props.selectedModules) {
          this.setState({selectedModules : this.props.selectedModules});
       
            notSelectedModules = this.state.modules.filter(module => {
                return !this.props.selectedModules.find(element => {
                    return element.id === module.id;
                });

            });
        
            this.setState({notSelectedModules: notSelectedModules});
        }
    }
    componentDidMount() {
        this.props.onRef(this);
         //this.setState({selectedModules : this.props.selectedModules});
        ModuleService.getModules()
                .then(modules => {
                  
                    this.state.modues= modules;
                    this.setState({modules: modules});
                })

    }

    hideModal() {
        //  document.getElementById("root").classList.remove('display-block');
        let element = document.getElementById("addmodule-modal");

        element.classList.add("display-none");
        document.body.classList.remove("modal-admin-body");
    }

    render() {



        return (
                <div  className="modal module-modal display-none" id="addmodule-modal">
                    <section  className="admin-form-modal modal-main"  >
                        <div className="" style={{width: "98%", margin: "0 auto"}}>
                            <ModalHeader title="SAJÁT MODULOK" filteritems={this.state.theme} filter={this.filter} filters={this.state.filters} hideModal={this.hideModal} />
                
                            <div className="row" style={{marginTop: '70px'}}>
                                <ModalTable tableName = "MODULOK"  itemList = {this.state.notSelectedModules}   select = {this.selectModule}/>
                
                            </div>
                        </div>
                
                    </section>
                </div>
                );
    }
}

export default SubThemeModuleSelectModal;

