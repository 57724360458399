import React from 'react';


class resendEmail extends React.Component {
  
    constructor(props, context) {
        super(props, context);
        this.state={
            email : ""
        }
       this.reSendEmail = this.reSendEmail.bind(this);
       this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(){
       
        fetch("/onlineUser/getEmail/"+localStorage.getItem("onlineUserId"))
        .then(response => response.text())
        .then(response =>{
            this.setState({email : response})
        })
    }

    handleChange (e){
        const {name,value} = e.target;
        this.setState({[name] : value});
    }
    reSendEmail(){
        document.getElementById("resend-email").classList.add("d-none");
        fetch("/onlineUser/update", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: localStorage.getItem("onlineUserId"), email : this.state.email })
        })
            .then(response => response.text())
            .then(response => {
                fetch("/onlineUser/sendEmail", {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ onlineUserId: localStorage.getItem("onlineUserId"), score: 0, totalScore: 0, themeId: 0, reSend: true })
                })
                    .then(response => response.text())
                    .then(response => {
                           
                    });
            });

    }
  render(){
    return(
        <div className="d-none" id="resend-email">
              <input id="email" className="muzped-input" name="email" value={this.state.email} onChange={this.handleChange}/>
              <button className="muzped-input" onClick={this.reSendEmail}></button>
        </div>
    )

  }
}

export default resendEmail